/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import Title from '../../title/Title';
import Autocomplete from '../../autocomplete/Autocomplete';
import TransferIcon from '../../../panel/settings/teams/updateTeamMember/img/transferIcon.svg';
import CloseButton from '../../../image/xButton.svg';
import AddRemoveInputForm from '../../addRemoveInput/addRemoveInputForm';
import { PrimaryButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import './transferOwnershipModal.css';

const TransferOwnerShipModal = (props) => {
    const {
        switchModalShow,
        openSwitchModal,
        setSwitchModalShow,
        accessPrivilege, setAccessPrivilege,
        transferOwner, setTransferOwner,
        btnConfirm,
    } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        bgcolor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 0,
        boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        borderRadius: '12px',
    };
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropDownLoading, setDropDownLoading] = useState(false);

    const stateChange = () => {
        setSwitchModalShow(!switchModalShow);
    };

    return (
        <Modal
          open={switchModalShow}
          onClose={openSwitchModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: 'blur(8px)',
            backgroundColor: '#344054B2',
          }}
        >
            <Box sx={style}>
                <div className="transfer-owner-modal-header">
                    <div className="icon-button-container">
                        <div className="icon-container">
                            <img style={{ margin: 'auto' }} src={TransferIcon} alt="transfer-owner-icon" loading="lazy" />
                        </div>
                        <div className="x-button-container">
                            <div type="button" className="user-x-button" onClick={(e) => stateChange(e)}>
                                <img src={CloseButton} alt="close-modal" />
                            </div>
                        </div>
                    </div>
                    <Title title="Transfer Ownership" deleteSubtitle titleSize="1.125rem" subtitleSize="0.875rem" gap="0.25rem" lineHeight="1.75rem">
                    When you transfer ownership of the agency workspace, you are giving the selected team member ownership access and rescinding your ownership of this agency.
                    <br />
                    <br />
                    This process is reversible, but will instead require that the new owner initiate the transfer. Be careful when selecting the team member below.
                    </Title>
                </div>
                <div className="owner-container">
                    <Autocomplete list={accessPrivilege} setSelectionList={setTransferOwner} selectionList={transferOwner} text="Select team member" isImage={false} inputValue={inputSelectonText} stateInputValue={setInputSelectonText} width="20.375rem" selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} />
                </div>
                <div className="transfer-owner-button-container">
                    <SecondaryButton label="Cancel" width="170px" onClickFunction={(e) => stateChange(e)} />
                    <PrimaryButton label="Transfer" width="170px" onClickFunction={btnConfirm} />
                </div>
            </Box>
        </Modal>
    );
};

export default TransferOwnerShipModal;
