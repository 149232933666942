/* eslint-disable no-nested-ternary */
import React, {
    useRef, useEffect, useState, useLayoutEffect,
} from 'react';
import moment from 'moment';
import MotionState from '../../motionState/MotionState';
import SpeedIndicatorField from '../../speedState/SpeedIndicatorField';
import BatteryState from '../../batteryState/BatteryState';
import ToolTips from '../../tooltip/ToolTips';
import '../Table.css';

const convertToDate = (timestamp) => {
    const cDate = moment(timestamp).format('X');
    const actualDate = moment.unix(cDate).format('DD MMMM YYYY');
    return actualDate;
};

const convertToTime = (timestamp) => {
    const cTime = moment(timestamp).format('X');
    const actualTime = moment.unix(cTime).format('hh:mm:ss A');
    return actualTime;
};

const setBatteryStatus = (voltage) => {
    let value;
    if (voltage) {
        if (voltage >= 4.0) {
            value = 'high';
        } else if (voltage >= 3.7) {
            value = 'medium';
        } else if (voltage <= 3.6) {
            value = 'low';
        } else {
            value = 'unknown';
        }
    } else {
        value = 'unknown';
    }
    return value;
};

const CustomGeolocation = ({ row }) => {
    const {
        latitude, longitude, address,
    } = row;
    const refElementLocalDisplay = useRef();
    const [hover, setHover] = useState(false);

    const compareSize = () => {
        if (refElementLocalDisplay && refElementLocalDisplay.current && refElementLocalDisplay.current.scrollWidth && refElementLocalDisplay.current.clientWidth) {
            const compare = refElementLocalDisplay.current.scrollWidth > refElementLocalDisplay.current.clientWidth;
            setHover(compare);
        }
    };
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    // remove resize listener again on "componentWillUnmount" (this for elipsis event listener)
    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);
    return (
        <div className="asset-device-column">
            <div className="main-first">{`${latitude !== null ? latitude : ''} ${latitude && longitude ? ',' : ''} ${longitude !== null ? longitude : ''}`}</div>
            <ToolTips text={address.localDisplay} disbledHover={!hover}>
                <div
                  className="secondary-second"
                  style={{
                    flex: '1 1 100%',
                    minWidth: 0,
                  }}
                >
                    <div ref={refElementLocalDisplay} className="elipsis-container">{address.localDisplay}</div>
                </div>
            </ToolTips>
        </div>
    );
};

const ColumnBatteryUpdate = ({ row }) => {
    const { dcVoltPerc, dcVolt } = row;
    // console.log(dcVoltPerc);
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div>{dcVoltPerc ? Math.floor(dcVoltPerc * 10) / 10 : '-'}</div>
            {
                // dcVoltPerc < 40 ? (<BatteryState />) : (
                //     dcVoltPerc === 0 ? (
                //         null
                //     ) : (null))
                dcVoltPerc === null ? (null) : (
                    dcVoltPerc < 40 ? (
                        <BatteryState />
                    ) : (null)
                )
            }
        </div>
    );
};

const ColumnLastUpdated = ({ row }) => {
    const { timestamp } = row;

    return (
        <div>
            <div className="main-first">
                {timestamp ? convertToDate(timestamp) : '-'}
                {' '}
            </div>
            <div className="secondary-second">
                {timestamp && convertToTime(timestamp)}
                {' '}
            </div>
        </div>
    );
};

const ColumnSpeed = (speedData) => {
    const { row, rowIndex } = speedData;
    const { kmh, kmhDifference } = row;
    // console.log(row);
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '12px' }}>{Number(kmh).toFixed(1)}</div>
            <SpeedIndicatorField kmhDifference={kmhDifference} />
        </div>

    );
};

const ColumnConnectivity = ({ row }) => {
    const { operatorName } = row;
    return (
        <div>{operatorName}</div>
    );
};

export const telemetryLogColumn = [
    {
        name: 'Date/Time',
        cell: (row) => <ColumnLastUpdated row={row} />,
    },
    {
        name: 'Geolocation',
        selector: (row) => row,
        // sortable: true,
        grow: '1.3',
        cell: (row) => <CustomGeolocation row={row} />,
    },
    {
        name: 'Motion State',
        selector: (row) => row.motionInference,
        // sortable: true,
        center: true,
        grow: '1.3',
        cell: (row) => <MotionState motionInference={row.motionInference} />,
    },
    {
        name: 'Speed (km/h)',
        selector: (row) => row.speed,
        // sortable: true,
        // grow: '1.1',
        center: true,
        cell: (row, rowIndex) => (
            <ColumnSpeed row={row} rowIndex={rowIndex} />
        ),
    },
    {
        name: 'Battery level (%)',
        // selector: (row) => row.,
        // sortable: true,
        cell: (row) => <ColumnBatteryUpdate row={row} />,
        center: true,
        // cell: (row) => <CustomLastUpdated row={row} />,
    },
    {
        name: 'Connectivity',
        cell: (row) => <ColumnConnectivity row={row} />,
        selector: (row) => row.operatorName,
        // sortable: true,
        center: true,
        // grow: '1.1',
    },
];
