import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Text from '../../../../component/text/Text';
import ColoredLine from '../../../../component/coloredLine/ColoredLine';
import Autocomplete from '../../../../component/autocomplete/Autocomplete';
import { BackButton, PrimaryButton, SecondaryButton } from '../../../../component/buttonComponent/ButtonComponent';
import GeofenceAlert from '../../../../component/notificationAlertModules/GeofenceAlert';
import './NotificationConfigurationDetails.css';

const NotificationConfigurationDetails = () => {
    const location = useLocation();
    const detailsRow = location.state ? location.state.row : null;
    const bboxPolygon = location.state ? location.state.bboxPolygon : null;
    const navigate = useNavigate();
    const [disableMainButton, setDisableMainButton] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [inputLoading, setInputLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectionList, setSelectionList] = useState({});
    const [dropDownLoading, setDropDownLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [alertTypeList, setAlertTypeList] = useState([{
            id: 0,
            name: 'Geofence',
        },
    ]);

    const backToSearch = () => {
        navigate('/notifications/configuration', { replace: true });
    };

    const backButton = () => {
        navigate('/notifications/configuration', { replace: true });
    };

    const switchBody = (value) => {
        switch (value) {
            case 0:
                return (
                    <>
                        <GeofenceAlert detailsRow={detailsRow} bboxPolygon={bboxPolygon} selectionList={selectionList} />
                    </>
            );
            default:
                return (
                    <></>
                );
        }
    };

    useEffect(() => {
        if (location.state) {
            const selected = alertTypeList.filter((x) => x.name === location.state.row.alertType);
            setSelectionList(selected[0]);
            setSelectedOption(selected[0].id);
        }
    }, []);

    return (
        <div className="notification-config-details-container">
            <div className="notification-config-details-back-button">
                <BackButton label="Back to alert configurations" btnClickBack={backToSearch} color="#FFFFFF" />
            </div>
            <div className="notification-config-details-header">
                <div className="notification-config-details-container-button">
                    <Text
                      text="Create alert"
                      weight="600"
                      size="1.125rem"
                      color="#101828"
                    />
                    {/* <div className="notitfication-config-details-button">
                        <SecondaryButton label="Cancel" onClickFunction={backButton} />
                        <PrimaryButton label={detailsRow ? 'Update' : 'Create'} onClickFunction={finalCreateButton} disable={!disableMainButton} loading={btnLoading} />
                    </div> */}
                </div>
                <ColoredLine />
                <div className="notification-config-body-container">
                    <div className="notification-config-body-text">
                        Alert Type
                    </div>
                    <Autocomplete
                      component="notificationDetails"
                      width="32rem"
                      list={alertTypeList}
                      selectionList={selectionList}
                      setSelectionList={setSelectionList}
                      text="Select alert type"
                      isAgencySelector={false}
                      isImage={false}
                      inputValue={inputValue}
                      stateInputValue={setInputValue}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      dropDownLoading={dropDownLoading}
                      setDropDownLoading={setDropDownLoading}
                      loadAsset={inputLoading}
                    />
                </div>
            </div>
            <div className="notification-config-details-body">
                {switchBody(selectionList.id)}
            </div>
        </div>
    );
};

export default NotificationConfigurationDetails;
