/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useMemo, useEffect } from 'react';
import './modal.css';
import Cookies from 'js-cookie';
import ReactCrop from 'react-image-crop';
import { PrimaryButton, SecondaryButton } from '../buttonComponent/ButtonComponent';
import SwitchAgency from './img/switchAgencyIcon.svg';
import CropImage from './img/cropImageIcon.svg';
import CloseButton from '../../image/xButton.svg';
import Title from '../title/Title';
import Autocomplete from '../autocomplete/Autocomplete';
import SwitchAgencyModal from './SwitchAgencyModal/SwitchAgencyModal';
import InviteUsersModal from './InviteUsersModal/InviteUsersModal';
import DeleteModal from './deletelModal/DeleteModal';
import TransferOwnerShipModal from './transferOwnership/TransferOwnershipModal';
import CropImageModal from './CropImageModal/CropImageModal';
import LogoutWarn from './LogoutWarn/LogoutWarn';
import SecurityLogout from './SecurityLogout/SecurityLogout';
import GenerateReport from './GenerateReport/GenerateReport';

export default function Modal(props) {
    const {
        switchModalShow,
        setSwitchModalShow,
        btnConfirm,
        openSwitchModal,
        module,
        dataRow,
        source,
        accessPrivilege, setAccessPrivilege,
        type, files, setOutput, output,
        setUpdateEmailLogout,
        emailList, setEmailList,
        selectionList, setSelectionList,
        transferOwner, setTransferOwner,
        subtitle,
        agencyData,
        errorState,
        triggerError,
        setTrigerError,
        agencyName,
        resetBtn,
} = props;

    const modalRender = (value) => {
        switch (value) {
            case 'SwitchAgency':
                return <SwitchAgencyModal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} btnConfirm={btnConfirm} />;
            case 'InviteUsers':
                return <InviteUsersModal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} emailList={emailList} setEmailList={setEmailList} btnConfirm={btnConfirm} selectionList={selectionList} setSelectionList={setSelectionList} source={source} agencyData={agencyData} agencyName={agencyName} />;
            case 'DeleteModal':
                return <DeleteModal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} btnConfirm={btnConfirm} dataRow={dataRow} source={source} errorState={errorState} triggerError={triggerError} setTrigerError={setTrigerError} />;
            case 'TransferOwnership':
                return <TransferOwnerShipModal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} accessPrivilege={accessPrivilege} setAccessPrivilege={setAccessPrivilege} btnConfirm={btnConfirm} transferOwner={transferOwner} setTransferOwner={setTransferOwner} />;
            case 'CropImage':
                return <CropImageModal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} type={type} files={files} setOutput={setOutput} output={output} />;
            case 'LogoutWarn':
                return <LogoutWarn switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} setUpdateEmailLogout={setUpdateEmailLogout} />;
            case 'Security':
                return <SecurityLogout switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} btnConfirm={btnConfirm} subtitle={subtitle} />;
            case 'GenerateReport':
                return <GenerateReport switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} resetBtn={resetBtn} />;
            default:
                return null;
        }
    };

    return (
        modalRender(module)
    );
}
