import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from '../../config/reducer/Auth';
import ImgWithTitle from '../../component/workspaceContainer/ImgWithTitle';
import Text from '../../component/text/Text';
import { TextButton, BackButton, PrimaryButton } from '../../component/buttonComponent/ButtonComponent';
import './reset-password.css';

const InvalidLink = ({ history }) => {
    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    const navigate = useNavigate();
    const btnBack = (e) => {
        e.preventDefault();
        navigate('/login', { replace: true });
    };

    return (
        <>
            {isMobileOrTablet && (
                <section className="forgotPW-mainMobile">
                    <div className="forgot-password-container-main-mobile">
                        <div className="forgot-password-content-mobile">
                            <ImgWithTitle titleText="Reset Password Link" subText="Your request to reset your password has expired, the link has already been used, or the link does not exist." icon="Invalid" style={{ width: '100%' }} />
                            <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                        </div>
                    </div>
                </section>
            )}
            {isDefault && (
                <div className="forgot-password-container-main">
                    <div className="forgot-password-container">
                        <ImgWithTitle titleText="Reset Password Link" subText="Your request to reset your password has expired, the link has already been used, or the link does not exist." icon="Invalid" />
                        <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                    </div>
                </div>
            )}
        </>
  );
};

export default InvalidLink;
