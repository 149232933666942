import React from 'react';
import PropTypes from 'prop-types';
import './progressBar.css';

const ProgressBar = (props) => {
    const {
        value,
        max,
        color,
        width,
    } = props;

    return (
        <div className="progress-bar-row">
            <div className="progress-bar">
                <progress value={value} max={max} />
            </div>
            <div className="progress-bar-value">
                {
                    // value ? `${(value / max) * 100}%` : '0%'
                    value ? `${Math.round((value / max) * 100)}%` : '0%'
                }

            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
    max: PropTypes.number,
};

ProgressBar.defaultProps = {
    max: 100,
};

export default ProgressBar;
