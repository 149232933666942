/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
import React, {
 useState, useEffect, useContext, useRef, useMemo, useCallback,
} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Cookies from 'js-cookie';
import moment from 'moment';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  CssBaseline, Skeleton, SwipeableDrawer, Typography,
} from '@mui/material';
import { Global } from '@emotion/react';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import SnackBar from '../../../component/snackbar/Snackbar';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import LiveIcon from '../../../image/liveIcon.svg';
import Title from '../../../component/title/Title';
import AssetTabFilter from '../../../component/assetTab/AssetTabFilter';
import FilterButton from '../../../component/filterButton/FilterButton';
import MapComponent from '../../../component/map/MapComponent';
import Autocomplete from '../../../component/autocomplete/Autocomplete';
import AssetCardDetails from '../../../component/assetDetailsCard/AssetDetailCard';
import AssetDetailCardMobile from '../../../component/assetDetailsCard/AssetDetailCardMobile';
import {
 APIGetV3Payload, APIGetDevices,
} from '../../../config/restAPI/FetchAPI';
import { WebsocketContext } from '../../../config/reducer/Websocket';
import { AuthContext } from '../../../config/reducer/Auth';
// import { liveNavigationList } from '../../../config/function/tabNavigationList';
import './mapOverview.css';
import { onDataChangeWS } from '../../../config/function/websocketFlyTo';
import { useApiData } from '../../../config/reducer/Modules';
import { APIWSDisconnect } from '../../../config/restAPI/DeleteAPI';
import { APIWSConnect } from '../../../config/restAPI/PostAPI';

const seperator = [
  <img src={LiveIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
  <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Map Overview</div>,
];

const drawerBleeding = 130;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#FFF' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

moment.updateLocale('en', {
  relativeTime: {
      future: (diff) => (diff === 'a few second(s) ago' ? diff : `in ${diff}`),
      past: (diff) => (diff === 'a few second(s) ago' ? diff : `${diff}`),
      s: 'a few second(s) ago',
      ss: 'a few second(s) ago',
      m: 'a minute ago',
      mm: '%d minutes ago',
      h: 'an hour ago',
      hh: '%d hours ago',
      d: 'a day ago',
      dd: '%d days ago',
      w: 'a week ago',
      ww: '%d weeks ago',
      M: 'a month ago',
      MM: '%d months ago',
      y: 'a year ago',
      yy: '%d years ago',
  },
});
moment.relativeTimeThreshold('s', 59);
moment.relativeTimeThreshold('m', 60);

const MapOverview = ({ history }) => {
  const isDefault = useMediaQuery({
    query: '(min-width: 1024px)',
});
const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 1023px)',
});
  const mapRefs = useRef();
  const { state } = useLocation();
  const [payloadState, setPayloadState] = useState('' || state);
  const payloadContext = useContext(WebsocketContext);
  const {
    setChannel, data: websocketData, newPayloadId, selectAsset, closeAssetCard, selectedAsset, CloseConnection,
  } = payloadContext;
  const context = useContext(AuthContext);
  const { apiResponse } = useApiData();
  const navigate = useNavigate();
  const token = Cookies.get('jwtToken');
  const agencyID = Cookies.get('agency-id');
  const agencyOid = Cookies.get('agencyUUID');
  const [loading, setLoading] = useState(false); // for getPayload loader
  const [loadAsset, setLoadAsset] = useState(false); // for getSearchAsset loader
  const [mapClickState, setMapClickState] = useState({});
  const [assetClick, setAssetClick] = useState('allAsset');
  const [navigtionClick, setNavigtionClick, navigationState, setNavigationState, idleState, setIdleState] = useOutletContext();
  const [listPoint, setListPoint] = useState([]); // this is for map marker
  const [clusterPoint, setClusterPoint] = useState([]);
  const [markerValue, setMarkerValue] = useState({}); // this for asset details card value (in asset detail card)
  const [popUpMarker, setPopUpMarker] = useState({});
  const [assetCardDisp, setAssetCardDisp] = useState(false); // this to display asset detail card state
  const [timer, setTimer] = useState('');
  const [timeStampDisplay, setTimeStampDisplay] = useState(''); // this for timestampdisplay in assetDetails card
  const [lastSeenTimestamp, setLastSeenTimestamp] = useState(''); // this for lastSeenTimestamp in asset details card
  const [assetSelectionList, setAssetSelectionList] = useState({}); // this for asset selection state
  const [listAsset, setListAsset] = useState([]); // this is for list search asset dropdown
  const [allAsset, setAllAssets] = useState([]);
  const [oneTimeLoad, setOneTimeLoad] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
  const [inputSelectonText, setInputSelectonText] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [emptyCardValue, setEmptyCardValue] = useState();
  const [viewMapping, setViewMapping] = useState({
    longitude: 101.587140,
    latitude: 3.085870,
    zoom: 14,
  });
  const [dropDownLoading, setDropDownLoading] = useState(false);

  const [open, setOpen] = useState(false);

  // const [idleState, setIdleState] = useState(false);
  const [idleCount, setIdleCount] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  const onIdle = async () => {
    setIdleState(true);
    const websocketDisconnected = await APIWSDisconnect(token, agencyOid);
    if (websocketDisconnected.status === 200) {
      CloseConnection('disconnect');
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 30_0000,
    // timeout: 15_000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // const drawerRef = useRef(null);
  const [drawerRef, setDrawerRef] = useState(undefined);

  const container = window !== undefined ? () => window.document.body : undefined;

  const liveNavigationList = [
    {
        text: 'Map Overview', value: 1, path: '/live/mapOverview', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.liveMap,
    },
    {
        text: 'Telemetry Snapshot', value: 2, path: '/live/telemetrySnapshot', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.telemetrySnapshot,
    },
    // {
    //     text: 'Asset Status', value: 3, path: '/live/assetStatus', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.assetStatus,
    // },
];
  // console.log('websocket', websocketData);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  // this function to change it to points with
  const markerPointFromAPI = (value) => {
    const pointsMapping = value.map((docs) => {
        if ((docs.latitude !== null || docs.longitude !== null) && (docs.latitude >= -90 && docs.latitude <= 90) && (docs.longitude >= -180 && docs.longitude <= 180) && (docs.snapshotState !== 'removed')) {
            return docs;
        }
          return null;
    });
    return pointsMapping;
  };

  const clusterPointFromAPI = (value) => {
    const clusterMapping = value.map((docs) => {
      if ((docs.latitude !== null || docs.longitude !== null) && (docs.latitude >= -90 && docs.latitude <= 90) && (docs.longitude >= -180 && docs.longitude <= 180) && (docs.snapshotState !== 'removed')) {
        return ({
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [docs.longitude, docs.latitude] },
        });
    }
      return null;
    });
    return clusterMapping;
  };

  // this is for the "last updated"
  const settingTimestamp = (timestamps) => {
    setTimeStampDisplay(moment.unix(timestamps).fromNow());
  };

  const settingTimestampLocation = (time) => {
      const timestamp = moment(time).format('X');
      const realTimestamp = moment.unix(timestamp).fromNow();
      return realTimestamp;
  };

  const timerFunction = (timeStamps) => {
    if (!loading) {
      clearInterval(timer);
      const newInterval = setInterval(() => settingTimestamp(timeStamps), 1000);
      setTimer(newInterval);
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, [timer]);

  const APISearchDevices = async (inputText) => {
    try {
      const params = {
        perPage: null,
        totalPerPage: 200,
        // deviceSerial: `CONTAINS,${inputText}`,
        searchKey: inputText,
      };
      const result = await APIGetDevices(setLoadAsset, token, agencyOid, params);
      // console.log(result);
      if (result.status === 200) {
        const { data } = result.data;
        const asset = data && data.length > 0 ? data.map((x) => ({
          uuid: x.oid,
          id: x.assetName,
          name: x.deviceSerial ? x.deviceSerial : '',
          pairId: x.deviceType,
          pairedAssets: '',
        })) : [];
        const sortCompare = asset.length > 0 ? _.sortBy(asset, 'name') : [];
        setAllAssets(sortCompare);
        setListAsset(sortCompare);
        setLoadAsset(false);
      } else {
        setAllAssets([]);
        setListAsset([]);
        setLoadAsset(false);
      }
    } catch (err) {
      // console.log('err', err);
      setLoadAsset(false);
    }
  };

  // this to called the getPayload API and return the let./
  const APIV3CalledGetPayload = async (deviceOid) => {
    let returnResult = null;
    try {
      const result = await APIGetV3Payload(setLoading, token, agencyOid, deviceOid);
      // console.log('return result', result);
      if (result.data.status === 200) {
        const { data } = result.data;
        // console.log('this called from APIV3CalledGetPayload', data);
        returnResult = data;
      } else {
        returnResult = null;
      }
      return returnResult;
    } catch (err) {
      // console.log('error', err);
      return returnResult;
    }
  };

  // new payload from websocket
  const onNewSubscribeData = async (id) => {
    if (id && (Array.isArray(listPoint) || listPoint)) {
      const cloneData = [...listPoint];
      const newData = cloneData.map((x) => ({ ...x })); // Shallow copy of cloneData

      if (websocketData.type === 'telemetry') {
        if (cloneData.filter((x) => x.deviceOid === websocketData.data.deviceOid).length < 1) { // if _id not exist in payloadData yet
            if (assetClick !== 'allAsset') {
                if (websocketData.data.motionInference === 200) {
                    newData.push(websocketData.data);
                    const filteredList = allAsset.filter((elem) => {
                      return newData.some((ele) => {
                        return ele.deviceOid === elem.uuid;
                      });
                    });
                    setListAsset(filteredList); // filter out redundant data so no replication
                }
            } else {
                cloneData.push(websocketData.data);
            }
        } else { // if the device is already on the map
            const existingIndex = newData.findIndex((x) => x.deviceOid === websocketData.data.deviceOid);
            if (existingIndex !== -1) {
              if (existingIndex !== -1) {
                if (assetClick === 'allAsset' || websocketData.data.motionInference === 200) {
                    newData[existingIndex] = websocketData.data;
                    if (assetClick !== 'allAsset') {
                        const filteredList = listAsset.filter((elem) => {
                            return newData.some((ele) => {
                                return ele.deviceOid === elem.uuid;
                            });
                        });
                        setListAsset(filteredList);
                    }
                } else {
                    newData.splice(existingIndex, 1);
                    const filteredList = listAsset.filter((elem) => {
                        return newData.some((ele) => {
                            return ele.deviceOid === elem.uuid;
                        });
                    });
                    setListAsset(filteredList);
                    setAssetCardDisp(false);
                }
            }
            }
        }
      }
      const filteredData = newData.filter((x) => x.latitude || x.longitude);
      setListPoint(filteredData);
      setClusterPoint(clusterPointFromAPI(filteredData));
    }
    // this is to get the updated data in asset card details on websocket
    if (assetCardDisp) {
      if (websocketData.type === 'telemetry') {
        if (newPayloadId && Object.keys(markerValue).length > 0 && markerValue.deviceOid === websocketData.data.deviceOid) {
          const realTimeStamp = moment(websocketData.data.timestamp).format('X');
          settingTimestamp(realTimeStamp);
          timerFunction(realTimeStamp);
          setMarkerValue(websocketData.data);
          onDataChangeWS(websocketData.data, mapRefs);
          setLastSeenTimestamp(Object.keys(websocketData.data).length > 0 ? settingTimestampLocation(websocketData.data.lastSeenTimestamp) : '');
        }
      }
    } else {
      setMarkerValue({});
      setTimeStampDisplay('');
      clearInterval(timer);
    }
  };

  // this to display all markerFunction;
  const allMarkerDisplayFunction = (payload, tabs) => {
    try {
      const convertGeo = markerPointFromAPI(payload);
      const filterGeo = convertGeo.filter((x) => x !== null);
      const assetList = assetClick === 'allAsset' ? filterGeo : filterGeo.filter((y) => y.motionInference === 200);
      if (assetClick !== 'allAsset') {
        if (assetClick === 'movingAsset') {
          const filteredList = listAsset.filter((elem) => {
            return assetList.some((ele) => {
              return ele.deviceOid === elem.uuid;
            });
          });
          setListAsset(filteredList);
        }
        if (Object.keys(markerValue).length > 0 && markerValue.motionInference !== 200) {
          setMarkerValue({});
          setAssetCardDisp(false);
          setSelectedOption(null);
          setAssetSelectionList({});
        }
      }
      if (assetClick === 'allAsset') {
        APISearchDevices('');
      }
      setListPoint(assetList);
      tabs && setOneTimeLoad(true);
      clearInterval(timer);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('allMarkerDisplayFunctionError');
    }
  };

  // this function is for onClick from dropdownlist (device)
  const assetSelectionDisplayCardFunction = (payload, valueMarker) => {
    try {
      // console.log('called');
      if (payload === null) {
        clearInterval(timer);
        setTimeStampDisplay('');
        setAssetCardDisp(true);
        setMarkerValue({});
        setLoading(false);
        setOpen(true);
      } else if (payload.length > 0) {
        if (idleState) {
          const realTimeStamp = moment(valueMarker[0].timestamp).format('X');
          onDataChangeWS(valueMarker[0], mapRefs);
          settingTimestamp(realTimeStamp);
          timerFunction(realTimeStamp);
          setMarkerValue({
            ...payload[0],
            timestamp: valueMarker[0].timestamp,
            longitude: valueMarker[0].longitude,
            latitude: valueMarker[0].latitude,
            address: valueMarker[0].address,
            battVolt: valueMarker[0].battVolt,
            heading: valueMarker[0].heading,
            motionInference: valueMarker[0].motionInference,
            operatorName: valueMarker[0].operatorName,
            signalQuality: valueMarker[0].signalQuality,
            kmh: valueMarker[0].kmh,
          });
          setPopUpMarker(valueMarker[0]);
        } else {
          const realTimeStamp = moment(payload[0].timestamp).format('X');
          onDataChangeWS(payload[0], mapRefs);
          settingTimestamp(realTimeStamp);
          timerFunction(realTimeStamp);
          setMarkerValue(payload[0]);
          setPopUpMarker(payload[0]);
        }
        setLastSeenTimestamp(payload.length > 0 ? settingTimestampLocation(payload[0].lastSeenTimestamp) : '');
        setAssetCardDisp(true);
        setLoading(false);
        setOpen(true);
      } else {
        clearInterval(timer);
        setTimeStampDisplay('');
        setAssetCardDisp(true);
        setMarkerValue({});
        setLoading(false);
        setOpen(true);
      }
    } catch (err) {
      setLoading(false);
      clearInterval(timer);
      setTimeStampDisplay('');
      console.log('assetSelectionDisplayCardFunctionError', err);
    }
  };

  // this function to clicked marker.
  const assetMarkerClickedFromMap = (payload, valueMarker) => {
    try {
      // console.log('go here onMapClick');
      setAssetCardDisp(true);
      if (idleState) {
        const realTimeStamp = moment(valueMarker.timestamp).format('X');
        settingTimestamp(realTimeStamp);
        timerFunction(realTimeStamp);
        setMarkerValue({
          ...payload[0],
          timestamp: valueMarker.timestamp,
          longitude: valueMarker.longitude,
          latitude: valueMarker.latitude,
          address: valueMarker.address,
          battVolt: valueMarker.battVolt,
          heading: valueMarker.heading,
          motionInference: valueMarker.motionInference,
          operatorName: valueMarker.operatorName,
          signalQuality: valueMarker.signalQuality,
          kmh: valueMarker.kmh,
        });
        onDataChangeWS(valueMarker, mapRefs);
      } else {
        const realTimeStamp = moment(payload[0].timestamp).format('X');
        settingTimestamp(realTimeStamp);
        timerFunction(realTimeStamp);
        setMarkerValue(payload[0]);
        onDataChangeWS(payload[0], mapRefs);
      }
      setLastSeenTimestamp(payload.length > 0 ? settingTimestampLocation(payload[0].lastSeenTimestamp) : '');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      clearInterval(timer);
      setTimeStampDisplay('');
      console.log('assetMarkerClickedFromMap', err);
    }
  };

  // this to called websocket
  useEffect(() => {
    onNewSubscribeData(newPayloadId);
  }, [newPayloadId]);

  useEffect(() => {
    if (Object.keys(markerValue).length > 0) {
      if (selectedAsset.deviceOid !== markerValue.deviceOid) {
        clearInterval(timer);
        setTimeStampDisplay('');
      }
    }
  }, [selectedAsset]);

  // this useEffect for onPageLoad
  useEffect(() => {
    if (token) {
      APISearchDevices('');
      APIV3CalledGetPayload().then((x) => allMarkerDisplayFunction(x, true)).catch((e) => console.log(e));
    }

    function handleWindowResize() {
        setWindowSize(getWindowSize());
    }
    setNavigtionClick(1);
    setNavigationState('mapOverview');
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // this useEffect, are if the list selected in dropdown it will trigger the APICalledPayload
  useEffect(() => {
    if (Object.keys(assetSelectionList).length > 0) {
      // APIv3CalledPayload(assetSelectionList.uuid, false);
      if (idleState) {
        // APIV3CalledGetPayload(assetSelectionList.uuid).then((x) => )
        const markerOnMap = listPoint.filter((x) => x.deviceOid === assetSelectionList.uuid);
        APIV3CalledGetPayload(assetSelectionList.uuid).then((x) => assetSelectionDisplayCardFunction(x, markerOnMap)).catch((e) => console.log('Error getting payload'));
      } else {
        APIV3CalledGetPayload(assetSelectionList.uuid).then((x) => assetSelectionDisplayCardFunction(x)).catch((e) => console.log('Error getting payload'));
      }
      // this selectAsset are store for websocket context
      selectAsset({ deviceOid: assetSelectionList.uuid, getAssetInfo: true });
    }
    // APICalledSearchAssetNGroup(); // will come back
  }, [assetSelectionList]);

  // this useEffect for calling all asset tabs and assets in motion
  useEffect(() => {
    if (assetClick) {
      APIV3CalledGetPayload().then((x) => allMarkerDisplayFunction(x, false)).catch((e) => console.log('Error'));
    }
  }, [assetClick]);

  // this useEffect for call the searchAsset API called. Interval for 0.5 sec
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout); // this to clear time out
    }
    setSearchTimeout( // to buffer the search by 0.5 second
      setTimeout(() => {
        // APICalledSearchAssetNGroup(); // call the api
        APISearchDevices(inputSelectonText);
      }, 500),
    );
    return () => clearTimeout(searchTimeout); // this also to clear time out
  }, [inputSelectonText]);

  // this function when click the marker and asset card will display
  const clickedMarker = async (e, value) => {
    e.preventDefault();
    setOpen(true);
    // console.log(value);
    const deviceOid = value.deviceOid ? value.deviceOid : value._id;
    setSelectedOption(listAsset.findIndex((x) => x.uuid === deviceOid));
    setAssetSelectionList(listAsset.find((x) => x.uuid === deviceOid));
    if (idleState) {
      // APIV3CalledGetPayload(deviceOid).then((x) => assetMarkerClickedFromMap(x, value)).((i) => console.log('Click on Marker'));
      const apiMarkerData = await APIV3CalledGetPayload(deviceOid).then((x) => assetMarkerClickedFromMap(x, value)).catch((i) => console.log('Click on Makrer'));
      setPopUpMarker(apiMarkerData);
    } else {
      // APIV3CalledGetPayload(deviceOid).then((x) => assetMarkerClickedFromMap(x)).catch((i) => console.log('Click on Marker'));
      const apiMarkerData = await APIV3CalledGetPayload(deviceOid).then((x) => assetMarkerClickedFromMap(x)).catch((i) => console.log('Click on Makrer'));
      setPopUpMarker(apiMarkerData);
    }
    // setMapClickState(deviceId);
    selectAsset({ deviceOid: deviceOid, getAssetInfo: true });
  };

  // this function for when close the asset card it will reset all the state.
  const onCloseDetailMarker = (e) => {
    e.preventDefault();
    clearInterval(timer);
    setLastSeenTimestamp('');
    setAssetCardDisp(false);
    setMarkerValue({});
    setAssetSelectionList({});
    setEmptyCardValue();
    closeAssetCard();
    setSelectedOption(null);
    APISearchDevices('');
  };

  const resumeLiveUpdate = async () => {
    const postResponse = await APIWSConnect(token, agencyOid);
    if (postResponse.status === 200) {
      setChannel(agencyID);
    }
    if (token) {
      APISearchDevices('');
      APIV3CalledGetPayload().then((x) => allMarkerDisplayFunction(x, false)).catch((e) => console.log(e));
    }
    // console.log('resume live update');
    setIdleState(false);
  };

  useEffect(() => {
    if (payloadState) {
      APIV3CalledGetPayload(payloadState.uuid).then((x) => {
        if (x) {
          assetMarkerClickedFromMap(x);
          // console.log('test');
        } else {
          assetSelectionDisplayCardFunction(x);
          setEmptyCardValue(payloadState);
        }
      });
      selectAsset({ deviceOid: payloadState.uuid, getAssetInfo: true });
    }
    window.history.replaceState({}, document.title);
  }, [payloadState]);

  return (
    <>
      {isMobileOrTablet && (
        <Root>
          <Global
            styles={{
              // '.MuiDrawer-root > .MuiBackdrop-root': {
              //   backgroundColor: 'rgba(0, 0, 0, 0)',
              // },
              '.MuiDrawer-root > .MuiPaper-root': {
                height: drawerRef ? drawerRef.offsetHeight + 32 : 'auto',
                // height: '55vh',
                overflow: 'visible',
                borderRadius: '13px',
                zIndex: 2,
              },
            }}
          />
        <div
          style={{
            position: 'absolute',
            padding: '8px',
            boxSizing: 'border-box',
            width: '100%',
            zIndex: 2,
          }}
        >
          <Autocomplete
            autocompleteComponent="AssetSearch"
            list={listAsset}
            setSelectionList={setAssetSelectionList}
            selectionList={assetSelectionList}
            text="Select device / asset"
            isImage
            isAgencySelector={false}
            inputValue={inputSelectonText}
            stateInputValue={setInputSelectonText}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            dropDownLoading={dropDownLoading}
            setDropDownLoading={setDropDownLoading}
            loadAsset={loadAsset}
          />
        </div>
          <MapComponent data={listPoint} mapRefs={mapRefs} markerClick={clickedMarker} assetCardDisp={assetCardDisp} markerPopUp={assetCardDisp} setMarkerPopUp={setAssetCardDisp} pointMarkerValue={markerValue} popUpMarker={popUpMarker} clusterPoint={clusterPoint} setViewMapping={setViewMapping} viewMapping={viewMapping} oneTimeLoad={oneTimeLoad} setOneTimeLoad={setOneTimeLoad} clusterClick />
          {assetCardDisp && (
              <SwipeableDrawer
                anchor="bottom"
                open={open}
                style={{
                  zIndex: 2,
                }}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <StyledBox
                  sx={{
                    position: 'absolute',
                    top: open ? '0px' : -drawerBleeding,
                    borderTopLeftRadius: 13,
                    borderTopRightRadius: 13,
                    visibility: 'visible',
                    right: 0,
                    left: 0,
                    height: '100%',
                    overflow: 'auto',
                  }}
                  className="live-Drawer"
                >
                  <Puller />
                  <AssetDetailCardMobile drawerRef={setDrawerRef} open={open} closeButton={onCloseDetailMarker} markerValue={markerValue} timeStampDisplay={timeStampDisplay} assetSelection={assetSelectionList} lastSeenTimestamp={lastSeenTimestamp} emptyCardValue={emptyCardValue} />
                </StyledBox>
              </SwipeableDrawer>
            )}
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              zIndex: 2,
              padding: '7px',
            }}
          >
            <AssetTabFilter setAssetClick={setAssetClick} assetClick={assetClick} />
          </div>
        </Root>
      )}
      {isDefault && (
        <div className="map-overview-container" style={{ width: windowSize.innerWidth - 280 }}>
          <ListNavigationTab list={liveNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
          <div className="map-overview-title">
            {/* <BreadCrumbs customSeperator={seperator} /> */}
            <Title title="Map Overview" subtitle="A bird's eye view of all your assets on a map" titleSize="1.875rem" />
          </div>
          <div className="map-overview-tabs">
            <AssetTabFilter setAssetClick={setAssetClick} assetClick={assetClick} />
            <div className="map-overview-buttons-input">
              {/* <SearchInput containerWidth="18.75rem" text="Search asset" containerHeight="2.75rem" /> */}
              <Autocomplete
                autocompleteComponent="AssetSearch"
                list={listAsset}
                setSelectionList={setAssetSelectionList}
                selectionList={assetSelectionList}
                text="Select device / asset"
                isImage
                isAgencySelector={false}
                inputValue={inputSelectonText}
                stateInputValue={setInputSelectonText}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                dropDownLoading={dropDownLoading}
                setDropDownLoading={setDropDownLoading}
                loadAsset={loadAsset}
              />
              {/* <FilterButton height="41px" /> */}
            </div>
          </div>
          <div className="map-overview-map">
            <MapComponent data={listPoint} mapRefs={mapRefs} markerClick={clickedMarker} assetCardDisp={assetCardDisp} markerPopUp={assetCardDisp} setMarkerPopUp={setAssetCardDisp} pointMarkerValue={markerValue} popUpMarker={popUpMarker} clusterPoint={clusterPoint} setViewMapping={setViewMapping} viewMapping={viewMapping} oneTimeLoad={oneTimeLoad} setOneTimeLoad={setOneTimeLoad} clusterClick />
            { assetCardDisp && <AssetCardDetails closeButton={onCloseDetailMarker} markerValue={markerValue} timeStampDisplay={timeStampDisplay} assetSelection={assetSelectionList} lastSeenTimestamp={lastSeenTimestamp} emptyCardValue={emptyCardValue} /> }
            { idleState && (
              <div style={{
                position: 'absolute',
                top: 24,
                right: 24,
                }}
              >
                <SnackBar title="Live Updates Paused" subtitile="It looks like you’re away, we’ve temporarily paused streaming of live updates." module="liveUpdate" action="liveUpdate" textButton textButtonLabel="Resume Live Updates" textButtonFunction={resumeLiveUpdate} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MapOverview;
