import React, { useState, useMemo, useContext } from 'react';
import '../modal.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import LogoutIcon from '../img/logoutUpdateIcon.svg';
import CloseButton from '../../../image/xButton.svg';
import Title from '../../title/Title';
import Autocomplete from '../../autocomplete/Autocomplete';
import { WebsocketContext } from '../../../config/reducer/Websocket';

export default function LogoutWarn(props) {
    const {
 switchModalShow, setSwitchModalShow, btnConfirm, logout, setUpdateEmailLogout,
} = props;
    const payloadContext = useContext(WebsocketContext);
    const { CloseConnection } = payloadContext;
    const agency = Cookies.get('listAgency') ? JSON.parse(Cookies.get('listAgency')) : [];
    const agencyId = Cookies.get('agency-id');
    const sessionID = Cookies.get('SessionID');
    const navigate = useNavigate();

    const listAgency = useMemo(() => {
        const value = agency.length > 0 ? agency.filter((i) => i.agencyId !== agencyId).map((x) => ({
            id: x.agency.agencyId,
            name: x.agency.agencyName ? x.agency.agencyName : 'Admin Console',
            agencyUUID: x.agency.oid,
            chatBotEnabled: x.chatBotEnabled,
            isSuspended: x.isSuspended,
            role: x.role,
            tgNotification: x.tgNotification,
        })) : [];

        return value;
    }, []);

    const stateChange = () => {
        setSwitchModalShow(!switchModalShow);
    };

    const confirmLogout = async (e) => {
        setUpdateEmailLogout(true);
    };

    // console.log('listAgency', listAgency);
    const [agencyList, setAgencyList] = useState(listAgency);
    const [selectionList, setSelectionList] = useState({});
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    return (
        <div className="modal-container-bg">
            <div className="modal-container-logout">
                <div className="modal-header">
                    <div className="modal-header-content">
                        <img loading="lazy" src={LogoutIcon} alt="switch-agency-icon" />
                        <Title title="Logout Required" subtitle="" titleSize="1.125rem" subtitleSize="0rem" gap="0rem" lineHeight="1.75rem" />
                    </div>
                    <div className="button-container">
                        <div type="button" role="button" className="x-button" onKeyUp={(e) => stateChange(e)} tabIndex="0" onClick={(e) => stateChange(e)}>
                            <img loading="lazy" src={CloseButton} alt="close-modal" />
                        </div>
                    </div>
                </div>
            <div className="modal-content">
                <Title title="" subtitle="Updating your email will require you to login again. Are you sure you want to save these details and logout?" titleSize="0rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.25rem" />
            </div>
                <div className="modal-actions-logout">
                    <div className="modal-actions-content">
                        <SecondaryButton label="Cancel" width="50%" onClickFunction={stateChange} />
                        <PrimaryButton label="Confirm" width="50%" onClickFunction={(e) => confirmLogout(e)} />
                    </div>
                </div>
            </div>
        </div>
    );
}
