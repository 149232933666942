import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './system-status.css';
import PropTypes from 'prop-types';
import Title from '../title/Title';
import Badge from '../badge/Badge';
import goodBadge from './img/check-circle.svg';
import badBadge from './img/system-degradation.svg';
import { TextButton } from '../buttonComponent/ButtonComponent';
import RocketIcon from './img/beta.svg';

export default function SystemStatus(props) {
    const {
        label, isAdmin,
    } = props;

    const navigate = useNavigate();

    function navIcon() {
        switch (label) {
            case 'All Systems Operational':
                return <Badge text="All Systems Operational" background="#D0E0E8" color="#26404E" icon={goodBadge} />;
            case 'Service Degradation':
                return <Badge text="Service Degradation" background="#FFFAEB" color="#B54708" icon={badBadge} />;
            // case 'Back to the old experience':
            //     // return <TextButton label={label} onClickFunction={handleOldExperienceButton} color="#475467" fontWeight="800" backgroundColor="#F9FAFB" />;
            //     return <Link reloadDocument to={horizonURL} className="system-status-beta-text">{label}</Link>;
            default:
                return null;
        }
    }

    return (
        <div className="system-status-main">
            <div className="card-monthly-budget">
                <div className="text-container-system-status">
                    {isAdmin ? (
                        <div className="text-system-beta-container">
                            <div className="text-system-beta-text-container">
                                <div className="text-system-beta-title">
                                    <div className="text-system-beta-text">
                                        Horizon Console
                                    </div>
                                </div>
                                <div className="text-system-beta-content">
                                    You are accessing the administrative console for Horizon. Be extra careful when applying new configurations.
                                </div>
                            </div>
                        </div>
                    ) : (
                    <div className="text-system-beta-container">
                        <div className="text-system-beta-text-container">
                            <div className="text-system-beta-title">
                                <img src={RocketIcon} alt="beta-icon" />
                                <div className="text-system-beta-text">
                                    Horizon 3.x
                                </div>
                            </div>
                            <div className="text-system-beta-content">
                                Welcome to the new Horizon experience. We’ve streamlined navigation and packed it with features for you to gain more visibility over your assets and devices.
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}
