/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import TextInput from '../textInput/TextInput';
import PlusIcon from '../../image/plus.svg';
import './addRemoveInputForm.css';

const AddRemoveInputForm = (props) => {
    const { emailList, setEmailList, source } = props;
    const [error, setError] = useState(false);
    const [keyUp, setKeyUp] = useState('', false);

    const handleEmailChange = (e, index) => {
      const { value } = e.target;
      const list = [...emailList];
      list[index].email = value;
      setEmailList(list);
    };

    const handleEmailRemove = (index) => {
      const list = [...emailList];
      list.splice(index, 1);
      setEmailList(list);
    };

    const handleEmailAdd = () => {
      setEmailList([...emailList, { email: '' }]);
    };

    return (
    <>
        {emailList.map((singleEmail, index) => (
          <>
            <>
              <TextInput label="Forgot" placeholder="user@asiamobiliti.com" setInput={setEmailList} input={emailList} name="email" type="text" error={error} setError={setError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={(e) => handleEmailChange(e, index)} />
            </>
            <>
              {/* {emailList.length !== 1 && (
                <button
                  type="button"
                  onClick={() => handleEmailRemove(index)}
                  className="remove-btn"
                >
                  <span>Remove</span>
                </button>
              )} */}
            </>
            {source !== 'agenciesInviteOwner' ? (
              <>
              {emailList.length - 1 === index && emailList.length < 5 && (
                <button
                  type="button"
                  onClick={handleEmailAdd}
                  className="add-btn"
                >
                  <img src={PlusIcon} alt="plus-icon" loading="lazy" />
                  <div className="invite-users-text">Add another</div>
                </button>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ))}
    </>
    );
};

export default AddRemoveInputForm;
