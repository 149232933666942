import React, { useState, useEffect } from 'react';
import { Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as turf from '@turf/turf';
import moment from 'moment';
import Cookies from 'js-cookie';
import Checkbox from '../../checkbox/Checkbox';
import RailIcon from '../img/railIcon.svg';
import RoadIcon from '../img/roadIcon.svg';
import EnterIcon from '../img/enter.svg';
import LeaveIcon from '../img/leave.svg';
import SmartlocksIcon from '../img/smartlocks.svg';
import VerticalDots from '../img/verticalDots.svg';
import MenuPopup from '../../menuPopup/MenuPopup';
import ToolTips from '../../tooltip/ToolTips';
import AvatarGroup from '../../avatarGroup/AvatarGroup';
import Modal from '../../modal/Modal';
import './ColumnNotification.css';
import '../Table.css';
import '../../modal/modal.css';

import { APIPatchAlertPolicy } from '../../../config/restAPI/PatchAPI';
import { APIDeleteAlertPolicy } from '../../../config/restAPI/DeleteAPI';

const convertToDate = (timestamp) => {
    const cDate = moment(timestamp).format('X');
    const actualDate = moment.unix(cDate).format('DD MMM YYYY');
    return actualDate;
};

const convertToTime = (timestamp) => {
    const cTime = moment(timestamp).format('X');
    const actualTime = moment.unix(cTime).format('HH:mm:ss a');
    return actualTime;
};

const CustomAlertType = ({ row }) => {
    const { alertType } = row;
    return (
        <div className="notification-table-alert-type-name">
            {' '}
            {alertType}
            {' '}
        </div>
    );
};

const CustomAlertName = ({ row }) => {
    const { alertName } = row;
    return (
        <div className="notification-table-alert-type-name">
            {' '}
            {alertName}
            {' '}
        </div>
    );
};

// const CustomAreas = ({ row }) => {
//     const { geofenceArea } = row;
//     return (
//         <div className="geofence-table-areas">
//             <div className="geofence-table-areas-text">{geofenceArea.length}</div>
//         </div>
//     );
// };

const CustomTags = ({ row }) => {
    const {
        associatedTags,
    } = row;

    const [assetTagsOverflowCount, setAssetTagsOverflowCount] = useState(0);
    const [assetTags, setAssetTags] = useState([]);
    const [overflowAssetTags, setOverflowAssetTags] = useState([]);
    const [hover, setHover] = useState(false);

    const ifAssetTagsOverflow = () => {
        if (associatedTags && associatedTags.length > 2) {
            const overflowedAssetTags = associatedTags.slice(2);
            setAssetTags(associatedTags.slice(0, 2));
            setOverflowAssetTags(overflowedAssetTags);
            setAssetTagsOverflowCount(overflowedAssetTags.length);
        }
    };

    useEffect(() => {
        ifAssetTagsOverflow();
    }, []);

    return (
        <>
        {associatedTags && associatedTags.length > 2 ? (
            <>
                {assetTags.map((value) => (
                    <div className="tags">
                        <div className="tags-text">
                            {value}
                        </div>
                    </div>
                ))}
                <ToolTips text={overflowAssetTags.join(', ')} disabled={!hover}>
                    <div className="overflow-tags">
                        <div>{`+${assetTagsOverflowCount}`}</div>
                    </div>
                </ToolTips>
            </>
        ) : (
            <>
            {associatedTags && associatedTags.map((value) => (
                <div className="tags">
                    <div className="tags-text">
                        {value}
                    </div>
                </div>
            ))}
            </>
        )}
        </>
    );
};

const CustomMenuButton = (assets) => {
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const { row, rowIndex } = assets;
    const [anchorEl, setAnchorEl] = useState(null);
    const [assetRow, setAssetRow] = useState();
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [switchModalShow, setSwitchModalShow] = useState(false);
    const [errorHandling, setErrorHandling] = useState({
        statusCode: null,
        statusMessage: null,
    });
    const [triggerError, setTriggerError] = useState(false);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAssetRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAssetRow();
        setAnchorEl(null);
    };

    const editButton = () => {
        // // this to make a list of polygon
        const centerOfEachPolygon = row.geofenceArea.length ? row.geofenceArea.map((x) => x.area[0].geometry) : null;
        // // this to convert it into array of coordinates (array of polygon)
        const listPolygon = centerOfEachPolygon ? centerOfEachPolygon.map((x) => x.coordinates) : null;
        // // convert array of polygon to multipolygon
        const multiPolygon = listPolygon ? turf.multiPolygon(listPolygon) : null;
        // // get bbox value from multipolygon (min x, max x, min y and max y) this is more to fitbound
        const bboxPolygon = multiPolygon ? turf.bbox(multiPolygon) : null;
        // // store the detail and bboxPolygon into routing
        navigate('/notifications/configuration/details', { replace: true, state: { row, bboxPolygon } });
    };

    const policiesStatus = async () => {
        try {
            const reducer = {
                alertPolicy: {
                    oid: row.oid,
                    isEnabled: !row.isEnabled,
                },
            };
            const result = await APIPatchAlertPolicy(token, setLoading, agencyID, reducer);
            if (result.data.status === 200) {
                setLoading(false);
                const triggerAPICalled = true;
                navigate('/notifications/configuration', { state: { fetchAPICall: triggerAPICalled, statusCode: 200, statusMessage: 'update completed' } });
            } else {
                setLoading(false);
                // handleClose();
                // setErrorHandling({
                //     statusCode: result.error.code,
                //     statusMessage: result.error.message,
                // });
                console.log('error on delete funciton');
            }
        } catch (err) {
            setLoading(false);
            console.log('error on enable funciton');
        }
    };

    const deleteButton = () => {
        setDeleteModal(true);
        setAssetRow();
        setAnchorEl(null);
    };

    const deleteFunction = async () => {
        try {
            const reducer = {
                oid: [row.oid],
            };
            const result = await APIDeleteAlertPolicy(token, setLoading, agencyID, reducer);
            if (result.data.status === 200) {
                setLoading(false);
                setDeleteModal(false);
                const triggerAPICalled = true;
                navigate('/notifications/configuration', {
                    state: {
                        fetchAPICall: triggerAPICalled,
                        snackbarStatus: 'success',
                        snackbarTitle: 'Success',
                        snackbarSub: 'Geofence Deleted!',
                    },
                });
            } else {
                setLoading(false);
                // handleClose();
                setErrorHandling({
                    statusCode: result.error.code,
                    statusMessage: result.error.message,
                });
                setTriggerError(true);
                console.log('error on delete funciton');
            }
        } catch (err) {
            setLoading(false);
            console.log('error from catch', err);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className="assets-button" id={rowIndex} aria-hidden="true" onClick={handleClick}>
                <div className="assets-menu">
                    <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                </div>
            </div>
            <Popover
              id={rowIndex}
              sx={{
                boxShadow: 2,
                borderRadius: 2,
            }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <MenuPopup page="notificationConfiguration" assetDetails={assetRow} rowData={row} editButton={editButton} deleteButton={deleteButton} policiesStatus={policiesStatus} />
            </Popover>
            {
                deleteModal && (
                    <div className="modal-container-table-bg">
                        <Modal setSwitchModalShow={setDeleteModal} switchModalShow={deleteModal} btnConfirm={deleteFunction} module="DeleteModal" source="Geofence" dataRow={row} errorState={errorHandling} triggerError={triggerError} setTrigerError={setTriggerError} />
                    </div>
                )
            }
        </div>
    );
};

const CustomAction = ({ row }) => {
    const { notification } = row;

    return (
        <div style={{ display: 'flex', gap: '4px' }}>
            { notification.generatePin
            ? (
                <ToolTips text="Generate Smart Lock Pin">
                                <img loading="lazy" src={SmartlocksIcon} alt="enter-icon" />
                </ToolTips>
                )
                            : null }
                            { notification.enter
                                ? (
                <ToolTips text="Notify Upon Entering">
                                    <img loading="lazy" src={EnterIcon} alt="enter-icon" />
                </ToolTips>
                )
                            : null }
                            { notification.leave
                            ? (
                <ToolTips text="Notify Upon Leaving">
                                <img loading="lazy" src={LeaveIcon} alt="leave-icon" />
                </ToolTips>
                )
            : null }
        </div>
    );
};

const ColumnLastUpdated = ({ row }) => {
    const { lastTrigger } = row;

    return (
        <div>
            <div className="main-first">
                {lastTrigger ? convertToDate(lastTrigger) : '-'}
                {' '}
            </div>
            <div className="secondary-second">
                {lastTrigger && `${convertToTime(lastTrigger)} GMT+8`}
                {' '}
            </div>
        </div>
    );
};

const ColumnCheckbox = ({ row }) => {
    const { isEnabled } = row;
    return (
        <Checkbox
          checked={isEnabled}
          disabled="disabled"
          width="20px"
          height="20px"
        />
    );
};

export const notificationColumn = [
    {
        name: 'Alert Type',
        selector: (row) => row.alertType,
        sortable: true,
        cell: (row) => <CustomAlertType row={row} />,
    },
    {
        name: 'Alert Name',
        selector: (row) => row.alertName,
        sortable: true,
        cell: (row) => <CustomAlertName row={row} />,
    },
    // {
    //     name: 'Areas',
    //     selector: (row) => row.geofenceArea,
    //     cell: (row) => <CustomAreas row={row} />,
    //     center: true,
    // },
    {
        name: 'Asset/Device Tags',
        selector: (row) => row.associatedTags,
        // grow: '1.7',
        id: 'customTags',
        cell: (row) => <CustomTags row={row} />,
        style: {
            gap: '4px',
        },
    },
    {
        name: 'Notified Users',
        selector: (row) => row.notifiedUsers,
        cell: (row) => <AvatarGroup users={row.notifiedUsers} />,
    },
    {
        name: 'Actions',
        // selector: (row) => row.actions,
        // grow: '1.3',
        cell: (row) => <CustomAction row={row} />,
        // center: true,
    },
    {
        name: 'Last Triggered',
        selector: (row) => row.lastTrigger,
        // grow: '1.3',
        cell: (row) => <ColumnLastUpdated row={row} />,
    },
    {
        name: 'Enabled',
        // selector: (row) => row.lastUpdated.date,
        grow: '1.3',
        cell: (row) => <ColumnCheckbox row={row} />,
        center: true,
    },
    {
        cell: (row, rowIndex) => (
              <CustomMenuButton row={row} rowIndex={rowIndex} />
        ),
        allowOverflow: true,
        button: true,
        width: '48px',
    },
];
