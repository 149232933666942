import React from 'react';
import SearchIcon from './img/searchLg.svg';
import './searchInput.css';

const SearchInput = (props) => {
  const {
 containerWidth, text, containerHeight, value, onChangeText,
} = props;
  return (
    <div className="search-input-container">
    <div className="search-input">
      {
        !value && (
          <img src={SearchIcon} className="iconn-search" alt="search-icon" />
        )
      }
      <input
        type="text"
        placeholder={text}
        className="inputSearch"
        // style={{
        //   width: containerWidth,
        //   height: containerHeight,
        //   backgroundColor: '#FFFFFF',
        // }}
        style={
          !value ? {
            width: containerWidth,
            height: containerHeight,
            backgroundColor: '#FFFFFF',

          } : {
            width: `calc(${containerWidth} + 1.625rem)`,
            height: containerHeight,
            backgroundColor: '#FFFFFF',
          }
        }
        value={value}
        onChange={(e) => onChangeText(e)}
      />
    </div>
    </div>
  );
};

export default SearchInput;
