import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import { APIGetDevices } from '../../../config/restAPI/FetchAPI';
import Title from '../../../component/title/Title';
import Table from '../../../component/table/Table';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
// import { inventoryNavigationList } from '../../../config/function/tabNavigationList';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import SnackBar from '../../../component/snackbar/Snackbar';
import { BulkActionButton, CreateWithIcon } from '../../../component/buttonComponent/ButtonComponent';
import SearchInput from '../../../component/searchInput/SearchInput';
import FilterButton from '../../../component/filterButton/FilterButton';
import InventoryTableEmptyState from '../../../component/tableEmptyState/InventoryTableEmptyState';
import TruckIcon from '../../../image/truckIcon.svg';
import { useApiData } from '../../../config/reducer/Modules';
import './AssetsDevice.css';
import '../geofence/geofence.css';

const navigationList = [
    { text: 'Assets & Devices', value: 1 },
    { text: 'Beacons', value: 2 },
    { text: 'Geofence', value: 3 },
    { text: 'Connectivity', value: 4 },
];

const seperator = [
    <img loading="lazy" src={TruckIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Assets & Devices</div>,
];

const AssetsDevice = (props) => {
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const { apiResponse } = useApiData();
    // console.log('apiResponse :', apiResponse);
    const navigate = useNavigate();
    const location = useLocation();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [devicesState, setDevicesState] = useState(true);
    const [emptyState, setEmptyState] = useState(false);
    const [showTable, setShowTable] = useState(true);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const bulkActionDisabled = devicesState === false || selectedRowCount <= 1;
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const role = Cookies.get('role');
    const [deviceData, setDeviceData] = useState([]);
    const [adminDeviceData, setAdminDeviceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [adminTotalRows, setAdminTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [adminConsole, setAdminConsole] = useState(false);
    const [triggerSnackbar, settriggerSnackbar] = useState(false); // trigger snackbar
    const [textTitleSnackbar, setTextTitleSnackbar] = useState(null);
    const [textSubSnackbar, setTextSubSnackbar] = useState(null);
    const [moduleSnackbar, setModuleSnackbar] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [searchText, setSearchText] = useState('');
    const defaultParams = { pageNumber: 1 };

    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();

    const inventoryNavigationList = [
        {
            text: 'Assets & Devices', value: 1, path: '/inventory/assetdevices', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.role === 'System Administrator') ? true : apiResponse?.module?.fleetManagement?.asset,
        },
        // {
        //     text: 'Beacons', value: 2, path: '/inventory/beacons', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.beacon,
        // },
        // {
        //     text: 'Geofence', value: 3, path: '/inventory/geofence', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.geofence,
        // },
        {
            text: 'Connectivity', value: 4, path: '/inventory/connectivity', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.connectivity,
        },
    ];

    const fetchDevices = async (params) => {
        try {
            const result = await APIGetDevices(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                // console.log(data);
                if (data.length > 0) {
                    const reducer = data && data.length > 0 ? data.map((x) => ({
                            id: x.oid,
                            deviceSN: x.deviceSerial,
                            assetName: x.assetName !== null ? (x.assetName) : (''),
                            assetType: [x.assetType],
                            tags: x.tags !== undefined ? (x.tags) : [],
                            sim: x.sim ? x.sim : {},
                            // simICCID: x.sim.iccid ? x.sim.iccid : '',
                            // dataUsage: {
                            //     usage: x.sim.dataUsage ? ((x.sim.dataUsage) / 1000000).toFixed(1) : (null),
                            //     limit: x.sim.dataLimit ? (x.sim.dataLimit) / 1000000 : 50,
                            // },
                            lastUpdated: {

                                // date: moment(x.lastUpdated).format('DD MMM YYYY'),
                                date: x.lastUpdated !== null ? (moment(x.lastUpdated).format('DD MMM YYYY')) : (null),
                                time: x.lastUpdated !== null ? (`${moment(x.lastUpdated).format('h:mm:ssa')} GMT${moment(x.lastUpdated).format('ZZ').replace('00', '').replace('0', '')}`) : (null),
                            },
                            description: x.description ? x.description : '',
                    })) : [];
                    // console.log(reducer);
                    if (reducer.length > 0) {
                        setShowTable(true);
                        setEmptyState(false);
                        setDeviceData(reducer);
                        setTotalRows(result.data.totalData);
                        setDevicesState(true);
                        setLoading(false);
                    } else {
                        setShowTable(false);
                        setEmptyState(true);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                    setDevicesState(false);
                }
            } else if (result.status === 204) {
                setShowTable(false);
                setEmptyState(true);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setDevicesState(false);
            // console.log('fgo here catch', err);
        }
    };

    const adminFetchDevices = async (params) => {
        try {
            const result = await APIGetDevices(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                // console.log(data);
                if (data.length > 0) {
                    // console.log('here');
                    // console.log(data);
                    const reducer = data.map((x) => ({
                            id: x.oid,
                            agency: x.agency,
                            deviceSN: x.deviceSerial,
                            description: x.internalDescription ? x.internalDescription : '',
                            assetName: x.assetName !== null ? (x.assetName) : (null),
                            assetType: [x.assetType],
                            // agencyName: (x.agency.agencyName).split(' ').slice(0, 2).join(' '),
                            agencyName: x.agency !== null ? (x.agency.agencyName).split(' ').slice(0, 2).join(' ') : (''),
                            tags: x.tags !== undefined ? (x.tags) : [],
                            simICCID: x.simIccid,
                            sim: x.sim ? x.sim : {},
                            latestPayload: {
                                // date: moment(x.lastUpdated).format('DD MMM YYYY'),
                                date: x.lastUpdated !== null ? (moment(x.lastUpdated).format('DD MMM YYYY')) : (null),
                                time: x.lastUpdated !== null ? (`${moment(x.lastUpdated).format('h:mm:ssa')} GMT${moment(x.lastUpdated).format('ZZ').replace('00', '').replace('0', '')}`) : (null),
                            },
                            dateCreated: {
                                date: x.dateCreated !== null ? (moment(x.dateCreated).format('DD MMM YYYY')) : (null),
                                time: x.dateCreated !== null ? (`${moment(x.dateCreated).format('h:mm:ssa')} GMT${moment(x.dateCreated).format('ZZ').replace('00', '').replace('0', '')}`) : (null),
                            },
                    }));
                    if (reducer.length > 0) {
                        setShowTable(true);
                        setEmptyState(false);
                        setAdminDeviceData(reducer);
                        setAdminTotalRows(result.data.totalData);
                        setDevicesState(true);
                        setLoading(false);
                    } else {
                        setShowTable(false);
                        setEmptyState(true);
                        setLoading(false);
                    }
                } else {
                    // console.log('err');
                    setLoading(false);
                    setDevicesState(false);
                }
            } else if (result.status === 204) {
                setShowTable(false);
                setEmptyState(true);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setDevicesState(false);
            // console.log('fgo here catch', err);
        }
    };

    const handlePageChange = (page) => {
        const rowsParams = {
            pageNumber: page,
        };
        fetchDevices(rowsParams);
    };
    const handleAdminPageChange = (page) => {
        const rowsParams = {
            pageNumber: page,
        };
        adminFetchDevices(rowsParams);
    };

    const handleAddDeviceButton = () => {
        navigate('/inventory/assetdevices/addDevice', { replace: true });
    };

    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    const switchBody = (value) => {
        switch (value) {
            case false:
                return (
                    <div className="inventory-body">
                        <div className="inventory-body-filter">
                            {/* <div className="bulkAction-button-container">
                                <BulkActionButton label="Bulk Action" width="8.1875rem" color="white" disable={bulkActionDisabled} />
                            </div> */}
                            <div className="inventory-filter-button">
                                <SearchInput containerWidth="25rem" text="Search" value={searchText} onChangeText={onChangeSearchText} />
                                {/* <FilterButton height="44px" /> */}
                            </div>
                        </div>
                        {showTable && (
                            <div className={loading ? 'inventory-body-text-loading' : 'inventory-body-text'}>
                                <Table
                                  selectedRowCount={selectedRowCount}
                                  setSelectedRowCount={setSelectedRowCount}
                                  tableData={deviceData}
                                  pagination
                                  module="inventory"
                                  handlePageChange={handlePageChange}
                                  loading={loading}
                                  totalRows={totalRows}
                                  backgroundColor="#F9FAFB"
                                />
                            </div>
                        )}
                        {emptyState && (
                            <div className="inventory-body-empty">
                                <InventoryTableEmptyState />
                            </div>
                        )}
                    </div>
                );
            case true:
                return (
                    <div className="inventory-body">
                        <div className="inventory-body-filter">
                            <SearchInput containerWidth="25rem" text="Search" value={searchText} onChangeText={onChangeSearchText} />
                            {/* <div className="bulkAction-button-container">
                                <BulkActionButton label="Bulk Action" width="8.1875rem" color="white" disable={bulkActionDisabled} />
                            </div> */}
                            <div className="inventory-filter-button">
                                {/* <FilterButton height="44px" /> */}
                                <CreateWithIcon label="Add Device" onClickFunction={handleAddDeviceButton} buttonWidth="8.75rem" buttonHeight="2.75rem" module="policy" />
                            </div>
                        </div>
                        {showTable && (
                            <div className={loading ? 'inventory-body-text-loading' : 'inventory-body-text'}>
                                <Table
                                  selectedRowCount={selectedRowCount}
                                  setSelectedRowCount={setSelectedRowCount}
                                  tableData={adminDeviceData}
                                //   checkbox
                                  pagination
                                  module="adminInventory"
                                  handlePageChange={handleAdminPageChange}
                                  loading={loading}
                                  totalRows={adminTotalRows}
                                  backgroundColor="#F9FAFB"
                                />
                            </div>
                        )}
                        {emptyState && (
                            <div className="inventory-body-empty">
                                <InventoryTableEmptyState />
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    const switchLoadData = (value) => {
        // console.log(value);
        switch (value) {
            case 'System Administrator':
                setAdminConsole(true);
                adminFetchDevices(defaultParams);
                break;
            case value !== 'System Administrator':
                fetchDevices(defaultParams);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (location.state) {
            if (location.state.snackbarStatus) {
                settriggerSnackbar(true);
                setTextTitleSnackbar(location.state.snackbarTitle);
                setTextSubSnackbar(location.state.snackbarSub);
                setModuleSnackbar(location.state.snackbarStatus);
                navigate(location.pathname, { replace: true });
            }
        }
     }, [location.state]);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        setSearchTimeout(
            setTimeout(() => {
                const searchingValue = {
                    searchKey: searchText,
                    pageNumber: 1,
                };
                if (apiResponse?.role === 'System Administrator') {
                    adminFetchDevices(searchingValue);
                } else {
                    fetchDevices(searchingValue);
                }
            }, 200),
        );
        return () => clearTimeout(searchTimeout);
    }, [searchText]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        if (token) {
            switchLoadData(role);
        }
        setNavigtionClick(1);
        setNavigationState('inventory');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className="inventory-container">
            <ListNavigationTab list={inventoryNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="inventory-header">
                <BreadCrumbs customSeperator={seperator} />
                {adminConsole ? (
                    <Title title="Devices" subtitle="IoT devices are the primary sources of telemetry data that enables a digital twin of the agency’s fleet of assets. Assign new IoT devices or manage existing IoT devices that have been assigned to the agency." titleSize="1.875rem" />
                ) : (
                    <Title title="Assets & Devices" subtitle="Assets are logical representation and grouping of tangible objects that your organisation tracks with IoT devices." titleSize="1.875rem" />
                )}
            </div>
            {switchBody(adminConsole)}
            {
                triggerSnackbar && (
                <div className="geofence-snackbar-container">
                    <SnackBar state={triggerSnackbar} setState={settriggerSnackbar} title={textTitleSnackbar} subtitile={textSubSnackbar} module={moduleSnackbar} action={moduleSnackbar} />
                </div>
                )
            }
        </div>
    );
};

export default AssetsDevice;
