/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Select, { components, PlaceholderProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import TrainIcon from './img/train.svg';
import TruckIcon from './img/truck.svg';
import './iconDropDown.css';

const customStyles = {
    control: (base) => ({
        ...base,
        height: 44,
        minHeight: 44,
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        border: '1px solid #D0D5DD',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
    }),
    menuPortal: (base) => ({
      ...base,
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
  }),
    menuList: (base) => ({
      ...base,
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#F9FAFB' : state.isFocused ? '#F9FAFB' : 'transparent',
        cursor: 'pointer',
        ':active': '#F9FAFB',
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'transparent',
    }),
};

export default function DropDownList(props) {
    const {
      selectedAssetType, setSelectedAssetType, assetTypeOptions, placeholder,
    } = props;

    const animatedComponents = makeAnimated();

    const handleChange = (e) => {
        setSelectedAssetType(e);
    };

    return (
      <div style={{ width: '512px', height: '44px' }}>
        <Select
          placeholder={placeholder}
          options={assetTypeOptions}
          styles={customStyles}
          onChange={handleChange}
          getOptionLabel={(e) => (
            <div className="menu-container">
                <div className="menu-icon">
                    {e.icon}
                </div>
                <div className="menu-text">
                    {e.label}
                </div>
                <div className="menu-subtitle">
                    {e.subtitle}
                </div>
            </div>
          )}
          defaultValue={selectedAssetType}
          components={{
            IndicatorSeparator: () => null,
            animatedComponents,
          }}
        />
      </div>
    );
}
