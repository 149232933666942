/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import './snackbar.css';
import PropTypes from 'prop-types';
import Navigation from '../navigation/Navigation';
import Account from '../account/Account';
import Title from '../title/Title';
import key from './img/key.svg';
import close from './img/close.svg';
import file from './img/file.svg';
import download from './img/download.svg';
import update from './img/update.svg';
import mail from './img/mail.svg';
import error from './img/error.svg';
import success from './img/success.svg';
import liveUpdate from './img/liveUpdate.svg';
import SystemStatus from '../systemStatus/SystemStatus';
import { TextButton } from '../buttonComponent/ButtonComponent';

export default function SnackBar(props) {
    const {
        state,
        setState,
        title,
        subtitile,
        module,
        deleteSubtitle,
        children,
        action,
        textButton,
        textButtonLabel,
        textButtonFunction,
    } = props;

    const stateChange = () => {
        setState(false);
    };

    const imageRender = (value) => {
        switch (value) {
            case 'Login':
                return key;
            case 'AssetPerformance':
                return file;
            case 'TelemetryLog':
                return download;
            case 'Update':
                return update;
            case 'Email':
                return mail;
            case 'success':
                return success;
            case 'error':
                return error;
            case 'liveUpdate':
                return liveUpdate;
            default:
                return null;
        }
    };

    const iconBackgroundColor = (value) => {
        let colors = { firstColor: '', secondColor: '' };
        switch (value) {
            case 'error':
                colors = { firstColor: '#FEE4E2', secondColor: '#FEF3F2' };
                return colors;
            case 'info':
                colors = { firstColor: '#B1CBD9', secondColor: '#D0E0E8' };
                return colors;
            case 'AssetPerformance':
                colors = { firstColor: '#FEE4E2', secondColor: '#FEF3F2' };
                return colors;
            case 'Update':
                colors = { firstColor: '#D1FADF', secondColor: '#ECFDF3' };
                return colors;
            case 'success':
                colors = { firstColor: '#D1FADF', secondColor: '#ECFDF3' };
                return colors;
            case 'liveUpdate':
                colors = { firstColor: '#FEF0C7', secondColor: '#FFFAEB' };
                return colors;
            default:
                return null;
        }
    };

    return (
        <div className="snackbar-container">
            <div className="snackbar-contents">
                <div className="snackbar-icon-container">
                    <div
                      className="snackbar-icon-circle"
                      style={
                        {
                            background: (iconBackgroundColor(action)).firstColor,
                            border: `0.375rem solid ${(iconBackgroundColor(action)).secondColor}`,
                        }
                      }
                    >
                        <img loading="lazy" src={imageRender(module)} className="snackbar-icon-key" alt="snackbar-icon" />
                    </div>
                </div>
                <div className="snackbar-text-content">
                    { subtitile && (
                        <Title title={title} subtitle={subtitile} titleSize="0.875rem" subtitleSize="0.875rem" gap="0.25rem" lineHeight="1.5rem" />
                    )}
                    { deleteSubtitle && (
                        <Title title={title} titleSize="0.875rem" subtitleSize="0.875rem" gap="0.25rem" lineHeight="1.5rem" deleteSubtitle>
                            {children}
                        </Title>
                    )}
                    { textButton && (
                        <TextButton label={textButtonLabel} onClickFunction={textButtonFunction} fontWeight="600" />
                    )}
                </div>
            </div>
            {
                textButton ? (
                    <>
                    </>
                ) : (
                <div className="snackbar-close-container" onClick={(e) => stateChange(e)}>
                    <div className="snackbar-close-button">
                        <div className="snackbar-close-button-icon-container">
                            <img loading="lazy" src={close} className="snackbar-close-button-icon" alt="snackbar-close" />
                        </div>
                    </div>
                </div>
                )
            }
        </div>
    );
}
