import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Popover } from '@mui/material';
import MenuPopup from '../../menuPopup/MenuPopup';
import RailIcon from '../img/railIcon.svg';
import RoadIcon from '../img/roadIcon.svg';
import VerticalDots from '../img/verticalDots.svg';
import './ColumnConnectivity.css';

const CustomLabel = ({ row }) => {
    const {
        iccid, label,
    } = row;

    return (
        <div>
            { }
            <div>
                <b>
                    {iccid}
                </b>
            </div>
            <div>
                <div
                  data-tag="allowRowEvents"
                  style={{
                    color: 'grey',
                    overflow: 'hidden',
                    whiteSpace: 'wrap',
                    textOverflow: 'ellipses',
                  }}
                >
                    { }
                    { label || ' - ' }
                </div>
            </div>
        </div>
    );
};

const CustomDevice = ({ row }) => {
    const { deviceSerial, assetType } = row;
    return (
        <div className="connectivity-cell">
            <div className="connectivity-table-text">
                {deviceSerial}
            </div>
            <div className="connectivity-device-icon">
                {(assetType?.length > 0 || assetType) ? (
                    <>
                        {assetType.includes('Road') ? (
                            <>
                                <img loading="lazy" src={RoadIcon} alt="RailIcon" />
                            </>
                        ) : (
                            <>
                            </>
                        )}
                        {assetType.includes('Rail') ? (
                            <>
                                <img loading="lazy" src={RailIcon} alt="RailIcon" />
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </>
                ) : (
                    <>
                    </>
                )}
            </div>
        </div>
    );
};

const CustomLastOnline = ({ row }) => {
    const {
        lastOnline,
    } = row;

    return (
        <div>
            {lastOnline !== null ? (
                <>
                    <div className="table-date-text">{lastOnline?.date}</div>
                    <div>
                        <div
                          className="table-time-text"
                          data-tag="allowRowEvents"
                          style={{
                            color: 'grey',
                            overflow: 'hidden',
                            whiteSpace: 'wrap',
                            textOverflow: 'ellipses',
                          }}
                        >
                            {lastOnline?.time}
                        </div>
                    </div>
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
};

const ColumnNetwork = ({ row }) => {
    const { network } = row;
    return (
        <div className="connectivity-table-text">
            { network || ' ' }
        </div>
    );
};

const CustomConnector = ({ row }) => {
    const { connector } = row;
    return (
        <div className="connectivity-table-text">
            { connector || ' ' }
        </div>
    );
};

const CustomDataUsage = ({ row }) => {
    const { dataUsage, dataLimit } = row;

    return (
        <>
        <div className="data-usage-text">
            {dataUsage ? (
                `${dataUsage} MB `
            ) : (
                '- MB '
            )}
            /
            {dataLimit ? (
                ` ${dataLimit} MB`
            ) : (
                ' - MB'
            )}
        </div>
        </>
    );
};

const CustomMenuButton = (simdetails) => {
    const { row, rowIndex } = simdetails;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [simRow, setSimRow] = useState();

    const handleClick = (event) => {
        setSimRow(row);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSimRow();
        setAnchorEl(null);
    };

    return (
        <div>
            <div className="assets-button" id={rowIndex} aria-hidden="true" onClick={handleClick}>
                <div className="assets-menu">
                    <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                </div>
            </div>
            <Popover
              id={rowIndex}
              sx={{
                boxShadow: 2,
                borderRadius: 3,
            }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <MenuPopup page="connectivity" />
            </Popover>
        </div>
    );
};

export const connectivityColumn = [
    {
        name: 'SIM ICCID / Label',
        selector: (row) => row.simICCID,
        sortable: true,
        cell: (row) => <CustomLabel row={row} />,
    },
    {
        name: 'Device SN',
        selector: (row) => row.deviceSerial,
        sortable: true,
        cell: (row) => <CustomDevice row={row} />,
    },
    {
        name: 'Last Online',
        selector: (row) => row.lastOnline.date,
        sortable: true,
        cell: (row) => <CustomLastOnline row={row} />,
    },
    {
        name: 'Network',
        selector: (row) => row.network,
        sortable: true,
        cell: (row) => <ColumnNetwork row={row} />,
    },
    // {
    //     name: 'Connector',
    //     selector: (row) => row.connector,
    //     sortable: true,
    //     cell: (row) => <CustomConnector row={row} />,
    // },
    {
        name: 'Data Usage / Limit',
        selector: (row) => row.dataUsage,
        sortable: true,
        cell: (row) => <CustomDataUsage row={row} />,
    },
    // {
    //     cell: (row, rowIndex) => (
    //           <CustomMenuButton row={row} rowIndex={rowIndex} />
    //     ),
    //     allowOverflow: true,
    //     button: true,
    //     width: '48px',
    // },
];
