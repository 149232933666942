import React from 'react';
import MovingIcon from './img/inMotion.svg';
import IdleIcon from './img/idle.svg';
import StopIcon from './img/stop.svg';
import InTowIcon from './img/inTow.svg';

const MotionState = (props) => {
  const { motionInference } = props;
// this to display markerImage;
  const motion = (value) => {
        switch (value) {
        case 100:
            return <img loading="lazy" src={StopIcon} alt="marker-icon" />;
        case 200:
            return <img loading="lazy" src={MovingIcon} alt="marker-icon" />;
        case 400:
            return <img loading="lazy" src={IdleIcon} alt="marker-icon" />;
        case 500:
            return <img loading="lazy" src={InTowIcon} alt="marker-icon" />;
        default:
            return null;
        }
  };
  return (
    <div>
        {motion(motionInference)}
    </div>
  );
};

export default MotionState;
