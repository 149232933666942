/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Popover } from '@mui/material';
import VerticalDots from '../img/verticalDots.svg';
import MenuPopup from '../../menuPopup/MenuPopup';
import './ColumnAdminInventory.css';

const CustomTitle = ({ row }) => {
    const {
        deviceSN, assetName,
    } = row;

    return (
        <div>
            { }
            <div>
                <b>
                {deviceSN}
                </b>
            </div>
            <div>
                <div
                  data-tag="allowRowEvents"
                  style={{
                    color: 'grey',
                    overflow: 'hidden',
                    whiteSpace: 'wrap',
                    textOverflow: 'ellipses',
                  }}
                >
                    { }
                    {!assetName ? (
                        '-'
                    ) : (
                        assetName
                    )}
                </div>
            </div>
        </div>
    );
};

const CustomAgencyName = ({ row }) => {
    const { agencyName } = row;

    return (
        <div className="admin-invtentory-agency-name-grid">
            {agencyName !== null ? (
                <>
                    <div className="admin-inventory-agency-name-text">
                        {agencyName}
                    </div>
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
};

const CustomDataUsage = ({ row }) => {
    const { sim } = row;

    return (
        <>
        {row.sim.iccid ? (
            <div className="data-usage-text">
                {sim.dataUsage === null ? (
                    '- MB '
                ) : (
                    `${(sim.dataUsage / 1000000).toFixed(1)} MB `
                )}
                /
                {` ${(sim.dataLimit / 1000000)} MB`}
            </div>
        ) : (
            <>
            </>
        )}
        {/* <div className={(dataUsage.usage >= 45 && dataUsage.usage <= 49) ? ('admin-data-usage-text-warning') : dataUsage.usage === 50 ? ('admin-data-usage-text-error') : ('admin-data-usage-text')}>
            {dataUsage.usage === null ? (
                '- MB '
            ) : (
                `${dataUsage.usage} `
            )}
            /
            {dataUsage.limit === null ? (
                '- MB'
            ) : (
                ` ${dataUsage.limit} MB`
            )}
        </div> */}
        </>
    );
};

const CustomLatestPayload = ({ row }) => {
    const {
        latestPayload,
    } = row;
    return (
        <div>
            {latestPayload !== null ? (
                <>
                    <div className="table-date-text">{latestPayload?.date}</div>
                    <div>
                        <div
                          className="table-time-text"
                          data-tag="allowRowEvents"
                          style={{
                            color: 'grey',
                            overflow: 'hidden',
                            whiteSpace: 'wrap',
                            textOverflow: 'ellipses',
                          }}
                        >
                            {latestPayload?.time}
                        </div>
                    </div>
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
};

const CustomDateCreated = ({ row }) => {
    const {
        dateCreated,
    } = row;

    return (
        <div>
            {dateCreated !== null ? (
                <>
                    <div className="table-date-text">{dateCreated.date}</div>
                    <div>
                        <div
                          className="table-time-text"
                          data-tag="allowRowEvents"
                          style={{
                            color: 'grey',
                            overflow: 'hidden',
                            whiteSpace: 'wrap',
                            textOverflow: 'ellipses',
                          }}
                        >
                            {dateCreated.time}
                        </div>
                    </div>
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
};

const CustomMenuButton = (assets) => {
    const { row, rowIndex } = assets;
    const [anchorEl, setAnchorEl] = useState(null);
    const [assetRow, setAssetRow] = useState();
    const handleClick = (event) => {
        setAssetRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAssetRow();
        setAnchorEl(null);
    };

    return (
        <div>
            <div className="assets-button" id={rowIndex} aria-hidden="true" onClick={handleClick}>
                <div className="assets-menu">
                    <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                </div>
            </div>
            <Popover
              id={rowIndex}
              sx={{
                boxShadow: 2,
                borderRadius: 2,
            }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <MenuPopup page="adminAssetAndDevices" assetDetails={assetRow} />
            </Popover>
        </div>
    );
};

export const adminInventoryColumn = [
    {
        name: 'Device SN / Asset Name',
        selector: (row) => row.deviceSN,
        sortable: true,
        grow: 1.3,
        cell: (row) => <CustomTitle row={row} />,
    },
    {
        name: 'Agency',
        selector: (row) => row.agencyName,
        sortable: true,
        cell: (row) => <CustomAgencyName row={row} />,
    },
    {
        name: 'SIM ICCID',
        selector: (row) => row.sim.iccid,
        sortable: true,
    },
    {
        name: 'Data Usage / Limit',
        selector: (row) => row.dataUsage,
        sortable: true,
        cell: (row) => <CustomDataUsage row={row} />,
    },
    {
        name: 'Latest Payload',
        selector: (row) => row.latestPayload.date,
        sortable: true,
        cell: (row) => <CustomLatestPayload row={row} />,
    },
    {
        name: 'Date Created',
        selector: (row) => row.dateCreated.date,
        sortable: true,
        cell: (row) => <CustomDateCreated row={row} />,
    },
    {
        cell: (row, rowIndex) => (
              <CustomMenuButton row={row} rowIndex={rowIndex} />
        ),
        allowOverflow: true,
        button: true,
        width: '48px',
    },
];
