import React, { forwardRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, useOutletContext } from 'react-router';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ListItem, Link, ListItemButton } from '@mui/material';
import { APIUpdateDevice } from '../../../../../config/restAPI/PatchAPI';
import BreadCrumbs from '../../../../../component/breadcrumbs/BreadCrumbs';
import TextArea from '../../../../../component/textAreaLimit/TextArea';
import InputTags from '../../../../../component/inputTags/InputTags';
import ConfigTab from '../../../../../component/configurationTab/configurationTab';
import DropDownList from '../../../../../component/iconDropDown/iconDropDown';
import Title from '../../../../../component/title/Title';
import TruckIcon from '../../../../../image/truckIcon.svg';
import CopyIcon from '../../../../../image/copy.svg';
import SnackBar from '../../../../../component/snackbar/Snackbar';
import ColoredLine from '../../../../../component/coloredLine/ColoredLine';
import Autocomplete from '../../../../../component/autocomplete/Autocomplete';
import Modal from '../../../../../component/modal/Modal';
import ClearableDropdown from '../../../../../component/clearableDropdown/clearableDropdown';
import { OutlinedDeleteButton, PrimaryButton, SecondaryButton } from '../../../../../component/buttonComponent/ButtonComponent';
import { APIGetAgencies } from '../../../../../config/restAPI/FetchAPI';
import { APIDeleteDevice } from '../../../../../config/restAPI/DeleteAPI';
import './Configuration.css';

const Alert = (props, ref) => (<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const Configuration = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log(state);
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const role = Cookies.get('role');
    const [assetName, setAssetName] = useState(state.assetName);
    const [tags, setTags] = useState(state.tags);
    const [removedTags, setRemovedTags] = useState([]);
    const [tagsHistory, setTagsHistory] = useState([]);
    const [descriptionValue, setDescriptionValue] = useState(state.description);
    const [loading, setLoading] = useState(false);
    const [agencyList, setAgencyList] = useState([]);
    const [agencyAssignment, setAgencyAssignment] = useState([]);
    const types = [
        {
            text: 'General',
            value: 1,
            path: '/inventory/assetdevices/configuration',
            enabled: true,
        },
        {
            text: 'Connectivity',
            value: 2,
            path: '/inventory/assetdevices/connectivity',
            enabled: state.sim && role !== 'System Administrator' ? Object.keys(state.sim).length > 0 : false,
        },
        // { text: 'Alerts', value: 3, path: '/inventory/assetdevices/alerts' },
        // { text: 'Smart Locks', value: 4, path: '/inventory/assetdevices/smartlocks' },
    ];
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [open, setOpen] = React.useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [saveButtonState, setSaveButtonState] = useState(false);
    const [inputTagsErr, setInputTagsErr] = useState(false);
    const [inputTagsMsg, setInputTagsMsg] = useState('');

    const seperator = [
        <img loading="lazy" src={TruckIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
        <ListItemButton
          disableRipple
          disableGutters
          onClick={() => navigate('/inventory/assetdevices', { replace: true })}
          underline="none"
          style={{ backgroundColor: 'transparent' }}
          sx={{
            display: 'flex',
            color: '#475467',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            }}
        >
            <div>
                Assets & Devices
            </div>
        </ListItemButton>,
        <ListItem
          sx={{
            padding: 0,
            color: '#475467',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontStyle: 'normal',
          }}
        >
            Configurations
        </ListItem>,
    ];

    const assetTypeOptions = [
        {
            value: 'Rail',
            label: 'Rail',
            icon:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 22H14.5M8 2H16M12 5V2M4 12H20M17 19L18.5 22M7 19L5.5 22M8.5 15.5H8.51M15.5 15.5H15.51M8.8 19H15.2C16.8802 19 17.7202 19 18.362 18.673C18.9265 18.3854 19.3854 17.9265 19.673 17.362C20 16.7202 20 15.8802 20 14.2V9.8C20 8.11984 20 7.27976 19.673 6.63803C19.3854 6.07354 18.9265 5.6146 18.362 5.32698C17.7202 5 16.8802 5 15.2 5H8.8C7.11984 5 6.27976 5 5.63803 5.32698C5.07354 5.6146 4.6146 6.07354 4.32698 6.63803C4 7.27976 4 8.11984 4 9.8V14.2C4 15.8802 4 16.7202 4.32698 17.362C4.6146 17.9265 5.07354 18.3854 5.63803 18.673C6.27976 19 7.11984 19 8.8 19ZM9 15.5C9 15.7761 8.77614 16 8.5 16C8.22386 16 8 15.7761 8 15.5C8 15.2239 8.22386 15 8.5 15C8.77614 15 9 15.2239 9 15.5ZM16 15.5C16 15.7761 15.7761 16 15.5 16C15.2239 16 15 15.7761 15 15.5C15 15.2239 15.2239 15 15.5 15C15.7761 15 16 15.2239 16 15.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
            subtitle: 'Categorises all rail and track based transport',
        },
        {
            value: 'Road',
            label: 'Road',
            icon:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 7H16.3373C16.5818 7 16.7041 7 16.8192 7.02763C16.9213 7.05213 17.0188 7.09253 17.1083 7.14736C17.2092 7.2092 17.2957 7.29568 17.4686 7.46863L21.5314 11.5314C21.7043 11.7043 21.7908 11.7908 21.8526 11.8917C21.9075 11.9812 21.9479 12.0787 21.9724 12.1808C22 12.2959 22 12.4182 22 12.6627V15.5C22 15.9659 22 16.1989 21.9239 16.3827C21.8224 16.6277 21.6277 16.8224 21.3827 16.9239C21.1989 17 20.9659 17 20.5 17M15.5 17H14M14 17V7.2C14 6.0799 14 5.51984 13.782 5.09202C13.5903 4.71569 13.2843 4.40973 12.908 4.21799C12.4802 4 11.9201 4 10.8 4H5.2C4.0799 4 3.51984 4 3.09202 4.21799C2.71569 4.40973 2.40973 4.71569 2.21799 5.09202C2 5.51984 2 6.0799 2 7.2V15C2 16.1046 2.89543 17 4 17M14 17H10M10 17C10 18.6569 8.65685 20 7 20C5.34315 20 4 18.6569 4 17M10 17C10 15.3431 8.65685 14 7 14C5.34315 14 4 15.3431 4 17M20.5 17.5C20.5 18.8807 19.3807 20 18 20C16.6193 20 15.5 18.8807 15.5 17.5C15.5 16.1193 16.6193 15 18 15C19.3807 15 20.5 16.1193 20.5 17.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
            subtitle: 'Categorises all road and land based transport',
        },
    ];

    const switchAssetType = (value) => {
        switch (value) {
            case 'Rail':
                return assetTypeOptions[0];
            case 'Road':
                return assetTypeOptions[1];
            default:
                return '';
        }
    };

    const [selectedAssetType, setSelectedAssetType] = useState(switchAssetType(state.assetType[0]));

    const handleAssetNameChange = (event) => {
        setAssetName(event.target.value);
    };

    const fetchAgencies = async (page) => {
        const perPage = 100;
        try {
            const result = await APIGetAgencies(setLoading, token, agencyID, page, perPage);
            if (result.data.status === 200) {
                const { data } = result.data;
                // console.log(data);
                if (data.length > 0) {
                    const reducer = data && data.length > 0 ? data.map((x) => ({
                        value: x.agencyId,
                        label: x.agencyName,
                        subtitle: `@${x.agencyId}`,
                        agencyUUID: x.oid,
                    })) : [];
                    setAgencyList(reducer);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const updateDevice = async (params) => {
        try {
            const result = await APIUpdateDevice(token, agencyID, params);
            if (result.data.status === 200) {
                navigate('/inventory/assetdevices', { state: { snackbarStatus: 'Update', snackbarTitle: 'Success', snackbarSub: 'Device successfully updated!' } });
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const deleteDevice = async () => {
        try {
            const params = {
                oid: [
                    state.id,
                ],
            };
            const result = await APIDeleteDevice(token, setLoading, agencyID, params);
            if (result.data.status === 200) {
                setLoading(false);
                navigate('/inventory/assetdevices', { state: { snackbarStatus: 'Update', snackbarTitle: 'Success', snackbarSub: 'Device successfully deleted!' } });
            } else {
                setLoading(false);
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const checkingConfigReducer = () => {
        const deviceObj = {
            oid: state.id,
        };

        const value = { devices: [deviceObj] };

        if (assetName !== state.assetName) {
            deviceObj.assetName = assetName;
        }

        if (JSON.stringify(tags) !== JSON.stringify(state.tags)) {
            if (removedTags.length > 0) {
                deviceObj.removeTags = removedTags;
            }
            deviceObj.tags = tags;
        }
        if (removedTags.length > 0) {
            deviceObj.removeTags = removedTags;
        }

        if (selectedAssetType !== '') {
            if (selectedAssetType?.value !== state.assetType[0]) {
                deviceObj.assetType = selectedAssetType.value;
           }
        }

        if (descriptionValue !== state.description) {
            deviceObj.description = descriptionValue;
        }

        return value;
    };

    const checkingReducer = () => {
        const deviceObj = {
            oid: state.id,
        };
        const value = { devices: [deviceObj] };

        if (assetName !== state.assetName) {
            deviceObj.assetName = assetName;
        }

        if (agencyAssignment === null) {
            deviceObj.agencyOid = '';
        } else if (agencyAssignment[0]?.value !== state?.agency?.agencyId) {
            if (agencyAssignment[0] === null) {
                deviceObj.agencyOid = '';
            } else {
                deviceObj.agencyOid = agencyAssignment[0]?.agencyUUID;
            }
        }

        if (selectedAssetType !== '') {
            if (selectedAssetType?.value !== state.assetType[0]) {
                deviceObj.assetType = selectedAssetType.value;
           }
        }

        if (descriptionValue !== state.description) {
            deviceObj.description = descriptionValue;
        }

        return value;
    };

    const saveButtonDisabled = () => {
        const value = {};
        if (assetName !== state.assetName) {
            value.assetName = assetName;
        }

        if (JSON.stringify(tags) !== JSON.stringify(state.tags)) {
            if (removedTags.length > 0) {
                value.removeTags = removedTags;
            }
            value.tags = tags;
        }

        if (selectedAssetType !== '') {
            if (selectedAssetType?.value !== state.assetType[0]) {
                value.assetType = selectedAssetType.value;
           }
        }

        if (descriptionValue !== state.description) {
            value.description = descriptionValue;
        }

        const checking = Object.keys(value).length > 0;
        return checking;
    };

    const checkingButtonDisabled = () => {
        const value = {};

        if (assetName !== state?.assetName) {
            value.assetName = assetName;
        }

        if (agencyAssignment === null) {
            value.agencyOid = null;
        } else if (agencyAssignment[0]?.value !== state?.agency?.agencyId) {
            value.agencyOid = agencyAssignment[0]?.agencyUUID;
        }

        if (selectedAssetType !== '') {
            if (selectedAssetType?.value !== state?.assetType[0]) {
                value.assetType = selectedAssetType.value;
            }
        }

        if (descriptionValue !== state?.description) {
            value.description = descriptionValue;
        }

        const checking = Object.keys(value).length > 0;
        return checking;
    };

    const handleConfigurationSaveButton = () => {
        try {
            if (state) {
                const updateList = checkingConfigReducer();
                updateDevice(updateList);
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const handleAdminSaveButton = () => {
        try {
            if (state) {
                const updateList = checkingReducer();
                updateDevice(updateList);
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const copyDevice = () => {
        navigator.clipboard.writeText(state.deviceSN);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpen(false);
    };

    const handleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const inventoryNavigate = () => {
        navigate('/inventory/assetdevices', { replace: true });
    };

    const handleAgencyAssignment = (e) => {
        setAgencyAssignment([e]);
    };

    const switchBody = (value) => {
        // console.log(value);
        switch (value) {
            case true:
                return (
                    <div className="configuration-body">
                        <div className="configuration-body-row">
                            <div className="configuration-main-text">
                                Device Serial Number
                            </div>
                            <div className="device-button">
                                <button type="button" className="device-input-button">
                                    <div className="device-input-text">{state.deviceSN}</div>
                                </button>
                                <button type="button" className="device-copy-button" onClick={copyDevice}>
                                    <img loading="lazy" src={CopyIcon} alt="CopyIcon" />
                                    <div className="device-copy-text">Copy</div>
                                </button>
                            </div>
                        </div>
                        <ColoredLine />
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Asset Name
                                </div>
                                <div className="configuration-supporting-text">
                                    Optional. Use this field to identify the specific asset that you are tracking
                                </div>
                            </div>
                            <input name="assetName" autoComplete="off" value={assetName} onChange={handleAssetNameChange} className="asset-name-input" />
                        </div>
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Agency Assignment
                                </div>
                            </div>
                            <div className="device-button">
                               <ClearableDropdown agencyOption={agencyList} placeholder="Select agency workspace" handleChange={(e) => handleAgencyAssignment(e)} agencyAssignment={agencyAssignment} />
                            </div>
                        </div>
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Asset Type
                                </div>
                            </div>
                            <DropDownList selectedAssetType={selectedAssetType} setSelectedAssetType={setSelectedAssetType} assetTypeOptions={assetTypeOptions} placeholder="Select asset type" />
                        </div>
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Internal Admin Notes
                                </div>
                                <div className="configuration-supporting-text">
                                    Add a brief description to further identify the asset or for specific notes.
                                </div>
                            </div>
                            <TextArea descriptionValue={descriptionValue} setDescriptionValue={setDescriptionValue} />
                        </div>
                        <ColoredLine />
                        <div className="configuration-body-row" style={{ gap: '0.75rem', alignSelf: 'flex-end' }}>
                            <SecondaryButton label="Cancel" width="80px" onClickFunction={inventoryNavigate} />
                            <OutlinedDeleteButton label="Delete Device" onClickFunction={handleDeleteModal} />
                            <PrimaryButton label="Save" width="65px" onClickFunction={handleAdminSaveButton} disable={!saveButtonState} />
                        </div>
                    </div>
                );
            case false:
                return (
                    <div className="configuration-body">
                        <div className="configuration-body-row">
                            <div className="configuration-main-text">
                                Device Serial Number
                            </div>
                            <div className="device-button">
                                <button type="button" className="device-input-button">
                                    <div className="device-input-text">{state.deviceSN}</div>
                                </button>
                                <button type="button" className="device-copy-button" onClick={copyDevice}>
                                    <img loading="lazy" src={CopyIcon} alt="CopyIcon" />
                                    <div className="device-copy-text">Copy</div>
                                </button>
                            </div>
                        </div>
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Asset Name
                                </div>
                                <div className="configuration-supporting-text">
                                    Optional. Use this field to identify the specific asset that you are tracking
                                </div>
                            </div>
                            <input name="assetName" autoComplete="off" value={assetName} onChange={handleAssetNameChange} className="asset-name-input" />
                        </div>
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Tags
                                </div>
                                <div className="configuration-supporting-text">
                                    Apply up to 6 tags to group similar assets
                                </div>
                            </div>
                            <div className="configuration-input-tags-container">
                                <InputTags
                                  tags={tags}
                                  setTags={setTags}
                                  removedTags={removedTags}
                                  setRemovedTags={setRemovedTags}
                                  tagsHistory={tagsHistory}
                                  maxLength="6"
                                  setInputTagsErr={setInputTagsErr}
                                  setInputTagsMsg={setInputTagsMsg}
                                />
                                {
                                    inputTagsErr && (
                                        <div className="configuration-error-message">
                                            {inputTagsMsg}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Asset Type
                                </div>
                            </div>
                            <DropDownList selectedAssetType={selectedAssetType} setSelectedAssetType={setSelectedAssetType} assetTypeOptions={assetTypeOptions} placeholder="Select asset type" />
                        </div>
                        <div className="configuration-body-row">
                            <div className="configuration-text-container">
                                <div className="configuration-main-text">
                                    Description
                                </div>
                                <div className="configuration-supporting-text">
                                    Add a brief description to further identify the asset or for specific notes
                                </div>
                            </div>
                            <TextArea descriptionValue={descriptionValue} setDescriptionValue={setDescriptionValue} />
                        </div>
                        <ColoredLine />
                        <div className="configuration-body-row" style={{ gap: '0.75rem', alignSelf: 'flex-end' }}>
                            <SecondaryButton label="Cancel" width="80px" onClickFunction={inventoryNavigate} />
                            <PrimaryButton label="Save" width="65px" onClickFunction={handleConfigurationSaveButton} disable={!saveButtonState} />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (token) {
            if (role === 'System Administrator') {
                fetchAgencies(1);
            }
        }
        setNavigationState('inventory');
        setNavigtionClick(1);
    }, []);

    useEffect(() => {
        setAgencyAssignment(state ? [agencyList?.find((x) => x.value === state?.agency?.agencyId)] : []);
    }, [agencyList]);

    useEffect(() => {
        if (state) {
            if (role === 'System Administrator') {
                if (checkingButtonDisabled()) {
                    setSaveButtonState(true);
                } else {
                    setSaveButtonState(false);
                }
            } else if (saveButtonDisabled()) {
                setSaveButtonState(true);
            } else {
                setSaveButtonState(false);
            }
        }
    }, [assetName, agencyAssignment, selectedAssetType, descriptionValue, tags, removedTags]);

    return (
        <div className="configuration-container">
            <div className="configuration-header">
                <BreadCrumbs customSeperator={seperator} />
                { !state.assetName ? (
                    <Title title="Configurations" subtitle={`${state.deviceSN}`} titleSize="1.875rem" />
                ) : (
                    <Title title="Configurations" subtitle={`${state.deviceSN} (${state.assetName})`} titleSize="1.875rem" />
                )}
                <div className="configuration-tabs">
                    <ConfigTab color="white" types={types} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} agencyRow={state} />
                </div>
            </div>
            {switchBody(role === 'System Administrator')}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Successfully copied Device Serial Number
                </MuiAlert>
            </Snackbar>
            {
                deleteModal && (
                    <div className="modal-container-table-bg">
                        <Modal setSwitchModalShow={setDeleteModal} switchModalShow={deleteModal} btnConfirm={deleteDevice} module="DeleteModal" source="AdminConsole" dataRow={state} />
                    </div>
                )
            }
        </div>
    );
};

export default Configuration;
