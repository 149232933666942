import React from 'react';
import LowBatteryIcon from './img/lowBattery.svg';
import './batteryState.css';

export default function BatteryState(props) {
    const { dcVoltPerc } = props;
    return (
        <div className="battery-low-container">
            <img loading="lazy" src={LowBatteryIcon} alt="low-battery" />
            <div className="battery-low-text">Low</div>
        </div>
    );
}
