import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { createTheme, ThemeProvider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const generateData = (count, yrange) => {
    let i = 0;
    const series = [];

    while (i < count) {
        const x = `${i}:00`;
        const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        series.push({
            x: x,
            y: y,
        });
        i += 1;
    }
    // console.log(series);

    return series;
};

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#365A6D',
        },
    },
});

const HeatMap = (props) => {
    const { data, loading } = props;

    const reverseArray = [...data].reverse();

    const heatMap = {
        series: reverseArray,
        options: {
            dataLabels: {
                enabled: false,
            },
            chart: {
                fontFamily: 'Inter',
                toolbar: {
                    show: false,
                },
            },
            colors: ['#FF0000'],
        },
    };

    return (
        <div id="chart" style={{ height: '100%' }}>
            {
                loading ? (
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}
                    >
                        <ThemeProvider theme={loadTheme}>
                            <CircularProgress color="amcolor" />
                        </ThemeProvider>
                    </div>
             ) : (<ReactApexChart options={heatMap.options} series={heatMap.series} type="heatmap" height={256} />)
            }
            {/* <ReactApexChart options={heatMap.options} series={heatMap.series} type="heatmap" height={256} /> */}
        </div>
    );
};

export default HeatMap;
