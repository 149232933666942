import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useOutletContext } from 'react-router-dom';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
// import { liveNavigationList } from '../../../config/function/tabNavigationList';
import './assetStatus.css';
import { useApiData } from '../../../config/reducer/Modules';

export default function AssetStatus() {
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const { apiResponse } = useApiData();
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const liveNavigationList = [
        {
            text: 'Map Overview', value: 1, path: '/live/mapOverview', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.liveMap,
        },
        {
            text: 'Telemetry Snapshot', value: 2, path: '/live/telemetrySnapshot', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.telemetrySnapshot,
        },
        // {
        //     text: 'Asset Status', value: 3, path: '/live/assetStatus', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.live?.assetStatus,
        // },
    ];
    // this useEffect for onPageLoad
    useEffect(() => {
    function handleWindowResize() {
        setWindowSize(getWindowSize());
    }
    setNavigtionClick(3);
    setNavigationState('mapOverview');
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    }, []);
    return (
    <div className="telemetry-snapshot-container" style={{ width: windowSize.innerWidth - 280 }}>
        <ListNavigationTab list={liveNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
    </div>
    );
}
