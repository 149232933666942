/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getCountryForTimezone } from 'countries-and-timezones';
import { Badge } from '@mui/material';
import styled from 'styled-components';
import { APIDeleteTeamMember } from '../../../config/restAPI/DeleteAPI';
import ToolTips from '../../tooltip/ToolTips';
import Modal from '../../modal/Modal';
import ProfilePicture from '../img/Avatar.svg';
import VerifiedTick from '../img/verified.svg';
import CubeIcon from '../img/cube.svg';
import TagIcon from '../img/tag.svg';
import Avatar from '../../avatar/Avatar';
import DeleteIcon from '../img/delete.svg';
import DisabledDeleteIcon from '../img/deleteDisabled.svg';
import EditIcon from '../img/edit.svg';
import EditDisabledIcon from '../img/editDisabled.svg';
import HelpCircle from '../img/helpCircle.svg';
import './ColumnTeamSettings.css';

const switchRoleName = (value) => {
    switch (value) {
        case 'Agency Owner':
            return 'Owner';
        case 'Agency Administrator':
            return 'Admin';
        case 'Agency Viewer':
            return 'Viewer';
        case 'Agency Editor':
            return 'Editor';
        default:
            return value;
    }
};

const loggedInRole = switchRoleName(Cookies.get('role'));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: '0.5px solid transparent',
}));

const CustomAccessPrivilegesTitle = () => (
    <div className="access-privilege-title">
        <div>
            Access privileges
        </div>
        <ToolTips text="Help Icon">
            <img src={HelpCircle} alt="help-icon" loading="lazy" />
        </ToolTips>
    </div>
);

const CustomName = ({ row }) => {
    const {
        member, profileImgUrl,
    } = row;
    // console.log(member.firstName);

    const memberFullName = `${member.firstName} ${member.lastName}`;

    return (
        <div className="name-container">
            <div className="name-avatar-container">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={(
                    <>
                        {member.verified === true ? (
                            <SmallAvatar alt="verified tick" src={VerifiedTick} style={{ width: '14px', height: '14px' }} imgProps={{ loading: 'lazy' }} />
                        ) : (
                            <></>
                        )}
                    </>
                  )}
                >
                    {/* <Avatar alt="Rem" src={ProfilePicture} imgProps={{ loading: 'lazy' }} /> */}
                    <Avatar name={memberFullName} alt="Rem" image={profileImgUrl} />
                </Badge>
            </div>
            <div className="table-member-name-container">
                <div>
                    {
                    member.firstName === null || member.lastName === null ? (
                        <div style={{ height: '15.5px' }} />
                    ) : (
                        `${memberFullName}`
                    )
                    }
                </div>
                <div>
                    <div
                      data-tag="allowRowEvents"
                      style={{
                        color: 'grey',
                        overflow: 'hidden',
                        whiteSpace: 'wrap',
                        textOverflow: 'ellipses',
                      }}
                    >
                        {member.email}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CustomLastLogin = ({ row }) => {
    const {
        lastLogin,
    } = row;

    // console.log(lastLogin.time);

    const date = moment(lastLogin.time).format('DD MMM');
    const time = moment(lastLogin.time).format('LT');

    return (
        <div>
            <div>
                {date !== 'Invalid date' && time !== 'Invalid date' ? (
                    <>
                    {date}
                    {' at '}
                    {time}
                    </>
                ) : (
                    <div style={{ height: '15.5px' }} />
                    )}
            </div>
            { }
            {date !== 'Invalid date' && time !== 'Invalid date' && lastLogin.location !== null ? (
                <div
                  data-tag="allowRowEvents"
                  style={{
                    color: 'grey',
                    overflow: 'hidden',
                    whiteSpace: 'wrap',
                    textOverflow: 'ellipses',
                  }}
                >
                    {lastLogin.location}
                </div>
            ) : (
                <div style={{ height: '15.5px' }} />
            )}
        </div>
    );
};

const CustomAccessPrivileges = ({ row }) => {
    const {
        accessPrivileges, tags, modules,
    } = row;

    const fleetKeys = [];
    const insightKeys = [];
    const liveKeys = [];

    const sentenceCase = (text) => {
        const result = text.replace(/([A-Z])/g, ' $1');
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    };

    if ('fleetManagement' in modules) {
        const keys = Object.keys(modules.fleetManagement).filter((key) => modules.fleetManagement[key] === true);
        fleetKeys.push(keys);
    }
    if ('insights' in modules) {
        const keys = Object.keys(modules.insights).filter((key) => modules.insights[key] === true);
        insightKeys.push(keys);
    }
    if ('live' in modules) {
        const keys = Object.keys(modules.live).filter((key) => modules.live[key] === true);
        liveKeys.push(keys);
    }

    const combinedKeys = fleetKeys.concat(insightKeys, liveKeys).flat();

    const moduleName = combinedKeys.map((x) => sentenceCase(x));

    return (
        <div className="access-privilige-cell">
            <div className={`
            ${accessPrivileges === 'Owner' ? 'access-privilege-owner-chip' : 'access-privilege-regular-chip'}
            `}
            >
                {accessPrivileges !== null ? (
                    <>
                    {accessPrivileges}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {
                (row.accessPrivileges === 'Admin') || (row.accessPrivileges === 'Owner') ? (
                    <></>
                ) : moduleName.length > 0 ? (
                    <ToolTips text={moduleName.join(', ')}>
                        <div className="access-privilege-modules">
                            <img src={CubeIcon} alt="cube-icon" loading="lazy" />
                            <div>{moduleName.length}</div>
                        </div>
                    </ToolTips>
                ) : (
                    <></>
                )
            }
            {
                (row.accessPrivileges === 'Admin') || (row.accessPrivileges === 'Owner') ? (
                    <></>
                ) : tags.length > 0 ? (
                    <ToolTips text={tags.join(', ')}>
                        <div className="access-privilege-tags">
                            <img src={TagIcon} alt="tag-icon" loading="lazy" />
                            <div>{tags.length}</div>
                        </div>
                    </ToolTips>
                ) : (
                    <></>
                )
            }
        </div>
    );
};

const Custom2FA = ({ row }) => {
    const {
        twoFA,
    } = row;

    return (
        <>
            {twoFA === true ? (
                <div className="enabled-chip">
                    <div className="enabled-chip-text">
                        Enabled
                    </div>
                </div>
            ) : (
                <>
                </>
            )}
        </>
    );
};

const CustomOptions = (member) => {
    const {
        row,
        rowIndex,
        column,
    } = member;
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const currentRole = switchRoleName(Cookies.get('role'));
    const userId = Cookies.get('_id');
    const [dataRow, setDataRow] = useState();
    const [enable, setEnable] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const divDisabled = currentRole === 'Viewer' || currentRole === 'Editor' || (currentRole !== 'Owner' && row.id === userId) || (currentRole !== 'Owner' && row.accessPrivileges === 'Owner');

    const handleEdit = (event) => {
        navigate('/settings/teams/updateMember', { replace: true, state: { row } });
    };

    const handleDelete = (event) => {
        setDeleteModal(true);
        setDataRow(row);
    };

    const deleteFunction = async () => {
        const params = {
            userOids: [
                dataRow.id,
            ],
        };
        try {
            const result = await APIDeleteTeamMember(token, agencyID, params);
            if (result.status === 200) {
                setDeleteModal(false);
                const calledNav = true;
                navigate('/settings/teams', { state: { calledNav } });
            } else {
                const { data } = result;
                // console.log('unsuccessfully updated: ', data);
            }
        } catch (err) {
            // console.log(err);
        }
    };

    return (
        <div className="member-options-container">
            {
                (currentRole === 'Owner' && row.accessPrivileges === 'Owner') || (row.id === userId) ? (
                    <div className="member-delete" id={rowIndex} aria-hidden="true" onClick={undefined} aria-disabled="true">
                        <ToolTips text="You cannot delete your account">
                            <img src={DisabledDeleteIcon} alt="delete-icon" loading="lazy" />
                        </ToolTips>
                    </div>
                ) : divDisabled ? (
                    <div className="member-delete" id={rowIndex} aria-hidden="true" onClick={undefined} aria-disabled={divDisabled}>
                        <ToolTips text="You cannot delete this account">
                            <img src={DisabledDeleteIcon} alt="delete-icon" loading="lazy" />
                        </ToolTips>
                    </div>
                ) : (
                    <div className="member-delete" id={rowIndex} aria-hidden="true" onClick={handleDelete}>
                        <img src={DeleteIcon} alt="delete-icon" loading="lazy" />
                    </div>
                )
            }
            {
                (row.id === userId) && (row.accessPrivileges !== 'Owner') ? (
                    <div className="member-edit" id={rowIndex} aria-hidden="true" onClick={undefined} aria-disabled={divDisabled}>
                        <ToolTips text="You cannot edit your account">
                            <img src={EditDisabledIcon} alt="edit-icon" loading="lazy" />
                        </ToolTips>
                    </div>
                ) : (row.accessPrivileges === 'Unverified user') ? (
                    <div className="member-edit" id={rowIndex} aria-hidden="true" onClick={undefined} aria-disabled={divDisabled}>
                        <ToolTips text="You cannot edit this account">
                            <img src={EditDisabledIcon} alt="edit-icon" loading="lazy" />
                        </ToolTips>
                    </div>
                ) : divDisabled ? (
                    <div className="member-edit" id={rowIndex} aria-hidden="true" onClick={undefined} aria-disabled={divDisabled}>
                        <ToolTips text="You cannot edit this account">
                            <img src={EditDisabledIcon} alt="edit-icon" loading="lazy" />
                        </ToolTips>
                    </div>
                ) : (
                    <div className="member-edit" id={rowIndex} aria-hidden="true" onClick={handleEdit}>
                        <img src={EditIcon} alt="edit-icon" loading="lazy" />
                    </div>
                )
            }
            {
                deleteModal && (
                    <div className="modal-container-table-bg">
                        <Modal setSwitchModalShow={setDeleteModal} switchModalShow={deleteModal} btnConfirm={deleteFunction} module="DeleteModal" source="Teams" dataRow={row} />
                    </div>
                )
            }
        </div>
    );
};

export const settingsColumn = [
    {
        name: 'Name',
        selector: (row) => row.member.firstName,
        grow: 2.0,
        sortable: true,
        cell: (row) => <CustomName row={row} />,
    },
    {
        name: 'Sessions',
        selector: (row) => row.sessions,
        grow: 0.5,
        sortable: true,
    },
    {
        name: 'Last Login',
        selector: (row) => row.lastLogin.time,
        grow: 1.6,
        sortable: true,
        cell: (row) => <CustomLastLogin row={row} />,
    },
    {
        name: <CustomAccessPrivilegesTitle />,
        selector: (row) => row.accessPrivileges,
        grow: 1.3,
        sortable: true,
        cell: (row) => <CustomAccessPrivileges row={row} />,
    },
    {
        name: '2FA',
        selector: (row) => row.twoFA,
        sortable: true,
        cell: (row) => <Custom2FA row={row} />,
    },
    {
        allowOverflow: true,
        button: true,
        cell: (row, rowIndex) => (
                <CustomOptions
                  row={row}
                  rowIndex={rowIndex}
                />
        ),
    },
];
