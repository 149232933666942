import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import getCountryISO3 from 'country-iso-2-to-3';
import { CountriesWithFlags, CountriesTimezone } from '../../../../../component/countriesDropdown/CountriesDropdown';
import { OutlinedDeleteButton, SecondaryButton, PrimaryButton } from '../../../../../component/buttonComponent/ButtonComponent';
import BreadCrumbs from '../../../../../component/breadcrumbs/BreadCrumbs';
import BuildingIcon from '../../../../../image/building.svg';
import CopyIcon from '../../../../../image/copy.svg';
import Title from '../../../../../component/title/Title';
import ConfigTab from '../../../../../component/configurationTab/configurationTab';
import TextInput from '../../../../../component/textInput/TextInput';
import Text from '../../../../../component/text/Text';
import ColoredLine from '../../../../../component/coloredLine/ColoredLine';
import Modal from '../../../../../component/modal/Modal';
import { APIDeleteAgency } from '../../../../../config/restAPI/DeleteAPI';
import { APIPatchAgencyDetails } from '../../../../../config/restAPI/PatchAPI';
import './agencyConfigurations.css';

const seperator = [
    <img loading="lazy" src={BuildingIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Agencies</div>,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Configurations</div>,
];

const navigationList = [
    {
        text: 'General',
        value: 1,
        path: '/agencies/Configurations/General',
        enabled: true,
    },
    {
        text: 'Inventory',
        value: 2,
        path: '/agencies/Configurations/Inventory',
        enabled: true,
    },
    {
        text: 'Users',
        value: 3,
        path: '/agencies/Configurations/Users',
        enabled: true,
    },
    {
        text: 'Billing',
        value: 4,
        path: '/agencies/Configurations/Billing',
        enabled: true,
    },
];

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const AgencyConfigurations = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agencyUUID');
    const [agencyData, setAgencyData] = useState({
        oid: state.oid,
        agencyId: state.agencyId,
        agencyName: state.agencyName,
        address: state.address,
        timezone: state.timezone,
        totalUsers: state.totalUsers,
        totalDevices: state.totalDevices,
    });
    const [upadteAgencyData, setUpdateAgencyData] = useState(agencyData);
    const [agencyName, setAgencyName] = useState(agencyData.agencyName);
    const [streetAddress, setStreetAddress] = useState(agencyData.address.street);
    const [city, setCity] = useState(agencyData.address.city);
    const [agencyState, setAgencyState] = useState(agencyData.address.state);
    const [postCode, setPostCode] = useState(agencyData.address.postcode);
    const [agencyCountry, setAgencyCountry] = useState(agencyData.address.country);
    const [agencyCountryCode, setAgencyCountryCode] = useState(agencyData.address.countryCode);
    const [agencyTimezone, setAgencyTimezone] = useState(agencyData.timezone);
    const [timezoneValue, setTimezoneValue] = useState(false);

    const [open, setOpen] = useState(false);
    const [navigtionClick, setNavigtionClick] = useOutletContext();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [deleteAgencyID, setDeleteAgencyID] = useState('');
    const fullHeight = windowSize.innerHeight;
    const disableDeleteButton = deleteAgencyID === agencyData.agencyId;
    const [saveButton, setSaveButtonstate] = useState(false);

    const countriesSelectorRef = useRef(null);
    const countriesTimezoneRef = useRef(null);

    const [inputSelectonText, setInputSelectonText] = useState('');
    const [switchModalShow, setSwitchModalShow] = useState(false);

    // Country dropdown
    const [listCountry, setListCountry] = useState([]); // this is for list search country dropdown
    const [countrySelectionList, setCountrySelectionList] = useState({}); // this for country selection state
    const [inputSelectonTextCountry, setInputSelectonTextCountry] = useState('');
    const [selectedFlag, setSelectedFlag] = useState('');
    const [countryUpdated, setCountryUpdated] = useState(0);

    // Timezone dropdown
    const [listTimezone, setListTimezone] = useState([]);
    const [timezoneSelectionList, setTimezoneSelectionList] = useState({});

    const heightPercentage = (ref) => {
        let heightState;
        if (ref.current !== null) {
            const percentage = (ref.current.offsetTop / windowSize.innerHeight) * 100;
            if (percentage > 60) {
                heightState = true;
            } else {
                heightState = false;
            }
        }
        return heightState;
    };

    const copyDevice = () => {
        navigator.clipboard.writeText(agencyData.agencyId);
        setOpen(true);
    };

    const handleCancelButton = () => {
        navigate('/agencies', { replace: true });
    };

    const handleDeleteModal = () => {
        setSwitchModalShow(true);
    };

    const handleDeleteAgency = async () => {
        const params = {
            agency: {
                oid: agencyData.oid,
                agencyId: agencyData.agencyId,
            },
        };
        try {
            const result = await APIDeleteAgency(token, agencyId, params);
            if (result.data.status === 200) {
                navigate('/agencies', { replace: true });
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const handleUpdateAgency = async (params) => {
        // console.log(params);
        try {
            const result = await APIPatchAgencyDetails(token, agencyId, params);
            if (result.data.status === 200) {
                navigate('/agencies', { replace: true });
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const updateReducer = () => {
        const value = {
            agency: {
                oid: agencyData.oid,
            },
        };

        if (agencyName !== agencyData.agencyName) {
            value.agency.agencyName = agencyName;
        }

        if (streetAddress !== agencyData.address.street) {
            value.agency.address = {
                ...value.agency.address,
                street: streetAddress,
            };
        }

        if (city !== agencyData.address.city) {
            value.agency.address = {
                ...value.agency.address,
                city: city,
            };
        }

        if (agencyState !== agencyData.address.state) {
            value.agency.address = {
                ...value.agency.address,
                state: agencyState,
            };
        }

        if (postCode !== agencyData.address.postcode) {
            value.agency.address = {
                ...value.agency.address,
                postcode: postCode,
            };
        }

        if (agencyCountryCode !== agencyData.address.countryCode) {
            value.agency.address = {
                ...value.agency.address,
                countryCode: agencyCountryCode,
                country: agencyCountry,
            };
        }

        if (agencyTimezone !== agencyData.timezone) {
            value.agency.timezone = agencyTimezone;
        }

        return value;
    };

    const handleUpdateSaveButton = () => {
        try {
            if (agencyData) {
                const updateList = updateReducer();
                handleUpdateAgency(updateList);
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const saveButtonDisabled = () => {
        const value = {};

        if (agencyName !== agencyData.agencyName) {
            value.agencyName = agencyName;
        }

        if (streetAddress !== agencyData.address.street) {
            value.address = {
                ...value.address,
                street: streetAddress,
            };
        }

        if (city !== agencyData.address.city) {
            value.address = {
                ...value.address,
                city: city,
            };
        }

        if (agencyState !== agencyData.address.state) {
            value.address = {
                ...value.address,
                state: agencyState,
            };
        }

        if (postCode !== agencyData.address.postcode) {
            value.address = {
                ...value.address,
                postcode: postCode,
            };
        }

        if (agencyCountryCode !== agencyData.address.countryCode) {
            value.address = {
                ...value.address,
                countryCode: agencyCountryCode,
                country: agencyCountry,
            };
        }

        if (agencyTimezone !== agencyData.timezone) {
            value.timezone = agencyTimezone;
        }

        const checking = Object.keys(value).length > 0;
        return checking;
    };

    /// COUNTRY ONCHANGE ///
    useEffect(() => {
        if ((countryUpdated > 0) && (timezoneSelectionList.name)) {
            if (countrySelectionList.id.length !== undefined) {
                if (countrySelectionList.id === agencyData.address.countryCode) {
                    const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                    setAgencyCountry(countrySelectionList.name);
                    setAgencyCountryCode(countrySelectionList.id);
                    setAgencyTimezone(filteredTimezone);
                } else if (countrySelectionList.id !== agencyData.address.countryCode) {
                    const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                    setAgencyCountry(countrySelectionList.name);
                    setAgencyCountryCode(countrySelectionList.id);
                    setAgencyTimezone(filteredTimezone);
                }
            }
        }
    }, [countryUpdated, timezoneSelectionList.name]);

    /// TIMEZONE ONCHANGE ///
    useEffect(() => {
        if (timezoneValue) {
            if (agencyData.timezone) {
                if (!(timezoneSelectionList.name).includes(agencyData.timezone)) {
                    const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                    setAgencyTimezone(filteredTimezone);
                }
            } else {
                const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                setAgencyTimezone(filteredTimezone);
            }
        }
    }, [timezoneValue, timezoneSelectionList.name]);

    useEffect(() => {
        if (agencyData) {
            if (saveButtonDisabled()) {
                setSaveButtonstate(false);
            } else {
                setSaveButtonstate(true);
            }
        }
    }, [agencyName, streetAddress, city, agencyState, postCode, agencyCountry, agencyCountryCode, agencyTimezone]);

    return (
        <div className="agency-configurations-container">
            <div className="agency-configurations-header">
                <div className="agency-configurations-page-header">
                    <BreadCrumbs customSeperator={seperator} />
                    <Title title="Configurations" titleSize="1.875rem" lineHeight="2.375rem" deleteSubtitle>
                        {agencyData.agencyName}
                        {' '}
                        •
                        {' '}
                        {agencyData.agencyId}
                    </Title>
                </div>
                <div className="agency-configurations-tabs">
                    <ConfigTab color="#FFFFFF" types={navigationList} module="agencies" users={agencyData.totalUsers} agencyRow={agencyData} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="agency-configurations-body">
                <div className="agency-configurations-form">
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <Text text="Agency ID" color="#344054" size="14px" weight="500" height="14px" lineHeight="20px" />
                        </div>
                        <div className="agency-configurations-input-button-container">
                            <button type="button" className="agency-configurations-input-button">
                                <div className="agency-configurations-input-text">{agencyData.agencyId}</div>
                            </button>
                            <button type="button" className="agency-configurations-copy-button" onClick={copyDevice}>
                                <img loading="lazy" src={CopyIcon} alt="CopyIcon" />
                                <div className="agency-configurations-copy-text">Copy</div>
                            </button>
                        </div>
                    </div>
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <Text text="Agency Name" height="auto" color="#344054" size="14px" weight="500" lineHeight="20px" />
                            <Text
                              text="Legal entity name in accordance with agreements or official binding document"
                              color="#475467"
                              height="auto"
                              size="14px"
                              weight="400"
                              lineHeight="20px"
                            />
                        </div>
                        <TextInput
                          label="AgencyID"
                          width="30.5rem"
                          error={false}
                          success={false}
                          value={agencyName}
                          type="text"
                          onChangeFunction={(e) => setAgencyName(e.target.value)}
                        />
                    </div>
                    <ColoredLine />
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <Text text="Street Address" height="auto" color="#344054" size="14px" weight="500" lineHeight="20px" />
                        </div>
                        <TextInput
                          label="AgencyID"
                          width="30.5rem"
                          error={false}
                          success={false}
                          value={streetAddress}
                          type="text"
                          onChangeFunction={(e) => setStreetAddress(e.target.value)}
                        />
                    </div>
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <Text text="City" height="auto" color="#344054" size="14px" weight="500" lineHeight="20px" />
                        </div>
                        <TextInput
                          label="AgencyID"
                          width="30.5rem"
                          error={false}
                          success={false}
                          value={city}
                          type="text"
                          onChangeFunction={(e) => setCity(e.target.value)}
                        />
                    </div>
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <Text text="State" height="auto" color="#344054" size="14px" weight="500" lineHeight="20px" />
                        </div>
                        <TextInput
                          label="AgencyID"
                          width="30.5rem"
                          error={false}
                          success={false}
                          value={agencyState}
                          type="text"
                          onChangeFunction={(e) => setAgencyState(e.target.value)}
                        />
                    </div>
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <Text text="Postcode" height="auto" color="#344054" size="14px" weight="500" lineHeight="20px" />
                        </div>
                        <TextInput
                          label="AgencyID"
                          width="30.5rem"
                          error={false}
                          success={false}
                          value={postCode}
                          type="text"
                          onChangeFunction={(e) => setPostCode(e.target.value)}
                        />
                    </div>
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <div className="agency-configurations-row-text" ref={countriesSelectorRef}>
                                Country
                            </div>
                        </div>
                        <div className="inner">
                            <CountriesWithFlags
                              list={listCountry}
                              setSelectionList={setCountrySelectionList}
                              selectionList={countrySelectionList}
                              text="Select country"
                              isImage
                              stateInputValue={setInputSelectonText}
                              width="30.5rem"
                              setSelectedFlag={setSelectedFlag}
                              initialValue={agencyData.address.countryCode}
                              timezoneCheck={upadteAgencyData.timezone}
                              lowPosition={heightPercentage(countriesSelectorRef)}
                              setCountryUpdated={setCountryUpdated}
                              countryUpdated={countryUpdated}
                            />
                        </div>
                    </div>
                    <div className="agency-configurations-form-row">
                        <div className="agency-configurations-form-text-container">
                            <div className="agency-configurations-row-text" ref={countriesTimezoneRef}>
                                Timezone
                            </div>
                        </div>
                        <div className="inner">
                            <CountriesTimezone
                              list={listTimezone}
                              setTimezoneSelectionList={setTimezoneSelectionList}
                              timezoneSelectionList={timezoneSelectionList}
                              text="Select timezone"
                              isImage
                              timezone
                              stateInputValue={setInputSelectonTextCountry}
                              width="30.5rem"
                              selectedFlag={selectedFlag}
                              lowPosition={heightPercentage(countriesTimezoneRef)}
                              initialValue={upadteAgencyData.timezone}
                              timezoneCheck={upadteAgencyData.timezone}
                              countryUpdated={countryUpdated}
                              setTimezoneValue={setTimezoneValue}
                              timezoneValue={timezoneValue}
                            />
                        </div>
                    </div>
                    <div className="agency-configurations-form-delete-row">
                        <div className="agency-configurations-form-row">
                            <div className="agency-configurations-form-text-container">
                                <Text text="Delete Agency" height="auto" color="#B42318" size="14px" weight="500" lineHeight="20px" />
                                <div className="agency-configurations-delete-warning-text">
                                    Warning: This process is irreversible!
                                    <ul style={{ paddingInlineStart: '25px' }}>
                                        <li>All assigned inventories will be detached and made available.</li>
                                        <li>Historical data will be available as per Horizon retention policy.</li>
                                        <li>User profiles with no access to other agency workspaces will be permanently deleted after 30 days.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="agency-configurations-form-delete-input-text">
                                <TextInput
                                  label="AgencyID"
                                  width="30.5rem"
                                  error={false}
                                  success={false}
                                  value={deleteAgencyID}
                                  placeholder="Input agency ID"
                                  type="text"
                                  onChangeFunction={(e) => setDeleteAgencyID(e.target.value)}
                                />
                                <div>
                                    Input the agency ID,
                                    {' '}
                                    <b>{agencyData.agencyId}</b>
                                    {' '}
                                    to confirm intentions to delete this agency.
                                </div>
                            </div>
                            <div className="agency-configurations-delete-button">
                                <OutlinedDeleteButton label="Delete" module="agencies" buttonHeight="40px" buttonWidth="104px" disable={!disableDeleteButton} onClickFunction={handleDeleteModal} />
                            </div>
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="agency-configurations-button-group">
                        <SecondaryButton label="Cancel" onClickFunction={handleCancelButton} />
                        <PrimaryButton label="Save" onClickFunction={handleUpdateSaveButton} disable={saveButton} />
                    </div>
                    { switchModalShow && (
                        <div className="modal-container-table-bg">
                            <Modal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} btnConfirm={handleDeleteAgency} dataRow={agencyData} source="Agencies" module="DeleteModal" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AgencyConfigurations;
