import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import OtpInput from '../../component/otpInput/OtpInput';
import Title from '../../component/title/Title';
import EmailIcon from './img/mailIcon.svg';
import Text from '../../component/text/Text';
import { APIVerifyEmail } from '../../config/restAPI/PostAPI';
import './verifyEmail.css';
import { PrimaryButton, TextButton, BackButton } from '../../component/buttonComponent/ButtonComponent';

export default function VerifyEmail() {
    const navigate = useNavigate();

    const [otpValue, setOtpValue] = useState('');
    const [keyUp, setKeyUp] = useState('', false);
    const [disableVerifyButton, setDisableVerifyButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invalidOTP, setInvalidOTP] = useState('');
    const token = Cookies.get('jwtToken');

    // Decode email
    const [encodedStringBtoA, setEncodedStringBtoA] = useState('');
    const [emailDecoded, setEmailDecoded] = useState('');

    // Retrieve and encode email on page load
    useEffect(() => {
        try {
            const url = window.location.pathname;
            const params = url.replace('/verifyEmail/', '');

            const tempsecretkey = 'dev_asiamobiliti';
            const decodeParams = CryptoJS.AES.decrypt(params, tempsecretkey).toString(CryptoJS.enc.Utf8);

            setEncodedStringBtoA(btoa(JSON.parse(decodeParams).email));

            setEmailDecoded(JSON.parse(decodeParams).email);
        } catch (e) {
            console.log('ERROR PARSING UNDEFINED JSON: ', e);
        }
    }, []);

    // Clear incorrect verification code input
    useEffect(() => {
        if (invalidOTP) {
            setOtpValue('');
        }
    }, [invalidOTP]);

    // Remove invalid OTP error message when typing in OTP
    useEffect(() => {
        if (otpValue) {
            setInvalidOTP('');
        }
    }, [otpValue]);

    // Called from 'Verify email' button
    const btnVerifyEmail = async (e) => {
        e.preventDefault();
        setDisableVerifyButton(true);
        setLoading(true);
        try {
            const param = { userEmail: encodedStringBtoA, emailVerificationCode: +otpValue };
            if (token) {
                const result = await APIVerifyEmail(param);
                if (result.data.status === 200) {
                    navigate('/login', { replace: true });
                    setInvalidOTP('');
                    setLoading(false);
                } else if (result.data.status === 501) {
                    setDisableVerifyButton(false);
                    setInvalidOTP('Incorrect verification code. Please try again.');
                    setLoading(false);
                }
            }
        } catch (err) {
            // console.log(err);
            setDisableVerifyButton(false);
            setInvalidOTP('Incorrect verification code. Please try again.');
            setLoading(false);
        }
    };

    // Call btnVerifyEmail when the 'Enter' button is tapped [WIP]
    useEffect(() => {
        if (keyUp !== '') {
            btnVerifyEmail();
        }
    }, [keyUp]);

    const btnClickBack = () => {
        navigate('/login', { replace: true });
    };

    return (
        <div className="verify-email-container">
            <div className="verify-email-header">
                <div className="email-icon">
                    <img src={EmailIcon} loading="lazy" alt="email-icon" />
                </div>
                <Text text="Check your email" size="1.875rem" weight="600" align="center" height="2.375rem" lineHeight="2.375rem" />
                <div className="email-subtitle">
                    <Text text="We sent a verification link to" color="#475467" size="1rem" weight="400" align="center" height="auto" lineHeight="3rem" />
                    <Text text={emailDecoded} color="#475467" size="1rem" weight="500" align="center" height="auto" lineHeight="0rem" />
                </div>
            </div>
            <div className="otp-container">
                <OtpInput value={otpValue} valueLength={6} setOtpValue={setOtpValue} setKeyUp={setKeyUp} keyUp={keyUp} />
                {invalidOTP && (
                    <div className="invalid-otp-container">
                        <div className="invalid-otp">
                            {invalidOTP}
                        </div>
                    </div>
                )}
            </div>
            <PrimaryButton label="Verify email" onClickFunction={btnVerifyEmail} disable={disableVerifyButton} loading={loading} />
            <div className="resend-email-conatiner">
                Didn’t receive the email?
                {/* <TextButton label="Click to resend" /> */}
                <a href="https://asiamobiliti" className="resend-button">Click to resend</a>
            </div>
            <BackButton label="Back to login" color="#FFFFFF" btnClickBack={btnClickBack} />
        </div>
    );
}
