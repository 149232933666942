import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Popover } from '@mui/material';
import ToolTips from '../../tooltip/ToolTips';
import MenuPopup from '../../menuPopup/MenuPopup';
import VerticalDots from '../img/verticalDots.svg';
import './ColumnAgencies.css';

const CustomAgencyName = ({ row }) => {
    const { agencyId, agencyName } = row;

    return (
        <div className="agencyName-container">
            <div
              className="agencyName-text"
              style={{
                overflow: 'hidden',
                whiteSpace: 'wrap',
                textOverflow: 'ellipses',
            }}
            >
                {agencyName}
            </div>
            <div>
                <div className="agencyId-text" data-tag="allowRowEvents">
                    {agencyId}
                </div>
            </div>
        </div>
    );
};

const CustomTotalDevices = ({ row }) => {
    const { totalDevices } = row;

    return (
        <div className="totalDevices-badge">
            {totalDevices}
        </div>
    );
};

const CustomTotalUsers = ({ row }) => {
    const { totalUsers } = row;

    return (
        <div className="totalDevices-badge">
            {totalUsers}
        </div>
    );
};

const CustomStatus = ({ row }) => {
    const { status } = row;

    let background;
    let color;

    switch (status) {
        case 'Active':
            background = '#ECFDF3';
            color = '#027A48';
            break;
        case 'Stale':
            background = '#FFFAEB';
            color = '#B54708';
            break;
        case 'Suspended':
            background = '#FEF3F2';
            color = '#B42318';
            break;
        default:
            break;
    }

    return (
        <div
          className="status-badge"
          style={{
            background: background,
            color: color,
          }}
        >
            {status}
        </div>
    );
};

const CustomLastLogin = ({ row }) => {
    const { lastLogin } = row;
    const { date, address } = lastLogin;

    const formatDate = moment(date).format('DD MMM YYYY');
    const formatTime = `${moment(date).format('h:mm:ssa')} GMT${moment(date).format('ZZ').replace('00', '').replace('0', '')}`;

    return (
        <div className="agenciesLastLogin-container">
            <div className="agenciesLastLogin-date">
                {date !== null ? (
                    formatDate
                ) : (
                    <div style={{ height: '15.5px' }} />
                )}
            </div>
            <div className="agenciesLastLogin-time">
                {address !== null ? (
                    address
                ) : (
                    <div style={{ height: '15.5px' }} />
                )}
            </div>
        </div>
    );
};

const CustomMaasServices = ({ row }) => {
    const { MaasServices } = row;

    const [assetTagsOverflowCount, setAssetTagsOverflowCount] = useState(0);
    const [assetTags, setAssetTags] = useState([]);
    const [overflowAssetTags, setOverflowAssetTags] = useState([]);
    const [hover, setHover] = useState(false);

    const ifAssetTagsOverflow = () => {
        if (MaasServices && MaasServices.length > 2) {
            const overflowedAssetTags = MaasServices.slice(2);
            setAssetTags(MaasServices.slice(0, 2));
            setOverflowAssetTags(overflowedAssetTags);
            setAssetTagsOverflowCount(overflowedAssetTags.length);
        }
    };

    useEffect(() => {
        ifAssetTagsOverflow();
    }, []);

    return (
        <>
        {MaasServices && MaasServices.length > 2 ? (
            <>
                {assetTags.map((value) => (
                    <div className="tags">
                        <div className="tags-text">
                            {value}
                        </div>
                    </div>
                ))}
                <ToolTips text={overflowAssetTags.join(', ')} disabled={!hover}>
                    <div className="overflow-tags">
                        <div>{`+${assetTagsOverflowCount}`}</div>
                    </div>
                </ToolTips>
            </>
        ) : (
            <>
            {MaasServices && MaasServices.map((value) => (
                <div className="tags">
                    <div className="tags-text">
                        {value}
                    </div>
                </div>
            ))}
            </>
        )}
        </>
    );
};

const CustomMenuButton = (assets) => {
    const { row, rowIndex } = assets;
    const [anchorEl, setAnchorEl] = useState(null);
    const [agencyRow, setAgencyRow] = useState();
    const handleClick = (event) => {
        setAgencyRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAgencyRow();
        setAnchorEl(null);
    };

    return (
        <div>
            <div className="assets-button" id={rowIndex} aria-hidden="true" onClick={handleClick}>
                <div className="assets-menu">
                    <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                </div>
            </div>
            <Popover
              id={rowIndex}
              sx={{
                boxShadow: 2,
                borderRadius: 2,
            }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <MenuPopup page="agencies" agencyRow={agencyRow} />
            </Popover>
        </div>
    );
};

export const agenciesColumn = [
    {
        name: 'Agency Name / ID',
        sortable: true,
        grow: '2',
        selctor: (row) => row.agencyName,
        cell: (row) => <CustomAgencyName row={row} />,
    },
    {
        name: 'Devices',
        center: true,
        cell: (row) => <CustomTotalDevices row={row} />,
    },
    {
        name: 'Users',
        center: true,
        cell: (row) => <CustomTotalUsers row={row} />,
    },
    {
        name: 'Status',
        cell: (row) => <CustomStatus row={row} />,
    },
    {
        name: 'Last Login',
        cell: (row) => <CustomLastLogin row={row} />,
    },
    {
        name: 'MaaS Services',
        grow: '2',
        cell: (row) => <CustomMaasServices row={row} />,
        style: {
            gap: '0.25rem',
        },
    },
    {
        cell: (row, rowIndex) => (
              <CustomMenuButton row={row} rowIndex={rowIndex} />
        ),
        allowOverflow: true,
        button: true,
        width: '48px',
    },
];
