import React from 'react';
import { TelegramButton } from '../../buttonComponent/ButtonComponent';
import '../stepComponent.css';

const telegramLink = process.env.REACT_APP_TELEGRAM_CHATBOT;

const TelegramStep1 = ({ isEnabled }) => (
        <>
            <ol className="step-list-styling">
                <li>
                    Access the
                    {' '}
                    { isEnabled ? (
                        <a href="/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', pointerEvents: 'none' }}>
                            Horizon Go Telegram chatbot
                        </a>
                    ) : (
                        <a href={telegramLink} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                            Horizon Go Telegram chatbot
                        </a>
                    )}
                    {' '}
                    and enter
                    {' '}
                    <b>
                        /start
                    </b>
                    {' '}
                    to begin.
                </li>
                <li>
                    Enter
                    {' '}
                    <b>
                        /connect
                    </b>
                    {' '}
                    to begin the registration process and follow the onscreen instructions.
                </li>
                <li>
                    Tap on
                    {' '}
                    <b>
                        Send Phone Number
                    </b>
                    {' '}
                    to provide your phone number to complete the registration process.
                </li>
            </ol>
        </>
);

const TelegramStep2 = () => (
    <>
        Allow Horizon Telegram chatbot to send you messages by tapping on
        {' '}
        <b>
            Enable Notifications.
        </b>
        <br />
        <br />
        You can always manage your preference via the
        {' '}
        <b>
            /settings
        {' '}
        </b>
        command.
    </>
);

const TelegramStep3 = ({ onClickFunction, isEnabled }) => (
    <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        Verify that your connection with the Horizon Telegram chatbot is working as intended.
        <TelegramButton label="Send Test Message" onClickFunction={() => onClickFunction()} module="telegram" buttonWidth="196px" disable={isEnabled} />
    </div>
);

const TelegramStep4 = () => (
    <>
        You are now connected to the Horizon Telegram chatbot.
    </>
);

export const TelegramSteps = [
    {
        id: 1,
        label: 'Register with Horizon Telegram chatbot',
        description: (isEnabled) => <TelegramStep1 isEnabled={isEnabled} />,
    },
    {
        id: 2,
        label: 'Enable notifications',
        description: () => <TelegramStep2 />,
    },
    {
        id: 3,
        label: 'Test connection',
        description: (onClickFunction, isEnabled) => <TelegramStep3 onClickFunction={onClickFunction} isEnabled={isEnabled} />,
    },
    {
        id: 4,
        label: 'Complete',
        description: () => <TelegramStep4 />,
    },
];

// const TelegramSteps = (props) => {
//     const { onClickFunction } = props;
//     const steps = [
//         {
//             id: 1,
//             label: 'Register with Horizon Telegram chatbot',
//             description:
//                 <>
//                     <ol className="step-list-styling">
//                         <li>
//                             Access the
//                             {' '}
//                             <a href={telegramLink} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
//                                 Horizon Go Telegram chatbot
//                             </a>
//                             {' '}
//                             and enter
//                             {' '}
//                             <b>
//                                 /start
//                             </b>
//                             {' '}
//                             to begin.
//                         </li>
//                         <li>
//                             Enter
//                             {' '}
//                             <b>
//                                 /connect
//                             </b>
//                             {' '}
//                             to begin the registration process and follow the onscreen instructions.
//                         </li>
//                         <li>
//                             Tap on
//                             {' '}
//                             <b>
//                                 Send Phone Number
//                             </b>
//                             {' '}
//                             to provide your phone number to complete the registration process.
//                         </li>
//                     </ol>
//                 </>,
//         },
//         {
//             id: 2,
//             label: 'Enable notifications',
//             description:
//                 <>
//                     Allow Horizon Telegram chatbot to send you messages by tapping on
//                     {' '}
//                     <b>
//                         Enable Notifications.
//                     </b>
//                     <br />
//                     <br />
//                     You can always change your preference within
//                     {' '}
//                     <b>
//                         /setting
//                     </b>
//                     .
//                 </>,
//         },
//         {
//             id: 3,
//             label: 'Test connection',
//             description:
//                 <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
//                     Verify that your connection with the Horizon Telegram chatbot is working as intended.
//                     <TelegramButton label="Send Test Message" onClickFunction={(e) => console.log(e)} module="telegram" buttonWidth="196px" />
//                 </div>,
//         },
//         {
//             id: 4,
//             label: 'Complete',
//             description: 'You are now connected to the Horizon Telegram chatbot.',
//         },
//     ];

//     return (
//         steps
//     );
// };
