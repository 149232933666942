/* eslint-disable no-useless-escape */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { CountriesWithFlags, CountriesTimezone } from '../../../../component/countriesDropdown/CountriesDropdown';
import { BackButton, PrimaryButton, SecondaryButton } from '../../../../component/buttonComponent/ButtonComponent';
import Title from '../../../../component/title/Title';
import ColoredLine from '../../../../component/coloredLine/ColoredLine';
import TextInput from '../../../../component/textInput/TextInput';
import { APICreateAgency } from '../../../../config/restAPI/PostAPI';
import './createAgency.css';

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const CreateAgency = () => {
    const navigate = useNavigate();
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agencyUUID');
    const [agencyIdInput, setAgencyIdInput] = useState('');
    const [agencyIdErr, setAgencyIdErr] = useState(false);
    const [agencyIdErrMessage, setAgencyIdErrMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [agencyName, setAgencyName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postCode, setPostCode] = useState('');
    const [windowSize, setWindowSize] = useState(getWindowSize());
    // Country dropdown
    const [listCountry, setListCountry] = useState([]); // this is for list search country dropdown
    const [countrySelectionList, setCountrySelectionList] = useState({}); // this for country selection state
    const [inputSelectonTextCountry, setInputSelectonTextCountry] = useState('');
    const [selectedFlag, setSelectedFlag] = useState('');
    const [countryUpdated, setCountryUpdated] = useState(0);

   // Timezone dropdown
   const [listTimezone, setListTimezone] = useState([]);
   const [timezoneSelectionList, setTimezoneSelectionList] = useState({});

    const disableButton = !((agencyIdErr === false && agencyIdInput.length >= 4) && (agencyName.length > 0 && Object.keys(timezoneSelectionList).length !== 0));
    // console.log(disableButton);

    const countriesSelectorRef = useRef(null);
    const countriesTimezoneRef = useRef(null);

    const [inputSelectonText, setInputSelectonText] = useState('');

    const regex = new RegExp(/^(?!admin|system)(?!\/\.\ \"\$\*\<\>\:\|\?)(?=[a-zA-Z0-9]{1})((\w)|([-])(\w)){4,63}$/m);

    const heightPercentage = (ref) => {
        let heightState;
        if (ref.current !== null) {
            const percentage = (ref.current.offsetTop / windowSize.innerHeight) * 100;
            if (percentage > 60) {
                heightState = true;
            } else {
                heightState = false;
            }
        }
        return heightState;
    };

    const handleOnChange = (value) => {
        if (regex.test(value) === true) {
            setSuccess(true);
            setAgencyIdInput(value);
            setAgencyIdErr(false);
        } else if (regex.test(value) === false) {
            setSuccess(false);
            setAgencyIdInput(value);
            setAgencyIdErr(true);
            setAgencyIdErrMessage(
                <>
                Agency ID must:
                    <ul style={{ paddingInlineStart: '25px' }}>
                        <li>not start with &quot;admin&quot; or &quot;system&quot;.</li>
                        <li>not contain special characters.</li>
                        <li>contain at least one alphanumeric character (a-z, A-Z, 0-9).</li>
                        <li>be between 4 and 63 characters.</li>
                    </ul>
                </>,
            );
        }
    };

    const CreateNewAgency = async () => {
        const params = {
            agency: {
                agencyId: agencyIdInput,
                agencyName: agencyName,
                address: {
                    street: streetAddress,
                    city: city,
                    state: state,
                    postcode: postCode,
                    country: countrySelectionList.name,
                    countrCode: countrySelectionList.id,
                },
                timezone: timezoneSelectionList.name,
            },
        };
        try {
            const result = await APICreateAgency(token, agencyId, params);
            if (result.data.status === 200) {
                navigate('/agencies', { replace: true });
            } else if (result.data.status === 400) {
                const { data } = result;
                if (data.message === 'Agency with agency id testagency is already exist') {
                    setAgencyIdErr(true);
                    setAgencyIdErrMessage('Agency ID already exists.');
                }
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const handleNavigate = () => {
        navigate('/agencies', { replace: true });
    };

    return (
        <div className="create-agency-container">
            <div className="create-agency-header">
                <BackButton label="Back to agencies" color="#FFFFFF" btnClickBack={() => handleNavigate()} />
            </div>
            <div className="create-agency-form">
                <div className="create-agency-form-header">
                    <div className="create-agency-form-title">
                        <Title title="Create agency" titleSize="1.125rem" lineHeight="1.75rem" deleteSubtitle />
                        <div className="create-agency-button-group">
                            <SecondaryButton label="Cancel" onClickFunction={() => handleNavigate()} />
                            <PrimaryButton label="Create" onClickFunction={CreateNewAgency} disable={disableButton} />
                        </div>
                    </div>
                    <ColoredLine />
                </div>
                <div className="create-agency-form-body">
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-required-field">
                                Agency ID
                            </div>
                            <div className="create-agency-row-supporting-text">
                                This cannot be modified at a later stage
                            </div>
                        </div>
                        <div className="create-agency-input">
                            <TextInput
                              label="AgencyID"
                              success={success}
                              error={agencyIdErr}
                              setError={setAgencyIdErr}
                              width="30.5rem"
                              type="text"
                              onChangeFunction={(e) => handleOnChange(e.target.value)}
                            />
                            {agencyIdErr && (
                                <div className="create-agency-error">{agencyIdErrMessage}</div>
                            )}
                        </div>
                    </div>
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-required-field">
                                Agency Name
                            </div>
                            <div className="create-agency-row-supporting-text">
                                Legal entity name in accordance with agreements or official binding document
                            </div>
                        </div>
                        <div className="create-agency-input">
                            <TextInput
                              label="AgencyID"
                              width="30.5rem"
                              error={false}
                              success={false}
                              type="text"
                              onChangeFunction={(e) => setAgencyName(e.target.value)}
                            />
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-row-text">
                                Street Address
                            </div>
                        </div>
                        <div className="create-agency-input">
                            <TextInput
                              label="AgencyID"
                              width="30.5rem"
                              error={false}
                              success={false}
                              type="text"
                              onChangeFunction={(e) => setStreetAddress(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-row-text">
                                City
                            </div>
                        </div>
                        <div className="create-agency-input">
                            <TextInput
                              label="AgencyID"
                              width="30.5rem"
                              error={false}
                              success={false}
                              type="text"
                              onChangeFunction={(e) => setCity(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-row-text">
                                State
                            </div>
                        </div>
                        <div className="create-agency-input">
                            <TextInput
                              label="AgencyID"
                              width="30.5rem"
                              error={false}
                              success={false}
                              type="text"
                              onChangeFunction={(e) => setState(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-row-text">
                                Postcode
                            </div>
                        </div>
                        <div className="create-agency-input">
                            <TextInput
                              label="AgencyID"
                              width="30.5rem"
                              error={false}
                              success={false}
                              type="text"
                              onChangeFunction={(e) => setPostCode(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-row-text" ref={countriesSelectorRef}>
                                Country
                            </div>
                        </div>
                        <div className="inner">
                            <CountriesWithFlags
                              list={listCountry}
                              setSelectionList={setCountrySelectionList}
                              selectionList={countrySelectionList}
                              text="Select country"
                              isImage
                              stateInputValue={setInputSelectonText}
                              width="30.5rem"
                              setSelectedFlag={setSelectedFlag}
                              lowPosition={heightPercentage(countriesSelectorRef)}
                              setCountryUpdated={setCountryUpdated}
                              countryUpdated={countryUpdated}
                            />
                        </div>
                    </div>
                    <div className="create-agency-form-row">
                        <div className="create-agency-row-content">
                            <div className="create-agency-required-field" ref={countriesTimezoneRef}>
                                Timezone
                            </div>
                        </div>
                        <div className="inner">
                            <CountriesTimezone
                              list={listTimezone}
                              setTimezoneSelectionList={setTimezoneSelectionList}
                              timezoneSelectionList={timezoneSelectionList}
                              text="Select timezone"
                              isImage
                              timezone
                              stateInputValue={setInputSelectonTextCountry}
                              width="30.5rem"
                              selectedFlag={selectedFlag}
                              lowPosition={heightPercentage(countriesTimezoneRef)}
                              countryUpdated={countryUpdated}
                            />
                        </div>
                    </div>
                    <ColoredLine />
                </div>
                <div className="create-agency-footer">
                    <SecondaryButton label="Cancel" onClickFunction={() => handleNavigate()} />
                    <PrimaryButton label="Create" onClickFunction={CreateNewAgency} disable={disableButton} />
                </div>
            </div>
        </div>
    );
};

export default CreateAgency;
