/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import { PrimaryButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import SwitchAgency from '../img/switchAgencyIcon.svg';
import CloseButton from '../../../image/xButton.svg';
import Title from '../../title/Title';
import Autocomplete from '../../autocomplete/Autocomplete';
import './SwitchAgencyModal.css';

const SwitchAgencyModal = (props) => {
    const {
        switchModalShow,
        setSwitchModalShow,
        btnConfirm,
        module,
    } = props;
    const agency = Cookies.get('listAgency') ? JSON.parse(Cookies.get('listAgency')) : [];
    const agencyId = Cookies.get('agency-id');
    const agencyOid = Cookies.get('agencyUUID');

    const listAgency = useMemo(() => {
        const value = agency.length > 0 ? agency.filter((i) => i.agencyId !== agencyId).map((x) => ({
            id: x.agency.agencyId,
            name: x.agency.agencyName ? x.agency.agencyName : 'Admin Console',
            agencyUUID: x.agency.oid,
            chatBotEnabled: x.chatBotEnabled,
            isSuspended: x.isSuspended,
            role: x.role,
            tgNotification: x.tgNotification,
        })) : [];
        // console.log(value);
        return value;
    }, []);
    const stateChange = () => {
        // console.log('modal should close');
        setSwitchModalShow(!switchModalShow);
    };

    // console.log('listAgency', listAgency);
    const [agencyList, setAgencyList] = useState(listAgency);
    const [selectionList, setSelectionList] = useState({});
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropDownLoading, setDropDownLoading] = useState(false);

    return (
        <div className="modal-container-bg">
            <div className="modal-container">
                <div className="modal-header">
                    <div className="modal-header-content">
                        <img src={SwitchAgency} alt="switch-agency-icon" />
                        <Title title="Switch Agency Workspace" subtitle="" titleSize="1.125rem" subtitleSize="0rem" gap="0rem" lineHeight="1.75rem" />
                    </div>
                    <div className="button-container">
                        <div type="button" className="x-button" onClick={(e) => stateChange(e)}>
                            <img src={CloseButton} alt="close-modal" />
                        </div>
                    </div>
                </div>
                <div className="modal-content">
                    <Autocomplete list={agencyList} setSelectionList={setSelectionList} selectionList={selectionList} text="Select agency workspace" isImage={false} isAgencySelector inputValue={inputSelectonText} stateInputValue={setInputSelectonText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} />
                    <Title title="" subtitle="Do not see your agency? Please contact your agency administrator for assistance." titleSize="0rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.25rem" />
                </div>
                <div className="modal-actions">
                    <div className="modal-actions-content">
                        <SecondaryButton label="Cancel" width="50%" onClickFunction={stateChange} />
                        <PrimaryButton label="Confirm" width="50%" onClickFunction={(e) => btnConfirm(e, selectionList)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SwitchAgencyModal;
