/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from 'react';
import './cropImageModal.css';
import Cookies from 'js-cookie';
import ReactCrop from 'react-image-crop';
import { PrimaryButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import SwitchAgency from '../img/switchAgencyIcon.svg';
import CropImage from '../img/cropImageIcon.svg';
import CloseButton from '../../../image/xButton.svg';
import Title from '../../title/Title';
import Autocomplete from '../../autocomplete/Autocomplete';

const CropImageModal = (props) => {
    const {
        switchModalShow,
        openSwitchModal,
        setSwitchModalShow,
        btnConfirm,
        dataRow,
        type, files, setOutput, output,
    } = props;

    const agency = Cookies.get('listAgency') ? JSON.parse(Cookies.get('listAgency')) : [];
    const agencyId = Cookies.get('agency-id');

    const listAgency = useMemo(() => {
        const value = agency.length > 0 ? agency.filter((i) => i.agencyId !== agencyId).map((x) => ({
            id: x.agencyId,
            name: x.agencyName ? x.agencyName : 'Admin Console',
            agencyUUID: x.agencyUUID,
            chatBotEnabled: x.chatBotEnabled,
            isSuspended: x.isSuspended,
            role: x.role,
            tgNotification: x.tgNotification,
        })) : [];

        return value;
    }, []);

    // console.log('listAgency', listAgency);
    const [agencyList, setAgencyList] = useState(listAgency);
    const [selectionList, setSelectionList] = useState({});
    const [inputSelectonText, setInputSelectonText] = useState('');

    // CROP IMAGE
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState();
    const [aspect, setAspect] = useState(1 / 1);
    const [image, setImage] = useState(null);
    const [completedCrop, setCompletedCrop] = useState();
    const [saveButtonState, setSaveButtonstate] = useState(false);

    const stateChange = () => {
        setSaveButtonstate(false);
        setSwitchModalShow(false);
    };

    useEffect(() => {
        if (!crop) {
            setSaveButtonstate(false);
        } else {
            setSaveButtonstate(true);
        }
    }, [crop]);

    const cropImageNow = () => {
        const img = document.getElementById('crop');
        const canvas = document.createElement('canvas');
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
          img,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );
        // console.log('CROP FUNCTION CALLED: 13', ctx);

        // Converting to base64
        const base64Image = canvas.toDataURL('image/png');
        const blobImage = canvas.toBlob((blob) => {
          const newImg = document.createElement('imgBlob');
          const url = URL.createObjectURL(blob);
          newImg.onload = () => {
            // no longer need to read the blob so it's revoked
            URL.revokeObjectURL(url);
          };
          newImg.src = url;
          document.body.appendChild(newImg);
        }, 'image/png', 0.99);
        // console.log('CROP FUNCTION CALLED: ', base64Image);
        setOutput(base64Image);
      };

    let thumbs;

    if (type === 'cropImage') {
    thumbs = files.map((file) => (
            <img
              style={{ height: '312px', alignSelf: 'center' }}
              id="crop"
              src={file.preview}
              alt="profile"
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
            }}
            />
    ));
    }

    useEffect(() => {
        if (files && files.length === 0) {
            setSwitchModalShow(false);
        }
    }, [files]);

    return (
        <div className="modal-container-bg">
            <div className="modal-container-crop">
                <div className="modal-header-crop">
                    <div className="modal-header-content-crop">
                        <img src={CropImage} alt="crop-icon" />
                        <Title title="Crop image" subtitle="Upload a 3024 x 3024px image for best results" titleSize="1.125rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.75rem" />
                    </div>
                        <div type="button" className="x-button-crop" onClick={(e) => stateChange(e)}>
                            <img src={CloseButton} alt="close-modal" />
                        </div>
                </div>
                    <ReactCrop
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      aspect={aspect}
                      onComplete={(complete) => setImage(complete)}
                    >
                        {thumbs}
                    </ReactCrop>
                <div className="modal-actions">
                    <div className="modal-actions-content">
                        <SecondaryButton label="Cancel" width="50%" onClickFunction={stateChange} />
                        <PrimaryButton
                          disable={!saveButtonState}
                          label="Confirm"
                          width="50%"
                          onClickFunction={cropImageNow}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CropImageModal;
