import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SeperatorIcon from './img/chevron-right.svg';
import './breadcrumbs.css';

const BreadCrumbs = (props) => {
    const [display, setdisplay] = useState();
    const { customSeperator } = props;

  return (
    <Breadcrumbs separator={<img loading="lazy" src={SeperatorIcon} alt="seperator" />} aria-label="breadcrumb" style={{ cursor: 'default' }}>
        {customSeperator}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
