/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import './header-sidebar.css';
// import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../searchInput/SearchInput';
import { PrivilegeContext } from '../../config/reducer/Privilege';
// import Navigation from '../navigation/Navigation';
import HeaderImage from '../../image/header.svg';
import ListNavigationSidebar from '../navigation/ListNavigationSidebar';
import { useApiData } from '../../config/reducer/Modules';

export default function HeaderSideBarMobile(props, { history }) {
    const {
        navigationState, setNavigationState, isAdmin, setNavigationClick,
    } = props;

    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });

    const navigate = useNavigate();
    const { apiResponse } = useApiData();
    const [liveState, setLiveState] = useState(true);
    const [insightState, setInsightState] = useState(true);
    const [inventoryState, setInventoryState] = useState(true);

    // const checkLiveEnabled = !((apiResponse?.module?.live?.liveMap === false) && (apiResponse?.module?.live?.telemetrySnapshot === false) && (apiResponse?.module?.live?.assetStatus === false));
    // const checkInsightsEnabled = !((apiResponse?.module?.insights?.assetPerformance === false));
    // const checkInventoryEnabled = !((apiResponse?.module?.fleetManagement?.asset === false) && (apiResponse?.module?.fleetManagement?.beacon === false) && (apiResponse?.module?.fleetManagement?.geofence === false));

    const headerNavigationList = [
        {
            text: 'Dashboard', value: 'dashboard', path: '/dashboard', enabled: true,
        },
        {
            text: 'Live', value: 'mapOverview', path: '/live/mapOverview', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : liveState,
        },
        {
            text: 'Insights', value: 'insights', path: '/insights/assetPerformance', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : insightState,
        },
        {
            text: 'Inventory', value: 'inventory', path: '/inventory/assetdevices', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : inventoryState,
        },
    ];

    const headerNavigationListMobile = [
        // {
            // text: 'Dashboard', value: 'dashboard', path: '/dashboard', enabled: true,
        // },
        {
            text: 'Live', value: 'mapOverview', path: '/live/mapOverview', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : liveState,
        },
        // {
        //     text: 'Insights', value: 'insights', path: '/insights/assetPerformance', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : insightState,
        // },
        // {
        //     text: 'Inventory', value: 'inventory', path: '/inventory/assetdevices', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : inventoryState,
        // },
    ];

    const headerNavigationListAdmin = [
        {
            text: 'Dashboard', value: 'dashboard', path: '/dashboard', enabled: true,
        },
        // {
        //     text: 'Live', value: 'mapOverview', path: '/live/mapOverview', enabled: true,
        // },
        // {
        //     text: 'Insights', value: 'insights', path: '/insights/assetPerformance', enabled: true,
        // },
        {
            text: 'Inventory', value: 'inventory', path: '/inventory/assetdevices', enabled: true,
        },
        {
            text: 'Agencies', value: 'agencies', path: '/agencies', enabled: true,
        },
    ];

    useEffect(() => {
        if ((apiResponse?.module?.live === undefined)) {
            setLiveState(false);
        }
        if ((apiResponse?.module?.insights === undefined)) {
            setInsightState(false);
        }

        if ((apiResponse?.module?.fleetManagement === undefined)) {
            setInventoryState(false);
        }
        if ((apiResponse?.module?.live?.liveMap === false) && (apiResponse?.module?.live?.telemetrySnapshot === false) && (apiResponse?.module?.live?.assetStatus === false)) {
            setLiveState(false);
        }
        if ((apiResponse?.module?.live?.liveMap === false) && (apiResponse?.module?.live?.telemetrySnapshot === false) && (apiResponse?.module?.live?.assetStatus === true)) {
            setLiveState(false);
        }
        if ((apiResponse?.module?.insights?.assetPerformance === false) && (apiResponse?.module?.insights?.iorail === false) && (apiResponse?.module?.insights?.ioroad === false)) {
            setInsightState(false);
        }
        if ((apiResponse?.module?.insights?.assetPerformance === false) && ((apiResponse?.module?.insights?.iorail === true) || (apiResponse?.module?.insights?.ioroad === true))) {
            setInsightState(false);
        }
        if ((apiResponse?.module?.fleetManagement?.asset === false) && (apiResponse?.module?.fleetManagement?.beacon === false) && (apiResponse?.module?.fleetManagement?.geofence === false)) {
            setInventoryState(false);
        }
    }, [apiResponse]);
    return (
        <div className="header-container-main">
            <div className="main-mobile-nav-container">
                <div className="header-nav">
                    <ListNavigationSidebar
                      list={isAdmin ? headerNavigationListAdmin : headerNavigationListMobile}
                      navigationState={navigationState}
                      setNavigationState={setNavigationState}
                      setNavigationClick={setNavigationClick}
                    />
                </div>
                {/* {isAdmin ? (
                <ListNavigationSidebar list={headerNavigationListAdmin} navigationState={navigationState} setNavigationState={setNavigationState} setNavigationClick={setNavigationClick} />) : (
                <ListNavigationSidebar list={headerNavigationList} navigationState={navigationState} setNavigationState={setNavigationState} setNavigationClick={setNavigationClick} />)} */}
            </div>
        </div>
    );
}
