import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const urlV3 = process.env.REACT_APP_RESTAPI_V3;
const xApiKey = process.env.REACT_APP_XAPIKEY;

export const APIUpdateDevice = async (token, agencyId, params) => {
    const result = await axios.patch(`${urlV3}/devices`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error.response.status);
    return result;
};

export const APIUpdateUsers = async (token, agencyId, params) => {
    // console.log('APIUpdateUsers', `${urlV3}/account`, token);
    const result = await axios.patch(`${urlV3}/account`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    // console.log('RESULT: ', result);
    return result;
};

export const APIUpdateTeamMember = async (token, agencyId, params) => {
    const result = await axios.patch(`${urlV3}/team`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};

export const APITransferOwnership = async (token, agencyId, params) => {
    const result = await axios.patch(`${urlV3}/transferOwnership`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};

export const APIPatchUpdatePassword = async (params, setLoading) => {
    setLoading(true);
    const result = await axios.patch(`${urlV3}/updatePassword`, params, {
        headers: {
            'x-api-key': xApiKey,
        },
    }).catch((error) => error);
    return result;
};

export const APIPatchAgencyDetails = async (token, agencyId, params) => {
    const result = await axios.patch(`${urlV3}/agency`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};

export const APIPatchGeofence = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.patch(`${urlV3}/geofence`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};

export const APIPatchSIM = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.patch(`${urlV3}/sims`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};

export const APIPatchAlertPolicy = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.patch(`${urlV3}/alertPolicy`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};

export const APIPatchAlerts = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.patch(`${urlV3}/alerts`, params, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};
