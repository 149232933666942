import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Popover } from '@mui/material';
import RailIcon from '../img/railIcon.svg';
import RoadIcon from '../img/roadIcon.svg';
import VerticalDots from '../img/verticalDots.svg';
import MenuPopup from '../../menuPopup/MenuPopup';
import ToolTips from '../../tooltip/ToolTips';

const CustomMenuButton = (assets) => {
    const { row, rowIndex } = assets;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [assetRow, setAssetRow] = useState();

    const insightsNavigate = (params) => {
        const uuid = params.id;
        navigate('/insights/assetPerformance', { replace: true, state: { uuid } });
    };

    const liveMapNavigate = (params) => {
        // const deviceOid = params.id;
        navigate('/live/mapOverview', { replace: true, state: { uuid: params.id, name: params.deviceSN, id: params.assetName } });
      };

    const handleClick = (event) => {
        setAssetRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAssetRow();
        setAnchorEl(null);
    };

    return (
        <div>
            <div className="assets-button" id={rowIndex} aria-hidden="true" onClick={handleClick}>
                <div className="assets-menu">
                    <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                </div>
            </div>
            <Popover
              id={rowIndex}
              sx={{
                boxShadow: 2,
                borderRadius: 3,
            }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <MenuPopup page="assetsAndDevices" assetDetails={assetRow} insightsNav={() => insightsNavigate(row)} liveMapNav={() => liveMapNavigate(row)} />
            </Popover>
        </div>
    );
};

const CustomTags = ({ row }) => {
    const {
        tags,
    } = row;

    const [assetTagsOverflowCount, setAssetTagsOverflowCount] = useState(0);
    const [assetTags, setAssetTags] = useState([]);
    const [overflowAssetTags, setOverflowAssetTags] = useState([]);
    const [hover, setHover] = useState(false);

    const ifAssetTagsOverflow = () => {
        if (tags && tags.length > 2) {
            const overflowedAssetTags = tags.slice(2);
            setAssetTags(tags.slice(0, 2));
            setOverflowAssetTags(overflowedAssetTags);
            setAssetTagsOverflowCount(overflowedAssetTags.length);
        }
    };

    useEffect(() => {
        ifAssetTagsOverflow();
    }, []);

    return (
        <>
        {tags && tags.length > 2 ? (
            <>
                {assetTags.map((value) => (
                    <div className="tags">
                        <div className="tags-text">
                            {value}
                        </div>
                    </div>
                ))}
                <ToolTips text={overflowAssetTags.join(', ')} disabled={!hover}>
                    <div className="overflow-tags">
                        <div>{`+${assetTagsOverflowCount}`}</div>
                    </div>
                </ToolTips>
            </>
        ) : (
            <>
            {tags && tags.map((value) => (
                <div className="tags">
                    <div className="tags-text">
                        {value}
                    </div>
                </div>
            ))}
            </>
        )}
        </>
    );
};

const CustomAssetType = ({ row }) => {
    const { assetType } = row;
    return (
        <div className="asset-type-grid">
            {assetType.length > 0 ? (
                <>
                    {assetType.includes('Road') ? (
                        <>
                            <div className="asset-type-text">
                                {assetType}
                            </div>
                            <img loading="lazy" src={RoadIcon} alt="RailIcon" />
                        </>
                    ) : (
                        <>
                        </>
                    )}
                    {assetType.includes('Rail') ? (
                        <>
                            <div className="asset-type-text">
                                {assetType}
                            </div>
                            <img loading="lazy" src={RailIcon} alt="RailIcon" />
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
};

const CustomLastUpdated = ({ row }) => {
    const {
        lastUpdated,
    } = row;

    return (
        <div>
            {lastUpdated !== null ? (
                <>
                    <div className="table-date-text">{lastUpdated.date}</div>
                    <div>
                        <div
                          className="table-time-text"
                          data-tag="allowRowEvents"
                          style={{
                            color: 'grey',
                            overflow: 'hidden',
                            whiteSpace: 'wrap',
                            textOverflow: 'ellipses',
                          }}
                        >
                            {lastUpdated.time}
                        </div>
                    </div>
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
};

const CustomTitle = ({ row }) => {
    const {
        deviceSN, assetName,
    } = row;

    return (
        <div>
            { }
            <div>
                <b>
                    {deviceSN}
                </b>
            </div>
            <div>
                <div
                  data-tag="allowRowEvents"
                  style={{
                    color: 'grey',
                    overflow: 'hidden',
                    whiteSpace: 'wrap',
                    textOverflow: 'ellipses',
                  }}
                >
                    { }
                    {!assetName ? (
                        '-'
                    ) : (
                        assetName
                    )}
                </div>
            </div>
        </div>
    );
};

const CustomDataUsage = ({ row }) => {
    const { sim } = row;

    return (
        <>
        {row.sim.iccid ? (
            <div className="data-usage-text">
                {sim.dataUsage === null ? (
                    '- MB '
                ) : (
                    `${(sim.dataUsage / 1000000).toFixed(1)} MB `
                )}
                /
                {` ${(sim.dataLimit / 1000000)} MB`}
            </div>
        ) : (
            <>
            </>
        )}
        </>
    );
};

export const inventoryColumn = [
    {
        name: 'Device SN / Asset Name',
        selector: (row) => row.deviceSN,
        sortable: true,
        grow: '1.7',
        cell: (row) => <CustomTitle row={row} />,
    },
    {
        name: 'Asset Type',
        selector: (row) => row.assetType,
        sortable: true,
        cell: (row) => <CustomAssetType row={row} />,
    },
    {
        name: 'Tags',
        selector: (row) => row.tags,
        sortable: true,
        grow: '1.7',
        id: 'customTags',
        cell: (row) => <CustomTags row={row} />,
        style: {
            gap: '4px',
        },
    },
    {
        name: 'SIM ICCID',
        selector: (row) => row.sim.iccid,
        sortable: true,
    },
    {
        name: 'Data Usage / Limit',
        selector: (row) => row.sim,
        sortable: true,
        grow: '1.3',
        cell: (row) => <CustomDataUsage row={row} />,
    },
    {
        name: 'Last Updated',
        selector: (row) => row.lastUpdated.date,
        grow: '1.3',
        sortable: true,
        cell: (row) => <CustomLastUpdated row={row} />,
    },
    {
        cell: (row, rowIndex) => (
              <CustomMenuButton row={row} rowIndex={rowIndex} />
        ),
        allowOverflow: true,
        button: true,
        width: '48px',
    },
];
