/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import {
  OutlinedInput,
  InputAdornment,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { PrimaryButton, SecondaryButton, TextButton } from '../buttonComponent/ButtonComponent';
import { ReactComponent as CalendarIcon } from '../buttonComponent/img/calendar.svg';
import './DateRangePicker.css';

const inputStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 16px',
  gap: '8px',
  width: '100%',
  height: '40px',
  background: '#FFFFFF',
  borderRadius: '8px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#667085',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },
  '& .MuiInputAdornment-root': {
    marginTop: 'auto',
    height: '1.1em',
    marginRight: 'auto',
  },
};

function CustomRangeInput({
  openCalendar, inputValue,
}) {
  return (
    <OutlinedInput
      onFocus={openCalendar}
      value={inputValue}
      readOnly
      sx={inputStyle}
      inputProps={{
        style: {
          padding: 0,
          width: '200px',
        },
      }}
      startAdornment={<InputAdornment position="start"><SvgIcon><CalendarIcon /></SvgIcon></InputAdornment>}
    />
  );
}

function momentFormatter(value) {
  const momentDate = moment(new Date(value));
  const validDate = momentDate.isValid() ? momentDate.format('MMM D, YYYY') : null;
  return validDate;
}

const DateRangePicker = (props) => {
  const {
    date, setDate, minDateRange, setMinDateRange, maxDateRange, setMaxDateRange, setApplyDatePicker, inputValue, setInputValue, dateRange, setDateRange,
  } = props;
  const [currentDate, setCurrentDate] = useState([]);
  const [inputDate, setInputDate] = useState([]);
  const datePickerRef = useRef();

  const toggleButtonStyle = {
    '&.MuiToggleButtonGroup-grouped': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px 16px',
      borderRadius: '6px !important',
      mx: 0,
      border: '0px !important',
    },
    '&.Mui-selected': {
      backgroundColor: '#F9FAFB',
    },
    '&.MuiToggleButton-root': {
      textTransform: 'none',
    },
    '&.MuiToggleButton-root:hover': {
      bgcolor: '#F9FAFB',
    },
  };

  const handleDateRange = (event, newRange) => {
    setDateRange(newRange);
    setApplyDatePicker(true);
  };

  const closeDatePicker = () => {
    setDate(currentDate);
    setInputValue(`${currentDate[0]} - ${currentDate[1]}`);
    datePickerRef.current.closeCalendar();
    setApplyDatePicker(true);
  };

  const valueChecking = () => {
    if (date.length > 0) {
      if (date !== currentDate) {
        setDate([date[0], date[1]]);
        setInputDate([date[0], date[1]]);
      }
    } else {
      setDate([]);
      setInputDate([]);
    }
  };

  const changeFunc = (e) => {
    setCurrentDate(e);
    setInputDate(e);
  };

  const CustomPreset = () => {
    const today = () => {
      const currentDay = new DateObject();
      setDate([currentDay, currentDay]);
      setInputValue(`${momentFormatter(currentDay)} - ${momentFormatter(currentDay)}`);
    };

    const yesterday = () => {
      const yesterDay = new DateObject().subtract(1, 'days');
      setDate([yesterDay, yesterDay]);
      setInputValue(`${momentFormatter(yesterDay)} - ${momentFormatter(yesterDay)}`);
    };

    const thisWeek = () => {
      const startOfWeek = new DateObject().toFirstOfWeek();
      const endOfWeek = new DateObject();
      setDate([startOfWeek, endOfWeek]);
      setInputValue(`${momentFormatter(startOfWeek)} - ${momentFormatter(endOfWeek)}`);
    };

    const lastWeek = () => {
      const startOfLastWeek = new DateObject().toFirstOfWeek().subtract(7, 'days');
      const endOfLastWeek = new DateObject().toLastOfWeek().subtract(7, 'days');
      setDate([startOfLastWeek, endOfLastWeek]);
      setInputValue(`${momentFormatter(startOfLastWeek)} - ${momentFormatter(endOfLastWeek)}`);
    };

    const thisMonth = () => {
      const startOfMonth = new DateObject().toFirstOfMonth();
      const endOfMonth = new DateObject();
      setDate([startOfMonth, endOfMonth]);
      setInputValue(`${momentFormatter(startOfMonth)} - ${momentFormatter(endOfMonth)}`);
    };

    const lastMonth = () => {
      const startOfLastMonth = new DateObject().toFirstOfMonth().subtract(1, 'months');
      const endOfLastMonth = new DateObject(startOfLastMonth).toLastOfMonth();
      setDate([startOfLastMonth, endOfLastMonth]);
      setInputValue(`${momentFormatter(startOfLastMonth)} - ${momentFormatter(endOfLastMonth)}`);
    };

    const thisYear = () => {
      const startOfYear = new DateObject().toFirstOfYear();
      const endOfYear = new DateObject();
      setDate([startOfYear, endOfYear]);
      setInputValue(`${momentFormatter(startOfYear)} - ${momentFormatter(endOfYear)}`);
    };

    const lastYear = () => {
      const startOfLastYear = new DateObject().toFirstOfYear().subtract(1, 'years');
      const endOfLastYear = new DateObject(startOfLastYear).toLastOfYear();
      setDate([startOfLastYear, endOfLastYear]);
      setInputValue(`${momentFormatter(startOfLastYear)} - ${momentFormatter(endOfLastYear)}`);
    };

    return (
      <div className="preset-column">
        <ToggleButtonGroup
          orientation="vertical"
          value={dateRange}
          exclusive
          onChange={handleDateRange}
          sx={{
            gap: '4px',
          }}
        >
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="today"
            onClick={today}
          >
            <div className="preset-text">Today</div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="yesterday"
            onClick={yesterday}
          >
            <div className="preset-text">Yesterday</div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="thisweek"
            onClick={thisWeek}
          >
            <div className="preset-text">This Week</div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="lastweek"
            onClick={lastWeek}
          >
            <div className="preset-text">Last Week</div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="thismonth"
            onClick={thisMonth}
          >
            <div className="preset-text">This Month</div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="lastmonth"
            onClick={lastMonth}
          >
            <div className="preset-text">Last Month</div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="thisyear"
            onClick={thisYear}
          >
            <div className="preset-text">This Year to date</div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            sx={toggleButtonStyle}
            value="lastyear"
            onClick={lastYear}
          >
            <div className="preset-text">Last Year</div>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  const CustomFooter = () => {
    const u = 1;

    return (
      <div className="date-range-custom-footer">
        <div className="date-range-input">
          <input readOnly className="date-range-text-input" value={momentFormatter(inputDate[0])} />
          <div className="seperator">
            {' - '}
          </div>
          <input readOnly className="date-range-text-input" value={momentFormatter(inputDate[1])} />
        </div>
        <div className="date-range-button">
          <SecondaryButton label="Cancel" width="5rem" onClickFunction={() => datePickerRef.current.closeCalendar()} />
          <PrimaryButton label="Apply" width="5rem" onClickFunction={closeDatePicker} disable={currentDate.length !== 2} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (currentDate.length < 2 && currentDate.length > 0) {
      setMinDateRange(new DateObject(currentDate[0]).subtract(30, 'days'));
      if (new DateObject(currentDate[0]).add(31, 'days') < new DateObject()) {
        setMaxDateRange(new DateObject(currentDate[0]).add(30, 'days'));
      }
    } else {
      setMaxDateRange(new Date());
      setMinDateRange(new DateObject().subtract(1, 'year'));
    }
  }, [currentDate]);

  return (
    <div>
      <DatePicker
        maxDate={maxDateRange}
        minDate={minDateRange}
        ref={datePickerRef}
        format="MMM DD, YYYY"
        className="custom"
        render={<CustomRangeInput inputValue={inputValue} />}
        value={date}
        onChange={(e) => changeFunc(e)}
        onOpen={() => valueChecking()}
        onClose={() => setCurrentDate([])}
        range
        rangeHover
        arrow={false}
        offsetY={8}
        numberOfMonths={2}
        weekStartDayIndex={1}
        plugins={[
          <CustomPreset position="left" />,
          <CustomFooter position="bottom" />,
        ]}
      />
    </div>
  );
};

export default DateRangePicker;
