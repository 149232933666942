/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
    AreaChart, Area, ResponsiveContainer, XAxis, YAxis,
} from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import arrowUp from './img/arrowUp.svg';
import arrowDown from './img/arrowDown.svg';
import dotIcon from './img/dot.svg';
import './card.css';

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#365A6D',
        },
    },
});

const Card = (props) => {
    const {
        title,
        width,
        body,
        apiData,
        loading,
        checkArrow,
        noChange,
        different,
        minWidth,
    } = props;
    // console.log('differeeenntt card', checkArrow);

    return (
        <div
          className="styleCard"
          style={{
            width: width,
            minWidth: minWidth,
            height: '190px',
            boxSizing: 'border-box',
            }}
        >
            {
                loading ? (
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%',
                    }}
                    >
                        <ThemeProvider theme={loadTheme}>
                            <CircularProgress color="amcolor" />
                        </ThemeProvider>
                    </div>
                ) : (
                    <div className="styleCardContent">
                        <div className="styleCardTitle">{title}</div>
                        <div className="styleDescription">
                            {
                                different ? (
                                    <div className="styleCardBody">
                                        <div className="styleBody">{body}</div>
                                        <div className="cardText">
                                            {
                                                noChange ? (
                                                    <div className="card-arrow" style={{ gap: '8px' }}>
                                                        <img loading="lazy" src={dotIcon} alt="" />
                                                        <div className="card-arrow-text" style={{ color: '#475467' }}>0%</div>
                                                    </div>
                                                ) : (
                                                        checkArrow ? (
                                                            <div className="card-arrow">
                                                                <img loading="lazy" src={arrowUp} alt="" />
                                                                <div className="card-arrow-text" style={checkArrow ? { color: '#027A48' } : { color: '#B42318' }}>{`${different}% `}</div>
                                                            </div>
                                                        ) : (
                                                            <div className="card-arrow">
                                                                <img loading="lazy" src={arrowDown} alt="" />
                                                                <div className="card-arrow-text" style={checkArrow ? { color: '#027A48' } : { color: '#B42318' }}>{`${different}% `}</div>
                                                            </div>
                                                        )
                                                )
                                            }
                                            <div className="styleSubtitle">
                                                vs last 7 days
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="styleCardBody-text">
                                        <div className="styleBody-text-only">{body}</div>
                                    </div>
                                )
                            }
                            {apiData ? (
                                <div className="card-responsive-chart">
                                    <ResponsiveContainer width="99%" height={100}>
                                        <AreaChart
                                          margin={{
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                          }}
                                          data={apiData}
                                        >
                                            {
                                                checkArrow ? (
                                                    <>
                                                        <defs>
                                                            <linearGradient id="colorIncrease" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="64.12%" stopColor="#D0E0E8" stopOpacity={1} />
                                                                <stop offset="100%" stopColor="#D0E0E8" stopOpacity={0} />
                                                            </linearGradient>
                                                        </defs>
                                                        <YAxis type="number" domain={['dataMin', 'auto']} tick={false} axisLine={false} />
                                                        <Area type="monotone" dataKey="uv" stroke="#45748C" strokeWidth={2} fillOpacity={1} fill="url(#colorIncrease)" />
                                                    </>
                                                ) : (
                                                    <>
                                                        <defs>
                                                            <linearGradient id="colorDecrease" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="64.12%" stopColor="#FEF3F2" stopOpacity={1} />
                                                                <stop offset="100%" stopColor="#FEF3F2" stopOpacity={0} />
                                                            </linearGradient>
                                                        </defs>
                                                        <YAxis type="number" domain={['dataMin', 'auto']} tick={false} axisLine={false} />
                                                        <Area type="monotone" dataKey="uv" stroke="#F04438" strokeWidth={2} fillOpacity={1} fill="url(#colorDecrease)" />
                                                    </>
                                                )
                                            }

                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )
            }

        </div>
    );
};

Card.defaultProps = {
    width: 350,
    height: 176,
    title: 'Template - Card Title',
    location: 'Location label',
    description: 'test',
};

export default Card;
