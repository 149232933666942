import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useMediaQuery } from 'react-responsive';
import { passwordStrength } from 'check-password-strength';
import ProgressBar from '@ramonak/react-progress-bar';
import './signup.css';
import HeaderImage from '../../image/header.svg';
import Title from '../../component/title/Title';
import TextInput from '../../component/textInput/TextInput';
import Text from '../../component/text/Text';
import asiamobiliti from '../login/img/asiamobiliti.png';
import { GoogleButton, PrimaryButton } from '../../component/buttonComponent/ButtonComponent';
import { APISignUpUser } from '../../config/restAPI/PostAPI';

export default function SignUp({ history }) {
    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    const token = Cookies.get('jwtToken');
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [input, setInput] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [firstNameError, setFirstNameError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [keyUp, setKeyUp] = useState('', false);
    const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
    const [passwordStrengthString, setPasswordStrengthString] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);
    const navigate = useNavigate();
    // Decode email
    const [encodedStringBtoA, setEncodedStringBtoA] = useState('');
    const [linkExpireAt, setLinkExpireAt] = useState('');

    // Retrieve and encode email on page load
    useEffect(() => {
        try {
            const url = window.location.pathname;
            const params = url.replace('/signup/', '');

            const tempsecretkey = 'dev_asiamobiliti';
            const decodeParams = CryptoJS.AES.decrypt(params, tempsecretkey).toString(CryptoJS.enc.Utf8);

            setEncodedStringBtoA(btoa(JSON.parse(decodeParams).emailAddress));
            setLinkExpireAt(JSON.parse(decodeParams).linkExpireAt);

            setInput({ ...input, email: JSON.parse(decodeParams).emailAddress });
        } catch (e) {
            console.log('ERROR PARSING UNDEFINED JSON: ', e);
        }
    }, []);

    const fNameOnChangeFunction = (e) => {
        setInput({ ...input, firstName: e.target.value });
    };

    const lNameOnChangeFunction = (e) => {
        setInput({ ...input, lastName: e.target.value });
    };

    const passwordOnChangeFunction = (e) => {
        setInput({ ...input, password: e.target.value });
    };

    const resetPassword = (e) => {
        e.preventDefault();
        if (input.password) {
            const passwordStr = passwordStrength(input.password).value;
            if (passwordStr === 'Too weak') { // Password strength is too weak, will show error message, and the 'Reset password' button is disabled
                setPasswordError(true);
                setPasswordStrengthValue(20);
                setPasswordStrengthString(passwordStr);
            } else { // Password strength is weak/medium/strong, error message is cleared, and the 'Reset password' button is enabled
                setPasswordError(false);
                setPasswordStrengthValue(40);
                setPasswordStrengthString(passwordStr);
            }
        }
    };

    const signUpUser = async () => {
        const params = {
            userEmail: encodedStringBtoA,
            password: btoa(input.password),
            userFirstName: input.firstName,
            userLastName: input.lastName,
        };
        try {
            const result = await APISignUpUser(setLoading, params);
            if (result.data.status === 200) {
                setLoading(false);
                navigate('/login', { replace: true });
            }
            // console.log(result);
        } catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        if (input.password) {
            const passwordStr = passwordStrength(input.password).value;
            if (passwordStr === 'Too weak') { // Password strength is too weak, will show error message, and the 'Reset password' button is disabled
                setPasswordError(true);
                setPasswordStrengthValue(15);
                setPasswordStrengthString(passwordStr);
                setBtnDisabled(true);
            } else if (passwordStr !== 'Too weak' && input.firstName !== '' && input.lastName !== '') { // Password strength is weak/medium/strong, error message is cleared, and the 'Reset password' button is enabled
                setBtnDisabled(false);
                setPasswordError(false);
                setPasswordStrengthString(passwordStr);
            } else {
                setBtnDisabled(true);
                setPasswordError(false);
                setPasswordStrengthString(passwordStr);
            }
            if (passwordStr === 'Weak') {
                setPasswordStrengthValue(35);
            } else if (passwordStr === 'Medium') {
                setPasswordStrengthValue(60);
            } else if (passwordStr === 'Strong') {
                setPasswordStrengthValue(100);
            }
        }
        if (input.password.length < 1) {
            setBtnDisabled(true);
        }
    }, [input]);

    useEffect(() => {
        if (keyUp !== '') {
            resetPassword();
        }
    }, [keyUp]);

    useEffect(() => {
        if (passwordStrengthString) {
            document.documentElement.style.setProperty('--strength-bar-width', `${passwordStrengthValue}%`);
            const color = getComputedStyle(document.documentElement).getPropertyValue('--strength-bar-width');
        }
    }, [passwordStrengthString]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return (
        <>
            {isMobileOrTablet && (
                <section className="signUp-mainMobile" style={{ height: document.documentElement.clientHeight }}>
                    <div className="signUp-row-mobile">
                        <div className="signUp-container-mobile">
                            <div className="signUp-header-container">
                                <div className="signUp-header-mobile">
                                    <img src={HeaderImage} className="logo" alt="header-logo" />
                                    <div className="signUp-header-content-mobile">
                                        <Title title="Sign up" deleteSubtitle titleSize="1.5rem" />
                                    </div>
                                </div>
                                <div className="signUp-content-mobile">
                                    <div className="signUp-form-mobile">
                                        <div className="signup-inputname-row">
                                            <div style={{ display: 'flex', width: '50%' }}>
                                                <TextInput label="First name*" setInput={setInput} input={input} name="firstName" type="text" error={firstNameError} setError={setFirstNameError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={fNameOnChangeFunction} />
                                            </div>
                                            <div style={{ display: 'flex', width: '50%' }}>
                                                <TextInput label="Last name*" setInput={setInput} input={input} name="lastName" type="text" error={lastNameError} setError={setLastNameError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={lNameOnChangeFunction} />
                                            </div>
                                        </div>
                                        <TextInput label="Email" placeholder={input.email} name="email" type="text" error={emailError} setError={setEmailError} disable />
                                        <div className="signup-password-form">
                                            <div className="signup-password-input">
                                                <TextInput label="Password*" placeholder="Create a password" setInput={setInput} input={input} name="password" type="password" error={passwordError} setError={setPasswordError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={passwordOnChangeFunction} />
                                            </div>
                                            {input.password && (
                                                <div className="signup-password-strength-container">
                                                    <ProgressBar
                                                      completed={passwordStrengthValue}
                                                      className="signupWrapperBar"
                                                      barContainerClassName="signupContainerBar"
                                                      completedClassName="signupBarCompletedBar"
                                                      isLabelVisible="false"
                                                      customLabel=" "
                                                      animateOnRender="true"
                                                      transitionTimingFunction="ease-in-out"
                                                      transitionDuration="1"
                                                    />
                                                    <Text size="0.875rem" color="#344054" weight="500" align="right" text={passwordStrengthString} />
                                                </div>
                                            )}
                                        </div>
                                        <PrimaryButton loading={loading} label="Get started" disable={btnDisabled} onClickFunction={signUpUser} />
                                        {/* <GoogleButton label="Sign up with Google" /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="signUp-footer-container">
                                <div className="signUp-footer-mobile">
                                    <Title
                                      width="100%"
                                      textAlign="center"
                                      margin="auto"
                                      title={`HRZNv${process.env.REACT_APP_VERSION} Build ${process.env.REACT_APP_BUILD_SHA}`}
                                      subtitle={`Copyright © ${new Date().getFullYear()} Asia Mobility Technologies. All Rights Reserved.`}
                                      titleSize="0.875rem"
                                      subtitleSize="0.875rem"
                                      gap="0rem"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {isDefault && (
                <div className="signup-row" style={{ minHeight: windowSize.innerHeight, minWidth: windowSize.innerWidth }}>
                    <div className="signup-column">
                        <div className="signup-header">
                            <img loading="lazy" src={HeaderImage} className="signup-logo" alt="header-logo" />
                        </div>
                        <div className="signup-container">
                            <Title title="Sign up" titleSize="2.75rem" />
                            <div className="signup-form">
                                <div className="signup-inputname-row">
                                    <div style={{ display: 'flex', width: '50%' }}>
                                        <TextInput label="First name*" setInput={setInput} input={input} name="firstName" type="text" error={firstNameError} setError={setFirstNameError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={fNameOnChangeFunction} />
                                    </div>
                                    <div style={{ display: 'flex', width: '50%' }}>
                                        <TextInput label="Last name*" setInput={setInput} input={input} name="lastName" type="text" error={lastNameError} setError={setLastNameError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={lNameOnChangeFunction} />
                                    </div>
                                </div>
                                <TextInput label="Email" placeholder={input.email} name="email" type="text" error={emailError} setError={setEmailError} disable />
                                <div className="signup-password-form">
                                    <div className="signup-password-input">
                                        <TextInput label="Password*" placeholder="Create a password" setInput={setInput} input={input} name="password" type="password" error={passwordError} setError={setPasswordError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={passwordOnChangeFunction} />
                                    </div>
                                    {input.password && (
                                        <div className="signup-password-strength-container">
                                            <ProgressBar
                                              completed={passwordStrengthValue}
                                              className="signupWrapperBar"
                                              barContainerClassName="signupContainerBar"
                                              completedClassName="signupBarCompletedBar"
                                              isLabelVisible="false"
                                              customLabel=" "
                                              animateOnRender="true"
                                              transitionTimingFunction="ease-in-out"
                                              transitionDuration="1"
                                            />
                                            <Text size="0.875rem" color="#344054" weight="500" align="right" text={passwordStrengthString} />
                                        </div>
                                    )}
                                </div>
                                <PrimaryButton loading={loading} label="Get started" disable={btnDisabled} onClickFunction={signUpUser} />
                                {/* <GoogleButton label="Sign up with Google" /> */}
                            </div>
                        </div>
                        <div className="signup-footer">
                            <Title title={`HRZNv${process.env.REACT_APP_VERSION} Build ${process.env.REACT_APP_BUILD_SHA}`} subtitle={`Copyright © ${new Date().getFullYear()} Asia Mobility Technologies. All Rights Reserved.`} titleSize="0.875rem" subtitleSize="0.875rem" gap="0rem" />
                        </div>
                    </div>
                    <div className="signup-image">
                        <div className="signup-wrapper">
                            <div className="signup-text-container">
                                <div className="signup-text-background">
                                    <div className="signup-text">
                                        Enterprise-ready, database-per-tenant IoT platform and API for management of assets, users and data
                                    </div>
                                    <div className="powered-text">
                                        <Title title="" subtitle="Powered by" titleSize="0rem" subtitleSize="1rem" gap="0rem" />
                                        <img
                                          src={asiamobiliti}
                                          loading="lazy"
                                          alt="Asia Mobiliti logo"
                                          style={{
                                            height: '1rem',
                                            paddingTop: '0.2rem',
                                            paddingLeft: '0.2rem',
                                          }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
