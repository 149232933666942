/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { TagsInput } from 'react-tag-input-component';
import './InputTags.css';

export default function InputTags(props) {
  const {
    tags,
    setTags,
    removedTags,
    setRemovedTags,
    disable,
    tagsHistory,
    maxLength,
    setInputTagsErr,
    setInputTagsMsg,
  } = props;

  let timeout;

  const handleRemoved = (e) => {
    if (!tagsHistory.includes(e)) {
      tagsHistory.push(e);
    }
  };

  const handleChange = (e) => {
    const deleteTag = tagsHistory.filter((x) => e.indexOf(x) === -1);
    setRemovedTags(deleteTag);
    setTags(e);
    if (maxLength) {
      if (tags.length <= 6) {
        setInputTagsErr(false);
      }
    }
  };

  const beforeAddValidate = (text) => {
      if (tags.length >= maxLength) {
        setInputTagsErr(true);
        setInputTagsMsg('Max limit of tags reached');
        return false;
      }
      return true;
  };

  const seperators = ['Enter', ' '];

  return (
    <>
    {disable ? (
      <div className="input-disable">
        <TagsInput
          disabled
          classNames={{
            input: 'disabled',
          }}
          value={tags}
          onChange={handleChange}
          onRemoved={handleRemoved}
          name="tags"
          placeHolder="Enter tags..."
          separators={seperators}
        />
      </div>
    ) : maxLength ? (
      <div className="input-enabled">
        <TagsInput
          value={tags}
          onChange={handleChange}
          onRemoved={handleRemoved}
          name="tags"
          placeHolder="Enter tags..."
          separators={seperators}
          beforeAddValidate={beforeAddValidate}
        />
      </div>
    ) : (
      <div className="input-enabled">
        <TagsInput
          value={tags}
          onChange={handleChange}
          onRemoved={handleRemoved}
          name="tags"
          placeHolder="Enter tags..."
          separators={seperators}
        />
      </div>
    )}
    </>
  );
}
