import React, { useState, useCallback } from 'react';
import {
  Pagination,
  PaginationItem,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { NextButton, PreviousButton } from '../../../buttonComponent/ButtonComponent';
import './TeamsPagination.css';

function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
}

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const TeamsPagination = (props) => {
    const {
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage,
        currentPage,
      } = props;

      const handlePrevious = useCallback(() => onChangePage(currentPage - 1), [currentPage, onChangePage]);
      const handleNext = useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage]);

      const numPages = getNumberOfPages(rowCount, rowsPerPage);

      const disabledPrevious = currentPage === 1;
      const disabledNext = currentPage === numPages;

      const handleChange = (event, value) => {
        onChangePage(value);
      };

    return (
        <div className="teams-pagination-body">
          <PreviousButton label="Previous" onClickFunction={handlePrevious} disable={disabledPrevious} />
          <ThemeProvider theme={theme}>
            <Pagination
              count={numPages}
              shape="rounded"
              onChange={handleChange}
              page={currentPage}
              hideNextButton
              hidePrevButton
            />
          </ThemeProvider>
          <NextButton label="Next" onClickFunction={handleNext} disable={disabledNext} />
        </div>
    );
};

export default TeamsPagination;
