/* eslint-disable no-nested-ternary */
import React from 'react';
import PlusIcon from '../../../image/plusIcon.svg';
import './geofence.css';

export default function AreaNameList(props) {
  const {
    polygon, createPolygonFunction, disabledAddPolygon, actualListPolygon, editPolygonDetails, editPolygonIndex, nameList, selectedListPolygon, cancelList, editList, deleteList, saveList, inputChange, disabledSaveButton, setPolygonListState,
  } = props;
  // console.log('editPOlygonIndex', editPolygonIndex);
  // console.log('nameList', nameList);
  // console.log('actualPolygonList', actualListPolygon);
  return (
    <div className="geofence-details-area-name-list">
      <div className="geofence-details-header-area-name-list">Area Name</div>
      <div className="geofence-details-body-a-n-container">
        <div>
          {
            actualListPolygon.length > 0 && actualListPolygon.map((x, index, arr) => (
              <div>
                {
                  editPolygonIndex === index ? (
                    <div className="geofence-details-body-area-name-list-active">
                      <input className="g-detail-a-input-text" value={nameList} onChange={(e) => inputChange(e)} />
                      <div className="g-detail-a-btn">
                        <div className="g-detail-a-edit-btn" role="button" tabIndex="0" onKeyUp={cancelList} onClick={cancelList} style={{ cursor: 'pointer', color: '#B54708' }}>Cancel</div>
                        <div
                          className={disabledSaveButton ? 'g-detail-a-edit-btn g-detail-a-btn-disabled' : 'g-detail-a-edit-btn'}
                          role="button"
                          tabIndex="0"
                          onKeyUp={() => saveList('edit')}
                          onClick={() => saveList('edit')}
                          style={{ cursor: 'pointer' }}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  ) : arr.length - 1 === index ? (
                  <div className="geofence-details-body-area-name-list">
                    <div className="g-detail-a-label-text">{x.name}</div>
                    <div className="g-detail-a-btn">
                      <div role="button" tabIndex="0" onKeyUp={() => deleteList(x, index)} onClick={() => deleteList(x, index)} className="g-detail-a-del-btn" style={{ cursor: 'pointer' }}>Delete</div>
                      <div className="g-detail-a-edit-btn" role="button" tabIndex="0" style={{ cursor: 'pointer' }} onKeyUp={() => editList(x, index)} onClick={() => editList(x, index)}>Edit</div>
                    </div>
                  </div>
                  ) : (
                    <div className="geofence-details-body-area-name-list-with-divider ">
                    <div className="g-detail-a-label-text">{x.name}</div>
                    <div className="g-detail-a-btn">
                      <div role="button" tabIndex="0" onKeyUp={() => deleteList(x, index)} onClick={() => deleteList(x, index)} className="g-detail-a-del-btn" style={{ cursor: 'pointer' }}>Delete</div>
                      <div className="g-detail-a-edit-btn" style={{ cursor: 'pointer' }} role="button" tabIndex="0" onKeyUp={() => editList(x, index)} onClick={() => editList(x, index)}>Edit</div>
                    </div>
                    </div>
                  )
                }
                {/* {
                  arr.length - 1 === index ? (
                    <div className="geofence-details-body-area-name-list">
                      <div className="g-detail-a-label-text">{x.areaName}</div>
                      <div className="g-detail-a-btn">
                        <div role="button" tabIndex="0" onKeyUp={() => deleteList(x, index)} onClick={() => deleteList(x, index)} className="g-detail-a-del-btn" style={{ cursor: 'pointer' }}>Delete</div>
                        <div className="g-detail-a-edit-btn" style={{ cursor: 'pointer' }}>Edit</div>
                      </div>
                    </div>
                  ) : (
                    <div className="geofence-details-body-area-name-list-with-divider ">
                      <div className="g-detail-a-label-text">{x.areaName}</div>
                      <div className="g-detail-a-btn">
                        <div role="button" tabIndex="0" onKeyUp={() => deleteList(x, index)} onClick={() => deleteList(x, index)} className="g-detail-a-del-btn" style={{ cursor: 'pointer' }}>Delete</div>
                        <div className="g-detail-a-edit-btn" style={{ cursor: 'pointer' }}>Edit</div>
                      </div>
                    </div>
                  )
                } */}
              </div>
            ))
          }
        </div>
          {
            disabledAddPolygon && (
              <div className="geofence-details-body-area-name-list-active">
                <input className="g-detail-a-input-text" onChange={(e) => inputChange(e)} />
                <div className="g-detail-a-btn">
                  <div className="g-detail-a-edit-btn" role="button" tabIndex="0" onKeyUp={cancelList} onClick={cancelList} style={{ cursor: 'pointer', color: '#B54708' }}>Cancel</div>
                  <div
                    className={disabledSaveButton ? 'g-detail-a-edit-btn g-detail-a-btn-disabled' : 'g-detail-a-edit-btn'}
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => saveList('add')}
                    onClick={() => saveList('add')}
                    style={{ cursor: 'pointer' }}
                  >
                      Save
                  </div>
                </div>
              </div>
            )
          }

          {/* <div className="geofence-details-body-area-name-list">
            <div className="g-detail-a-label-text">Warehouse</div>
            <div className="g-detail-a-btn">
              <div className="g-detail-a-del-btn" style={{ cursor: 'pointer' }}>Delete</div>
              <div className="g-detail-a-edit-btn" style={{ cursor: 'pointer' }}>Edit</div>
            </div>
          </div> */}
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}> */}
        <div
          style={{ cursor: 'pointer' }}
          className={disabledAddPolygon ? 'geofence-details-footer-area-name-list-disabled' : 'geofence-details-footer-area-name-list'}
          role="button"
          tabIndex="0"
          onKeyUp={createPolygonFunction}
          onClick={createPolygonFunction}
        >
          <img loading="lazy" src={PlusIcon} alt="plus-icon" />
          <div>Draw New Area</div>
        </div>
      {/* </div> */}

    </div>
  );
}
