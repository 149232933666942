import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
    createTheme,
    ThemeProvider,
    Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import './buttonComponent.css';
import ExportIcon from './img/exportIcon.svg';
import GoogleIcon from './img/google.svg';
import TruckWhiteIcon from './img/truckWhite.svg';
import ArrowIcon from './img/arrowLeft.svg';
import NextArrow from './img/arrowRight.svg';
import To from '../../image/forwardArrow.svg';
import RefreshIcon from './img/refresh.svg';
import TelemetrySnapshotIcon from './img/telemetrySnapshot.svg';
import TruckIcon from './img/truckGrey.svg';
import AssetStatusIcon from './img/assetStatus.svg';
import LineChartUp from './img/lineChartUp.svg';
import CalendarIcon from './img/calendar.svg';
import SignalIcon from './img/signal.svg';
import WhiteArrowDownIcon from './img/arrowDown.svg';
import BlackArrowDownIcon from './img/arrowDown2.svg';
import InviteUserIcon from './img/inviteUser.svg';
import PlusIcon from './img/plusIcon.svg';
import DeleteIcon from './img/delete.svg';
import MessageDotsIcon from './img/Message.svg';
import MenuBarIcon from './img/menuBar.svg';
import ChevronIcon from './img/chevronDown.svg';

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#FFFFFF',
        },
    },
});

export const TextButton = (props) => {
    const {
 label, onClickFunction, disable, fontWeight, color, backgroundColor,
} = props;
    return (
        <button
          type="button"
          className="text-button"
          onClick={(e) => onClickFunction(e)}
          style={disable ? {
            opacity: 0.7,
            backgroundColor,
          } : { fontWeight, color, backgroundColor }}
          disabled={disable}
        >
            {label}
        </button>
    );
};

export const PrimaryButton = (props) => {
    const {
        label, width, onClickFunction, disable, fontSize, loading,
    } = props;
    return (
        <button
          type="button"
          className="primary-button"
          style={disable ? {
                background: '#92B7C9', border: '1px solid #92B7C9', width: width, cursor: 'auto', fontSize: fontSize,
                } : { border: '1px solid #365A6D', width: width, fontSize: fontSize }}
          onClick={(e) => onClickFunction(e)}
          disabled={disable}
        >
        <ThemeProvider theme={loadTheme}>
        { loading && (
            <CircularProgress
              color="amcolor"
              style={{
              width: '20px', height: '20px', justifySelf: 'center', alignSelf: 'center',
              }}
            />
        )}
        </ThemeProvider>
            {label}
        </button>
    );
};

export const SecondaryButton = (props) => {
    const {
        label, width, onClickFunction, imageIcon, fontSize, disable,
    } = props;
    return (
        <button
          type="button"
          className="secondary-button"
          style={disable ? {
              border: '1px solid #EAECF0', width: width, cursor: 'auto', fontSize: fontSize, color: '#D0D5DD',
              } : {
 border: '1px solid #D0D5DD', width: width, fontSize: fontSize, color: '#344054',
             }}
        //   style={{ width: width, fontSize: fontSize }}
          onClick={(e) => onClickFunction(e)}
        >
            {
                imageIcon && (
                    <img loading="lazy" src={ArrowIcon} alt="Back icon" style={{ width: '0.729rem', height: '0.729rem' }} />
                )
            }
            {label}
        </button>
    );
};

export const GoogleButton = (props) => {
    const { label, width } = props;
    return (
        <button type="button" className="google-button" style={{ width: width }}>
            <img loading="lazy" src={GoogleIcon} alt="Google Icon" style={{ width: '1.5rem', height: '1.5rem' }} />
            {label}
        </button>
    );
};

export const LearnMore = (props) => {
    const { label } = props;
    return (
        <button type="button" className="learn-more-button ">{label}</button>
    );
};

export const ManageAssetButton = (props) => {
    const { label, btnClick } = props;
    return (
        <button
          type="button"
          className="manage-asset-button"
          onClick={(e) => btnClick(e)}
        >
            <img loading="lazy" src={TruckWhiteIcon} alt="truckwhite" />
            {label}
        </button>
    );
};

export const ContinueButton = (props) => {
    const { label, disable, btnContinue } = props;
    return (
        <button
          type="button"
          style={disable ? { background: '#92B7C9', border: '1px solid #92B7C9' } : { background: '#365A6D', border: '1px solid #365A6D' }}
          className="continue-button"
          onClick={(e) => btnContinue(e)}
          disabled={disable}
        >
            {label}
        </button>
    );
};

export const BackButton = (props) => {
    const { label, btnClickBack, color } = props;
    return (
        <button type="button" className="back-button-container" style={{ background: color, padding: '0rem' }} onClick={(e) => btnClickBack(e)}>
            <img loading="lazy" src={ArrowIcon} alt="arrowIcon" />
            {label}
        </button>
    );
};

export const ErrorPageRedirectButton = (props) => {
    const { label, description } = props;
    const openEmail = () => {
        window.open('mailto:support@horizonapp.cloud');
    };
    return (
        <button
          type="button"
          className="error-page-redirect-button-container"
          onClick={(e) => {
            openEmail();
            e.preventDefault();
          }}
        >
            <div className="error-page-redirect-button-column">
                <div className="error-page-redirect-button-row">
                    <div className="error-page-redirect-button-label">{label}</div>
                    <img loading="lazy" src={To} alt="To icon" style={{ width: '0.729rem', height: '0.729rem' }} />
                </div>
                <div className="error-page-redirect-button-description">{description}</div>
            </div>
        </button>
    );
};

export const LatestDataButton = (props) => {
    const { label, refreshClick, disable } = props;
    return (
        <button
          type="button"
          className="asset-card-refresh-button-container"
          onClick={(e) => refreshClick(e)}
          style={disable && {
                opacity: '0.6',
          }}
          disabled={disable}
        >
            <div className="asset-card-refresh-button">
                <img loading="lazy" src={RefreshIcon} alt="refreshIcon" />
                <div className="asset-card-get-data-text">{label}</div>
            </div>
        </button>
    );
};

export const AssetCardButton = (props) => {
    const {
        indicator, navigateButton, label, disabled,
     } = props;

    const imgIndicator = (value) => {
        switch (value) {
            case 'telemetry':
                return <img loading="lazy" src={TelemetrySnapshotIcon} alt="marker-icon" />;
            case 'assetStatus':
                return <img loading="lazy" src={AssetStatusIcon} alt="marker-icon" />;
            case 'truck':
                return <img loading="lazy" src={TruckIcon} alt="marker-icon" />;
            default:
                return null;
          }
    };
    return (
        <div className="asset-card-button-container" onClick={(e) => navigateButton(e)} onKeyDown={() => navigateButton()} role="button" tabIndex="0">
            {imgIndicator(indicator)}
            {label}
        </div>
    );
};

export const InsightButton = (props) => {
    const {
        label, width, onClickFunction, imageIcon,
    } = props;
    return (
        <button type="button" className="dashboard-insight-button" style={{ width: width }} onClick={(e) => onClickFunction(e)}>
            {
                imageIcon && (
                    <img loading="lazy" src={LineChartUp} alt="lineChartIcon" />
                )
            }
            {label}
        </button>
    );
};

export const DatePickerButton = (props) => {
    const {
        label, width, onClickFunction, imageIcon,
    } = props;
    return (
        <button type="button" className="select-date-button" style={{ width: { width } }} onClick={(e) => onClickFunction(e)}>
            {
                imageIcon && (
                    <img loading="lazy" src={CalendarIcon} alt="Calendar icon" />
                )
            }
            {label}
        </button>
    );
};

export const LiveMapButton = (props) => {
    const {
        label, width, onClickFunction, imageIcon,
    } = props;
    return (
        <button type="button" className="live-map-button" style={{ width: { width } }} onClick={(e) => onClickFunction(e)}>
            {
                imageIcon && (
                    <img loading="lazy" src={SignalIcon} alt="Signal icon" />
                )
            }
            {label}
        </button>
    );
};

export const BulkActionButton = (props) => {
    const {
        label, width, onClickFunction, disable, background, color, height,
    } = props;
    const imgIndicator = (value) => {
        switch (value) {
            case 'black':
                return <img src={BlackArrowDownIcon} alt="white-arrow-down-icon" loading="lazy" />;
            case 'white':
                return <img src={WhiteArrowDownIcon} alt="black-arrow-down-icon" loading="lazy" />;
            default:
                return null;
        }
    };
    return (
        <button
          type="button"
          className="bulk-action-button"
          style={
            disable ? {
                      opacity: '0.6',
                      width: { width },
                      background: background,
                      color: color,
                      height: height,
                    } : {
                      width: { width },
                      background: background,
                      color: color,
                      height: height,
                    }
          }
          onClick={(e) => onClickFunction(e)}
        >
            {imgIndicator(color)}
            {label}
        </button>
    );
};

export const OutlinedButton = (props) => {
    const {
        label, onClickFunction, width, disable,
    } = props;
    return (
        <button
          type="button"
          className="outlined-button"
          style={disable ? {
                opacity: '0.6', border: '1px solid #D0D5DD', width: width,
                } : { border: '1px solid #D0D5DD', width: width }}
          onClick={(e) => onClickFunction(e)}
          disabled={disable}
        >
            {label}
        </button>
    );
};

export const PreviousButton = (props) => {
    const {
        label, onClickFunction, width, disable,
    } = props;
    return (
        <button
          type="button"
          className="outlined-button"
          style={disable ? {
                opacity: '0.6', border: '1px solid #D0D5DD', width: width,
                } : { border: '1px solid #D0D5DD', width: width }}
          onClick={(e) => onClickFunction(e)}
          disabled={disable}
        >
        <img loading="lazy" src={ArrowIcon} alt="arrowIcon" />
            {label}
        </button>
    );
};

export const NextButton = (props) => {
    const {
        label, onClickFunction, width, disable,
    } = props;
    return (
        <button
          type="button"
          className="outlined-button"
          style={disable ? {
                opacity: '0.6', border: '1px solid #D0D5DD', width: width,
                } : { border: '1px solid #D0D5DD', width: width }}
          onClick={(e) => onClickFunction(e)}
          disabled={disable}
        >
            {label}
            <img loading="lazy" src={NextArrow} alt="arrowIcon" />
        </button>
    );
};

export const ExportCSVButton = (props) => {
    const {
        label, onClickFunction, width, disable, buttonHeight,
    } = props;
    return (
        <button
          type="button"
          className="export-csv-button"
          style={disable ? {
            opacity: '0.6',
            border: '1px solid #D0D5DD',
            height: buttonHeight,
            cursor: 'default',
          } : { height: buttonHeight }}
          disabled={disable}
          onClick={(e) => onClickFunction(e)}
        >
            <img loading="lazy" src={ExportIcon} alt="export-icon" />
            {label}
        </button>
    );
};

export const CreateWithIcon = (props) => {
    const {
        label, onClickFunction, buttonHeight, buttonWidth, module, disable, loading, color, backgroundColor, show,
    } = props;
    const imgIndicator = (value) => {
        switch (value) {
            case 'users':
                return <img loading="lazy" src={InviteUserIcon} alt="user-icon" />;
            case 'policy':
                return <img loading="lazy" src={PlusIcon} alt="plus-icon" />;
            case 'agencies':
                return <img loading="lazy" src={DeleteIcon} alt="delete-icon" />;
            case 'assetPerformance':
                return (
                <ThemeProvider theme={loadTheme}>
                { loading === true ? (
                    <CircularProgress color="amcolor" style={{ width: '30px', height: '30px' }} />
                ) : (
                    <></>
                )}
                </ThemeProvider>
                );
            case 'trips':
                return <img loading="lazy" src={TelemetrySnapshotIcon} alt="marker-icon" />;
            case 'tripsTimeline':
                return <img loading="lazy" src={ChevronIcon} alt="chevron-icon" style={show ? { transform: 'rotate(180deg)', transition: 'transform 150ms ease' } : { transform: 'rotate(0deg)', transition: 'transform 150ms ease' }} />;
            default:
                return null;
        }
    };
    return (
        <button
          type="button"
          className="bulk-action-button"
          style={disable ? {
            opacity: '0.6', border: '1px solid #D0D5DD', height: buttonHeight, width: buttonWidth, color: color, backgroundColor: backgroundColor,
          } : {
            height: buttonHeight, width: buttonWidth, color: color, backgroundColor: backgroundColor,
          }}
          disabled={disable}
          onClick={(e) => onClickFunction(e)}
        >
            {module === 'assetPerformance' ? (
                <>
                {loading === true ? (
                    <>
                    {imgIndicator(module)}
                    </>
                ) : (
                    <>
                    {label}
                    </>
                )}
                </>
            ) : (
                <>
                {imgIndicator(module)}
                {label}
                </>
            )}
        </button>
    );
};

export const DeleteButton = (props) => {
    const {
        label,
        onClickFunction,
        buttonHeight,
        buttonWidth,
        disable,
        fontSize,
    } = props;
    return (
        <button
          type="button"
          className="button-delete"
          style={{ height: buttonHeight, width: buttonWidth, fontSize: fontSize }}
          disabled={disable}
          onClick={(e) => onClickFunction(e)}
        >
            {label}
        </button>
    );
};

export const OutlinedDeleteButton = (props) => {
    const {
        label,
        onClickFunction,
        buttonHeight,
        buttonWidth,
        disable,
        module,
    } = props;
    const imgIndicator = (value) => {
        switch (value) {
            case 'agencies':
                return <img loading="lazy" src={DeleteIcon} alt="delete-icon" />;
            default:
                return null;
        }
    };
    return (
        <button
          type="button"
          className="outline-button-delete"
          style={
            disable ? {
                opacity: '0.6',
                height: buttonHeight,
                width: buttonWidth,
                pointerEvents: 'none',
            } : {
                height: buttonHeight,
                width: buttonWidth,
            }
        }
          disabled={disable}
          onClick={(e) => onClickFunction(e)}
        >
            {module === 'agencies' ? (
                <>
                    {imgIndicator(module)}
                    {label}
                </>
            ) : (
                <>
                {label}
                </>
            )}
        </button>
    );
};

export const TelegramButton = (props) => {
    const {
        label, onClickFunction, buttonHeight, buttonWidth, module, disable, loading,
    } = props;
    const imgIndicator = (value) => {
        switch (value) {
            case 'telegram':
                return <img loading="lazy" src={MessageDotsIcon} alt="user-icon" />;
            default:
                return null;
        }
    };
    return (
        <button
          type="button"
          className="telegram-message-button"
          style={disable ? {
            opacity: '0.6', border: '1px solid #D0D5DD', height: buttonHeight, width: buttonWidth,
          } : { height: buttonHeight, width: buttonWidth }}
          disabled={disable}
          onClick={(e) => onClickFunction(e)}
        >
            {imgIndicator(module)}
            {label}
        </button>
    );
};

export const TopbarMenuButton = (props) => {
    const {
        label, onClickFunction, buttonHeight, buttonWidth, module, disable, loading,
    } = props;

    return (
        <button
          type="button"
          className="topbar-menu-button"
          style={disable ? {
            opacity: '0.6', border: '1px solid #D0D5DD', height: buttonHeight, width: buttonWidth,
          } : { height: buttonHeight, width: buttonWidth }}
          disabled={disable}
          onClick={(e) => onClickFunction(e)}
        >
            <img loading="lazy" src={MenuBarIcon} alt="menu-icon" />
        </button>
    );
};

export const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 36,
    height: 20,
    margin: 0,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#365A6D',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
