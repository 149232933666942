/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, {
    useEffect, useState, useContext, useRef,
} from 'react';
// import moment from 'moment';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { DatePickerButton, InsightButton, LiveMapButton } from '../../component/buttonComponent/ButtonComponent';
import Card from '../../component/card/Card';
import DateTabFilter from '../../component/dateTab/dateTabFilter';
import FilterButton from '../../component/filterButton/FilterButton';
import Title from '../../component/title/Title';
import ColoredLine from '../../component/coloredLine/ColoredLine';
import MapComponent from '../../component/map/MapComponent';
import {
    APIGetV3Payload, APIGetAssetUtilisation, APIGetAssetUtilisationPerc, APIGetTotalDevices, APIGetActiveDevicePerc, APIGetTotalIngestedPayloads,
} from '../../config/restAPI/FetchAPI';
import { APIWSConnect } from '../../config/restAPI/PostAPI';
import { WebsocketContext } from '../../config/reducer/Websocket';
import { useApiData } from '../../config/reducer/Modules';
import DateRangePicker from '../../component/dateRangePicker/DateRangePicker';
import '../../component/map/mapComponent.css';
import './Dashboard.css';
import AssetStatus from '../../component/assetStatus/AssetStatus';
import HeatMap from '../../component/heatMap/HeatMap';

const Dashboard = ({ history }) => {
    const navigate = useNavigate();
    const mapRefs = useRef();
    const payloadContext = useContext(WebsocketContext);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const {
        setChannel, data: websocketData, newPayloadId, selectedAssetInfo, selectAsset,
    } = payloadContext;
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agency-id');
    const agencyOid = Cookies.get('agencyUUID');
    const name = Cookies.get('name');
    const role = Cookies.get('role');
    const { moduleLoading, apiUser, apiResponse } = useApiData();
    const getTimeZone = apiUser?.data?.timezone ? apiUser.data.timezone : 'Greenwich';
    const [adminConsole, setAdminConsole] = useState(false);

    // moment().tz(getTimeZone).format();

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [markerValue, setMarkerValue] = useState({}); // this for asset details card value (in asset detail card)
    const [assetCardDisp, setAssetCardDisp] = useState(false); // this to display asset detail card state
    const [selectedOption, setSelectedOption] = useState(null);
    const [assetSelectionList, setAssetSelectionList] = useState({}); // this for asset selection state
    const [dateClick, setDateClick] = useState('24hours');
    const [selectedDate, setSelectedDate] = useState({
        fromTime: '',
        toTime: '',
    });
    const [listPoint, setListPoint] = useState([]); // this is for map marker

    // console.log('getTimeZone', getTimeZone);

    // loading state
    const [loading, setLoading] = useState(false);
    const [loadingGraph, setLoadingGraph] = useState(false);
    const [adminLoadingCard, setAdminLoadingCard] = useState(null);
    const [loadingCard, setLoadingCard] = useState(false);
    const [loadingIngestedPayloads, setLoadingIngestedPaylods] = useState(null);
    const [loadingTotalDevices, setLoadingTotalDevices] = useState(null);
    const [loadingActiveDevices, setLoadingActiveDevices] = useState(null);
    const [applyDatePicker, setApplyDatePicker] = useState(false);
    const [inputValue, setInputValue] = useState('Select Dates');

    const [timer, setTimer] = useState('');
    const [assetClick, setAssetClick] = useState('allAsset');
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState, idleState, setIdleState] = useOutletContext();
    const [oneTimeLoad, setOneTimeLoad] = useState(false);
    const [viewMapping, setViewMapping] = useState({
        longitude: 101.587140,
        latitude: 3.085870,
        zoom: 14,
    });
    const [graphState, setGraphState] = useState([]);
    const [cardState, setCardState] = useState({
        checkIfNegativeNumber: true,
        percentageDifferent: 0,
        records: [],
        totalUtilisedPerc: 0,
    });

    const [activeDeviceState, setActiveDeviceState] = useState({
        checkIfNegativeNumber: true,
        percentageDifferent: 0,
        records: [],
        activeDevicesPerc: 0,
    });
    const [totalDeviceState, setTotalDeviceState] = useState({
        records: [],
        totalCreatedDevice: 0,
        precentageDiff: 0,
    });
    const [ingestedPayloadState, setIngestedPayloadState] = useState({
        percentageDiff: 0,
        records: [],
        ingestedPayloads: 0,
        checkIfNegativeNumber: true,
    });
    const [dateRange, setDateRange] = useState([]);
    const [date, setDate] = useState([]);

    const firstName = () => {
        const nameArray = name.split(' ');
        return nameArray[0];
    };

    const markerPointFromAPI = (value) => {
        const pointsMapping = value.map((docs) => {
            if ((docs.latitude !== null || docs.longitude !== null) && (docs.latitude >= -90 && docs.latitude <= 90) && (docs.longitude >= -180 && docs.longitude <= 180) && (docs.snapshotState !== 'removed')) {
                return docs;
            }
            return null;
        });
        return pointsMapping;
    };

    const APIV3CalledGetPayload = async (deviceOid) => {
        let returnResult = null;
        try {
          const result = await APIGetV3Payload(setLoading, token, agencyOid, deviceOid);
        //   console.log('return result', result);
          if (result.data.status === 200) {
            const { data } = result.data;
            // console.log('this called from APIV3CalledGetPayload', data);
            returnResult = data;
          } else {
            returnResult = null;
          }
          return returnResult;
        } catch (err) {
          // console.log('error', err);
          return returnResult;
        }
    };

    const assetUtilistationAllGet = async (params) => {
        try {
            // console.time();
            const result = await APIGetAssetUtilisation(setLoadingGraph, token, agencyOid, params);
            // console.log('resultGraphAssetUtilistaion', result);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data.length > 0) {
                    const reducer = data.map((i) => ({
                        name: i.name,
                        data: i.data.length > 0 ? i.data.map((x) => ({
                            x: `${x.x}:00`,
                            y: x.y,
                        })) : [],
                    }));
                    // console.log('dataaaaaa', reducer);
                    setGraphState(reducer);
                }
                setLoadingGraph(false);
                // console.timeEnd();
            } else {
                // console.log('error from result status either than 200');
                setLoadingGraph(false);
                // console.timeEnd();
            }
        } catch (err) {
            // console.log('assetUtilisationcatch error', err);
            setLoadingGraph(false);
            // console.timeEnd();
        }
    };

    const assetUtilistationPerc = async (params) => {
        try {
            // console.time();
            const result = await APIGetAssetUtilisationPerc(setLoadingCard, token, agencyOid, params);
            // console.log('resultCardAssetUtilisation', result);
            if (result.data.status === 200) {
                const { data } = result.data;
                // console.log('dataaaaaa', data);
                if (data) {
                    const reducer = {
                        totalUtilisedPerc: data.totalUtilisedPerc,
                        percentageDiff: data.percentageDiff,
                        checkIfNegativeNumner: data.percentageDiff > 0,
                        records: data.records.length > 0 ? data.records.map((x) => ({
                            name: `${x.start} ${x.end}`,
                            uv: x.totalUtilisedPerc,
                        })) : [],
                    };
                    setCardState(reducer);
                }
                setLoadingCard(false);
                // console.timeEnd();
            } else {
                console.log('error from result status either than 200');
                setLoadingCard(false);
                // console.timeEnd();
            }
        } catch (err) {
            console.log('assetUtilisation error');
            setLoadingCard(false);
            // console.timeEnd();
        }
    };

    const totalDevice = async (params) => {
        try {
            const result = await APIGetTotalDevices(setLoadingTotalDevices, token, agencyOid, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data) {
                    const reducer = {
                        totalCreatedDevice: data.totalDevices,
                        precentageDiff: data.percentageDiff,
                        checkIfNegativeNumber: data.percentageDiff > 0,
                    };
                    setTotalDeviceState(reducer);
                    setLoadingTotalDevices(false);
                } else {
                    setLoadingTotalDevices(false);
                }
            } else {
                console.log('Total device return status other 200');
                setLoadingTotalDevices(false);
            }
        } catch (err) {
            console.log('totalDevices error');
            setLoadingCard(false);
        }
    };

    const activeDevicePerc = async (params) => {
        try {
            const result = await APIGetActiveDevicePerc(setLoadingActiveDevices, token, agencyOid, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                // console.log('dataaaaaa', data);
                if (data) {
                    const reducer = {
                        activeDevicesPerc: data.activeDevicesPerc,
                        percentageDiff: data.percentageDiff,
                        checkIfNegativeNumber: data.percentageDiff > 0,
                        records: data.records.length > 0 ? data.records.map((x) => ({
                            name: `${x.start} ${x.end}`,
                            uv: x.activeDevicesPerc,
                        })) : [],
                    };
                    setActiveDeviceState(reducer);
                    setLoadingActiveDevices(false);
                } else {
                    setLoadingActiveDevices(false);
                }
            } else {
                console.log('Active device return status other 200');
                setLoadingActiveDevices(false);
            }
        } catch (err) {
            console.log('activeDevicePerc arror');
            setLoadingCard(false);
        }
    };

    const totalIngestedPayloads = async (params) => {
        try {
            const result = await APIGetTotalIngestedPayloads(setLoadingIngestedPaylods, token, agencyOid, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data) {
                    const reducer = {
                        percentageDiff: data.percDiff,
                        records: data.records.length > 0 ? data.records.map((x) => ({
                            name: `${x.start} ${x.end}`,
                            uv: x.nSamples,
                        })) : [],
                        ingestedPayloads: data.total,
                        checkIfNegativeNumber: data.percDiff > 0,
                    };
                    setIngestedPayloadState(reducer);
                    setLoadingIngestedPaylods(false);
                } else {
                    setLoadingIngestedPaylods(false);
                }
            } else {
                console.log('Total ingested payload return status other 200');
                setLoadingIngestedPaylods(false);
            }
        } catch (err) {
            console.log('ingestedPaylods error');
            setLoadingCard(false);
        }
    };
        // this to display all markerFunction;
    const allMarkerDisplayFunction = (payload, tabs) => {
        try {
            const convertGeo = markerPointFromAPI(payload);
            const filterGeo = convertGeo.filter((x) => x !== null);
            const assetList = assetClick === 'allAsset' ? filterGeo : filterGeo.filter((y) => y.motionInference === 200);
        if (assetClick !== 'allAsset') {
            if (Object.keys(markerValue).length > 0 && markerValue.motionInference !== 200) {
            setMarkerValue({});
            setAssetCardDisp(false);
            setSelectedOption(null);
            setAssetSelectionList({});
            }
        }
        setListPoint(assetList);
        tabs && setOneTimeLoad(true);
        clearInterval(timer);
        setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log('allMarkerDisplayFunctionError');
        }
    };

    const stateChange = (e, nameValue) => {
        // e.preventDefault();
        setNavigationState(nameValue);
        navigate('/live/mapOverview', { replace: true });
        // console.log('nameValue', nameValue);
    };

    const switchLoadData = (value, params) => {
        switch (value) {
            case 'System Administrator':
                setAdminConsole(true);
                // setAdminLoadingCard(true);
                assetUtilistationAllGet(params);
                totalDevice(params);
                activeDevicePerc(params);
                totalIngestedPayloads(params);
                break;
            case (value !== 'System Administrator'):
                setAdminConsole(false);
                assetUtilistationAllGet(params);
                assetUtilistationPerc(params);
                break;
            default:
                setAdminConsole(false);
                assetUtilistationAllGet(params);
                assetUtilistationPerc(params);
                break;
        }
    };

    // to display dateChecking
    const dateChecking = (nameTab) => {
        switch (nameTab) {
            case '30days':
                return {
                    toTime: moment().tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss'),
                    fromTime: moment().tz(getTimeZone).subtract(30, 'days').format('YYYY-MM-DD[T]hh:mm:ss'),
                };
            case '6months':
                return {
                    toTime: moment().tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss'),
                    fromTime: moment().tz(getTimeZone).subtract(6, 'months').format('YYYY-MM-DD[T]hh:mm:ss'),
                };
            case '7days':
                return {
                    toTime: moment().tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss'),
                    fromTime: moment().tz(getTimeZone).subtract(7, 'days').format('YYYY-MM-DD[T]hh:mm:ss'),
                };
            case '24hours':
                return {
                    toTime: moment().tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss'),
                    fromTime: moment().subtract(1, 'days').tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss'),
                };
            default:
                return null;
        }
    };

    // this relates to select dates
    useEffect(() => {
        if (applyDatePicker) {
            if (date.length > 0) {
                const params = {
                    fromTime: date[0] ? moment(new Date(date[0])).tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss') : null,
                    toTime: date[1] ? moment(new Date(date[1])).tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss') : null,
                };
                // this to check if fromTime and toTime sending in true value
                if (Object.values(params).every((value) => { if (value === null) { return false; } return true; })) {
                    // console.log('go here if all true', params);
                    assetUtilistationAllGet(params);
                    assetUtilistationPerc(params);
                    setDateClick(null);
                }
             }
             setApplyDatePicker(false);
        }
    }, [applyDatePicker]);

    const btnDateTabFilter = (e, valueName) => {
      try {
        setDateClick(valueName);
        const checkDate = dateChecking(valueName);
        // console.log('checkDate', { checkDate, valueName });
        assetUtilistationPerc(checkDate);
        assetUtilistationAllGet(checkDate);
        setDate([]);
        // console.log('dateChecking', checkDate);
      } catch (err) {
        console.log('btnDateFilterTabErrorFucntion');
      }
    };

    const handleInsightButtonClick = () => {
        navigate('/insights/assetPerformance', { replace: true });
    };

    useEffect(() => {
        if (token) {
            const params = {
                toTime: moment().tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss'),
                fromTime: moment().subtract(7, 'days').tz(getTimeZone).format('YYYY-MM-DD[T]hh:mm:ss'),
            };
            switchLoadData(role, params);
            APIV3CalledGetPayload().then((x) => allMarkerDisplayFunction(x, true)).catch((e) => console.log(e));
            // assetUtilistationAllGet(params);
            // assetUtilistationPerc(params);
            // totalDevice(params);
            // activeDevicePerc(params);
        }

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        setNavigationState('dashboard');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const WSReconnect = async () => {
        const postResponse = await APIWSConnect(token, agencyOid);
        if (postResponse.status === 200) {
            setChannel(agencyID);
        }
    };

    useEffect(() => {
        if (idleState) {
            WSReconnect();
            setIdleState(false);
        }
    }, []);

    // useEffect(() => {
    //     if (moduleLoading) {
    //         return null;
    //     }
    //     return undefined;
    // }, [moduleLoading]);

    // useEffect(() => {
    //     if ((loadingActiveDevices && loadingActiveDevices && loadingIngestedPayloads) === false) {
    //         setAdminLoadingCard(false);
    //     }
    // }, [loadingTotalDevices, loadingActiveDevices, loadingIngestedPayloads]);

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <div className="dashboard-title">
                    <Title title={`Welcome back, ${name && firstName()}`} subtitle="Here is an overview of your asset's overall health" titleSize="1.875rem" />
                    {
                        (apiResponse && apiResponse.role === 'Agency Administrator') || (apiResponse && apiResponse.role === 'Agency Owner') ? (
                            <InsightButton label="Insights" width="8.25rem" imageIcon="insightIcon" onClickFunction={handleInsightButtonClick} />
                        ) : (
                            apiResponse?.module?.insights?.assetPerformance === false || apiResponse?.module?.insights?.assetPerformance === undefined ? (
                                <></>
                            ) : (
                                <InsightButton label="Insights" width="8.25rem" imageIcon="insightIcon" onClickFunction={handleInsightButtonClick} />
                            )
                        )
                    }
                </div>
                {/* <div className="dashboard-filters">
                    <div className="dashboard-date-filter">
                        <DateTabFilter dateClick={dateClick} onClickTab={btnDateTabFilter} />
                    </div>
                    <div className="dashboard-datePicker-filter-button">
                        <DatePickerButton label="Select dates" width="9.063rem" imageIcon="calendarIcon" />
                        <DateRangePicker date={date} setDate={setDate} dateRange={dateRange} setDateRange={setDateRange} minDateRange={new Date().setMonth(new Date().getMonth() - 6)} maxDateRange={new Date()} setApplyDatePicker={setApplyDatePicker} inputValue={inputValue} setInputValue={setInputValue} />
                        <FilterButton height="40px" />/
                    </div>
                </div> */}
            </div>
            <div className="dashboard-metric-group-container">
                <div className="dashboard-metric-group">
                    {adminConsole ? (
                        <>
                            <Card title="Total Devices" body={`${totalDeviceState?.totalCreatedDevice}`} loading={loadingTotalDevices} width="20%" />
                            <Card title="Active Devices" body={`${activeDeviceState?.activeDevicesPerc?.toFixed(2)}%`} checkArrow={activeDeviceState.checkIfNegativeNumber} apiData={activeDeviceState.records} loading={loadingActiveDevices} different={`${activeDeviceState?.percentageDiff ? activeDeviceState.percentageDiff.toFixed(2) : 0}`} minWidth="40%" />
                            <Card title="Total Ingested Payloads" body={`${ingestedPayloadState?.ingestedPayloads}`} checkArrow={ingestedPayloadState.checkIfNegativeNumber} apiData={ingestedPayloadState.records} loading={loadingIngestedPayloads} different={`${ingestedPayloadState?.percentageDiff ? ingestedPayloadState.percentageDiff.toFixed(2) : 0}`} minWidth="40%" />
                        </>
                    ) : (
                        <Card title="Asset Utilisation" body={`${cardState.totalUtilisedPerc ? cardState?.totalUtilisedPerc?.toFixed(2) : '0'}%`} checkArrow={cardState.checkIfNegativeNumner} noChange={cardState.totalUtilisedPerc === null || undefined} apiData={cardState.records} loading={loadingCard} different={`${cardState?.percentageDiff ? cardState.percentageDiff.toFixed(2) : '0'}`} />
                    )}
                </div>
            </div>
            <div className="dashboard-assets-overview">
                <div className="dashboard-assets-overview-title-container">
                    <div className="assets-overview-title">
                        {!adminConsole ? (
                            <Title title="Assets Overview" titleSize="1.125rem" lineHeight="1.75rem" />
                            ) : (
                            <Title title="Devices Overview" titleSize="1.125rem" lineHeight="1.75rem" />
                        )}
                        {
                            (apiResponse && apiResponse.role === 'Agency Administrator') || (apiResponse && apiResponse.role === 'Agency Owner') ? (
                                <LiveMapButton label="Live Map Overview" imageIcon="SignalIcon" onClickFunction={stateChange} />
                            ) : (
                                apiResponse?.module?.live?.liveMap === false || apiResponse?.module?.live?.liveMap === undefined ? (
                                    <></>
                                ) : (
                                    <LiveMapButton label="Live Map Overview" imageIcon="SignalIcon" onClickFunction={stateChange} />
                                )
                            )
                        }
                    </div>
                </div>
                <ColoredLine />
                <div className="dashboard-assets-body">
                    <div className="dashboard-map">
                        {!loading && <MapComponent mapRefs={mapRefs} data={listPoint} markerClick={false} markerPopUp={false} pointMarkerValue={false} setViewMapping={setViewMapping} viewMapping={viewMapping} oneTimeLoad={oneTimeLoad} setOneTimeLoad={setOneTimeLoad} width={400} height={300} clusterClick={false} />}
                    </div>
                    <AssetStatus assetList={listPoint} />
                </div>
            </div>
            <div className="dashboard-heatmap-utilisation">
                <div className="dashboard-heatmap-utilisation-title">
                    <div className="assets-overview-title">
                        {!adminConsole ? (
                            <Title title="Asset Utilisation Heatmap" titleSize="1.125rem" lineHeight="1.75rem" />
                        ) : (
                            <Title title="Devices Utilisation Heatmap" titleSize="1.125rem" lineHeight="1.75rem" />
                        )}
                    </div>
                </div>
                <ColoredLine />
                <div className="dashboard-heatmap-utilisation-chart">
                    <HeatMap data={graphState} loading={loadingGraph} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
