import React, { useState, useEffect } from 'react';
import ToolTips from '../tooltip/ToolTips';
import './avatarGroup.css';

export default function AvatarGroup(props) {
  const {
 users, fontSize, lineHeight, image,
} = props;
  const [elipsis, setElipsis] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const size = 5;

  // console.log('notifiedUsers', users);

  function getInitials(names) {
    let initialsArray = [];
    let initialsFinal;
      const arr = names.split(' ');
      if (arr.length > 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < arr.length; i++) {
          const initials = arr[i].charAt(0).toUpperCase();
          initialsArray = [...initialsArray, initials];
        }
        initialsFinal = initialsArray;
      } else if (arr.length === 1) {
        names.charAt(0).toUpperCase();
        initialsFinal = names.charAt(0).toUpperCase();
      }
      // this only display 2 initials slice
      const onlyTwoAlphabet = initialsFinal.length > 1 ? initialsFinal.slice(0, 2) : initialsFinal;
      return onlyTwoAlphabet;
    }

  useEffect(() => {
      if (users.length > size) {
        const userRemain = users.length - size;
        setElipsis(true);
        setRemaining(userRemain);
      } else {
        setElipsis(false);
      }
  }, []);

  return (
    <div>
        {
          elipsis ? (
            <div className="parent-avatar-group">
                {
                  users.slice(0, size).map((x) => (
                    <div className="single-avatar">
                      {
                        x.url ? (
                          <ToolTips text={x.id} disableHoverListener>
                           <img className="img-avatar" loading="lazy" src={x.url} alt="avatar-user" />
                          </ToolTips>

                        ) : (
                          <ToolTips text={x.id} disableHoverListener>
                            <div className="group-initials-text">
                              {getInitials(x.id)}
                            </div>
                          </ToolTips>
                        )
                      }

                    </div>

                  ))
                }
                <div className="hidden-avatars">{`+${remaining}`}</div>
            </div>
          ) : (
            <div className="parent-avatar-group">
                {
                  users.length > 0 ? users.map((x) => (
                    <div className="single-avatar">
                      {
                        x.url ? (
                          <ToolTips text={x.id} disableHoverListener>
                            <img className="img-avatar" loading="lazy" src={x.url} alt="avatar-user" />
                          </ToolTips>
                        ) : (
                          <ToolTips text={x.id} disableHoverListener>
                            <div className="group-initials-text">
                              {getInitials(x.id)}
                            </div>
                          </ToolTips>
                        )
                      }

                    </div>
                  )) : null
                }

            </div>
          )
        }
    </div>
  );
}
