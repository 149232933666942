/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Select from 'react-select';

const customStyles = {
    control: (base) => ({
        ...base,
        height: '44px',
        minHeight: '44px',
        borderRadius: '8px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        border: '1px solid #D0D5DD',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
    }),
    menuPortal: (base) => ({
        ...base,
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
    }),
    menuList: (base) => ({
        ...base,
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
      }),
      option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#F9FAFB' : state.isFocused ? '#F9FAFB' : 'transparent',
          cursor: 'pointer',
          ':active': '#F9FAFB',
      }),
};

const ClearableDropdown = (props) => {
    const {
        agencyOption, placeholder, handleChange, agencyAssignment,
    } = props;

    const [isClearable, setIsClearable] = useState(true);
    return (
        <div style={{ width: '512px', height: '44px' }}>
            <Select
              onChange={handleChange}
              value={agencyAssignment}
              className="clearable-dropdown"
              placeholder={placeholder}
              styles={customStyles}
              isClearable={isClearable}
              isSearchable={false}
              getOptionLabel={(e) => (
                <div className="menu-container">
                    <div className="menu-text">
                        {e.label}
                    </div>
                    <div className="menu-subtitle">
                        {e.subtitle}
                    </div>
                </div>
              )}
              options={agencyOption}
            />
        </div>
    );
};

export default ClearableDropdown;
