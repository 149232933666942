/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
import React, {
    useState, useCallback, useRef, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import Map, {
    Marker, Source, Popup, Layer,
} from 'react-map-gl';
import * as turf from '@turf/turf';
import WebMercatorViewport from '@math.gl/web-mercator';
import OriginIcon from './img/origin.svg';
import DestinationIcon from './img/destination.svg';
import './mapTripReport.css';

import { firstCoordinate } from '../../config/function/miscFunction';

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function MapTripReport(props) {
    const {
        oneTimeLoad,
        setOneTimeLoad,
        tripsLineString,
        tripDetailsData,
        setTripDetailsData,
        page,
        setPage,
        hoverTripDetail,
        dateRange,
        setDateRange,
        tripList,
        resetChart,
        setFirstCoordsOrigin,
        firstCoordsOrigin,
        hoverInfo,
        setHoverInfo,
        resetState,
        checked,
        setChecked,
    } = props;

    const reducer = (value) => {
        const featureCollection = {
            type: 'FeatureCollection',
            features: value.map((e, index) => {
                const container = {
                    type: 'Feature',
                    properties: {
                        id: index + 1,
                        content: {
                            assetName: e.assetName,
                            origin: e.origin,
                            destination: e.destination,
                            startTraceId: e.startTraceId,
                            startTime: e.startTime,
                            endTime: e.endTime,
                            idlingRatio: e.idlingRatio,
                            deviceSerial: e.deviceSerial,
                            status: e.status,
                            totalDistance: e.totalDistance,
                            totalDuration: e.totalDuration,
                            tripGeo: e.tripGeo,
                        },
                    },
                    geometry: e.tripGeo,
                };
                return container;
            }),
        };
        return featureCollection;
    };

    const selectedLayer = {
        id: 'route',
        type: 'line',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#365A6D',
            'line-opacity': page === 0 ? 0.5 : 1.0,
            'line-width': 4,
        },
    };

    const highlightLayer = {
        id: 'route-highlight',
        type: 'line',
        source: 'route',
        paint: {
            'line-color': '#365A6D',
            'line-width': 4,
        },
    };

    const mapRef = useRef();

    const [mapData, setMapData] = useState();

    const [originPoint, setOriginPoint] = useState({});

    const [destinationPoint, setDestinationPoint] = useState({});

    const [viewMapping, setViewMapping] = useState({
        longitude: 101.58714,
        latitude: 3.08587,
        zoom: 14,
    });

    const onHover = useCallback((event) => {
        const feature = event.features && event.features[0];
        setHoverInfo({
            id: feature && feature.properties.id,
        });
    }, []);

    const selectedLine = (hoverInfo && hoverInfo.id) || '';
    const filter = useMemo(() => ['in', 'id', selectedLine], [selectedLine]);

    // this for fitbound in first load
    const polygonFitbound = (box) => {
        try {
            const viewport = new WebMercatorViewport({ width: 300, height: 600 });
            // the reason for below reducer are for the fitbound format which is [ [lon, lat], [lon, lat]]
            const reducer = [[box[0], box[1]], [box[2], box[3]]];
            const { longitude, latitude, zoom } = viewport.fitBounds(reducer, { padding: 20 });
            return { longitude, latitude, zoom };
        } catch (err) {
            // console.log('error for polygonfitbound', err);
            return { longitude: 101.58714, latitude: 3.08587, zoom: 10 };
        }
    };

    const moveCamera = (longitude, latitude, zoom) => {
        mapRef?.current?.flyTo({
            center: [longitude, latitude], zoom: zoom, duration: 700,
        });
    };

    const polylineClick = (event) => {
        const feature = event.length > 0 ? event[0]?.properties?.content : false;
        if (feature) {
            const detailValue = JSON.parse(feature);
            setTripDetailsData(detailValue);
            setPage((page + 1) % 2);
        }
    };

    useEffect(() => {
        if (page === 0) {
            setMapData(tripsLineString);
            const bbox = turf.bbox(tripsLineString);
            const mapping = polygonFitbound(bbox);
            moveCamera(mapping.longitude, mapping.latitude, mapping.zoom);
            setViewMapping({ ...mapping });
            // const mappedValue = tripsLineString.features.map((x) => x.geometry);
            // const takeFirstValue = firstCoordinate(mappedValue);
            // setFirstCoordsOrigin(takeFirstValue);
        }
    }, [page, resetState]);

    useEffect(() => {
        const mappedValue = tripList.map((x) => x.tripGeo);
        const takeFirstValue = firstCoordinate(mappedValue);
        setFirstCoordsOrigin(takeFirstValue);
    }, [tripList]);

    useEffect(() => {
        if (tripDetailsData) {
            let geometry;
            if (tripDetailsData.tripGeo.type === 'GeometryCollection') {
                geometry = turf.coordAll(tripDetailsData.tripGeo.geometries[1]);
            } else {
                geometry = turf.coordAll(tripDetailsData.tripGeo);
            }

            const bbox = turf.bbox(tripDetailsData.tripGeo);
            const mapping = polygonFitbound(bbox);
            moveCamera(mapping.longitude, mapping.latitude, mapping.zoom);
            setMapData(tripDetailsData.tripGeo);
            setOriginPoint({
                longitude: geometry[0][0],
                latitude: geometry[0][1],
            });
            setDestinationPoint({
                // longitude: tripDetailsData.tripGeo.coordinates.at(-1)[0],
                longitude: geometry.at(-1)[0],
                latitude: geometry.at(-1)[1],
            });
            // setViewMapping({ ...mapping });
        }
    }, [tripDetailsData]);

    useEffect(() => {
        if (dateRange) {
            const filteredTrips = tripList.filter((x) => moment(x.startTime).valueOf() > dateRange.min && moment(x.startTime).valueOf() <= dateRange.max);
            const filteredMapLineStrings = reducer(filteredTrips);
            setMapData(filteredMapLineStrings);
            // console.log('firest hellow, arghh', filteredMapLineStrings);
            // const mappedValue = tripList.map((x) => x.tripGeo);
            // const valueFirst = firstCoordinate(tripsLineString);
            // setFirstCoordsOrigin(valueFirst);
            const bbox = turf.bbox(filteredMapLineStrings);
            const mapping = polygonFitbound(bbox);
            moveCamera(mapping.longitude, mapping.latitude, mapping.zoom);
            setViewMapping({ ...mapping });
        }
    }, [dateRange]);

    return (
        <Map
          {...viewMapping}
          ref={mapRef}
          onMove={(event) => setViewMapping(event.viewState)}
          onMouseMove={onHover}
          onClick={(event) => polylineClick(event.features)}
          mapStyle="mapbox://styles/asiamobiliti/ckb1yq6080opd1inb3lkdmbii"
          interactiveLayerIds={['route', 'route-highlight']}
          mapboxAccessToken={mapboxToken}
          width={600}
          height={600}
        >
            <Source type="geojson" data={mapData}>
                <Layer {...selectedLayer} />
                <Layer {...highlightLayer} filter={filter} />
            </Source>
            {tripDetailsData ? (
                <>
                    <Popup
                      style={{
                        opacity: '80%',
                      }}
                      longitude={originPoint.longitude ?? null}
                      latitude={originPoint.latitude ?? null}
                      closeButton={false}
                      closeOnClick={false}
                    >
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <img src={OriginIcon} loading="lazy" alt="origin-icon" />
                            Origin
                        </div>
                    </Popup>
                    <Popup
                      style={{
                        opacity: '80%',
                      }}
                      longitude={destinationPoint.longitude ?? null}
                      latitude={destinationPoint.latitude ?? null}
                      closeButton={false}
                      closeOnClick={false}
                    >
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <img src={DestinationIcon} loading="lazy" alt="destination-icon" />
                            Destination
                        </div>
                    </Popup>
                </>
            ) : (
                <>
                {hoverInfo && hoverInfo.id ? (
                    firstCoordsOrigin && firstCoordsOrigin.length > 0 && firstCoordsOrigin.filter((x, index) => hoverInfo.id === index + 1).map((x) => (
                        <Marker
                          latitude={x[1] ?? null}
                          longitude={x[0] ?? null}
                        >
                            <div className="origin-marker">
                                {/* <img src={OriginIcon} loading="lazy" alt="origin-icon" /> */}
                                {`${hoverInfo.id}`}
                            </div>
                        </Marker>
                    ))
                ) : (
                    checked && (
                        firstCoordsOrigin && firstCoordsOrigin.length > 0 && firstCoordsOrigin.map((x, index) => (
                            <Marker
                              longitude={x[0] ?? null}
                              latitude={x[1] ?? null}
                            >
                                <div className="origin-marker">
                                    {/* <img src={OriginIcon} loading="lazy" alt="origin-icon" /> */}
                                    {`${index + 1}`}
                                </div>
                            </Marker>
                        ))
                    )
                )}
                </>
            )}
            <style>
                {
                    `
              .mapboxgl-popup-content {
                display: flex;
                box-shadow: 0rem 0.75rem 1rem -0.875rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
                border-radius: 0.5rem;
                font-weight: 600;
                font-size: 0.75rem;
                width: 100%;
                text-align: center;
                box-sizing: border-box;
                padding: 0.75rem 1rem;
              }
            `
                }
            </style>
        </Map>
    );
}
