/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import './text-input.css';
import Text from '../text/Text';
import Show from './img/show.svg';
import Hide from './img/hide.svg';
import Invalid from './img/invalid.svg';
import EmailIcon from './img/email.svg';
import VerifiedIcon from './img/verified.svg';
import GoogleIcon from '../../image/google.svg';
import ValidIcon from './img/valid.svg';

export default function TextInput(props) {
    const {
        label,
        placeholder,
        setInput,
        input,
        name,
        type,
        error,
        setError,
        keyUp,
        setKeyUp,
        onChangeFunction,
        width,
        emailState,
        disable,
        value,
        success,
        minWidth,
        maxWidth,
    } = props;

    const [inputType, setInputType] = useState('password');

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setKeyUp(!keyUp);
        }
    };

    const stateChange = (e) => {
        if (error === false) {
            setError(false);
        }
    };

    const showHide = () => {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    };

    return (
        <div className="text-input-container">
            {label === 'Settings' ? (
                <div className="text-input">
                    <input
                      className="text-input-box"
                      value={value}
                      style={error === false ? {
                        border: '0.063rem solid #D0D5DD',
                        paddingLeft: '3rem',
                        // width: width,
                        width: '28rem',
                      } : {
                        border: '0.063rem solid #FDA29B',
                        paddingLeft: '3rem',
                        // width: width,
                        width: '31rem',
                      }}
                      placeholder={placeholder}
                      //   onChange={(e) => { setInput({ ...input, [name]: e.target.value }); stateChange(e); }}
                      onChange={(e) => { onChangeFunction(e); stateChange(e); }}
                      onKeyUpCapture={(e) => handleKeyUp(e)}
                    />
                    {error === true && (<img src={Invalid} className="iconnn-invalid" alt="header-logo" />)}
                    {emailState === 'verified' ? (
                        <img src={VerifiedIcon} className="iconnn-forgot" alt="verified-email-text-input-icon" />) : (
                        <>
                            {emailState === 'google' ? (
                                <img src={GoogleIcon} className="iconnn-google" alt="google-email-text-input-icon" />
                            ) : (
                                <img src={EmailIcon} className="iconnn-forgot" alt="email-text-input-icon" />
                            )}
                        </>
                    )}
                </div>
            ) : (
                <>
                    {label === 'Forgot' ? (
                        <div className="text-input" style={{ width: '100%' }}>
                            <input
                              className="text-input-box"
                              style={error === false ? {
                                border: '0.063rem solid #D0D5DD',
                                paddingLeft: '3rem',
                                width: width || '100%',
                                boxSizing: 'border-box',
                              } : {
                                border: '0.063rem solid #FDA29B',
                                paddingLeft: '3rem',
                                width: width || '100%',
                                boxSizing: 'border-box',
                              }}
                              placeholder={placeholder}
                              disabled={disable}
                              onChange={(e) => { onChangeFunction(e); stateChange(e); }}
                              onKeyUpCapture={(e) => handleKeyUp(e)}
                            />
                            {error === true && (<img src={Invalid} className="iconnn-invalid" alt="header-logo" />)}
                            <img src={EmailIcon} className="iconnn-forgot" alt="email-text-input-icon" />
                        </div>
                    ) : (
                        <>
                            {label === 'AgencyID' ? (
                                <>
                                    <div className="text-input">
                                        <input
                                          className="text-input-box"
                                          style={(success === false && error === false) ? {
                                            border: '0.063rem solid #D0D5DD',
                                            width: width,
                                            minWidth: minWidth,
                                            maxWidth: maxWidth,
                                          } : (success === true) ? {
                                            border: '0.063rem solid #D0D5DD',
                                            width: `calc(${width} - 2.125rem)`,
                                            minWidth: minWidth,
                                            maxWidth: maxWidth,
                                            paddingLeft: '3rem',
                                          } : {
                                            border: '0.063rem solid #FDA29B',
                                            width: width,
                                            minWidth: minWidth,
                                            maxWidth: maxWidth,
                                          }}
                                          type={type}
                                          value={value}
                                          placeholder={placeholder}
                                          disabled={disable}
                                          onChange={(e) => { onChangeFunction(e); }}
                                          onKeyUpCapture={(e) => handleKeyUp(e)}
                                        />
                                        {error === true && (
                                            <img src={Invalid} className="iconnn-invalid" alt="header-logo" />
                                        )}
                                        {success === true && (
                                            <img src={ValidIcon} className="iconnn-valid" alt="valid-id-log" />
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {label === 'Plain' ? (
                                        <>
                                            <div className="text-input">
                                            <input
                                              className="text-input-box"
                                              style={error === false ? {
                                                border: '0.063rem solid #D0D5DD',
                                                width: width,
                                              } : {
                                                border: '0.063rem solid #FDA29B',
                                                width: width,
                                              }}
                                              type={label.includes('Password') ? inputType : type}
                                              placeholder={placeholder}
                                              disabled={disable}
                                              // onChange={(e) => { setInput({ ...input, [name]: e.target.value }); stateChange(e); }}
                                              onChange={(e) => onChangeFunction(e)}
                                              onKeyUpCapture={(e) => handleKeyUp(e)}
                                            />
                                                {error === true && (
                                                    <img src={Invalid} className="iconnn-invalid" alt="header-logo" />)}
                                                {((label.includes('Password')) && error === false) && (
                                                    <div className="iconnn-show" onClick={(e) => showHide()}>
                                                        {
                                                            inputType === 'password' ? <img src={Hide} alt="show-hide" /> : <img src={Show} alt="show-hide" />
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Text size="0.875rem" color="#344054" weight="500" align="left" text={label} />
                                            <div className="text-input" style={{ width: '100%' }}>
                                                <input
                                                  className="text-input-box"
                                                  style={error === false ? {
                                                    border: '0.063rem solid #D0D5DD',
                                                    width: width || '100%',
                                                    boxSizing: 'border-box',
                                                  } : {
                                                    border: '0.063rem solid #FDA29B',
                                                    width: width || '100%',
                                                    boxSizing: 'border-box',
                                                  }}
                                                  type={label.includes('Password') ? inputType : type}
                                                  placeholder={placeholder}
                                                  disabled={disable}
                                                  // onChange={(e) => { setInput({ ...input, [name]: e.target.value }); stateChange(e); }}
                                                  onChange={(e) => { onChangeFunction(e); stateChange(e); }}
                                                  onKeyUpCapture={(e) => handleKeyUp(e)}
                                                />
                                                {error === true && (
                                                    <img src={Invalid} className="iconnn-invalid" alt="header-logo" />)}
                                                {((label.includes('Password')) && error === false) && (
                                                    <div className="iconnn-show" onClick={(e) => showHide()}>
                                                        {
                                                            inputType === 'password' ? <img src={Hide} alt="show-hide" /> : <img src={Show} alt="show-hide" />
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
