/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import Cookies from 'js-cookie';
import Title from '../../../component/title/Title';
import { TextButton } from '../../../component/buttonComponent/ButtonComponent';
import SearchInput from '../../../component/searchInput/SearchInput';
import ConfigTab from '../../../component/configurationTab/configurationTab';
import ColoredLine from '../../../component/coloredLine/ColoredLine';
import MailIcon from './img/mailIcon.svg';
import TelegramIcon from './img/telegram.svg';
import TickIcon from './img/check.svg';
import SlackIcon from './img/slack.svg';
import PhoneIcon from './img/phoneIcon.svg';
import WebhookIcon from './img/webhookIcon.svg';
import ErrorIcon from './img/alertIcon.svg';
import SwitchButton from '../../../component/switchButton/switchButton';
import { APIGetUsers } from '../../../config/restAPI/FetchAPI';
import './notifications.css';

const navigationList = [
    {
        text: 'Profile',
        value: 1,
        path: '/settings/profile',
        enabled: true,
    },
    {
        text: 'Security',
        value: 2,
        path: '/settings/security',
        enabled: true,
    },
    {
        text: 'Team',
        value: 3,
        path: '/settings/teams',
        enabled: true,
    },
    {
        text: 'Notifications',
        value: 4,
        path: '/settings/notifications',
        enabled: true,
    },
    // { text: 'Integrations', value: 5, path: '/settings/integrations' },
    // { text: 'API', value: 6, path: '/settings/API' },
    // { text: 'Billing', value: 7, path: '/settings/billing' },
];

const user = {
    oid: '63da880486a39b13a1678724',
    userFirstName: 'Suhana',
    userLastName: 'Ramly',
    userEmail: 'suhana.ramly@asiamobiliti.com',
    imageUrl: '',
    countryCode: 'MYS',
    timezone: 'Asia/Kuala_Lumpur',
    contactCode: 60,
    contactNumber: 123456789,
    channels: [
        {
            type: 'Telegram',
            isEnabled: true,
            connectionStatus: 200,
            lastUpdated: '2023-03-22T17:18:41.880Z',
            chatId: '229260988',
            notificationEnabled: true,
        },
        {
            type: 'Slack',
            isEnabled: false,
            connectionStatus: 200,
            lastUpdated: '2023-03-22T17:18:41.880Z',
            chatId: '229260988',
            notificationEnabled: false,
        },
        {
            type: 'SMS',
            isEnabled: false,
            connectionStatus: 200,
            lastUpdated: '2023-03-22T17:18:41.880Z',
            chatId: '229260988',
            notificationEnabled: false,
        },
    ],
    privilege: {
        role: 100,
        module: {},
        agency: {
            agencyId: 'asiamobiliti',
            agencyName: 'Asia Mobility Technologies Sdn Bhd',
        },
    },
    sessions: [
        {
            oid: '641b3871c08ad225e06ac8cb',
            currentSession: true,
            lastSeenTimestamp: '2023-03-22T17:18:41.880Z',
            isActive: true,
            device: {
                model: '2018 Macbook Pro 15-inch',
                type: 'desktop',
            },
            address: 'Terengganu, Malaysia',
            expireAt: '2023-03-30T17:18:41.888Z',
        },
    ],
};

const Notifications = (props) => {
    const { totalUsers } = props;
    const navigate = useNavigate();
    const agencyId = Cookies.get('agencyUUID');
    const token = Cookies.get('jwtToken');
    const [loading, setLoading] = useState(false);
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [userData, setUserData] = useState({});
    const [telegramStatus, setTelegramStatus] = useState([]);
    const [slackStatus, setSlackStatus] = useState([]);
    const [phoneStatus, setPhoneStatus] = useState([]);
    const [webHookStatus, setWebHookStatus] = useState('Error');

    const fetchUserDetails = async () => {
        try {
            const result = await APIGetUsers(setLoading, token, agencyId);
            const { data } = result.data;
            setUserData(data);
            setTelegramStatus(data?.channels?.find((x) => x.type === 'Telegram'));
            setSlackStatus(data?.channels?.find((x) => x.type === 'Slack'));
            setPhoneStatus(data?.channels?.find((x) => x.type === 'SMS'));
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleTelegramButton = () => {
        navigate('/settings/notifications/manageNotifications', { replace: true, state: { module: 'Telegram' } });
    };

    const switchRowContent = (value) => {
        switch (value) {
            case 'telegram':
                return (
                    <>
                        <div className="notifications-manage-button">
                            <TextButton label="Manage" onClickFunction={() => handleTelegramButton()} fontWeight="600" />
                        </div>
                        {
                            loading ? (
                                <>
                                </>
                            ) : (
                                userData?.channels?.length === 0 ? (
                                    <>
                                    </>
                                ) : (
                                    telegramStatus?.isEnabled === true ? (
                                        <div className="notifications-status-badge-enabled">
                                            <img src={TickIcon} loading="lazy" alt="tick-icon" />
                                            <div className="status-text-enabled">
                                                Enabled
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                        </>
                                    )
                                )
                            )
                        }
                        {/* {telegramStatus.isEnabled === true ? (
                            <>
                                <div className="notifications-status-badge-enabled">
                                    <img src={TickIcon} loading="lazy" alt="tick-icon" />
                                    <div className="status-text-enabled">
                                        Enabled
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="notifications-coming-soon-badge">
                                    <div className="notifications-coming-soon-text">
                                        Coming Soon
                                    </div>
                                </div>
                            </>
                        )} */}
                    </>
                );
            case 'slack':
                return (
                    <>
                        <div className="notifications-manage-button">
                            <TextButton label="Manage" onClickFunction={() => { }} />
                        </div>
                        {slackStatus.isEnabled === true ? (
                            <>
                                <div className="notifications-status-badge-enabled">
                                    <img src={TickIcon} loading="lazy" alt="tick-icon" />
                                    <div className="status-text-enabled">
                                        Enabled
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </>
                );
            case 'phone':
                return (
                    <>
                        {phoneStatus.isEnabled === true ? (
                            <>
                                <div className="notifications-manage-button">
                                    <TextButton label="Manage" onClickFunction={() => { }} />
                                </div>
                                <div className="notifications-status-badge-enabled">
                                    <img src={TickIcon} loading="lazy" alt="tick-icon" />
                                    <div className="status-text-enabled">
                                        Enabled
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </>
                );
            default:
                return (
                    <>
                        <div className="notifications-coming-soon-badge">
                            <div className="notifications-coming-soon-text">
                                Coming Soon
                            </div>
                        </div>
                    </>
                );
        }
    };

    useEffect(() => {
        fetchUserDetails();
        // GetValues();
        setNavigationState('settings');
        setNavigtionClick(4);
    }, []);

    return (
        <div className="notifications-container">
            <div className="notifications-header-section">
                <div className="notifications-title">
                    <Title title="Settings" titleSize="1.875rem" lineHeight="2.375rem" />
                    {/* <SearchInput containerWidth="20rem" text="Search" /> */}
                </div>
                <div className="notifications-tabs">
                    <ConfigTab color="#FCFCFD" module="settings" types={navigationList} users={totalUsers} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="notifications-column">
                <div className="notifications-column-header">
                    <div className="notifications-main-text">
                        Notification channels
                    </div>
                    <div className="notifications-supporting-text">
                        Setup and manage notification channels to receive timely operational information and alerts. We may still send you important notifications about your account outside of your notification settings.
                    </div>
                </div>
                <ColoredLine />
                <div className="notifications-main-body">
                    <div className="notifications-row">
                        <div className="notifications-body-text">
                            <div className="notifications-text-and-icon">
                                <img src={MailIcon} loading="lazy" alt="mail-icon" />
                                <div className="notifications-body-main-text">
                                    Email
                                </div>
                            </div>
                            <div className="notifications-body-supporting-text">
                                When enabled, operational notifications and alerts will be sent to
                                {' '}
                                <b>
                                    {userData?.userEmail ? (
                                        userData.userEmail
                                    ) : (
                                        'your email'
                                    )}
                                </b>
                                .
                            </div>
                        </div>
                        <div className="manage-button-container">
                            {/* <SwitchButton /> */}
                            <div className="notifications-coming-soon-badge">
                                <div className="notifications-coming-soon-text">
                                    Coming Soon
                                </div>
                            </div>
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="notifications-row">
                        <div className="notifications-body-text">
                            <div className="notifications-text-and-icon">
                                <img src={TelegramIcon} loading="lazy" alt="mail-icon" />
                                <div className="notifications-body-main-text">
                                    Telegram
                                </div>
                            </div>
                            <div className="notifications-body-supporting-text">
                                Manage your connection towards the Horizon Telegram Chatbot to receive operational notifications and interact with your data.
                            </div>
                        </div>
                        <div className="manage-button-container">
                            {switchRowContent('telegram')}
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="notifications-row">
                        <div className="notifications-body-text">
                            <div className="notifications-text-and-icon">
                                <img src={SlackIcon} loading="lazy" alt="mail-icon" />
                                <div className="notifications-body-main-text">
                                    Slack
                                </div>
                            </div>
                            <div className="notifications-body-supporting-text">
                                Connect Horizon to your Slack Workspace to enable notifications through your Slack Channels
                            </div>
                        </div>
                        <div className="manage-button-container">
                            {switchRowContent()}
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="notifications-row">
                        <div className="notifications-body-text">
                            <div className="notifications-text-and-icon">
                                <img src={PhoneIcon} loading="lazy" alt="mail-icon" />
                                <div className="notifications-body-main-text">
                                    Text Message (SMS)
                                </div>
                            </div>
                            <div className="notifications-body-supporting-text">
                                When enabled, operational notifications and alerts will be sent to
                                {' '}
                                <b>
                                    { userData?.contactNumber ? (
                                        `(+${userData.contactCode})${userData.contactNumber}`
                                    ) : (
                                        'your phone number'
                                    )}
                                </b>
                                .
                                {' '}
                                To change your phone number, navigate to the
                                {' '}
                                <Link to="/settings/profile" style={{ textDecoration: 'none' }}>
                                    Profile Settings
                                </Link>
                                {' '}
                                page.
                            </div>
                        </div>
                        <div className="manage-button-container">
                            {switchRowContent()}
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="notifications-row">
                        <div className="notifications-body-text">
                            <div className="notifications-text-and-icon">
                                <img src={WebhookIcon} loading="lazy" alt="mail-icon" />
                                <div className="notifications-body-main-text">
                                    Webhook
                                </div>
                            </div>
                            <div className="notifications-body-supporting-text">
                                Configure webhooks to receive operational notifications towards your own service endpoints
                            </div>
                        </div>
                        <div className="manage-button-container">
                            {/* <div className="notifications-manage-button">
                                <TextButton label="Manage" onClickFunction={() => { }} />
                            </div>
                            <div className="notifications-status-badge-error">
                                <img src={ErrorIcon} loading="lazy" alt="tick-icon" />
                                <div className="status-text-error">
                                    {webHookStatus}
                                </div>
                            </div> */}
                            {switchRowContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
