import React from 'react';
import './text.css';
import PropTypes from 'prop-types';

export default function Text(props) {
    const {
        text, color, size, weight, align, height, lineHeight,
    } = props;
    return (
        <div
          className="main-text"
          style={{
                color: color, fontSize: size, fontWeight: weight, textAlign: align, height: height, lineHeight: lineHeight,
            }}
        >
{text}
        </div>
    );
}
Text.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
};
