import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';
import './sidebar.css';
import HeaderSideBar from '../../component/header/HeaderSideBar';
import FooterNavigation from '../../component/footer/FooterNavigation';
import Modal from '../../component/modal/Modal';
import { WebsocketContext } from '../../config/reducer/Websocket';
import { AuthContext } from '../../config/reducer/Auth';
import { APIWSDisconnect } from '../../config/restAPI/DeleteAPI';
import { APIWSConnect } from '../../config/restAPI/PostAPI';

export default function Sidebar(props, { history }) {
  const {
    navigationClick, setNavigationClick, navigationState, setNavigationState, cookiesUpdated,
  } = props;
  const navigate = useNavigate();
  const [message, setMessage] = useState([]);
  const payloadContext = useContext(WebsocketContext);
  const context = useContext(AuthContext);
  const { reConnect } = context;
  const { CloseConnection, setChannel } = payloadContext;
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [switchModalShow, setSwitchModalShow] = useState(false);
  // const [navigationState, setNavigationState] = useState('dashboard');

  // ADMIN STATE
  const [isAdmin, setIsAdmin] = useState(false);

  const sessionID = Cookies.get('SessionID');
  const agencyOid = Cookies.get('agencyUUID');
  const token = Cookies.get('jwtToken');
  const role = Cookies.get('role');

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    if (role === 'System Administrator') {
      setIsAdmin(true);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  document.addEventListener('mouseup', (e) => {
    const container = document.getElementById('sidebar-container-main');
    if (container && !container.contains(e.target)) {
        container.style.display = 'none';
    }
  });

  const btnAgencySelector = async (e, value) => {
    // console.log(value);
    e.preventDefault();
    try {
      const websocketDisconnected = await APIWSDisconnect(token, agencyOid);
      if (websocketDisconnected.status === 200) {
        CloseConnection('disconnect');
        if (value.id === 'admin') {
          const postResponse = await APIWSConnect(token, value.agencyUUID);
          if (postResponse.status === 200) {
            reConnect(value, null);
            setChannel(value.id);
            navigate('/dashboard', { replace: true });
            window.location.reload();
          }
        } else {
          const postResponse = await APIWSConnect(token, value.agencyUUID);
          if (postResponse.status === 200) {
            reConnect(value, null);
            setChannel(value.id);
            navigate('/live/mapOverview', { replace: true });
            window.location.reload();
          } else {
            console.log('go here if error after send in response websocket login');
          }
        }
      }
    } catch (err) {
      // console.log('go here if error catch');
    }
};

  return (
    <div className="sidebar-container-main" style={{ height: windowSize.innerHeight }}>
      <div className="sidebar-contents">
        <HeaderSideBar history={history} setNavigationState={setNavigationState} navigationState={navigationState} isAdmin={isAdmin} setNavigationClick={setNavigationClick} />
        <FooterNavigation history={history} switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} setNavigationState={setNavigationState} navigationState={navigationState} isAdmin={isAdmin} setNavigationClick={setNavigationClick} cookiesUpdated={cookiesUpdated} />
      </div>
      { switchModalShow && (
          <div className="modal-container-bg-topleft">
              <Modal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} btnConfirm={btnAgencySelector} module="SwitchAgency" />
          </div>
      ) }
    </div>
  );
}
