/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
import React, {
 useState, useCallback, useRef, useEffect,
} from 'react';
import Map from 'react-map-gl';
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import WebMercatorViewport from '@math.gl/web-mercator';

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function MapGeofence(props) {
  const {
    setStartDraw,
    setPolygonList,
    // polygonList,
    fitboundEDit,
    setFitboundEDit,
    setActivatePolygonClick,
    setEditPolygonIndex,
    setSelectedPolygon,
    setFullSelectedPolygon,
    bboxPolygon,
  } = props;
  const [viewMapping, setViewMapping] = useState({
    longitude: 101.58714,
    latitude: 3.08587,
    zoom: 14,
  });

  const mapRef = useRef();

  // this for fitbound in first load
  const polygonFitbound = (box) => {
    try {
      const viewport = new WebMercatorViewport({ width: 300, height: 600 });
      // the reason for below reducer are for the fitbound format which is [ [lon, lat], [lon, lat]]
      const reducer = [[box[0], box[1]], [box[2], box[3]]];
      const { longitude, latitude, zoom } = viewport.fitBounds(reducer, { padding: 20 });
      return { longitude, latitude, zoom };
    } catch (err) {
      // console.log('error for polygonfitbound', err);
      return { longitude: 101.58714, latitude: 3.08587, zoom: 10 };
    }
  };

  useEffect(() => {
    if (bboxPolygon) {
      // bboxPolygon value comes from columnGeofence (using turf bbox);
      const mapping = polygonFitbound(bboxPolygon);
      setViewMapping({ ...mapping });
    }
  }, []);

  useEffect(() => {
    // console.log('viewMapping', fitboundEDit);
    if (Object.keys(fitboundEDit).length > 0) {
      const bbox = turf.bbox(fitboundEDit.area[0].geometry);
      const mapping = polygonFitbound(bbox);
      setViewMapping({ ...mapping });
      setFitboundEDit({});
    }
  }, [fitboundEDit]);

  const onMapLoad = useCallback(() => {
    // declare mapbox draw class
    const draws = new MapboxDraw({
      displayControlsDefault: false,
    });
    setStartDraw(draws); // add in to state for declaring draw polygon
    mapRef.current.addControl(draws); // add control

    // callback after draw polygon are created
    mapRef.current.on('draw.create', (e) => {
      try {
        // this to retrieved all the polygon feature and add the value into state.
        const latestPolygonList = e.features;
        // console.log(e);
        // console.log('finishDraw', e);
        setPolygonList(latestPolygonList);
      } catch (err) {
        console.log('Error', err);
      }
    });

    // callback on onClick event (map)
    mapRef.current.on('click', (e) => {
      try {
        // onclick point x
        const { x } = e.point;
        // onclick point y
        const { y } = e.point;
        // console.log('mapRef', mapRef.current);
        // console.log('polygonList', polygonList);
        // this to get id if click the polygon, if outside polygon it will retun empty array
        const getFeatureId = draws.getFeatureIdsAt({ x: x, y: y });
        const getSelectedPolygon = draws.getSelected();
        // console.log('getFeatureId', getFeatureId);
        // console.log('actualListPolygon', actualListPolygon);
        // this logic to add id into selectedPoly state fir edit or delete event.
        if (getFeatureId.length > 0) {
          setActivatePolygonClick(true);
          // const fi
          setSelectedPolygon(getFeatureId);
          const reducerFullSelected = {
            area: getSelectedPolygon.features,
            oid: getSelectedPolygon?.features[0]?.id,
            name: getSelectedPolygon?.features[0]?.properties?.name,
          };
          // console.log(reducerFullSelected);
        // console.log('getSelected', getSelectedPolygon);
          // setPolygonList(getFeatureId);
          setFullSelectedPolygon(reducerFullSelected);

          // this for fitbound click polygon
          const centerPolygon = turf.bbox(getSelectedPolygon.features[0].geometry);
          const mapping = polygonFitbound(centerPolygon);
          setViewMapping({ ...mapping });
          // console.log('centerPolygon', mapping);
        } else {
          // setSelectedPolygon([]);
          // console.log('go here hellow');
        //   setPolygonList([]);
          setActivatePolygonClick(null);
          setEditPolygonIndex(null);
        }
      } catch (err) {
        // console.log('clickStateLayerErr', err);
      }
    });

    mapRef.current.on('draw.update', (e) => {
      try {
        const getSelectedPolygon = draws.getSelected();
        const featureId = getSelectedPolygon.features[0].id;
        // console.log(getSelectedPolygon);
        setActivatePolygonClick(true);
        setSelectedPolygon(featureId);
        const reducerFullSelected = {
            area: getSelectedPolygon.features,
            oid: getSelectedPolygon?.features[0]?.id,
            name: getSelectedPolygon?.features[0]?.properties?.name,
        };
        setFullSelectedPolygon(reducerFullSelected);
      } catch (err) {
        console.log('Error', err);
      }
    });
  }, []);
  // console.log('modeHandler', modeHandler);
  return (
    <Map
      {...viewMapping}
      ref={mapRef}
      onMove={(event) => setViewMapping(event.viewState)}
      mapStyle="mapbox://styles/asiamobiliti/ckb1yq6080opd1inb3lkdmbii"
      mapboxAccessToken={mapboxToken}
      width="100%"
      height="100%"
      attributionControl={false}
      onLoad={onMapLoad}
    />
  );
}
