import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import { useLocation, useOutletContext } from 'react-router-dom';
import BuildingIcon from '../../../../../image/building.svg';
import BreadCrumbs from '../../../../../component/breadcrumbs/BreadCrumbs';
import Title from '../../../../../component/title/Title';
import ConfigTab from '../../../../../component/configurationTab/configurationTab';
import { BulkActionButton, CreateWithIcon } from '../../../../../component/buttonComponent/ButtonComponent';
import Modal from '../../../../../component/modal/Modal';
import Table from '../../../../../component/table/Table';
import AgencyUsersEmptyState from '../../../../../component/tableEmptyState/AgencyUsersEmptyState';
import { APIGetV3TeamMembers } from '../../../../../config/restAPI/FetchAPI';
import { APIInviteTeam } from '../../../../../config/restAPI/PostAPI';
import './agencyUsers.css';

const switchRoleName = (value) => {
    switch (value) {
        case 'Agency Owner':
            return 'Owner';
        case 'Agency Administrator':
            return 'Admin';
        case 'Agency Viewer':
            return 'Viewer';
        case 'Agency Editor':
            return 'Editor';
        case 'System Adminstrator':
            return 'Admin';
        default:
            return value;
    }
};

const seperator = [
    <img loading="lazy" src={BuildingIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Agencies</div>,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Configurations</div>,
];

const navigationList = [
    {
        text: 'General',
        value: 1,
        path: '/agencies/Configurations/General',
        enabled: true,
    },
    {
        text: 'Inventory',
        value: 2,
        path: '/agencies/Configurations/Inventory',
        enabled: true,
    },
    {
        text: 'Users',
        value: 3,
        path: '/agencies/Configurations/Users',
        enabled: true,
     },
    {
        text: 'Billing',
        value: 4,
        path: '/agencies/Configurations/Billing',
        enabled: true,
    },
];

const AgencyUsers = () => {
    const { state } = useLocation();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const [agencyData, setAgencyData] = useState(state);
    const [agencyUsers, setAgencyUsers] = useState([]);
    // const [totalUsers, setTotalUsers] = useState(0);
    const [navigtionClick, setNavigtionClick] = useOutletContext();
    const [switchModalShow, setSwitchModalShow] = useState(false);
    const [inviteAgencyOwnerModal, setInviteAgencyOwnerModal] = useState(false);
    const [emailList, setEmailList] = useState([{ email: '' }]);
    const [selectionList, setSelectionList] = useState({});
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [emptyState, setEmptyState] = useState(false);
    const [tableState, setTableState] = useState(!emptyState);

    const openSwitchModal = () => {
        setSwitchModalShow(!switchModalShow);
        setEmailList([{ email: '' }]);
        setSelectionList({});
    };

    const openInviteAgencyOwner = () => {
        setInviteAgencyOwnerModal(!inviteAgencyOwnerModal);
        setEmailList([{ email: '' }]);
    };

    const fetchTeamMembers = async (page) => {
        try {
            const params = {
                agencyOid: agencyData.oid,
                pageNumber: page,
                totalPerPage: perPage,
            };
            const result = await APIGetV3TeamMembers(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data, totalData } = result.data;
                if (data.length > 0) {
                    setAgencyData({ ...agencyData, totalUsers: totalData });
                    const reducer = data && data.length > 0 ? data.map((x, index) => ({
                        index: index,
                        id: x.oid,
                        member: {
                            firstName: (x.userFirstName !== undefined) ? (x.userFirstName) : (null),
                            lastName: (x.userLastName !== undefined) ? (x.userLastName) : (null),
                            email: x.userEmail,
                        },
                        profileImgUrl: (x.profileImageUrl !== undefined) ? (x.profileImageUrl) : (null),
                        sessions: Object.prototype.hasOwnProperty.call(x, 'totalSession') ? (x.totalSession) : (null),
                        lastLogin: {
                            time: (x.lastLogin !== undefined) ? (x.lastLogin.lastSeenTimestamp) : (''),
                            location: (x.lastLogin !== undefined) ? (x.lastLogin.address) : (''),
                        },
                        accessPrivileges: (x.privilege.role !== undefined) ? (switchRoleName(x.privilege.role)) : ('Unverified user'),
                        tags: (x.privilege.tags !== undefined) ? (x.privilege.tags) : ([]),
                        modules: (x.privilege.module !== undefined) ? (x.privilege.module) : ({}),
                        twoFA: Object.prototype.hasOwnProperty.call(x, 'twoFA') ? (x.twoFA) : (null),
                        phoneNumber: Object.prototype.hasOwnProperty.call(x, 'contactNumber') ? (x.contactNumber) : (null),
                    })) : [];
                    setEmptyState(false);
                    setTableState(true);
                    setAgencyUsers(reducer);
                    setTotalRows(result.data.totalData);
                    setLoading(false);
                } else {
                    setAgencyData({ ...agencyData, totalUsers: 0 });
                    setEmptyState(true);
                    setTableState(false);
                    setLoading(false);
                }
            }
        } catch (error) {
            // console.log(error);
        }
    };

    const handlePageChange = (page) => { fetchTeamMembers(page); };

    const inviteAgencyOwner = async () => {
        const reducer = emailList ? emailList.map((x) => Base64.encode(x.email)) : [];
        const params = {
            agencyOid: agencyData.oid,
            team: {
                emails: reducer,
                role: 'Agency_Owner',
            },
        };
        try {
            const result = await APIInviteTeam(token, agencyID, params);
            const { data } = result;
            if (data.status === 200) {
                fetchTeamMembers(1);
                setEmailList([{ email: '' }]);
                setInviteAgencyOwnerModal(!inviteAgencyOwnerModal);
                setSelectionList({});
            } else {
                console.log('Error');
                setSelectionList({});
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const inviteAgencyUsers = async () => {
        const reducer = emailList ? emailList.map((x) => Base64.encode(x.email)) : [];
        const params = {
            agencyOid: agencyData.oid,
            team: {
                emails: reducer,
                role: selectionList.value,
            },
        };

        try {
            const result = await APIInviteTeam(token, agencyID, params);
            const { data } = result;
            if (data.status === 200) {
                fetchTeamMembers(1);
                setEmailList([{ email: '' }]);
                setSwitchModalShow(!switchModalShow);
                setSelectionList({});
            } else {
                console.log('Error');
                setSelectionList({});
            }
        } catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
      fetchTeamMembers(1);
    }, []);

    return (
        <div className="agency-users-container">
            <div className="agency-users-header">
                <div className="agency-users-page-header">
                    <BreadCrumbs customSeperator={seperator} />
                    <Title title="Configurations" titleSize="1.875rem" lineHeight="2.375rem" deleteSubtitle>
                        {agencyData.agencyName}
                        {' '}
                        •
                        {' '}
                        {agencyData.agencyId}
                    </Title>
                </div>
                <div className="agency-users-tabs">
                    <ConfigTab color="#FFFFFF" types={navigationList} module="agencies" users={agencyData.totalUsers} agencyRow={agencyData} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="agency-users-body">
                <div className="agency-users-table-header">
                    <div className="agency-users-table-header-text">
                        <div className="agency-users-table-title">
                            <div className="agency-users-header-text">
                                Users
                            </div>
                            {agencyData.totalUsers > 0 ? (
                                <div className="agency-users-count">
                                    {agencyData.totalUsers}
                                    {' '}
                                    users
                                </div>
                            ) : (
                                <div className="agency-users-count-empty">
                                    {agencyData.totalUsers}
                                    {' '}
                                    users
                                </div>
                            )}
                        </div>
                        <div className="agency-users-table-subtitle">
                            Manage the agency’s team members and their account permissions here.
                        </div>
                    </div>
                    <div className="agency-users-table-button-group">
                        {agencyUsers.length > 0 ? (
                            <>
                                <BulkActionButton color="black" background="white" label="Bulk Action" imageIcon="dropDownIcon" onClickFunction={() => {}} />
                                <CreateWithIcon label="Invite users" buttonHeight="2.5rem" module="users" onClickFunction={openSwitchModal} />
                            </>
                        ) : (
                            <>
                                <CreateWithIcon label="Invite Agency Owner" module="users" buttonHeight="2.5rem" onClickFunction={openInviteAgencyOwner} />
                            </>
                        )}
                    </div>
                    {
                        switchModalShow && (
                            <Modal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} module="InviteUsers" emailList={emailList} setEmailList={setEmailList} btnConfirm={() => inviteAgencyUsers()} selectionList={selectionList} setSelectionList={setSelectionList} source="agencies" agencyData={agencyData} />
                        )
                    }
                    {
                        openInviteAgencyOwner && (
                            <Modal switchModalShow={inviteAgencyOwnerModal} setSwitchModalShow={setInviteAgencyOwnerModal} openSwitchModal={openInviteAgencyOwner} module="InviteUsers" emailList={emailList} setEmailList={setEmailList} btnConfirm={() => inviteAgencyOwner()} selectionList={selectionList} setSelectionList={setSelectionList} source="agenciesInviteOwner" agencyData={agencyData} />
                        )
                    }
                </div>
                <div className="agency-users-table-container">
                    {tableState && (
                        <Table
                          selectedRowCount={selectedRowCount}
                          setSelectedRowCount={setSelectedRowCount}
                          tableData={agencyUsers}
                          checkbox
                          pagination
                          striped
                          module="agencyUsers"
                          handlePageChange={handlePageChange}
                          loading={loading}
                          totalRows={totalRows}
                        />
                    )}
                    {
                        emptyState && (
                            <AgencyUsersEmptyState />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AgencyUsers;
