/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState, useRef } from 'react';
import './uploadFile.css';
import PropTypes from 'prop-types';
import Dropzone, { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import Navigation from '../navigation/Navigation';
import Account from '../account/Account';
import Title from '../title/Title';
import share from './img/share.svg';
import 'react-image-crop/dist/ReactCrop.css';
import { SecondaryButton, BackButton, PrimaryButton } from '../buttonComponent/ButtonComponent';
import Modal from '../modal/Modal';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#D0D5DD';
    }
    if (props.isDragReject) {
        return '#EAECF0';
    }
    if (props.isFocused) {
        return '#EAECF0';
    }
    return '#EAECF0';
  };

  const Container = styled.div`
    box-sizing: border-box;
    border-width: 1px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-radius: 12px;
    border-style: solid;
    background-color: #ffffff;
    // color: #ffffff;
    outline: none;
    transition: border .24s ease-in-out;
  `;

  export default function UploadFile(props) {
    const {
      profileImage, setProfileImage, setOutput, output,
    } = props;

// CROP IMAGE
const [src, setSrc] = useState(null);
const [crop, setCrop] = useState();
const [aspect, setAspect] = useState(1 / 1);
const [image, setImage] = useState(null);
// const [output, setOutput] = useState(null);
const [completedCrop, setCompletedCrop] = useState();

// MODAL
  const [switchModalShow, setSwitchModalShow] = useState(false);

// FILE LIMIT ERROR
  const [fileLimitError, setFileLimitError] = useState(false);

const cropRef = useRef();

const selectImage = (file) => {
  setSrc(URL.createObjectURL(file));
};

  const [files, setFiles] = useState([]);
  const [filesString, setFilesString] = useState(null);
  // const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
  //   // Disable click and keydown behavior
  //   noClick: true,
  //   noKeyboard: true
  // });
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
      open,
    } = useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
      },
      onDrop: (acceptedFilesFromDrop) => {
        setFiles(acceptedFilesFromDrop.map((file) => Object.assign(file, {
          preview: URL.createObjectURL(file),
        })));
        if (acceptedFilesFromDrop.length > 0) {
          // console.log('sjkdlvnlsw', acceptedFilesFromDrop);
          setFileLimitError(false);
        } else {
          setFileLimitError(true);
        }
      },
      maxSize: 20000000,
    });

    if (files.length > 0) {
      setProfileImage(files);
    }

    useEffect(() => {
      if (files.length > 0) {
        // console.log('files', files[0].path);
        setFilesString(files[0].path);
      }
    }, [files]);

    useEffect(() => {
      if (filesString) {
        setProfileImage(filesString);
      }
    }, [filesString]);

const cropImageNow = () => {
  const img = document.getElementById('crop');
  const canvas = document.createElement('canvas');
  const scaleX = img.naturalWidth / img.width;
  const scaleY = img.naturalHeight / img.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    img,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );
  // console.log('CROP FUNCTION CALLED: 13', ctx);

  // Converting to base64
  const base64Image = canvas.toDataURL('image/png');
  const blobImage = canvas.toBlob((blob) => {
    const newImg = document.createElement('imgBlob');
    const url = URL.createObjectURL(blob);
    newImg.onload = () => {
      // no longer need to read the blob so it's revoked
      URL.revokeObjectURL(url);
    };
    newImg.src = url;
    document.body.appendChild(newImg);
  }, 'image/png', 0.99);
  // console.log('CROP FUNCTION CALLED: ', base64Image);
  setOutput(base64Image);
};

useEffect(() => {
  setFiles([]);
}, [output]);

useEffect(() => {
  if (files.length > 0) {
    setSwitchModalShow(true);
  }
}, [files]);

  const thumbs = files.map((file) => (
    <img
      id="crop"
      // src={file.preview}
      src="https://storage.googleapis.com/deepspace-beaver-249201.appspot.com/profileImage/642e8340c729b4d05dcb01ab.jpg"
      alt="profile"
      onLoad={() => { URL.revokeObjectURL(file.preview); cropImageNow(); }}
    />
  ));

    return (
      <div className="upload-container">
      {switchModalShow && (
          <div className="modal-container-bg-topleft">
            <Modal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} btnConfirm={cropImageNow} type="cropImage" files={files} setOutput={setOutput} output={output} module="CropImage" />
          </div>
      ) }
        <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
                <div className="settings-upload-photo">
                    {/* <input {...getInputProps()} /> */}
                <img src={share} className="snackbar-close-button-icon" alt="snackbar-close" />
                <div className="settings-text-container">
                    <div className="upload-text-line">
                        <div className="upload-image-text1">
                        Click to upload
                        </div>
                        <div className="upload-image-text2">
                        or drag and drop
                        </div>
                    </div>
                    <div className="upload-image-text">
                    PNG or JPG (max. 20MB)
                    </div>
                </div>
                {completedCrop
                && (
                  <div>
                    <img
                      src={completedCrop}
                      alt="completedCrop"
                    />
                  </div>
              )}
                </div>
        </Container>
        {fileLimitError && (
            <div className="settings-comments">
                Please upload an image smaller than 20MB.
            </div>
        )}
      </div>
    );
  }
