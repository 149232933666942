import React, { useState, useMemo, useContext } from 'react';
import '../modal.css';
import './generateReport.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import LogoutIcon from '../img/logoutUpdateIcon.svg';
import ReportIcon from '../img/generateReport.svg';
import CloseButton from '../../../image/xButton.svg';
import Title from '../../title/Title';
import Autocomplete from '../../autocomplete/Autocomplete';
import { WebsocketContext } from '../../../config/reducer/Websocket';

export default function GenerateReport(props) {
    const {
 switchModalShow, setSwitchModalShow, setUpdateEmailLogout, resetBtn,
} = props;

    const stateChange = () => {
        setSwitchModalShow(!switchModalShow);
        resetBtn();
    };

    const confirmLogout = async (e) => {
        setUpdateEmailLogout(true);
    };
    return (
        <div className="modal-container-bg">
            <div className="modal-container-logout">
                <div className="modal-header-generate-report">
                    <div className="modal-header-content-generate-report">
                        <img loading="lazy" src={ReportIcon} alt="switch-agency-icon" />
                        <Title title="Generating Report" subtitle="" titleSize="1.125rem" subtitleSize="0rem" gap="0rem" lineHeight="1.75rem" />
                    </div>
                </div>
            <div className="modal-content-generate-report">
                <Title title="" subtitle="A report will be sent to the email address associated with this account." titleSize="0rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.25rem" />
            </div>
                <div className="modal-actions-logout">
                    <div className="modal-actions-content">
                        <PrimaryButton label="Confirm" width="100%" onClickFunction={stateChange} />
                    </div>
                </div>
            </div>
        </div>
    );
}
