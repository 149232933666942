/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';
import { Base64 } from 'js-base64';
import { useOutletContext, useLocation, useNavigate } from 'react-router';
import Modal from '../../../component/modal/Modal';
import Table from '../../../component/table/Table';
import Title from '../../../component/title/Title';
import ConfigTab from '../../../component/configurationTab/configurationTab';
import { BulkActionButton, CreateWithIcon, SecondaryButton } from '../../../component/buttonComponent/ButtonComponent';
import './Teams.css';
import { APIGetV3TeamMembers } from '../../../config/restAPI/FetchAPI';
import { APIInviteTeam } from '../../../config/restAPI/PostAPI';

const navigationList = [
    {
        text: 'Profile',
        value: 1,
        path: '/settings/profile',
        enabled: true,
    },
    {
        text: 'Security',
        value: 2,
        path: '/settings/security',
        enabled: true,
    },
    {
        text: 'Team',
        value: 3,
        path: '/settings/teams',
        enabled: true,
    },
    {
        text: 'Notifications',
        value: 4,
        path: '/settings/notifications',
        enabled: true,
    },
    // { text: 'Integrations', value: 5, path: '/settings/integrations' },
    // { text: 'API', value: 6, path: '/settings/API' },
    // { text: 'Billing', value: 7, path: '/settings/billing' },
];

const switchRoleName = (value) => {
    switch (value) {
        case 'Agency Owner':
            return 'Owner';
        case 'Agency Administrator':
            return 'Admin';
        case 'Agency Viewer':
            return 'Viewer';
        case 'Agency Editor':
            return 'Editor';
        case 'System Adminstrator':
            return 'Admin';
        default:
            return value;
    }
};

const Teams = (props) => {
    const { totalUsers } = props;
    const { state } = useLocation();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const agencyName = Cookies.get('agency-name');
    const loggedInRole = switchRoleName(Cookies.get('role'));
    const loggedInAccount = Cookies.get('_id');
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [switchModalShow, setSwitchModalShow] = useState(false);
    const bulkActionDisabled = selectedRowCount <= 1;
    // const [totalUsers, setTotalUsers] = useState();
    const [teamData, setTeamData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [emailList, setEmailList] = useState([{ email: '' }]);
    const [selectionList, setSelectionList] = useState({});
    const rowDisabledCriteria = (row) => row.id === loggedInAccount || loggedInRole === 'Viewer' || loggedInRole === 'Editor' || (loggedInRole !== 'Owner' && row.accessPrivileges === 'Owner');
    // console.log(loggedInAccount);

    const fetchTeamMembers = async (page) => {
        try {
            const params = {
                pageNumber: page,
                totalPerPage: perPage,
            };
            const result = await APIGetV3TeamMembers(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data, totalData } = result.data;
                // setTotalUsers(totalData);
                if (data.length > 0) {
                    const reducer = data && data.length > 0 ? data.map((x, index) => ({
                        index: index,
                        id: x.oid,
                        member: {
                            firstName: (x.userFirstName !== undefined) ? (x.userFirstName) : (null),
                            lastName: (x.userLastName !== undefined) ? (x.userLastName) : (null),
                            email: x.userEmail,
                        },
                        profileImgUrl: (x.profileImageUrl !== undefined) ? (x.profileImageUrl) : (null),
                        sessions: Object.prototype.hasOwnProperty.call(x, 'totalSession') ? (x.totalSession) : (null),
                        lastLogin: {
                            time: (x.lastLogin !== undefined) ? (x.lastLogin.lastSeenTimestamp) : (''),
                            location: (x.lastLogin !== undefined) ? (x.lastLogin.address) : (''),
                        },
                        accessPrivileges: (x.privilege.role !== undefined) ? (switchRoleName(x.privilege.role)) : ('Unverified user'),
                        tags: (x.privilege.tags !== undefined) ? (x.privilege.tags) : ([]),
                        modules: (x.privilege.module !== undefined) ? (x.privilege.module) : ({}),
                        twoFA: Object.prototype.hasOwnProperty.call(x, 'twoFA') ? (x.twoFA) : (null),
                        phoneNumber: Object.prototype.hasOwnProperty.call(x, 'contactNumber') ? (x.contactNumber) : (null),
                    })) : [];
                    // const filteredData = reducer.filter((x) => (x.accessPrivileges === 'Owner' || x.accessPrivileges === 'Admin' || x.accessPrivileges === 'Editor' || x.accessPrivileges === 'Viewer'));
                    setTeamData(reducer);
                    setTotalRows(result.data.totalData);
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => { fetchTeamMembers(page); };

    const handlePerRowsChange = async (newPerPage, page) => {
        try {
            const params = {
                pageNumber: page,
                totalPerPage: newPerPage,
            };
            const result = await APIGetV3TeamMembers(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data.length > 0) {
                    const reducer = data && data.length > 0 ? data.map((x, index) => ({
                        id: x.oid,
                        index: index,
                        member: {
                            firstName: (x.userFirstName !== undefined) ? (x.userFirstName) : (null),
                            lastName: (x.userLastName !== undefined) ? (x.userLastName) : (null),
                            email: x.userEmail,
                        },
                        profileImgUrl: (x.profileImageUrl !== undefined) ? (x.profileImageUrl) : (null),
                        sessions: Object.prototype.hasOwnProperty.call(x, 'totalSession') ? (x.totalSession) : (null),
                        lastLogin: {
                            time: (x.lastLogin !== undefined) ? (x.lastLogin.lastSeenTimestamp) : (''),
                            location: (x.lastLogin !== undefined) ? (x.lastLogin.address) : (''),
                        },
                        accessPrivileges: (x.privilege.role !== undefined) ? (switchRoleName(x.privilege.role)) : (null),
                        tags: (x.privilege.tags !== undefined) ? (x.privilege.tags) : ([]),
                        modules: (x.privilege.module !== undefined) ? (x.privilege.module) : ({}),
                        twoFA: Object.prototype.hasOwnProperty.call(x, 'twoFA') ? (x.twoFA) : (null),
                        phoneNumber: Object.prototype.hasOwnProperty.call(x, 'phoneNumber') ? (x.phoneNumber) : (null),
                    })) : [];
                    // const filteredData = reducer.filter((x) => (x.accessPrivileges === 'Owner' || x.accessPrivileges === 'Admin' || x.accessPrivileges === 'Editor' || x.accessPrivileges === 'Viewer'));
                    setTeamData(reducer);
                    setPerPage(newPerPage);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
            // console.log('here catch');
        }
    };

    const openSwitchModal = () => {
        setSwitchModalShow(!switchModalShow);
        setEmailList([{ email: '' }]);
        setSelectionList({});
    };

    const sendEmailFunction = async () => {
        const reducer = emailList ? emailList.map((x) => Base64.encode(x.email)) : [];
        const params = {
            team: {
                emails: reducer,
                role: selectionList.value,
            },
        };

        try {
            const result = await APIInviteTeam(token, agencyID, params);
            const { data } = result;
            if (data.status === 200) {
                fetchTeamMembers(1);
                setEmailList([{ email: '' }]);
                setSwitchModalShow(!switchModalShow);
                setSelectionList({});
            } else {
                console.log('Error');
                setSelectionList({});
            }
        } catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        fetchTeamMembers(1);
    }, [state]);

    useEffect(() => {
        fetchTeamMembers(1);
        setNavigationState('settings');
        setNavigtionClick(3);
    }, []);

    return (
        <div className="teams-container">
            <div className="teams-header-section">
                <Title title="Settings" titleSize="1.875rem" lineHeight="2.375rem" />
                <div className="teams-tabs">
                    <ConfigTab color="#FCFCFD" module="settings" types={navigationList} users={totalUsers} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="teams-body">
                <div className="teams-table-header">
                    <div className="teams-table-header-text ">
                        <div className="teams-table-title">
                            <div className="header-text">Team Members</div>
                            {totalUsers ? (
                                <div className="member-count">
                                    {totalUsers}
                                    {' users'}
                                </div>
                            ) : (
                            <>
                            </>
                            )}
                        </div>
                        <div className="teams-table-subtitle">
                            Manage your team members and their account permissions here.
                        </div>
                    </div>
                    <div className="teams-table-button-group">
                        {loggedInRole === 'Viewer' || loggedInRole === 'Editor' ? (
                            <></>
                        ) : (
                            <>
                                {/* <BulkActionButton color="black" background="white" label="Bulk Action" imageIcon="dropDownIcon" disable={bulkActionDisabled} onClickFunction={() => {}} /> */}
                                <CreateWithIcon label="Invite users" buttonHeight="2.5rem" module="users" onClickFunction={openSwitchModal} />
                            </>
                        )}
                        {
                            switchModalShow && (
                                <Modal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={openSwitchModal} module="InviteUsers" emailList={emailList} setEmailList={setEmailList} btnConfirm={sendEmailFunction} selectionList={selectionList} setSelectionList={setSelectionList} source="teams" agencyName={agencyName} />
                            )
                        }
                    </div>
                </div>
                <div className={loading ? 'teams-table-container-loading' : 'teams-table-container'}>
                    <Table
                      selectedRowCount={selectedRowCount}
                      setSelectedRowCount={setSelectedRowCount}
                      rowDisabledCriteria={rowDisabledCriteria}
                      tableData={teamData}
                      checkbox
                      pagination
                      module="teams"
                      handlePerRowsChange={handlePerRowsChange}
                      handlePageChange={handlePageChange}
                      loading={loading}
                      totalRows={totalRows}
                      backgroundColor="#FFFFFF"
                      striped
                    />
                </div>
            </div>
        </div>
    );
};

export default Teams;
