/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import './menu-popup.css';
import { useNavigate } from 'react-router-dom';
import MenuOptionItem from '../menuOptionItem/MenuOptionItem';
import Modal from '../modal/Modal';

export default function MenuPopup(props) {
    const {
        page,
        logout,
        switchModalShow,
        setSwitchModalShow,
        agencyList,
        assetDetails,
        rowData,
        editButton,
        enabledDisabledButton,
        deleteButton,
        handleSwitchModal,
        agencyRow,
        policiesStatus,
        markAsRead,
        liveMapNav,
        insightsNav,
        selectedCheckboxes,
    } = props;
    const navigate = useNavigate();
    const [optionDisabled, setOptionDisabled] = useState(true); // Set Switch Agency Workspace state here
    const stateChange = () => {
        if (agencyList.length !== 1) {
            setSwitchModalShow(!switchModalShow);
        }
    };

    const configurationsNavigate = () => {
        navigate('/inventory/assetdevices/configuration', { replace: true, state: assetDetails });
    };

    const agenciesConfigurationNavigate = () => {
        navigate('/agencies/Configurations/General', { replace: true, state: agencyRow });
    };

    const navigationBody = (value) => {
        switch (value) {
            case 'footerNavigationMobile':
                return (
                    <>
                        <MenuOptionItem label="Switch Agency Workspace" menuButtonClicked={stateChange} disable={agencyList.length === 1} />
                        <MenuOptionItem label="Log out" menuButtonClicked={logout} />
                    </>
                );
            case 'footerNavigation':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label="Switch Agency Workspace" menuButtonClicked={stateChange} disable={agencyList.length === 1} bottomBorder />
                        <MenuOptionItem label="Log out" menuButtonClicked={logout} bottomBorder />
                    </div>
                );
            case 'assetsAndDevices':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label="Configurations" menuButtonClicked={configurationsNavigate} bottomBorder />
                        <MenuOptionItem label="Live Map Overview" menuButtonClicked={liveMapNav} />
                        <MenuOptionItem label="Telemetry Logs" menuButtonClicked={insightsNav} />
                    </div>
                );
            case 'adminAssetAndDevices':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label="Configurations" menuButtonClicked={configurationsNavigate} bottomBorder />
                        {/* <MenuOptionItem label="Live Map Overview" menuButtonClicked={() => {}} /> */}
                        {/* <MenuOptionItem label="Telemetry Logs" menuButtonClicked={() => {}} /> */}
                    </div>
                );
            case 'geofence':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label="Edit" menuButtonClicked={editButton} bottomBorder={false} />
                        {
                            rowData.isEnabled ? <MenuOptionItem label="Disable Policy" menuButtonClicked={policiesStatus} bottomBorder /> : <MenuOptionItem label="Enable Policy" menuButtonClicked={policiesStatus} bottomBorder />
                        }
                        <MenuOptionItem label="Delete" menuButtonClicked={deleteButton} bottomBorder />
                    </div>
                );
            case 'notificationConfiguration':
                return (
                    <div className="menupopup-container">
                    <MenuOptionItem label="Edit" menuButtonClicked={editButton} bottomBorder={false} />
                    {
                        rowData.isEnabled ? <MenuOptionItem label="Disable Alert" menuButtonClicked={policiesStatus} bottomBorder /> : <MenuOptionItem label="Enable Alert" menuButtonClicked={policiesStatus} bottomBorder />
                    }
                    <MenuOptionItem label="Delete" menuButtonClicked={deleteButton} bottomBorder />
                    </div>
                );
            case 'security':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label="Log out of all devices" menuButtonClicked={handleSwitchModal} bottomBorder />
                    </div>
                );
            case 'agencies':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label="Configurations" menuButtonClicked={agenciesConfigurationNavigate} bottomBorder />
                        <MenuOptionItem label="Live Map Overview" menuButtonClicked={() => {}} bottomBorder={false} />
                        <MenuOptionItem label="Telemetry Logs" menuButtonClicked={() => {}} bottomBorder />
                    </div>
                );
            case 'connectivity':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label="Configurations" menuButtonClicked={() => {}} bottomBorder={false} />
                    </div>
                );
            case 'notifications':
                return (
                    <div className="menupopup-container">
                        <MenuOptionItem label={Object.values(selectedCheckboxes).every((v) => v === false) ? 'Mark all as read' : 'Mark as read'} menuButtonClicked={markAsRead} bottomBorder />
                        {/* <MenuOptionItem label="Archive all" menuButtonClicked={() => {}} bottomBorder={false} /> */}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {navigationBody(page)}
        </>
    );
}
