import React, { useReducer, createContext } from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { Base64 } from 'js-base64';

const initialState = { user: null };

/**
 * **If cookies jwtToken exist, decode the token (to get the expiry date)**
 */
if (Cookies.get('jwtToken')) {
    const decodedToken = jwtDecode(Cookies.get('jwtToken'));
    if (decodedToken.exp < Date.now()) {
        Cookies.remove('jwtToken');
        Cookies.remove('agency-id');
        Cookies.remove('role');
        Cookies.remove('SessionID');
        Cookies.remove('agency-name');
        Cookies.remove('agencyUUID');
        Cookies.remove('_id');
        Cookies.remove('name');
        Cookies.remove('privileges');
        Cookies.remove('profileImageUrl');
        Cookies.remove('timezone');
    } else {
        initialState.user = decodedToken;
    }
}

const AuthContext = createContext({
    user: null,
    login: (userData) => {},
    logout: () => {},
    reConnect: (userData) => {},
    checktoken: (token) => {},
    Encode: (str) => {},
});

function authReducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
        return {
            ...state,
            user: action.payload,
            userData: action.payload,
        };
        case 'LOGOUT':
        return {
            ...state,
            user: null,
            userData: null,
        };
        case 'RECONNECT':
        return {
            ...state,
            user: action.payload,
        };
        default:
        return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);
    // const option = { expires: 10 };
    const login = (userData, sessionID, selectionList, privileges) => {
        // console.log('here', selectionList);
        // console.log('Context userAuth sessionIDddd', userData);
        const option = { expires: new Date(jwtDecode(userData.tokens).exp) };
        if (sessionID) {
            // console.log('Context userAuth sessionID', userData);
            Cookies.set('_id', userData.user.oid, option);
            Cookies.set('jwtToken', userData.tokens, option);
            Cookies.set('agencyUUID', selectionList.agencyUUID, option);
            Cookies.set('agency-id', selectionList.id, option);
            Cookies.set('role', selectionList.role.replace(/_/g, ' '), option);
            Cookies.set('agency-name', selectionList.name, option);
            Cookies.set('SessionID', sessionID, option);
            Cookies.set('profileImageUrl', userData.user.profileImageUrl, option);
            if (userData.user.timezone) {
                Cookies.set('timezone', userData.user.timezone, option);
            }
            // console.log('userData.user.profileImageUrl: ', userData.user.profileImageUrl);
            if (userData.user.userLastName) {
                Cookies.set('name', `${userData.user.userFirstName} ${userData.user.userLastName}`, option);
            } else {
                Cookies.set('name', userData.user.userFirstName, option);
            }
            Cookies.set('listAgency', JSON.stringify(privileges), option);
        } else {
            // console.log('Context userAuth sessionIDssss', userData);
            Cookies.set('_id', userData.user.oid, option);
            Cookies.set('jwtToken', userData.tokens, option);
            Cookies.set('agencyUUID', selectionList.agencyUUID, option);
            Cookies.set('agency-id', selectionList.id, option);
            Cookies.set('role', selectionList.role.replace(/_/g, ' '), option);
            Cookies.set('agency-name', selectionList.name ? selectionList.name : 'Asia Mobility Technologies Sdn Bhd', option);
            Cookies.set('profileImageUrl', userData.user.profileImageUrl, option);
            if (userData.user.timezone) {
                Cookies.set('timezone', userData.user.timezone, option);
            }
            // console.log('userData.user.profileImageUrl: ', userData.user.profileImageUrl);
            if (userData.user.userLastName) {
                Cookies.set('name', `${userData.user.userFirstName} ${userData.user.userLastName}`, option);
            } else {
                Cookies.set('name', userData.user.userFirstName, option);
            }
            Cookies.set('listAgency', JSON.stringify(privileges), option);
        }

        dispatch({
            type: 'LOGIN',
            payload: userData,
        });
    };

    const logout = () => {
        // console.log('logout go here woots')
        Cookies.remove('jwtToken');
        Cookies.remove('agency-id');
        Cookies.remove('role');
        Cookies.remove('SessionID');
        Cookies.remove('agency-name');
        Cookies.remove('agencyUUID');
        Cookies.remove('name');
        Cookies.remove('_id');
        Cookies.remove('name');
        Cookies.remove('privileges');
        Cookies.remove('firstName');
        Cookies.remove('listAgency');
        Cookies.remove('profileImageUrl');
        Cookies.remove('timezone');
        dispatch({ type: 'LOGOUT' });
    };

    const reConnect = (selectionList, sessionID, timezone) => {
        // console.log(selectionList);
        // console.log('selectionList', selectionList);
        const option = { expires: new Date(jwtDecode(Cookies.get('jwtToken')).exp) };
        if (sessionID) {
            // console.log("Context userAuth sessionID", sessionID)
            Cookies.set('agencyUUID', selectionList.agencyUUID, option);
            Cookies.set('agency-id', selectionList.id, option);
            Cookies.set('role', selectionList.role.replace(/_/g, ' '), option);
            Cookies.set('agency-name', selectionList.name, option);
            Cookies.set('SessionID', sessionID, option);
        } else {
            Cookies.set('agencyUUID', selectionList.agencyUUID, option);
            Cookies.set('agency-id', selectionList.id, option);
            Cookies.set('role', selectionList.role.replace(/_/g, ' '), option);
            Cookies.set('agency-name', selectionList.name ? selectionList.name : 'Asia Mobility Technologies Sdn Bhd', option);
        }
        dispatch({ type: 'RECONNECT', payload: selectionList });
    };

    const Encode = (str) => Base64.encode(str);

    return (
        <AuthContext.Provider
          value={{
 user: state.user, login, logout, Encode, reConnect,
}}
          {...props}
        />
    );
}

export { AuthContext, AuthProvider };
