import React from 'react';
import { useNavigate } from 'react-router';
import TelemetryEmptyIcon from './img/telemetrySShotEmpty.svg';
import Text from '../text/Text';
import { InsightButton } from '../buttonComponent/ButtonComponent';
import './tableemptystate.css';

const TelemetrySShotEmptyState = (props) => {
  const { text } = props;
  const navigate = useNavigate();

  const navigateToInsight = () => {
    navigate('/insights/assetPerformance', { replace: true });
  };

  return (
      <div className="empty-state-container">
        <img loading="lazy" src={TelemetryEmptyIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
        <div className="empty-state-text">
            <Text text="No Telemetry Snapshots" size="1.25rem" weight="600" align="center" height="auto" lineHeight="30px" />
            <Text text="Telemetry data will appear here when new data is ingested, and will only be available as long as this tab is open." size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
            <Text text="To obtain telemetry data further back in time, consider querying from the Telemetry Logs Insights report" size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
        </div>
        <div className="empty-state-button">
          <InsightButton label="Telemetry Logs" width="max-content" imageIcon onClickFunction={navigateToInsight} />
        </div>
      </div>
  );
};

export default TelemetrySShotEmptyState;
