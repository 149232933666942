import React from 'react';
import ActivateIcon from './img/activateIcon.svg';
import AgencySelectorIcon from './img/agencySelector.svg';
import EmailIcon from './img/emailIcon.svg';
import PasswordIcon from './img/passwordKey.svg';
import InvalidLink from './img/invalidLink.svg';
import GreenTick from './img/greenTick.svg';
import './imgwithtitle.css';

export default function ImgWithTitle(props) {
    const {
        titleText, subText, icon, style,
    } = props;

    const headerImg = () => {
        switch (icon) {
            case 'agency':
                return <img loading="lazy" src={AgencySelectorIcon} alt="agencyselector" />;
            case 'Live':
                return <img loading="lazy" src={ActivateIcon} alt="activateicon" />;
            case 'Insights':
                return <img loading="lazy" src={EmailIcon} alt="emailicon" />;
            case 'Users':
                return <img loading="lazy" src={PasswordIcon} alt="passwordicon" />;
            case 'Invalid':
                return <img loading="lazy" src={InvalidLink} alt="invalidlink" />;
            case 'Success':
                return <img loading="lazy" src={GreenTick} alt="greentick" />;
            default:
                return null;
        }
    };
  return (
    <div className="img-with-class-container" style={style}>
        {headerImg()}
        <div className="img-with-class-header-text">{titleText}</div>
        {subText && (<div className="img-with-class-subtext">{subText}</div>)}
    </div>
  );
}
