import React, { useState, useEffect } from 'react';
import MovingIcon from './img/movingTab.svg';
import './assetTabFilter.css';

export default function AssetTabFilter(props) {
    const { setAssetClick, assetClick } = props;

    const btnAssets = (e, name) => {
      e.preventDefault();
      setAssetClick(name);
    };
  return (
    <div className="asset-tab-container">
        <button type="button" style={assetClick === 'allAsset' ? { borderTopLeftRadius: '0.5rem', borderBottomLeftRadius: '0.5rem', backgroundColor: '#F9FAFB' } : { borderTopLeftRadius: '0.5rem', borderBottomLeftRadius: '0.5rem' }} onClick={(e) => btnAssets(e, 'allAsset')} className="asset-tab-button">
            <div className="asset-tab-text">All Assets</div>
        </button>
        <button
          type="button"
          style={assetClick === 'movingAsset' ? {
            borderTopRightRadius: '0.5rem', borderBottomRightRadius: '0.5rem', borderLeft: '0.5rem', padding: '0rem 1rem 0rem 0.25rem', gap: '0rem', backgroundColor: '#F9FAFB',
          } : {
            borderTopRightRadius: '0.5rem', borderBottomRightRadius: '0.5rem', borderLeft: '0.5rem',
          }}
          onClick={(e) => btnAssets(e, 'movingAsset')}
          className="asset-tab-button"
        >
            {assetClick === 'movingAsset' && <img loading="lazy" src={MovingIcon} alt="move-icon" />}
            <div className="asset-tab-text">Assets In Motion</div>
        </button>
    </div>
  );
}
