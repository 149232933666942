/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React, {
  useEffect, useContext, useState, useLayoutEffect,
} from 'react';
import Cookies from 'js-cookie';
import { useNavigate, Navigate } from 'react-router-dom';
import { AuthContext } from '../reducer/Auth';
// import { PrivilegeContext } from '../reducer/Privilege';
// import { APIGetUsers } from '../restAPI/FetchAPI';
import { useApiData } from '../reducer/Modules';

const ProtectedRoute = ({ user, redirectTo, children }) => {
  const { loading, apiResponse } = useApiData();
  const token = Cookies.get('jwtToken');
  const role = Cookies.get('role');
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { logout } = context;

    if (role === 'System Administrator') {
      return children;
    }
    switch (user) {
      case 'dashboard':
        if (token) {
          return children;
        } else {
          return <Navigate to={redirectTo} replace />;
        }
      case 'liveMap':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.live?.[user] === true) {
            return children;
          } else if (apiResponse?.module?.live?.[user] === false) {
            if (apiResponse?.module?.live?.telemetrySnapshot === false && apiResponse?.module?.live?.assetStatus === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.live?.telemetrySnapshot === true) {
              return <Navigate to="/live/telemetrySnapshot" replace />;
            } else if (apiResponse?.module?.live?.assetStatus === true) {
              return <Navigate to="/403" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'telemetrySnapshot':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.live?.[user] === true) {
            return children;
          } else if (apiResponse?.module?.live?.[user] === false) {
            if (apiResponse?.module?.live?.liveMap === false && apiResponse?.module?.live?.assetStatus === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.live?.liveMap === true) {
              return <Navigate to="/live/mapOverview" replace />;
            } else if (apiResponse?.module?.live?.assetStatus === true) {
              return <Navigate to="/403" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'assetStatus':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.live?.[user] === true) {
            return <Navigate to="/403" replace />;
          } else if (apiResponse?.module?.live?.[user] === false) {
            if (apiResponse?.module?.live?.telemetrySnapshot === false && apiResponse?.module?.live?.liveMap === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.live?.liveMap === true) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.live?.telemetrySnapshot === true) {
              return <Navigate to="/403" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'assetPerformance':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.insights?.[user] === true) {
            return children;
          } else if (apiResponse?.module?.insights?.[user] === false) {
            if (apiResponse?.module?.insights?.iorail === false && apiResponse?.module?.insights?.ioroad === false && apiResponse?.module?.insights?.odAnalytics === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.insights?.odAnalytics === true) {
              return <Navigate to="/insights/ODAnalytics" replace />;
            } else if (apiResponse?.module?.insights?.iorail === true) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.insights?.ioroad === true) {
              return <Navigate to="/403" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'iorail':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.insights?.[user] === true) {
            return <Navigate to="/403" replace />;
          } else if (apiResponse?.module?.insights?.[user] === false) {
            if (apiResponse?.module?.insights?.assetPerformance === false && apiResponse?.module?.insights?.ioroad === false && apiResponse?.module?.insights?.odAnalytics === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.insights?.assetPerformance === true) {
              return <Navigate to="/insights/assetPerformance" replace />;
            } else if (apiResponse?.module?.insights?.ioroad === true) {
              return <Navigate to="/insights/IOroad" replace />;
            } else if (apiResponse?.module?.insights?.odAnalytics === true) {
              return <Navigate to="/insights/odAnalytics" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'ioroad':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.insights?.[user] === true) {
            return <Navigate to="/403" replace />;
          } else if (apiResponse?.module?.insights?.[user] === false) {
            if (apiResponse?.module?.insights?.iorail === false && apiResponse?.module?.insights?.assetPerformance === false && apiResponse?.module?.insights?.odAnalytics === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.insights?.iorail === true) {
              return <Navigate to="/insights/IOrail" replace />;
            } else if (apiResponse?.module?.insights?.assetPerformance === true) {
              return <Navigate to="/insights/assetPerformance" replace />;
            } else if (apiResponse?.module?.insights?.odAnalytics === true) {
              return <Navigate to="/insights/odAnalytics" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'odAnalytics':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.insights?.[user] === true) {
            return children;
          } else if (apiResponse?.module?.insights?.[user] === false) {
            if (apiResponse?.module?.insights?.iorail === false && apiResponse?.module?.insights?.assetPerformance === false && apiResponse?.module?.insights?.ioroad === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.insights?.iorail === true) {
              return <Navigate to="/insights/IOrail" replace />;
            } else if (apiResponse?.module?.insights?.assetPerformance === true) {
              return <Navigate to="/insights/assetPerformance" replace />;
            } else if (apiResponse?.module?.insights?.ioroad === true) {
              return <Navigate to="/insights/IOroad" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'asset':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.fleetManagement?.[user] === true) {
            return children;
          } else if (apiResponse?.module?.fleetManagement?.[user] === false) {
            if (apiResponse?.module?.fleetManagement?.geofence === false && apiResponse?.module?.fleetManagement?.beacon === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.fleetManagement?.geofence === true) {
              return <Navigate to="/inventory/geofence" replace />;
            } else if (apiResponse?.module?.fleetManagement?.connectivity === true) {
              return <Navigate to="/inventory/connectivity" replace />;
            } else if (apiResponse?.module?.fleetManagement?.beacon === true) {
              return <Navigate to="/403" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'geofence':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.fleetManagement?.[user] === true) {
            return children;
          } else if (apiResponse?.module?.fleetManagement?.[user] === false) {
            if (apiResponse?.module?.fleetManagement?.asset === false && apiResponse?.module?.fleetManagement?.beacon === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.fleetManagement?.asset === true) {
              return <Navigate to="/inventory/assetdevices" replace />;
            } else if (apiResponse?.module?.fleetManagement?.connectivity === true) {
              return <Navigate to="/inventory/connectivity" replace />;
            } else if (apiResponse?.module?.fleetManagement?.beacon === true) {
              return <Navigate to="/403" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'beacon':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.fleetManagement?.[user] === true) {
            return <Navigate to="/403" replace />;
          } else if (apiResponse?.module?.fleetManagement?.[user] === false) {
            if (apiResponse?.module?.fleetManagement?.geofence === false && apiResponse?.module?.fleetManagement?.asset === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.fleetManagement?.asset === true) {
              return <Navigate to="/inventory/assetdevices" replace />;
            } else if (apiResponse?.module?.fleetManagement?.geofence === true) {
              return <Navigate to="/inventory/geofence" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'connectivity':
        if (token) {
          if (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner' || apiResponse?.module?.fleetManagement?.[user] === true) {
            return children;
          } else if (apiResponse?.module?.fleetManagement?.[user] === false) {
            if (apiResponse?.module?.fleetManagement?.geofence === false && apiResponse?.module?.fleetManagement?.asset === false) {
              return <Navigate to="/403" replace />;
            } else if (apiResponse?.module?.fleetManagement?.asset === true) {
              return <Navigate to="/inventory/assetdevices" replace />;
            } else if (apiResponse?.module?.fleetManagement?.beacon) {
              return <Navigate to="/inventory/beacons" replace />;
            } else if (apiResponse?.module?.fleetManagement?.geofence === true) {
              return <Navigate to="/inventory/geofence" replace />;
            }
          }
        } else {
          return <Navigate to={redirectTo} replace />;
        }
        break;
      case 'settings':
        if (token) {
          return children;
        } else {
          return <Navigate to={redirectTo} replace />;
        }
      case 'notifications':
        if (token) {
          return children;
        } else {
          return <Navigate to={redirectTo} replace />;
        }
      default:
        return <Navigate to="/403" replace />;
    }
};

export default ProtectedRoute;
