/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import { CSVLink, CSVDownload } from 'react-csv';
import styled from 'styled-components';
import Title from '../../../../component/title/Title';
import Table from '../../../../component/table/Table';
import { BackButton, ExportCSVButton } from '../../../../component/buttonComponent/ButtonComponent';
import SnackBar from '../../../../component/snackbar/Snackbar';
import './TelemetryLog.css';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 5,
    [`&.${linearProgressClasses.root}`]: {
        height: '8px',
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EAECF0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#365A6D',
    },
}));

const TelemetryLog = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        assetDetails,
        fromTime,
        toTime,
        telemData,
    } = location.state;
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(telemData.length);
    const [incorrectCredentials, setIncorrectCredentials] = useState(false);
    const [progress, setProgress] = useState(0);

    const headers = [
        { label: 'Timestamp', key: 'timestamp' },
        { label: 'Latitude', key: 'latitude' },
        { label: 'Longitude', key: 'longitude' },
        { label: 'Motion State', key: 'motionInference' },
        { label: 'Speed', key: 'kmh' },
        { label: 'DC Volt', key: 'dcVoltPerc' },
        { label: 'Batt Volt', key: 'battVoltPerc' },
        { label: 'Connectivity', key: 'operatorName' },
    ];

    const csvData = (telemetry) => {
        const switchMotionInference = (value) => {
            switch (value) {
            case 100:
                return 'Stopped';
            case 200:
                return 'In Motion';
            case 400:
                return 'Idle';
            case 500:
                return 'In Tow';
            default:
                return null;
            }
      };
        const data = telemetry.map((x) => ({
            timestamp: x.timestamp,
            latitude: x.latitude,
            longitude: x.longitude,
            motionInference: switchMotionInference(x.motionInference),
            kmh: x.kmh,
            dcVoltPerc: x.dcVoltPerc,
            battVoltPerc: x.battVoltPerc,
            operatorName: x.operatorName,
        }));

        return data;
    };

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item) => {
            let ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'Telemetry Log.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const backToSearch = () => {
        navigate('/insights/assetPerformance', { replace: true });
    };

    const setVisible = () => {
        setIncorrectCredentials(true);
        setProgress(20);
    };

      useEffect(() => {
        if (incorrectCredentials === true) {
            if (progress < 100) {
                const timer = setInterval(() => {
                    setProgress((prevProgress) => (prevProgress + 20));
                }, 500);
                return () => {
                    if (progress === 80) {
                        downloadCSV(csvData(telemData));
                    }
                    clearInterval(timer);
                };
            }
        }
      }, [progress]);

    return (
        <div className="telemetry-log-container">
            <div className="telemetry-log-header">
                <BackButton label="Back to search" btnClickBack={backToSearch} color="#FFFFFF" />
                <div className="telemetry-log-title-container">
                    <Title title="Telemetry Log" titleSize="30px" lineHeight="38px" deleteSubtitle />
                    <ExportCSVButton label="Export CSV" buttonHeight="40px" onClickFunction={(e) => setVisible(e)} />
                </div>
                <div className="telemetry-log-details">
                    <div className="telemetry-log-label">
                        <Title subtitle="Timeline" subtitleSize="14px" subtitleLineHeight="20px" />
                        <div className="telemetry-log-text" style={{ fontWeight: 500 }}>
                            {fromTime}
                            {' '}
                            -
                            {' '}
                            {toTime}
                        </div>
                    </div>
                    <div className="telemetry-log-label">
                        <Title subtitle="Asset / Device" subtitleSize="14px" subtitleLineHeight="20px" />
                        <div className="telemetry-log-asset-device">
                            <div className="telemetry-log-name-text">
                                {assetDetails.name}
                                {' '}
                                •
                            </div>
                            <div className="telemetry-log-text">
                                {' '}
                                {assetDetails.id}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="telemetry-log-table">
                <Table
                  selectedRowCount={selectedRowCount}
                  setSelectedRowCount={setSelectedRowCount}
                  tableData={telemData}
                  module="telemetryLog"
                  pagination={false}
                  loading={loading}
                  totalRows={totalRows}
                />
            </div>
            {incorrectCredentials && (
                <div className="notification">
                    <SnackBar state={incorrectCredentials} setState={setIncorrectCredentials} title="Exporting Data" deleteSubtitle module="TelemetryLog" action="info">
                        <div className="telemetry-notification-body">
                            Your download will begin shortly
                            <div className="telemetry-progress-bar">
                                <BorderLinearProgress variant="determinate" value={progress} style={{ width: '280px' }} />
                                <div className="telemetry-progress-bar-text">
                                    {progress}
                                    % processed...
                                </div>
                            </div>
                        </div>
                    </SnackBar>
                </div>
            )}
        </div>
    );
};

export default TelemetryLog;
