import React, { forwardRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, useOutletContext } from 'react-router';
import Cookies from 'js-cookie';
import { styled } from '@mui/material/styles';
import {
    ListItem,
    Link,
    ListItemButton,
    Snackbar,
    Switch,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import TruckIcon from '../../../../../image/truckIcon.svg';
import BreadCrumbs from '../../../../../component/breadcrumbs/BreadCrumbs';
import ConfigTab from '../../../../../component/configurationTab/configurationTab';
import Title from '../../../../../component/title/Title';
import SIMIcon from './img/simIcon.svg';
import CopyIcon from '../../../../../image/copy.svg';
import './connectivity.css';
import { PrimaryButton, SecondaryButton, IOSSwitch } from '../../../../../component/buttonComponent/ButtonComponent';
import Text from '../../../../../component/text/Text';
import ColoredLine from '../../../../../component/coloredLine/ColoredLine';
import { APIPatchSIM } from '../../../../../config/restAPI/PatchAPI';

const InventoryConnectivity = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { sim } = state;
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const role = Cookies.get('role');
    const [assetName, setAssetName] = useState(state.assetName);
    const [checked, setChecked] = useState(sim.activated ? sim.activated : false);
    const [SIMID, setSIMID] = useState(sim.id ? sim.id : undefined);
    const [ICCID, setICCID] = useState(sim.iccid ? sim.iccid : undefined);
    const [dataLimit, setDataLimit] = useState(sim.dataLimit ? (sim.dataLimit / 1000000).toFixed(1) : undefined);
    const [lifetimeUsage, setLifeTimeUsage] = useState(sim.lifetimeUsage ? (sim.lifetimeUsage / 1000000).toFixed(1) : undefined);
    const [onlineFor, setOnlineFor] = useState(sim.onlineFor ? (sim.onlineFor / 60000).toFixed(0) : undefined);
    const [alertThresholdValue, setAlertThresholdValue] = useState(sim.alertThreshold ? sim.alertThreshold : undefined);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const [defaultValues, setDefaultValues] = useState(sim);

    const types = [
        {
            text: 'General',
            value: 1,
            path: '/inventory/assetdevices/configuration',
            enabled: true,
        },
        {
            text: 'Connectivity',
            value: 2,
            path: '/inventory/assetdevices/connectivity',
            enabled: true,
        },
        // { text: 'Alerts', value: 3, path: '/inventory/assetdevices/alerts' },
        // { text: 'Smart Locks', value: 4, path: '/inventory/assetdevices/smartlocks' },
    ];
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();

    const [bodyState, setBodyState] = useState(true);

    const seperator = [
        <img loading="lazy" src={TruckIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
        <ListItemButton
          disableRipple
          disableGutters
          onClick={() => navigate('/inventory/assetdevices', { replace: true })}
          underline="none"
          style={{ backgroundColor: 'transparent' }}
          sx={{
            display: 'flex',
            color: '#475467',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontStyle: 'normal',
          }}
        >
            <div>
                Assets & Devices
            </div>
        </ListItemButton>,
        <ListItem
          sx={{
            padding: 0,
            color: '#475467',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Inter',
            fontStyle: 'normal',
          }}
        >
            Configurations
        </ListItem>,
    ];

    const updateSIM = async (params) => {
        try {
            const result = await APIPatchSIM(token, setLoading, agencyID, params);
            if (result.data.status === 200) {
                setLoading(false);
                navigate('/inventory/assetdevices', { state: { snackbarStatus: 'Update', snackbarTitle: 'Success', snackbarSub: 'SIM successfully updated!' } });
            }
        } catch (err) {
            setLoading(false);
            // console.log(err);
        }
    };

    const inventoryNavigate = () => {
        navigate('/inventory/assetdevices', { replace: true });
    };

    const copyDevice = (e) => {
        navigator.clipboard.writeText(e);
        // setOpen(true);
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleChangeAlertThreshold = (event) => {
        setAlertThresholdValue(event.target.value);
    };

    const checkingConfigReducer = () => {
        const value = { deviceOids: [state.id] };

        if (alertThresholdValue !== defaultValues.alertThreshold) {
            value.dataLimit = { alertThreshold: parseInt(alertThresholdValue, 10) };
        }

        if (checked !== defaultValues.activated) {
            value.activated = checked;
        }

        return value;
    };

    const saveButtonDisabled = () => {
        const value = {};

        if (alertThresholdValue !== defaultValues.alertThreshold) {
            value.alertThreshold = alertThresholdValue;
        }

        if (checked !== defaultValues.activated) {
            value.activated = checked;
        }

        const checking = Object.keys(value).length > 0;
        return checking;
    };

    const handleConnectivitySaveButton = () => {
        try {
            if (state) {
                const updateList = checkingConfigReducer();
                // console.log(updateList);
                updateSIM(updateList);
            }
        } catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        setNavigationState('inventory');
        setNavigtionClick(2);
    }, []);

    useEffect(() => {
        if (saveButtonDisabled()) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [alertThresholdValue, checked]);

    useEffect(() => {
        if ((defaultValues.alertThreshold === undefined) && (alertThresholdValue === '')) {
            setAlertThresholdValue(undefined);
        }
    }, [alertThresholdValue]);

    return (
        <div className="configuration-connectivity-container">
            <div className="configuration-connectivity-header">
                <BreadCrumbs customSeperator={seperator} />
                {!state.assetName ? (
                    <Title title="Configurations" subtitle={`${state.deviceSN}`} titleSize="1.875rem" />
                ) : (
                    <Title title="Configurations" subtitle={`${state.deviceSN} (${state.assetName})`} titleSize="1.875rem" />
                )}
                <div className="configuration-connectivity-tabs">
                    <ConfigTab color="white" types={types} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} agencyRow={state} />
                </div>
            </div>
            <div className="configuration-connectivity-body">
                {bodyState ? (
                    <>
                        <div className="configuration-connectivity-form">
                            <div className="configuration-connectivity-body-row">
                                <div className="configuration-connectivity-main-text">
                                    Activated
                                </div>
                                <IOSSwitch checked={checked} onChange={handleChange} />
                            </div>
                            <div className="configuration-connectivity-body-row">
                                <div className="configuration-connectivity-main-text">
                                    SIM ID
                                </div>
                                <div className="copy-button-container">
                                    <input type="text" className="copy-input-button" value={SIMID} disabled />
                                    <button type="button" className="copy-button-connectivity" onClick={(e) => copyDevice(SIMID)}>
                                        <img loading="lazy" src={CopyIcon} alt="CopyIcon" />
                                        <div className="copy-connectivity-text">Copy</div>
                                    </button>
                                </div>
                            </div>
                            <div className="configuration-connectivity-body-row">
                                <div className="configuration-connectivity-main-text">
                                    ICCID
                                </div>
                                <div className="copy-button-container">
                                    <input type="text" className="copy-input-button" value={ICCID} disabled />
                                    <button type="button" className="copy-button-connectivity" onClick={(e) => copyDevice(ICCID)}>
                                        <img loading="lazy" src={CopyIcon} alt="CopyIcon" />
                                        <div className="copy-connectivity-text">Copy</div>
                                    </button>
                                </div>
                            </div>
                            <div className="configuration-connectivity-body-row">
                                <div className="configuration-connectivity-main-text">
                                    Data Limit
                                </div>
                                <div className="connectivity-input-container">
                                    <button type="button" className="connectivity-input-data-limit" disabled>
                                        <div className="connectivity-input-text">MB</div>
                                    </button>
                                    <input type="text" className="connectivity-input-data-limit-value" value={dataLimit} disabled />
                                </div>
                            </div>
                            <div className="configuration-connectivity-body-row">
                                <div className="configuration-connectivity-main-text">
                                    Lifetime Usage
                                </div>
                                <div className="connectivity-input-container">
                                    <button type="button" className="connectivity-input-data-limit" disabled>
                                        <div className="connectivity-input-text">MB</div>
                                    </button>
                                    <input type="text" className="connectivity-input-data-limit-value" value={lifetimeUsage} disabled />
                                </div>
                            </div>
                            <div className="configuration-connectivity-body-row">
                                <div className="configuration-connectivity-main-text">
                                    Online for
                                </div>
                                <div className="connectivity-input-container">
                                    <button type="button" className="connectivity-input-data-limit" disabled>
                                        <div className="connectivity-input-text">minutes</div>
                                    </button>
                                    <input type="text" className="connectivity-input-data-limit-value" value={onlineFor} disabled />
                                </div>
                            </div>
                            {/* <ColoredLine /> */}
                            {/* <div className="configuration-connectivity-body-row">
                                <div className="configuration-connectivity-main-text">
                                    Alert Threshold
                                    <div className="configuration-connectivity-subtitle">
                                        Sends out a notification when SIM usage breaches this limit for the month
                                    </div>
                                </div>
                                <div className="connectivity-input-container">
                                    <button type="button" className="connectivity-input-data-limit">
                                        <div className="connectivity-input-text">MB</div>
                                    </button>
                                    <input type="number" className="connectivity-input-alert-threshold-value" value={alertThresholdValue} onChange={handleChangeAlertThreshold} />
                                </div>
                            </div> */}
                        </div>
                        <div className="configuration-connectivity-footer">
                            <ColoredLine />
                            <div className="configuration-connectivity-footer-buttons">
                                <SecondaryButton label="Cancel" onClickFunction={inventoryNavigate} />
                                <PrimaryButton label="Save" onClickFunction={handleConnectivitySaveButton} disable={!btnDisabled} loading={loading} />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="configuration-connectivity-empty-state-container">
                        <img loading="lazy" src={SIMIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
                        <div className="configuration-connectivity-empty-state-text">
                            <Text text="No VERGE IoT SIM Detected" size="1.25rem" weight="600" align="center" height="auto" lineHeight="30px" />
                            <Text text="Manage your asset connectivity with VERGE IoT SIMs through Horizon in a single pane of glass." size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
                            <Text text="This page will be automatically populated upon usage of a VERGE IoT SIM" size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InventoryConnectivity;
