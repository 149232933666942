import * as turf from '@turf/turf';

export const firstCoordinate = (value) => {
    let takeFirstCoordinate;
    try {
        // this to take the first coords;
        const usingTurf = value.map((x) => turf.coordAll(x));
        takeFirstCoordinate = usingTurf.map((x) => x[0]);
        return takeFirstCoordinate;
    } catch (err) {
        takeFirstCoordinate = null;
        return takeFirstCoordinate;
    }
};
