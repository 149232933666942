import React, { useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import HeaderImage from '../../image/header.svg';
import useComponentVisible from '../../config/outsideClick/useComponentVisible';
import { CreateWithIcon, TopbarMenuButton } from '../../component/buttonComponent/ButtonComponent';
import './Topbar.css';
import Modal from '../../component/modal/Modal';
import HeaderSideBarMobile from '../../component/header/HeaderSiderBarMobile';
import FooterNavigationMobile from '../../component/footer/FooterNavigationMobile';
import { WebsocketContext } from '../../config/reducer/Websocket';
import { AuthContext } from '../../config/reducer/Auth';
import { APIWSConnect } from '../../config/restAPI/PostAPI';
import { APIWSDisconnect } from '../../config/restAPI/DeleteAPI';

export default function Topbar(props, { history }) {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible,
    } = useComponentVisible(false);
    const {
        navigationClick, setNavigationClick, navigationState, setNavigationState, cookiesUpdated,
      } = props;
    const navigate = useNavigate();
    const [message, setMessage] = useState([]);
    const payloadContext = useContext(WebsocketContext);
    const context = useContext(AuthContext);
    const { reConnect } = context;
    const { CloseConnection, setChannel } = payloadContext;

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const sessionID = Cookies.get('SessionID');
    const agencyOid = Cookies.get('agencyUUID');
    const token = Cookies.get('jwtToken');
    const role = Cookies.get('role');

    const [isAdmin, setIsAdmin] = useState(false);

    const [switchModalShow, setSwitchModalShow] = useState(false);

    const [showMenu, setShowMenu] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const toggleMenu = () => {
        setShowMenu(!showMenu);
        setIsComponentVisible(true);
    };

    const btnAgencySelector = async (e, value) => {
      // console.log(value);
      e.preventDefault();
      try {
        const websocketDisconnected = await APIWSDisconnect(token, agencyOid);
        if (websocketDisconnected.status === 200) {
          CloseConnection('disconnect');
          if (value.id === 'admin') {
            const postResponse = await APIWSConnect(token, value.agencyUUID);
            if (postResponse.status === 200) {
              reConnect(value, null);
              setChannel(value.id);
              navigate('/dashboard', { replace: true });
              window.location.reload();
            }
          } else {
            const postResponse = await APIWSConnect(token, value.agencyUUID);
            if (postResponse.status === 200) {
              reConnect(value, null);
              setChannel(value.id);
              navigate('/live/mapOverview', { replace: true });
              window.location.reload();
            } else {
              console.log('go here if error after send in response websocket login');
            }
          }
        }
      } catch (err) {
        // console.log('go here if error catch');
      }
  };
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        if (role === 'System Administrator') {
            setIsAdmin(true);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (isComponentVisible === false) {
             setShowMenu(false);
        }
    }, [isComponentVisible]);

    useEffect(() => {
        setIsComponentVisible(false);
    }, [navigationState]);

    return (
        <>
            <div className="topbar-container-main">
                <div className="topbar-logo-container">
                    <img loading="lazy" src={HeaderImage} className="logo-image" alt="header-logo" />
                </div>
                <div className="topbar-menu-container">
                    <TopbarMenuButton onClickFunction={() => toggleMenu()} />
                </div>
            </div>
            { showMenu && (
                <div className="topbar-menu-blur">
                    <div ref={ref} className="topbar-menu">
                        <HeaderSideBarMobile history={history} setNavigationState={setNavigationState} navigationState={navigationState} isAdmin={isAdmin} setNavigationClick={setNavigationClick} />
                        <FooterNavigationMobile history={history} switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} setNavigationState={setNavigationState} navigationState={navigationState} isAdmin={isAdmin} setNavigationClick={setNavigationClick} cookiesUpdated={cookiesUpdated} />
                    </div>
                </div>
            ) }
        { switchModalShow && (
          <div className="modal-container-bg-topleft">
              <Modal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} btnConfirm={btnAgencySelector} module="SwitchAgency" />
          </div>
      ) }
        </>
    );
}
