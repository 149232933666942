/* eslint-disable no-nested-ternary */
import React, { useState, Children, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Stepper, Step, StepLabel, StepContent, StepConnector, Typography, stepConnectorClasses, stepContentClasses,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import './stepComponent.css';
import CompletedIcon from './img/Check.svg';
import { TelegramButton } from '../buttonComponent/ButtonComponent';

import { TelegramSteps } from './StepContent/stepContent';

const customTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#475467',
          paddingLeft: '20px',
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderLeft: '2px solid #365A6D',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: '4px 0',
        },
        label: {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#344054',
          '&.Mui-completed': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-root': {
            color: '#D0E0E8',
          },
          '&.MuiStepIcon-root.Mui-completed': {
            color: '#D0E0E8',
          },
          '&.MuiStepIcon-root.Mui-active': {
            color: '#D0E0E8',
            borderRadius: '12px',
            boxShadow: '0px 0px 0px 4px #F4EBFF',
          },
        },
      },
    },
  },
});

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  background: '#F3F4F6',
  color: '#E5E7EB',
  display: 'flex',
  height: 24,
  width: 24,
  justifyContent: 'center',
  borderRadius: '12px',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#365A6D',
    background: '#B1CBD9',
    boxShadow: '0px 0px 0px 4px #F4EBFF',
  }),
  ...(ownerState.completed && {
    background: '#B1CBD9',
  }),
  '& .CustomStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? (
        <img src={CompletedIcon} alt="completed-icon" loading="lazy" />
      ) : (
        <div className="CustomStepIcon-circle" />
      )}
    </CustomStepIconRoot>
  );
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#365A6D',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#365A6D',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#E5E7EB',
    borderWidth: 2,
  },
}));

export default function VerticalLinearStepper(props) {
  const {
    module, activeStep, sendTestMessage, isEnabled,
  } = props;
  // const [activeStep, setActiveStep] = useState(0);
  const switchModule = (value) => {
    switch (value) {
      case 'Telegram':
        return TelegramSteps;
      default:
        return null;
    }
  };

  const CustomStepContent = (stepContentProps) => {
    const { index, children } = stepContentProps;

    return (
      <>
        {index < activeStep ? (
          <div className="Custom-step-content-completed">
            {children}
          </div>
        ) : index === switchModule(module).length - 1 ? (
          <div className="Custom-step-content-last">
            {children}
          </div>
        ) : (
          <div className="Custom-step-content">
            {children}
          </div>
        )}
      </>
    );
  };

  const switchStepperContent = (value, step, index) => {
    switch (value) {
      case 'Telegram':
        return (
          <>
            {step.id === 1 ? (
              <CustomStepContent index={index} activeStep={activeStep}>
                <Typography>
                  {step.description(isEnabled)}
                </Typography>
              </CustomStepContent>
            ) : step.id === 3 ? (
              <CustomStepContent index={index} activeStep={activeStep}>
                <Typography>
                  {step.description(sendTestMessage, isEnabled)}
                </Typography>
              </CustomStepContent>
            ) : (
              <CustomStepContent index={index} activeStep={activeStep}>
                <Typography>
                  {step.description()}
                </Typography>
              </CustomStepContent>
            )}
          </>
        );
      default:
        return (
          <>
            <CustomStepContent index={index} activeStep={activeStep}>
              <Typography>
                {step.description()}
              </Typography>
            </CustomStepContent>
          </>
        );
    }
  };

  return (
    <Box sx={{ maxWidth: '512px' }}>
      <ThemeProvider theme={customTheme}>
        <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomConnector />}>
          {switchModule(module).map((step, index) => (
            <Step key={step.label} expanded>
              <StepLabel StepIconComponent={CustomStepIcon}>
                {step.label}
              </StepLabel>
              {switchStepperContent(module, step, index)}
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
    </Box>
  );
}
