/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import './avatar.css';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import MenuPopup from '../menuPopup/MenuPopup';
import Menu from '../../image/hamburgerDotMenu.svg';

export default function Avatar(props) {
  const {
    name, size, fontSize, lineHeight, image,
  } = props;

  // this to getInitials Name
  function getInitials(names) {
    let initialsArray = [];
    let initialsFinal;
      const arr = names.split(' ');
      if (arr.length > 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < arr.length; i++) {
          const initials = arr[i].charAt(0).toUpperCase();
          initialsArray = [...initialsArray, initials];
        }
        initialsFinal = initialsArray;
      } else if (arr.length === 1) {
        names.charAt(0).toUpperCase();
        initialsFinal = names.charAt(0).toUpperCase();
      }
      // this only display 2 initials slice
      const onlyTwoAlphabet = initialsFinal.length > 1 ? initialsFinal.slice(0, 2) : initialsFinal;
      return onlyTwoAlphabet;
    }

    useEffect(() => {
      // if (ref.current.clientWidth) {
          document.documentElement.style.setProperty('--size', size);
          const labelWidth = getComputedStyle(document.documentElement).getPropertyValue('--size');
      // }
  }, []);

  return (
    <>
          <div
            className="avatar"
            style={{
              width: size,
              height: size,
              fontSize: fontSize,
              lineHeight: lineHeight,
            }}
          >
            { (image && ((image !== 'null') || (image !== undefined))) ? (
                      <img
                        src={image}
                        className="avatar"
                        style={{
                          width: size,
                          height: size,
                          fontSize: fontSize,
                          lineHeight: lineHeight,
                        }}
                        alt="profile"
                        onLoad={() => { URL.revokeObjectURL(image); }}
                      />
            ) : (
              <div
                className="initials-text"
                style={{
                fontSize: fontSize,
              }}
              >
{name && getInitials(name)}

              </div>
              ) }
          </div>
    </>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
};
