import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '../autocomplete/Autocomplete';
import PlusIcon from '../../image/plusIcon.svg';
import SlackIcon from './img/slackIcon.svg';
import TelegramIcon from './img/telegramIcon.svg';
import EmailIcon from './img/emailIcon.svg';
import PhoneIcon from './img/phoneIcon.svg';
import WebhookIcon from './img/shareIcon.svg';
import WhatsappIcon from './img/whatsappIcon.svg';
import AlertIcon from './img/alertIcon.svg';

import './geofenceactionuser.css';

const tempListUser = [
    { id: 'John Doe', name: 'john.doe@asiamobiliti.com' },
    { id: 'Jane Doe', name: 'jane.doe@asiamobiliti.com' },
    { id: 'Astro Boy', name: 'astro.boy@asiamobiliti.com' },
];

export default function GeofenceActionUser(props) {
    const {
        users, newUserFunction, userCancel, disabledAddUser, saveUser, removeUser, listDropdown, setSelectedOption, selectedOption, loading,
    } = props;
    const size = 3;
    const [selectionList, setSelectionList] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [dropDownLoading, setDropDownLoading] = useState(false);

    const iconChannels = (value) => {
        switch (value) {
            case 'Slack':
                return <img loading="lazy" src={SlackIcon} alt="slack-icon" style={{ width: '20px', height: '20px' }} />;
            case 'Telegram':
                return <img loading="lazy" src={TelegramIcon} alt="telegram-icon" style={{ width: '20px', height: '20px' }} />;
            case 'Email':
                return <img loading="lazy" src={EmailIcon} alt="email-icon" style={{ width: '20px', height: '20px' }} />;
            case 'Phone':
                return <img loading="lazy" src={PhoneIcon} alt="phone-icon" style={{ width: '20px', height: '20px' }} />;
            case 'Share':
                return <img loading="lazy" src={WebhookIcon} alt="webhook-icon" style={{ width: '20px', height: '20px' }} />;
            case 'Whatsapp':
                return <img loading="lazy" src={WhatsappIcon} alt="whatsapp-icon" style={{ width: '20px', height: '20px' }} />;
            default:
                return null;
        }
    };

    function stringAvatar(name) {
        const test = name ? {
            sx: {
                backgroundColor: '#F2F4F7', color: '#475467', fontSize: '1rem', fontWeight: 500,
            },
            alt: 'avatar-icon',
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        } : {
            sx: {
                backgroundColor: '#F2F4F7', color: '#475467', fontSize: '1rem', fontWeight: 500,
            },
            alt: 'avatar-icon',
            children: 'NA',
        };
        return test;
      }

    return (
        <div className="g-details-action-user-container">
            <div className="g-details-action-user-header">
                <div>User</div>
                <div>Notification Channels</div>
            </div>
            <div>
                <div className="g-details-action-user-body-list-container">
                    {
                        users.length > 0 && users.map((x, index, arr) => (
                        <div>
                            {
                                arr.length - 1 === index ? (
                                    <div className="g-details-action-user-body">
                                        <div className="g-details-action-user-avatar-body">
                                            {
                                                x.url ? (
                                                    <Avatar alt="avatar-icon" src={x.url} />
                                                ) : (
                                                    <Avatar {...stringAvatar(x.id)} />
                                                )
                                            }
                                            <div>
                                                <div className="g-details-action-user-body-name">{x.id !== '' ? x.id : ''}</div>
                                                <div className="g-details-action-user-body-email">{x.name}</div>
                                            </div>
                                        </div>
                                        <div className="g-details-action-user-body-notification">
                                            {
                                                x.channel.length > size ? (
                                                    <div className="g-details-action-user-body-elipsis-container">
                                                        <div className="gap">
                                                            {
                                                                x.channel.slice(0, size).map((i) => (
                                                                    iconChannels(i.type)
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="g-details-action-remaining-elipsis">{`+${x.channels.length - size}`}</div>
                                                    </div>
                                                ) : (
                                                    <div className="g-details-action-user-body-notification">
                                                        {
                                                            x.channel.length > 0 ? (
                                                                <div className="gap">
                                                                    {
                                                                        x.channel.map((i) => (
                                                                            iconChannels(i.type)
                                                                        ))
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="empty">
                                                                    <img loading="lazy" src={AlertIcon} alt="alert-icon" />
                                                                    <div>No Channels Configured</div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="g-details-action-user-remove-button" onClick={() => removeUser(index, x)} onKeyUp={() => removeUser(index, x)} tabIndex="0" role="button" style={{ cursor: 'pointer' }}>Remove</div>
                                    </div>
                                ) : (
                                    <div className="g-details-action-user-body-with-divider">
                                        <div className="g-details-action-user-avatar-body">
                                            {
                                                x.url ? (
                                                    <Avatar alt="avatar-icon" src={x.url} />
                                                ) : (
                                                    <Avatar {...stringAvatar(x.id)} />
                                                )
                                            }
                                            <div>
                                                <div className="g-details-action-user-body-name">{x.id !== '' ? x.id : '\n'}</div>
                                                <div className="g-details-action-user-body-email">{x.name}</div>
                                            </div>
                                        </div>
                                        <div className="g-details-action-user-body-notification">
                                            {
                                                x.channel.length > size ? (
                                                    <div className="g-details-action-user-body-elipsis-container">
                                                        <div className="gap">
                                                            {
                                                                x.channel.slice(0, size).map((i) => (
                                                                    iconChannels(i.type)
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="g-details-action-remaining-elipsis">{`+${x.channels.length - size}`}</div>
                                                    </div>
                                                ) : (
                                                    <div className="g-details-action-user-body-notification">
                                                        {
                                                            x.channel.length > 0 ? (
                                                                <div className="gap">
                                                                    {
                                                                        x.channel.map((i) => (
                                                                            iconChannels(i.type)
                                                                        ))
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="empty">
                                                                    <img loading="lazy" src={AlertIcon} alt="alert-icon" />
                                                                    <div>No Channels Configured</div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="g-details-action-user-remove-button" onClick={() => removeUser(index, x)} onKeyUp={() => removeUser(index, x)} tabIndex="0" role="button" style={{ cursor: 'pointer' }}>Remove</div>
                                    </div>
                                )
                            }
                        </div>

                        ))
                    }
                </div>
                {
                    disabledAddUser && (
                        <div className="g-details-action-user-body-with-above-divider">
                            <div>
                                <Autocomplete autocompleteComponent="Geofence" list={listDropdown} text="Select team member" isImage={false} isAgencySelector={false} inputValue={inputValue} stateInputValue={setInputValue} setSelectionList={setSelectionList} selectionList={selectionList} selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} loadAsset={loading} />
                            </div>
                            <div />
                            <div className="g-details-action-user-remove-button">
                                <div onClick={userCancel} onKeyUp={userCancel} tabIndex="0" role="button" style={{ cursor: 'pointer' }}>Cancel</div>
                                <div className="g-detail-a-edit-btn" onClick={() => saveUser(selectionList)} onKeyUp={() => saveUser(selectionList)} tabIndex="0" role="button" style={{ cursor: 'pointer' }}>Save</div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div>
                <div className="g-details-action-user-button" style={{ cursor: 'pointer' }} role="button" onClick={newUserFunction} onKeyUp={newUserFunction} tabIndex={0}>
                    <img loading="lazy" src={PlusIcon} alt="plus-icon" />
                    <div role="button">Add New User</div>
                </div>
            </div>
        </div>
    );
}
