import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './configurationTab.css';

const Tab = styled.button`
display: flex;
flex-direction: row;
gap: 8px;
padding: 0.0625rem 0.25rem 0.6875rem 0.25rem;
cursor: pointer;
opacity: 0.6;
background: ${(props) => props.color};
border: 0;
outline: 0;
border-bottom: 0.125rem solid transparent;
transition: ease border-bottom 250ms;
${({ active }) => active
&& `
    border-bottom: 0.125rem solid #26404E;
    opacity: 1;
    `
}
`;

const ConfigTab = (props) => {
    const {
        color,
        types,
        users,
        navigtionClick,
        setNavigtionClick,
        agencyRow,
        module,
    } = props;
        const navigate = useNavigate();

        const stateChange = (e, nameValue) => {
            e.preventDefault();
            setNavigtionClick(nameValue);
            // console.log(types.find((x) => x.value === nameValue).path);
            navigate(types.find((x) => x.value === nameValue).path, { replace: true, state: agencyRow });
            // navigate(types[nameValue - 1].path, { replace: true, state: agencyRow });
        };

        const switchModule = (value, type) => {
            switch (value) {
                case 'settings':
                    return (
                        <>
                        {type.text === 'Team' ? (
                            <>
                            <div className="config-tab-text">
                                    {type.text}
                            </div>
                            { users ? (
                                <div className="config-tab-users">
                                    {users}
                                </div>
                            ) : (
                                <>
                                </>
                            )}
                            </>
                        ) : (
                            <div className="config-tab-text">
                                {type.text}
                            </div>
                        )}
                        </>
                    );
                case 'agencies':
                    return (
                        <>
                        {type.text === 'Users' ? (
                            <>
                            <div className="config-tab-text">
                                    {type.text}
                            </div>
                            <div className={`config-tab-${users === 0 ? 'users-empty' : 'users'}`}>
                                {users}
                            </div>
                            </>
                        ) : (
                            <div className="config-tab-text">
                                {type.text}
                            </div>
                        )}
                        </>
                    );
                default:
                    return (
                        <div className="config-tab-text">
                            {type.text}
                        </div>
                    );
            }
        };

    return (
        <>
        <div className="config-tab-button-group">
            {types.map((type) => type.enabled === true && (
                <Tab
                  color={color}
                  key={type.text}
                  active={navigtionClick === type.value}
                  onClick={(e) => stateChange(e, type.value)}
                >
                    {switchModule(module, type)}
                </Tab>
            ))}
        </div>
        </>
    );
};

export default ConfigTab;
