import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import MenuPopup from '../menuPopup/MenuPopup';
import VerticalDots from './img/VerticalDots.svg';
import './notificationConfigTab.css';

const Tab = styled.button`
display: flex;
flex-direction: row;
gap: 8px;
padding: 0.0625rem 0.25rem 0.6875rem 0.25rem;
cursor: pointer;
opacity: 0.6;
background: ${(props) => props.color};
border: 0;
outline: 0;
border-bottom: 0.125rem solid transparent;
transition: ease border-bottom 250ms;
${({ active }) => active
&& `
    border-bottom: 0.125rem solid #26404E;
    opacity: 1;
    `
}
`;

const NotifConfigTab = (props) => {
    const {
        color,
        types,
        unread,
        notifFilter,
        setNotifFilter,
        agencyRow,
        module,
        setData,
        data,
        markAsRead,
        fetchAlerts,
        selectedCheckboxes,
    } = props;
        const navigate = useNavigate();

        const [anchorEl, setAnchorEl] = useState(false);
        const [assetRow, setAssetRow] = useState();

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        const stateChange = (e, nameValue) => {
            e.preventDefault();
            setNotifFilter(nameValue);
            if (nameValue === 'unread') {
                if (data) {
                    const filteredData = data.filter((x) => x.read === false);
                    setData(filteredData);
                }
            }
            if (nameValue === 'all') {
                fetchAlerts();
            }
        };

        const switchModule = (value, type) => {
            switch (value) {
                case 'notifications':
                    return (
                        <>
                        {type.text === 'Unread' ? (
                            <>
                            <div className="notif-config-tab-text">
                                    {type.text}
                            </div>
                            { unread ? (
                                <div className="notif-config-tab-users">
                                    {unread}
                                </div>
                            ) : (
                                <>
                                </>
                            )}
                            </>
                        ) : (
                            <div className="notif-config-tab-text">
                                {type.text}
                            </div>
                        )}
                        </>
                    );
                default:
                    return (
                        <div className="notif-config-tab-text">
                            {type.text}
                        </div>
                    );
            }
        };

    return (
        <>
        <div className="notif-config-tab-button-group">
            <div className="notif-config-tab-buttons">
                {types.map((type) => type.enabled === true && (
                    <Tab
                      color={color}
                      key={type.text}
                      active={notifFilter === type.value}
                      onClick={(e) => stateChange(e, type.value)}
                    >
                        {switchModule(module, type)}
                    </Tab>
                ))}
            </div>
            <div className="notif-config-tab-button" aria-hidden="true" onClick={handleClick}>
                <div className="assets-menu">
                    <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                </div>
            </div>
            <Popover
              sx={{
                boxShadow: 2,
                borderRadius: 2,
            }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
                <MenuPopup page="notifications" markAsRead={markAsRead} selectedCheckboxes={selectedCheckboxes} />
            </Popover>
        </div>
        </>
    );
};

export default NotifConfigTab;
