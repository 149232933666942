import React, { useState, useRef, useEffect } from 'react';
import { CircleFlag } from 'react-circle-flags';
import {
 getTimeZones, rawTimeZones, timeZonesNames, abbreviations,
} from '@vvo/tzdb';
import countryCodes from 'country-codes-list';
import parsePhoneNumber, {
  findPhoneNumbersInText,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import useComponentVisible from '../../config/outsideClick/useComponentVisible';
import AssetIcon from './img/timezone.svg';
import CaretDown from '../../image/caretDown.svg';
import './countriesDropdown.css';

export const CountriesTimezone = (props) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const {
    setTimezoneSelectionList, timezoneSelectionList, text, isImage, timezone, stateInputValue, width, selectedFlag, lowPosition, initialValue, cancelState, timezoneCheck, countryUpdated, setTimezoneValue, timezoneValue,
  } = props;
  const refs = useRef(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [listTimezone, setListTimezone] = useState([]);

    // Pass selected value to autopopulate Timezone component
    useEffect(() => {
      if ((selectedOption === null) || !timezoneValue) {
        if (listTimezone) {
          // eslint-disable-next-line array-callback-return
          const filterListCountry = listTimezone.filter((item, index) => {
            try {
              if (countryUpdated === 0) {
                if (initialValue) {
                  if ((item.name).includes(initialValue)) {
                    setSelectedOption(index);
                    setTimezoneSelectionList(item);
                  }
                }
              } else if ((item.name).includes(initialValue)) {
                  setSelectedOption(index);
                  setTimezoneSelectionList(item);
                }
            } catch (e) {
              console.log('Timezone error:', e);
            }
          });
        }
      }
    }, [selectedOption, initialValue, listTimezone, countryUpdated, timezoneValue]);

  useEffect(() => {
    if (cancelState) {
      if (listTimezone) {
        // eslint-disable-next-line array-callback-return
        const filterListCountry = listTimezone.filter((item, index) => {
          try {
            if (initialValue) {
              if ((item.name).includes(initialValue)) {
                setSelectedOption(index);
                setTimezoneSelectionList(item);
              }
            } else if (!initialValue) {
              setSelectedOption(null);
              setTimezoneSelectionList({});
            }
          } catch (e) {
            console.log('Timezone cancel state error:', e);
          }
        });
      }
    }
  }, [cancelState]);

  // Remove _ from timezones
  const checkUnderscore = () => {
    let nameString;
    if (timezoneSelectionList.name.includes('_')) {
        nameString = timezoneSelectionList.name.replaceAll('_', ' ');
    } else {
        nameString = timezoneSelectionList.name;
    }
    return nameString;
  };

  // this function for toggle display the list
  const toggleOptions = () => {
    setIsOptionsOpen(true);
    setIsComponentVisible(!isComponentVisible);
  };

  // this function for close the dropdown
  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
    setIsComponentVisible(false);
  };

  // this function for list keyDown
  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const refss = listTimezone.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleClick = (id) => refss[id].current.scrollIntoView({
    // behavior: 'smooth',
    block: 'nearest',
  });

  // this function for list keydown
  const handleListKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        setIsOptionsOpen(false);
        setIsComponentVisible(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : listTimezone.length - 1,
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(
          selectedOption === listTimezone.length - 1 ? 0 : selectedOption + 1,
        );
        break;
      case e.key:
          if ((e.key.match(/^[a-zA-Z]*$/)) && (e.key.length === 1)) {
            const stop = listTimezone.filter((res) => res.name.substring(0, 1) === e.key.toUpperCase());
            if (stop.length !== 0) {
              handleClick(stop[0].id);
            }
          }
        break;
      default:
        break;
    }
  };

  // this function for onInput div editable value onChange
  const inputOnchange = (e) => {
    e.preventDefault();
    stateInputValue(e.currentTarget.textContent);
  };

  // Autoselect timezone based on country selected
const filterTimezone = (flag) => {
    const timeZonesWithUtc = getTimeZones({ includeUtc: true });

    // Timezone mapped
    const countryTimezoneMapped = timeZonesWithUtc.map((a) => ({
    id: a.countryCode,
    name: `${a.name} (${a.abbreviation})`,
    description: `UTC${a.currentTimeFormat.substring(
        a.currentTimeFormat.indexOf('+') + 0,
        a.currentTimeFormat.indexOf(' '),
    )}`,
    }));

    // From country dropdown
    const filterState = countryTimezoneMapped.filter((item) => item.id === flag);

    // If timezone is different from country dropdown
    const filterStateDiff = countryTimezoneMapped.filter((item) => (item.name).includes(timezoneCheck));

  if ((filterState[0].id) && (filterStateDiff.length > 0)) {
    // Follow country dropdown
    if (countryUpdated > 0) {
      const timezoneObj = {
          id: filterState[0].id,
          name: filterState[0].name,
          description: filterState[0].description,
      };
      setTimezoneSelectionList(timezoneObj);
    } else {
      // From Get Account API
      const timezoneObj = {
          id: filterStateDiff[0].id,
          name: filterStateDiff[0].name,
          description: filterStateDiff[0].description,
      };
      setTimezoneSelectionList(timezoneObj);
    }
  } else {
    if (filterState.length > 0) {
      // Follow country dropdown
      if (countryUpdated > 0) {
        const timezoneObj = {
            id: filterState[0].id,
            name: filterState[0].name,
            description: filterState[0].description,
        };
        setTimezoneSelectionList(timezoneObj);
      }
    }
    if ((filterStateDiff.length > 0) && timezoneCheck) {
        // From Get Account API
        const timezoneObj = {
            id: filterStateDiff[0].id,
            name: filterStateDiff[0].name,
            description: filterStateDiff[0].description,
        };
        setTimezoneSelectionList(timezoneObj);
    }
}
};

useEffect(() => {
    if (selectedFlag !== '') {
        filterTimezone(selectedFlag);
    }
}, [selectedFlag]);

const populateListTimezone = async () => {
  try {
      const timeZonesWithUtc = getTimeZones({ includeUtc: true });

      // Timezone mapped
      const countryTimezoneMapped = timeZonesWithUtc.map((a) => ({
      id: a.countryCode,
      name: `${a.name} (${a.abbreviation})`,
      description: `UTC${a.currentTimeFormat.substring(
          a.currentTimeFormat.indexOf('+') + 0,
          a.currentTimeFormat.indexOf(' '),
      )}`,
      }));
      const countriesSorted = countryTimezoneMapped.sort((a, b) => {
          if (a.name < b.name) {
          return -1;
          }
          if (a.name > b.name) {
          return 1;
          }
          return 0;
      });
      setListTimezone(countriesSorted);
  } catch (err) {
    console.log('Populate timezone list error: ', err);
  }
};

useEffect(() => { populateListTimezone(); }, []);

  return (
  <div ref={ref} className="selection-wrapper-countriesDropdown">
      <div
        className="selections-container-countriesDropdown"
      >
        <div
          className={isComponentVisible ? 'selection-button-countriesDropdown expanded' : 'selection-button-countriesDropdown'}
          onClick={toggleOptions}
          onKeyDown={handleListKeyDown}
          role="button"
          tabIndex="0"
          style={{
            width: width,
            cursor: 'pointer',
            }}
        >
          {
              Object.keys(timezoneSelectionList).length > 0 ? (
                  <>
                    {isImage && <img src={AssetIcon} alt="asset-icon" />}
                    <div
                      style={{
                        display: 'inline-grid',
                        gridAutoFlow: 'column',
                        maxWidth: '100%',
                        gridAutColumns: '1fr',
                        gap: '8px',
                      }}
                      className="btn-list-container-countriesDropdown"
                      ref={refs}
                      suppressContentEditableWarning
                      contentEditable={!timezone}
                      onInput={(e) => inputOnchange(e)}
                    >
                        <div className="btn-list-agency-name-countriesDropdown">{checkUnderscore()}</div>
                        <div className={timezone ? 'btn-list-agency-id-countriesDropdown' : 'btn-list-asset-id-countriesDropdown'}>
                          {timezoneSelectionList.description}
                        </div>
                    </div>
                  </>
                ) : (
                  <div className="btn-list-container-countriesDropdown">
                      {isImage && <img src={AssetIcon} alt="asset-icon" />}
                      <div className="btn-list-placeholder-countriesDropdown" contentEditable={!timezone} suppressContentEditableWarning onInput={(e) => inputOnchange(e)}>{!isComponentVisible ? text : ''}</div>
                  </div>

                )
              }
        </div>
        <ul
          className={`options-countriesDropdown ${isComponentVisible ? 'show' : ''}`}
          role="listbox"
          aria-activedescendant={listTimezone[selectedOption]}
          tabIndex={-1}
          onKeyDown={handleListKeyDown}
          style={lowPosition ? { bottom: '2.2rem' } : { bottom: '0rem' }}
        >
          {listTimezone.map((option, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`${option.id}${index}`}
              ref={refss[option.id]}
              role="option"
              style={{
                display: 'flex', justifyContent: 'space-between', alignItem: 'center',
              }}
              aria-selected={Object.keys(timezoneSelectionList).length > 0 && selectedOption === index}
              tabIndex={null}
              onKeyDown={handleKeyDown(index)}
              onClick={(e) => {
                setTimezoneSelectionList(option);
                setSelectedThenCloseDropdown(index);
                setTimezoneValue(true);
              }}
            >
              {
                timezone ? (
                <div style={{
                    display: 'flex', alignItem: 'center', gap: '8px', alignSelf: 'stretch', flexGrow: 1,
                    }}
                >
                  <div className="list-agency-countriesDropdown">{option.name.includes('_') ? (option.name.replaceAll('_', ' ')) : (option.name)}</div>
                  <div className="list-agency-id-countriesDropdown">{`${option.description}`}</div>
                </div>
                ) : (
                <div
                  style={{
                    display: 'inline-grid',
                    gridAutoFlow: 'column',
                    maxWidth: '100%',
                    gridAutColumns: '1fr',
                    gap: '8px',
                  }}
                >
                   { option.name && <div className="list-agency-countriesDropdown">{option.name}</div> }
                  { option.id && <div className="list-asset-id-countriesDropdown">{option.id}</div> }
                </div>
                )
              }
            </li>
          ))}
        </ul>
      </div>
  </div>
  );
};

export const CountriesWithFlags = (props) => {
    const {
      ref,
      isComponentVisible,
      setIsComponentVisible,
    } = useComponentVisible(false);
    const {
      setSelectionList, selectionList,
      text,
      isImage,
      stateInputValue,
      width,
      setSelectedFlag,
      lowPosition,
      initialValue,
      cancelState,
      timezoneCheck,
      setCountryUpdated, countryUpdated,
    } = props;
    const refs = useRef(null);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [listCountry, setListCountry] = useState([]); // this is for list search country dropdown
    const [countrySelectionList, setCountrySelectionList] = useState({}); // this for country selection state

    const CircleFlags = (country) => <CircleFlag countryCode={listCountry[selectedOption].id} style={{ width: '1.25rem' }} />;

    // Pass selected value to autopopulate Timezone component
    useEffect(() => {
      if (selectedOption === null) {
        setSelectedFlag(initialValue);
        if (listCountry) {
          // eslint-disable-next-line array-callback-return
          const filterListCountry = listCountry.filter((item, index) => {
            try {
              if (item.id === initialValue) {
                // console.log('aa: ', index);
                setSelectedOption(index);
                setSelectionList(item);
              }
            } catch (e) {
              console.log('Country selector error:', e);
            }
          });
        }
      } else if (selectedOption > 0) {
        setSelectedFlag(listCountry[selectedOption].id);
      }
    }, [selectedOption, initialValue, listCountry, timezoneCheck]);

    useEffect(() => {
      if (cancelState) {
        setSelectedFlag(initialValue);
        if (listCountry) {
          // eslint-disable-next-line array-callback-return
          const filterListCountry = listCountry.filter((item, index) => {
            try {
              if (item.id === initialValue) {
                setSelectedOption(index);
                setSelectionList(item);
              } else if (initialValue === undefined) {
                setSelectedOption(null);
                setSelectionList({});
              }
            } catch (e) {
              console.log('Country selector cancel state error:', e);
            }
          });
        }
      }
    }, [cancelState]);

    // Remove _ from country list
    const checkUnderscore = () => {
      let nameString;
      if (listCountry[selectedOption] && listCountry[selectedOption].name.includes('_')) {
          nameString = listCountry[selectedOption].name.replaceAll('_', ' ');
      } else if (listCountry[selectedOption]) {
          nameString = listCountry[selectedOption].name;
      }
      return nameString;
    };

    // this function for toggle display the list
    const toggleOptions = () => {
      setIsOptionsOpen(!isOptionsOpen);
      setIsComponentVisible(!isComponentVisible);
    };

    // this function for close the dropdown
    const setSelectedThenCloseDropdown = (index) => {
      setSelectedOption(index);
      setIsOptionsOpen(false);
      setIsComponentVisible(false);
    };

    // this function for list keyDown
    const handleKeyDown = (index) => (e) => {
      switch (e.key) {
        case ' ':
        case 'SpaceBar':
        case 'Enter':
          e.preventDefault();
          setSelectedThenCloseDropdown(index);
          break;
        default:
          break;
      }
    };

    const refss = listCountry.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});

    // Function called when a country is clicked
    const handleClick = (id) => refss[id].current.scrollIntoView({
      // behavior: 'smooth',
      block: 'nearest',
    });

    // this function for list keydown
    const handleListKeyDown = (e) => {
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          setIsOptionsOpen(false);
          setIsComponentVisible(false);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedOption(
            selectedOption - 1 >= 0 ? selectedOption - 1 : listCountry.length - 1,
          );
          break;
        case 'ArrowDown':
          e.preventDefault();
          setSelectedOption(
            selectedOption === listCountry.length - 1 ? 0 : selectedOption + 1,
          );
          break;
        case e.key:
            if ((e.key.match(/^[a-zA-Z]*$/)) && (e.key.length === 1)) {
              const stop = listCountry.filter((res) => res.name.substring(0, 1) === e.key.toUpperCase());
              handleClick(stop[0].id);
            }
          break;
        default:
          break;
      }
    };

    // this function for onInput div editable value onChange
    const inputOnchange = (e) => {
      e.preventDefault();
      stateInputValue(e.currentTarget.textContent);
    };

    const populateListCountry = async () => {
      try {
          const myCountryCodesObject = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');
          const countryCodeValues = Object.values(myCountryCodesObject);

          const countriesFlagsMapped = countryCodeValues.map((a) => ({
              id: a.substring(
                  a.indexOf('[') + 1,
                  a.lastIndexOf(']'),
              ),
              name: a.substring(
                  a.indexOf('] ') + 2,
                  a.lastIndexOf(':'),
              ),
              icons: `https://hatscripts.github.io/circle-flags/flags/${a.substring(
                  a.indexOf('[') + 1,
                  a.lastIndexOf(']'),
              )}.svg`,
          }));

           const countriesSorted = countriesFlagsMapped.sort((a, b) => {
               if (a.name < b.name) {
               return -1;
               }
               if (a.name > b.name) {
               return 1;
               }
               return 0;
           });
          setListCountry(countriesSorted);
      } catch (err) {
          console.log('populate country code list error', err);
      }
  };

  useEffect(() => { populateListCountry(); }, []);

    return (
    <div ref={ref} className="selection-wrapper-countriesDropdown">
        <div className="selections-container-countriesDropdown">
          <div
            className={isComponentVisible ? 'selection-button-countriesDropdown expanded' : 'selection-button-countriesDropdown'}
            onClick={toggleOptions}
            onKeyDown={handleListKeyDown}
            role="button"
            tabIndex="0"
            style={{
              width: width,
              cursor: 'pointer',
              }}
          >
            {
                Object.keys(selectionList).length > 0 ? (
                    <>
                      <div
                        style={{
                          display: 'inline-grid',
                          gridAutoFlow: 'column',
                          maxWidth: '100%',
                          gridAutColumns: '1fr',
                          gap: '8px',
                        }}
                        className="btn-list-container-countriesDropdown"
                        ref={refs}
                        suppressContentEditableWarning
                        contentEditable={false}
                        onInput={(e) => inputOnchange(e)}
                      >
                        <CircleFlag countryCode={listCountry[selectedOption].id.toLowerCase()} style={{ width: '1.25rem', height: '1.25rem' }} />
                          <div className="btn-list-agency-name-countriesDropdown">{listCountry[selectedOption].name}</div>
                      </div>
                    </>
                  ) : (
                    <div className="btn-list-container-countriesDropdown">
                        <div className="btn-list-placeholder-countriesDropdown" contentEditable={false} suppressContentEditableWarning onInput={(e) => inputOnchange(e)}>{!isComponentVisible ? text : ''}</div>
                    </div>
                  )
                }
          </div>
          <ul
            className={`options-countriesDropdown ${isComponentVisible ? 'show' : ''}`}
            role="listbox"
            aria-activedescendant={listCountry[selectedOption]}
            tabIndex={-1}
            onKeyDown={handleListKeyDown}
            style={lowPosition ? { bottom: '2.2rem' } : { bottom: '0rem' }}
          >
            {listCountry.map((option, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={`${option.id}${index}`}
                ref={refss[option.id]}
                role="option"
                style={{
                  display: 'flex', justifyContent: 'space-between', alignItem: 'center',
                }}
                aria-selected={Object.keys(selectionList).length > 0 && selectedOption === index}
                tabIndex={null}
                onKeyDown={handleKeyDown(index)}
                onClick={(e) => {
                  setCountryUpdated(countryUpdated + 1);
                  setSelectionList(option);
                  setSelectedThenCloseDropdown(index);
                }}
              >
              <div style={{
                    display: 'flex', alignItem: 'center', gap: '8px', alignSelf: 'stretch', flexGrow: 1, flexDirection: 'row', lineHeight: '1.5rem', alignContent: 'center',
                    }}
              >
                    <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>
                        <CircleFlag countryCode={option.id.toLowerCase()} style={{ width: '1.25rem', height: '1.25rem' }} />
                    </div>
                    <div className="list-agency-countriesDropdown">{option.name}</div>
              </div>
              </li>
            ))}
          </ul>
        </div>
    </div>
    );
};

export const CountriesCallingCode = (props) => {
    const {
      ref,
      isComponentVisible,
      setIsComponentVisible,
    } = useComponentVisible(false);
    const {
      setSelectionList,
      selectionList,
      text, isImage,
      stateInputValue,
      width,
      lowPosition,
      message,
      setMessage,
      disabled,
      value,
      initialValue,
      cancelState,
      setPhoneNumberValue,
      setPhoneNumberInput,
    } = props;
    const refs = useRef(null);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [messageString, setMessageString] = useState('');
    const [phoneInputDisable, setPhoneInputDisable] = useState(false);
    const [listCallingCode, setSelectionListCallingCode] = useState([]);

    useEffect(() => {
      if (listCallingCode) {
        // eslint-disable-next-line array-callback-return
        const filterListCountry = listCallingCode.filter((item, index) => {
          try {
            if (item.value === `+${initialValue}`) {
              setSelectedOption(index);
              setSelectionList(item);
            }
          } catch (e) {
            console.log('Phone number selector error:', e);
          }
        });
      }
    }, [initialValue]);

    useEffect(() => {
      if (cancelState) {
        if (listCallingCode) {
          // eslint-disable-next-line array-callback-return
          const filterListCountry = listCallingCode.filter((item, index) => {
            try {
              if (item.value === `+${initialValue}`) {
                setSelectedOption(index);
                setSelectionList(item);
              }
            } catch (e) {
              console.log('Phone number cancel state error:', e);
            }
          });
        }
      }
    }, [cancelState]);

    const checkUnderscore = () => {
      let nameString;
      if (listCallingCode[selectedOption] && listCallingCode[selectedOption].name.includes('_')) {
          nameString = listCallingCode[selectedOption].name.replaceAll('_', ' ');
      } else if (listCallingCode[selectedOption]) {
          nameString = listCallingCode[selectedOption].name;
      }
      return nameString;
    };

    // this function for toggle display the list
    const toggleOptions = () => {
      setIsOptionsOpen(!isOptionsOpen);
      setIsComponentVisible(!isComponentVisible);
    };

    // this function for close the dropdown
    const setSelectedThenCloseDropdown = (index) => {
      setSelectedOption(index);
      setIsOptionsOpen(false);
      setIsComponentVisible(false);
    };

    // this function for list keyDown
    const handleKeyDown = (index) => (e) => {
      switch (e.key) {
        case ' ':
        case 'SpaceBar':
        case 'Enter':
          e.preventDefault();
          setSelectedThenCloseDropdown(index);
          break;
        default:
          break;
      }
    };

    const listRef = useRef();

    // this function for onInput div editable value onChange
    const inputOnchange = (e) => {
      e.preventDefault();
      stateInputValue(e.currentTarget.textContent);
    };

    const handleChange = (event) => {
        const result = event.target.value.replace(/[a-z,`,!,@,#,$,%,^,&,*,=,-]/gi, '');

        if (selectedOption) {
          if (result.includes(`(${listCallingCode[selectedOption].value})`)) {
            const formattedHP = result.replace(`(${listCallingCode[selectedOption].value})`, '');
            const validationStatus = validatePhoneNumberLength(formattedHP, `${listCallingCode[selectedOption].id}`);
            if (validationStatus === 'TOO_SHORT' || validationStatus === undefined || validationStatus === 'NOT_A_NUMBER') {
              setPhoneNumberValue(formattedHP.replace(`(${listCallingCode[selectedOption].value})`, ''));
              setPhoneNumberInput(true);
              // setMessage(formattedHP.replace(`(${listCallingCode[selectedOption].value})`, ''));
            }
          }
        } else if (result.includes(`(${selectionList.value})`)) {
          const formattedHP = result.replace(`(${selectionList.value})`, '');
          const validationStatus = validatePhoneNumberLength(formattedHP, `${selectionList.id}`);
          if (validationStatus === 'TOO_SHORT' || validationStatus === undefined || validationStatus === 'NOT_A_NUMBER') {
            setPhoneNumberValue(formattedHP.replace(`(${selectionList.value})`, ''));
            setPhoneNumberInput(true);
            // setMessage(formattedHP.replace(`(${selectionList.value})`, ''));
          }
        }
    };

    const refss = listCallingCode.reduce((acc, val) => {
      acc[val.id] = React.createRef();
      return acc;
    }, {});

    const handleClick = (id) => refss[id].current.scrollIntoView({
      block: 'nearest',
    });

    // this function for list keydown
    const handleListKeyDown = (e) => {
      let result;
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        setIsOptionsOpen(false);
        setIsComponentVisible(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : listCallingCode.length - 1,
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(
          selectedOption === listCallingCode.length - 1 ? 0 : selectedOption + 1,
        );
        break;
      case e.key:
          if ((e.key.match(/^[a-zA-Z]*$/)) && (e.key.length === 1)) {
            const stop = listCallingCode.filter((res) => res.name.substring(0, 1) === e.key.toUpperCase());
            handleClick(stop[0].id);
          }
        break;
      default:
        break;
    }
  };

  const populateListCallingCode = () => {
    const myCountryCodesObject = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');
    const countryCodeValues = Object.values(myCountryCodesObject);
  const countriesCCMappedConst = countryCodeValues.map((a, i) => ({
    id: a.substring(
        a.indexOf('[') + 1,
        a.lastIndexOf(']'),
    ),
    name: a.substring(
        a.indexOf('] ') + 2,
        a.lastIndexOf(':'),
    ),
    value: a.substring(
        a.indexOf('+') + 0,
    ),
}));
const countriesCCSorted = countriesCCMappedConst.sort((a, b) => {
   if (a.name < b.name) {
     return -1;
   }
   if (a.name > b.name) {
     return 1;
   }
   return 0;
 });
 setSelectionListCallingCode(countriesCCSorted);
};

useEffect(() => { populateListCallingCode(); }, []);

    return (
    <div className="calling-code-container">
    <div className="inner">
        <div className="full-height">
    <div ref={ref} className="selection-wrapper-countriesDropdown">
        <div className="selections-container-countriesDropdown">
          <div
            className={isComponentVisible ? 'selection-button-countriesDropdown-cc expanded' : 'selection-button-countriesDropdown-cc'}
            onClick={toggleOptions}
            onKeyDown={handleListKeyDown}
            role="button"
            tabIndex="0"
            style={disabled ? {
              pointerEvents: 'none',
              boxShadow: 'none',
              borderTopRightRadius: '0rem',
              borderBottomRightRadius: '0rem',
              background: '#F9FAFB',
              border: 'none',
              height: 'auto',
              color: '#667085',
            } : {}}
          >
            {
                Object.keys(selectionList).length > 0 ? (
                    <>
                      <div
                        style={{
                          display: 'inline-grid',
                          gridAutoFlow: 'column',
                          maxWidth: '100%',
                          gridAutColumns: '1fr',
                          gap: '8px',
                        }}
                        className="btn-list-container-countriesDropdown"
                        ref={refs}
                        suppressContentEditableWarning
                        contentEditable={false}
                        onInput={(e) => inputOnchange(e)}
                      >
                            <div className="btn-list-agency-name-countriesDropdown">{selectedOption ? listCallingCode[selectedOption].id : selectionList.id}</div>
                      </div>
                    </>
                  ) : (
                    <div className="btn-list-container-countriesDropdown">
                        <div className="btn-list-placeholder-countriesDropdown" contentEditable={false} suppressContentEditableWarning onInput={(e) => inputOnchange(e)}>{!isComponentVisible ? text : ''}</div>
                    </div>
                  )
                }
          </div>
          <ul
            ref={listRef}
            className={`options-countriesDropdown ${isComponentVisible ? 'show' : ''}`}
            role="listbox"
            aria-activedescendant={listCallingCode[selectedOption]}
            tabIndex={-1}
            onKeyDown={handleListKeyDown}
            style={lowPosition ? { bottom: '2.2rem' } : { backgroundColor: '#FFFFFF' }}
          >
            {listCallingCode.map((option, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={`${option.id}${index}`}
                ref={refss[option.id]}
                role="option"
                style={{
                  display: 'flex', justifyContent: 'space-between', alignItem: 'center',
                }}
                aria-selected={Object.keys(selectionList).length > 0 && selectedOption === index}
                tabIndex={null}
                onKeyDown={handleKeyDown(index)}
                onClick={(e) => {
                  setSelectionList(option);
                  setSelectedThenCloseDropdown(index);
                }}
              >
              <div style={{
                    display: 'flex', alignItem: 'center', gap: '8px', alignSelf: 'stretch', flexGrow: 1, flexDirection: 'row', lineHeight: '1.5rem', alignContent: 'center',
                    }}
              >
                    <div className="list-agency-countriesDropdown">{option.name}</div>
                    <div className="list-agency-id-countriesDropdown-cc">{option.value}</div>
              </div>
              </li>
    ))}
          </ul>
        </div>
    </div>
        </div>
    </div>
    <input
      name="assetName"
      value={selectedOption ? `(${listCallingCode[selectedOption].value})${value}` : `(${selectionList.value})${value}`}
      className="callingcode-input-box"
      onChange={handleChange}
      style={disabled ? {
        pointerEvents: 'none',
        boxShadow: 'none',
        borderTopLeftRadius: '0rem',
        borderBottomLeftRadius: '0rem',
        background: '#F9FAFB',
        border: 'none',
        color: '#667085',
      } : {}}
    />
    </div>
    );
};
