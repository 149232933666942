/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
    useEffect, useState, useCallback, useMemo,
} from 'react';
import {
    Box,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import {
    useTransition,
    animated,
    AnimatedProps,
    useSpringRef,
} from '@react-spring/web';
import moment from 'moment';
import { BackButton, CreateWithIcon, PrimaryButton } from '../buttonComponent/ButtonComponent';
import OriginIcon from './img/origin.svg';
import DestinationIcon from './img/destination.svg';
import DistanceIcon from './img/distance.svg';
import ConnectorIcon from './img/connector.svg';
import ClockIcon from './img/clock.svg';
import TruckIcon from './img/truck.svg';
import CompletedBadge from './img/completed.svg';
import OngoingBadge from './img/ongoing.svg';
import './TripReportList.css';

function parseDuration(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    hours = (hours < 10) ? `0${hours}` : hours;
    minutes = (minutes < 10) ? `0${minutes}` : minutes;
    remainingSeconds = (remainingSeconds < 10) ? `0${remainingSeconds}` : remainingSeconds;

    // return (
    //     `${hours > 0 ? `${hours > 1 ? `${hours} hours` : `${hours} hour`} ` : ''} ${minutes > 0 ? `${minutes > 1 ? `${minutes} minutes` : `${minutes} minute`} ` : ''} ${remainingSeconds > 0 ? `${remainingSeconds > 1 ? `${remainingSeconds} seconds` : `${remainingSeconds} second`} ` : ''}`
    // );
    return `${hours}:${minutes}:${remainingSeconds}`;
}

function parseReadableDuration(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    hours = (hours < 10) ? `${hours}` : hours;
    minutes = (minutes < 10) ? `${minutes}` : minutes;
    remainingSeconds = (remainingSeconds < 10) ? `${remainingSeconds}` : remainingSeconds;

    // return hours + ':' + minutes + ':' + remainingSeconds;
    return (
        `${hours > 0 ? `${hours > 1 ? `${Math.round(hours)} hours` : `${Math.round(hours)} hour`} ` : ''} ${minutes > 0 ? `${minutes > 1 ? `${Math.round(minutes)} minutes` : `${Math.round(minutes)} minute`} ` : ''} ${remainingSeconds > 0 ? `${remainingSeconds > 1 ? `${Math.round(remainingSeconds)} seconds` : `${Math.round(remainingSeconds)} second`} ` : ''}`
    );
    // return `${hours}:${minutes}:${remainingSeconds}`;
}

export default function TripReportList(props) {
    const {
        tripList,
        setTripList,
        tripDetailsData,
        setTripDetailsData,
        page,
        setPage,
        onClick,
        hoverTripDetail,
        setHoverTripDetail,
        tripData,
        dateRange,
        setDateRange,
        hoverInfo,
        setHoverInfo,
    } = props;

    const transitions = useTransition(page, {
        keys: null,
        from: {
            opacity: 0,
            transform: page === 1 ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)',
            // transform: 'translate3d(0%,0,0)',
            position: 'absolute',
        },
        enter: {
            opacity: 1,
            position: 'absolute',
            // transform: 'translate3d(0%,0,0)',
            transform: page === 1 ? 'translate3d(-0%,0,0)' : 'translate3d(0%,0,0)',
        },
        leave: {
            opacity: 0,
            position: 'absolute',
            transform: page === 1 ? 'translate3d(-50%,0,0)' : 'translate3d(50%,0,0)',
            // transform: 'translate3d(-50%,0,100px)',
        },
        // exitBeforeEnter: true,
    });

    const [connectorHeight, setConnectorHeight] = useState(0);

    const handleClickRow = (event, value) => {
        setTripDetailsData(value);
        setPage((page + 1) % 2);
    };

    const onHover = useCallback((event, index) => {
        setHoverInfo({
            id: index && index,
        });
    }, []);

    const handleLeave = useCallback((event, value) => {
        setHoverInfo(value);
    }, []);

    const renderRow = (values) => {
        const {
            index, style, data,
        } = values;

        const tripReport = data[index];

        const updateStyle = {
            ...style,
            borderBottom: '0.063rem solid #EAECF0',
        };

        return (
            <ListItem style={updateStyle} key={index} component="div" disablePadding>
                <ListItemButton
                  style={{ height: '100%', padding: '0px' }}
                  onClick={(event) => handleClickRow(event, tripReport)}
                  onMouseMove={(event) => onHover(event, index + 1)}
                  onMouseLeave={(event) => handleLeave(event, undefined)}
                >
                    <div className="tripList-row">
                        <div className="tripList-origin-road-container">
                            {index + 1}
                        </div>
                        <div className="tripList-icons">
                            <img loading="lazy" src={OriginIcon} alt="origin-icon" />
                            <div className="connector-icon" style={{ display: 'flex' }}>
                                <img loading="lazy" src={ConnectorIcon} alt="connector-icon" />
                            </div>
                            <img loading="lazy" src={DestinationIcon} alt="destination-icon" />
                        </div>
                        <div className="tripList-origin-destination">
                            <div className="tripList-origin-container">
                                <div
                                  className="tripList-origin-road-container"
                                  style={{
                                    maxWidth: '14.5rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                  }}
                                >
                                    {tripReport.origin ? tripReport.origin : '-'}
                                </div>
                                <div className="tripList-origin-timestamp-container">
                                    {moment(tripReport.startTime).format('h:mm:ss a, MMMM D YYYY')}
                                </div>
                            </div>
                            <div className="tripList-destination-container">
                                <div
                                  className="tripList-destination-road-container"
                                  style={{
                                    maxWidth: '14.5rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                  }}
                                >
                                    {tripReport.destination ? tripReport.destination : '-'}
                                </div>
                                <div className="tripList-destination-timestamp-container">
                                    {moment(tripReport.endTime).format('h:mm:ss a, MMMM D YYYY')}
                                </div>
                            </div>
                        </div>
                    </div>
                </ListItemButton>
            </ListItem>
        );
    };

    const TripDetailsCard = () => {
        return (
            <div className="tripDetails-container">
                <div className="tripDetails-container-body">
                    <div className="tripDetails-device-header">
                        <div className="tripDetails-title-container">
                            <div className="tripDetails-deviceName-container">
                                <img loading="lazy" src={TruckIcon} alt="truck-icon" />
                                <div className="tripDetails-deviceSN">{tripDetailsData?.assetName ? tripDetailsData.assetName : '-'}</div>
                                {tripDetailsData?.status === 'Completed' ? (
                                    <img loading="lazy" src={CompletedBadge} alt="completed-badge" />
                                ) : (
                                    <img loading="lazy" src={OngoingBadge} alt="ongoing-badge" />
                                )}
                            </div>
                            {tripDetailsData?.status === 'Ongoing' && (
                            <div className="tripDetails-CTA-button-container">
                                <CreateWithIcon module="trips" onClickFunction={() => { }} disable={tripDetailsData?.status !== 'Ongoing'} backgroundColor="#FFFFFF" color="#344054" buttonHeight="24px" />
                            </div>
                            )}
                        </div>
                        <div className="tripDetails-deviceSN-container">
                            {tripDetailsData?.deviceSerial ? `Device ${tripDetailsData.deviceSerial}` : '-'}
                        </div>
                    </div>
                    <div className="tripDetails-separator" />
                    <div className="tripList-tripDetails-row">
                        <div className="tripList-icons">
                            <img loading="lazy" src={OriginIcon} alt="origin-icon" />
                            <div className="connector-icon" style={{ display: 'flex' }}>
                                <img loading="lazy" src={ConnectorIcon} alt="connector-icon" style={{ height: `${connectorHeight - 16}px`, width: '3px' }} />
                            </div>
                            <img loading="lazy" src={DestinationIcon} alt="destination-icon" />
                        </div>
                        <div className="tripList-origin-destination">
                            <div className="tripList-origin-container" id="origin-container">
                                <div className="tripList-origin-road-container">
                                    {tripDetailsData?.origin ? tripDetailsData.origin : '-'}
                                </div>
                                <div className="tripList-origin-timestamp-container">
                                    {moment(tripDetailsData?.startTime).format('h:mm:ss a, MMMM D YYYY')}
                                </div>
                            </div>
                            <div className="tripList-destination-container" id="destination-container">
                                <div className="tripList-destination-road-container">
                                    {tripDetailsData?.destination ? tripDetailsData.destination : '-'}
                                </div>
                                <div className="tripList-destination-timestamp-container">
                                    {moment(tripDetailsData?.endTime).format('h:mm:ss a, MMMM D YYYY')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tripDetails-separator" />
                    <div className="tripDetails-distance-duration-container">
                        <div className="tripList-tripDetails-distance-container" style={{ gap: '1rem' }}>
                            <img loading="lazy" src={ClockIcon} alt="distance-icon" />
                            <div className="tripList-tripDetails-distance-text-container">
                                <div className="tripList-tripDetails-distance-text">
                                    Trip Duration
                                </div>
                                <div className="tripList-tripDetails-text">
                                    {`${parseReadableDuration(tripDetailsData?.totalDuration)}`}
                                </div>
                            </div>
                        </div>
                        <div className="tripList-tripDetails-distance-container" style={{ gap: '1rem' }}>
                            <img loading="lazy" src={DistanceIcon} alt="distance-icon" />
                            <div className="tripList-tripDetails-distance-text-container">
                                <div className="tripList-tripDetails-distance-text">
                                    Distance Travelled
                                </div>
                                <div className="tripList-tripDetails-text">
                                    {`${tripDetailsData?.totalDistance / 1000} km`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (dateRange) {
            const filteredTrips = tripList.filter((x) => moment(x.startTime).valueOf() > dateRange.min && moment(x.startTime).valueOf() <= dateRange.max);
            setTripList(filteredTrips);
        }
    }, [dateRange]);

    useEffect(() => {
        const origin = document.getElementById('origin-container');
        const destination = document.getElementById('destination-container');
        if (origin && destination) {
            const getPositionAtCenter = (element) => {
                const rect = element.getBoundingClientRect();
                const {
                    top, left, width, height,
                } = rect;

                return {
                    x: left + width / 2,
                    y: top + height / 2,
                };
            };

            const getDistanceBetweenElements = (a, b) => {
                const aPosition = getPositionAtCenter(a);
                const bPosition = getPositionAtCenter(b);

                return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
            };

            const distance = getDistanceBetweenElements(origin, destination);
            setConnectorHeight(distance);
        }
    }, [page]);

    return (
        <div className="tripList-container">
            <div className="transition-container">
                {page === 0 ? (
                    <>
                        <div className="tripList-header">
                            Trips Activity
                        </div>
                        <FixedSizeList
                          height={396}
                          width={388}
                          itemSize={132}
                          itemData={tripList}
                          itemCount={tripList.length}
                          overscanCount={1}
                        >
                            {renderRow}
                        </FixedSizeList>
                    </>
                ) : (
                    <>
                        <div className="tripList-header">
                            <BackButton label="Back" btnClickBack={onClick} color="#F9FAFB" />
                        </div>
                        <TripDetailsCard />
                    </>
                )}
                {/* {transitions((styles, item) => {
                    return item === 0 ? (
                        <animated.div
                          style={{
                            ...styles, overflow: 'hidden', height: '100%', width: '100%',
                          }}
                        >
                            <div className="tripList-header">
                                Trips Activity
                            </div>
                            <FixedSizeList
                              height={396}
                              width={388}
                              itemSize={132}
                              itemData={tripList}
                              itemCount={tripList.length}
                              overscanCount={1}
                            >
                                {renderRow}
                            </FixedSizeList>
                        </animated.div>
                    ) : (
                        <animated.div
                          style={{
                            ...styles, overflow: 'hidden', height: '100%', width: '100%',
                          }}
                        >
                            <div className="tripList-header">
                                <BackButton label="Back" btnClickBack={onClick} color="#F9FAFB" />
                            </div>
                            <TripDetailsCard />
                        </animated.div>
                    ); */}
                {/* })} */}
            </div>
        </div>
    );
}
