import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import ApexCharts from 'apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material';
import { OutlinedButton, CreateWithIcon } from '../buttonComponent/ButtonComponent';
import './Drawer.css';

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#FFFFFF',
        },
    },
});

const Drawer = (props) => {
    const {
        show, resetButtonState, handleToggleDrawer, tripData, tripChart, setTripChart, setDateRange, setTripList, resetChart,
    } = props;

    const [loading, setLoading] = useState(false);

    function parseDuration(seconds) {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainingSeconds = seconds % 60;

        hours = (hours < 10) ? `${hours}` : hours;
        minutes = (minutes < 10) ? `${minutes}` : minutes;
        remainingSeconds = (remainingSeconds < 10) ? `${remainingSeconds}` : remainingSeconds;

        // return hours + ':' + minutes + ':' + remainingSeconds;
        return (
            `${hours > 0 ? `${hours > 1 ? `${Math.round(hours)} hrs` : `${Math.round(hours)} hr`} ` : ''} ${minutes > 0 ? `${minutes > 1 ? `${Math.round(minutes)} mins` : `${Math.round(minutes)} min`} ` : ''} ${remainingSeconds > 0 ? `${remainingSeconds > 1 ? `${Math.round(remainingSeconds)} s` : `${Math.round(remainingSeconds)} s`} ` : ''}`
        );
        // return `${hours}:${minutes}:${remainingSeconds}`;
    }

    // const dataTest = tripData.tripsPerDay.map((x) => [`${x.timestamp}000`, x.totalTripPerDay]);

    const chartData = {
        options: {
            chart: {
                id: 'trips-bar-Chart',
                width: '100%',
                toolbar: {
                    show: false,
                },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                fontFamily: 'Inter',
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    },
                    zoomed: (chart, { xaxis }) => {
                        const zoomedXmin = xaxis.min;
                        const zoomedXmax = xaxis.max;
                        const zoomedData = tripChart.filter((point) => point[0] >= zoomedXmin && point[0] <= zoomedXmax);
                        setTripChart(zoomedData);
                        setDateRange(xaxis);
                    },
                },
            },
            fill: {
                colors: ['#73A2BA'],
            },
            legend: {
                show: false,
            },
            xaxis: {
                type: 'datetime',
            },
            tooltip: {
                x: {
                  format: 'dd MMM yyyy',
                },
              },
              dataLabels: {
                enabled: false,
              },
        },
        series: [
            {
                name: 'Trips',
                data: tripChart,
            },
        ],
    };

    return (
        <div className="drawer-container" style={show ? { height: '7rem' } : { height: '22rem' }}>
            <div className="drawer-header">
                <div className="drawer-header-mainText">
                    Total Trips
                    <div className="drawer-header-textValue">
                        {tripData.tripList.length}
                    </div>
                </div>
                <div className="drawer-header-mainText">
                    Total Travel Distance
                    <div className="drawer-header-textValue">
                        {`${Math.round(((tripData.totalDistance / 1000) + Number.EPSILON) * 100) / 100} km`}
                    </div>
                </div>
                <div className="drawer-header-mainText">
                    Total Trip Duration
                    <div className="drawer-header-textValue">
                        {parseDuration(tripData.totalDuration)}
                    </div>
                </div>
                <div className="drawer-header-mainText">
                    Average Travel Distance
                    <div className="drawer-header-textValue">
                        {`${Math.round(((tripData.avgDistance / 1000) + Number.EPSILON) * 100) / 100} km`}
                    </div>
                </div>
                <div className="drawer-header-mainText">
                    Average Trip Duration
                    <div className="drawer-header-textValue">
                        {parseDuration(tripData.avgDuration)}
                    </div>
                </div>
                <div className="drawer-header-buttonGroup">
                    <OutlinedButton label="Reset" onClickFunction={() => resetChart()} disable={resetButtonState} />
                    <CreateWithIcon label="Trip Timeline" module="tripsTimeline" backgroundColor="#FFFFFF" color="#344054" onClickFunction={handleToggleDrawer} show={show} />
                </div>
            </div>
            {!show && (
                <div className="drawer-body">
                    <Chart
                      options={chartData.options}
                      series={chartData.series}
                      type="bar"
                      height="100%"
                      style={{ width: '100%' }}
                    />
                </div>
            )}
        </div>
    );
};

export default Drawer;
