import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import { useApiData } from '../../../config/reducer/Modules';
// import { inventoryNavigationList } from '../../../config/function/tabNavigationList';
import { APIGetSIMDetails } from '../../../config/restAPI/FetchAPI';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import Title from '../../../component/title/Title';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import TruckIcon from '../../../image/truckIcon.svg';
import SearchInput from '../../../component/searchInput/SearchInput';
import ConnectivityTableEmptyState from '../../../component/tableEmptyState/ConnectivityTableEmptyState';
import Table from '../../../component/table/Table';
import ConfigTab from '../../../component/configurationTab/configurationTab';
import './connectivity.css';

export default function Connectivity() {
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const { apiResponse } = useApiData();

    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchText, setSearchText] = useState('');

    // Table state
    const defautlParams = { pageNumber: 1 }; // this to default params for fetchAPI
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(true);
    const [emptyTable, setEmptyTable] = useState(false);
    const [connectivityData, setConnectivityData] = useState([]);

    // Connectivity Table
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const inventoryNavigationList = [
        {
            text: 'Assets & Devices', value: 1, path: '/inventory/assetdevices', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.asset,
        },
        // {
        // text: 'Beacons', value: 2, path: '/inventory/beacons', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.beacon,
        // },
        // {
        //     text: 'Geofence', value: 3, path: '/inventory/geofence', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.geofence,
        // },
        {
            text: 'Connectivity', value: 4, path: '/inventory/connectivity', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.connectivity,
        },
    ];

    const navigationList = [
        {
            text: 'SIMs',
            value: 4,
            path: '/inventory/connectivity',
            enabled: true,
        },
    ];

    const fetchSIMDetails = async (params) => {
        try {
            const result = await APIGetSIMDetails(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data, totalData } = result.data;
                if (data && data.length > 0) {
                    const reducer = data.map((x) => ({
                        id: x.id ? x.id : '-',
                        iccid: x.iccid ? x.iccid : '-',
                        activated: x.activated ? x.activated : false,
                        connector: x.connector ? x.connector : '-',
                        dataUsage: x.dataUsage ? (x.dataUsage / 1000000).toFixed(1) : '-',
                        dataLimit: x.dataLimit ? x.dataLimit / 1000000 : 50,
                        deviceSerial: x.deviceSerial ? x.deviceSerial : '-',
                        isOnline: x.isOnline ? x.isOnline : false,
                        label: x.label ? x.label : '-',
                        lastOnline: {
                            date: x.lastOnline !== null ? (moment(x.lastOnline).format('DD MMM YYYY')) : (null),
                            time: x.lastOnline !== null ? (`${moment(x.lastOnline).format('h:mm:ssa')} GMT${moment(x.lastOnline).format('ZZ').replace('00', '').replace('0', '')}`) : (null),
                        },
                        lifeTimeUsage: x.lifeTimeUsage ? x.lifeTimeUsage : '-',
                        network: x.network ? x.network : '-',
                        networkWhitelist: x.networkWhitelist ? x.networkWhitelist : '-',
                        technologies: x.technologies ? x.technologies : {},
                    }));
                    setEmptyTable(false);
                    setShowTable(true);
                    setConnectivityData(reducer);
                    setTotalRows(totalData);
                } else {
                    setEmptyTable(true);
                    setShowTable(false);
                    setConnectivityData([]);
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    // this onChange for search text
    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    // click next button in table
    const handlePageChange = (page) => {
        const pagePerNumber = {
            pageNumber: page,
        };
        fetchSIMDetails(pagePerNumber);
    };

    // this useEffect for onPageLoad
    useEffect(() => {
        if (token) {
            fetchSIMDetails(defautlParams);
        }
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        setNavigtionClick(4);
        setNavigationState('inventory');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        setSearchTimeout(
            setTimeout(() => {
                const searchingValue = {
                    searchKey: searchText,
                    pageNumber: 1,
                };
                fetchSIMDetails(searchingValue);
            }, 200),
        );
        return () => clearTimeout(searchTimeout);
    }, [searchText]);

    return (
        <div className="connectivity-container">
            <ListNavigationTab list={inventoryNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="connectivity-header">
                <Title title="Connectivity" subtitle="Manage your asset connectivity with VERGE IoT SIMs through Horizon in a single pane of glass" titleSize="1.875rem" />
                <div className="connectivity-tabs">
                    <ConfigTab color="#FFFF" types={navigationList} module="assetPerformance" navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="connectivity-body">
                <div className="connectivity-body-filter">
                    <div className="connectivity-bulk-button-container">
                        {/* <BulkActionButton label="Bulk action" width="8.1875rem" color="white" disable={bulkActionDisabled} /> */}
                        <SearchInput containerWidth="25rem" text="Search" containerHeight="22px" value={searchText} onChangeText={onChangeSearchText} />
                    </div>
                </div>
                {showTable && (
                    <div className={loading ? 'connectivity-body-text-loading' : 'connectivity-body-text'}>
                        <Table
                          selectedRowCount={selectedRowCount}
                          setSelectedRowCount={setSelectedRowCount}
                          tableData={connectivityData}
                          pagination
                          module="connectivity"
                          handlePageChange={handlePageChange}
                          loading={loading}
                          totalRows={totalRows}
                          backgroundColor="#F9FAFB"
                        />
                    </div>
                )}
                {emptyTable && (
                    <div className="connectivity-body-empty">
                        <ConnectivityTableEmptyState />
                    </div>
                )}
            </div>
        </div>
    );
}
