import React, { useState, useRef } from 'react';
import moment from 'moment';
import Checkbox from '../checkbox/Checkbox';
import EnterIcon from './img/enter.svg';
import ColoredLine from '../coloredLine/ColoredLine';
import UnreadIcon from './img/unread.svg';
import GeofenceAlertIcon from './img/geofenceAlert.svg';
import './notificationList.css';

const NotificationList = (props) => {
    const {
        data, selectedCheckboxes, setSelectedCheckboxes, handleChecked,
    } = props;

    const checkAlertType = (type) => {
        switch (type) {
            case 101:
                return 'Geofence Alert';
            default:
                return null;
        }
    };

    return (
        <div className="notificationList-body">
            {data && data.map((x, index) => (
                <>
                    <div className="notification-feed">
                        <Checkbox checked={!!selectedCheckboxes[x.oid]} width="20px" height="20px" onClick={() => handleChecked(x.oid)} value={x.oid} />
                        <div className="notificationFeed-container">
                            <div className="notificationFeed-body">
                                <div className="notificationFeed-body-header">
                                    {x.read === false && (
                                        <img src={UnreadIcon} alt="unread-icon" loading="lazy" className="unread-icon" />
                                    )}
                                    <img src={GeofenceAlertIcon} alt="enter-icon" loading="lazy" />
                                    <div className="notification-type">
                                        {checkAlertType(x.type)}
                                    </div>
                                    <div className="notification-time">
                                        {moment(x.timestamp).fromNow()}
                                    </div>
                                </div>
                                <div className="notificationFeed-body-content">
                                    <div className="notificationFeed-body-text-container">
                                        <div className="notificationFeed-body-text">
                                            {x.message}
                                        </div>
                                        {x.areaName && (
                                            <div className="notificationFeed-body-text">
                                                {`Area Name: ${x.areaName}`}
                                            </div>
                                        )}
                                        {x.geofenceName && (
                                            <div className="notificationFeed-body-text">
                                                {`Geofence Name: ${x.geofenceName}`}
                                            </div>
                                        )}
                                        <div className="notificationFeed-body-text">
                                            {`Agency Local Time: ${moment(x.timestamp).format('DD MMM YYYY h:mm a')}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ColoredLine />
                </>
            ))}
        </div>
    );
};

export default NotificationList;
