/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
 BrowserRouter as Router, Redirect, Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import './error-page.css';
import Title from '../../component/title/Title';
import { PrimaryButton, SecondaryButton, ErrorPageRedirectButton } from '../../component/buttonComponent/ButtonComponent';
import peep404 from '../../image/shaun.svg';
import peep500 from '../../image/farhan.svg';
import peep403 from '../../image/melissa.svg';
import MapOverview from '../../panel/live/mapOverview/MapOverview';

export default function ErrorPage({ history }) {
    const token = Cookies.get('jwtToken');

    const location = useLocation();

    const navigate = useNavigate();

    const errorCode = location.pathname;

    // console.log('HISTORY: ', location.pathname);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());

    function handleWindowResize() {
        setWindowSize(getWindowSize());
    }

    const navigateBack = (e) => {
        e.preventDefault();
        // console.log('back pressed');
        if (errorCode.includes(404)) {
            navigate(-3, { replace: true });
        } else if (errorCode.includes(403)) {
            navigate(-1, { replace: true });
        } else if (errorCode.includes(500)) {
            navigate(-3, { replace: true });
        }
    };

    const navigateDashboard = (e) => {
        e.preventDefault();
        navigate('/live/mapOverview', { replace: true });
    };

    useEffect(() => {
            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
    }, []);

    function populateErrorObject() {
        let updatedValue = {
            code: '',
            title: '',
            subtitle: '',
            peep: '',
            peepWidth: '',
            peepHeight: '',
        };
        if (errorCode.includes(404)) {
            updatedValue = {
                code: '404 error',
                title: 'Page not found',
                subtitle: 'Sorry, the page you are looking for doesn\'t exist.',
                peep: peep404,
                peepWidth: '32rem',
                peepHeight: '43.188rem',
            };
        } else if (errorCode.includes(500)) {
            updatedValue = {
                code: '500 error',
                title: 'Unexpected error',
                subtitle: 'Yikes, this is our bad! Our engineers have been notified and will begin exterminating the bugs.',
                peep: peep500,
                peepWidth: '29.688rem',
                peepHeight: '40.063rem',
            };
        } else if (errorCode.includes(403)) {
            updatedValue = {
                code: '403 error',
                title: 'Forbidden',
                subtitle: 'You don\'t have permission to access this page. You\'ve wandered too far off your quest.',
                peep: peep403,
                peepWidth: '27.375rem',
                peepHeight: '36.938rem',
            };
        }
        return updatedValue;
    }

    return (
        // <div className="error-page-container-main">
            <div className="error-page-container" style={{ height: windowSize.innerHeight, width: windowSize.innerWidth }}>
                <div className="error-page-left-content">
                    <div className="error-page-header">
                        <div className="error-page-heading">
                            {populateErrorObject().code}
                        </div>
                        <Title
                          title={populateErrorObject().title}
                          subtitle={populateErrorObject().subtitle}
                          titleSize="3.75rem"
                          subtitleSize="1.25rem"
                          gap="2rem"
                          subtitleLineHeight="1.875rem"
                        />
                    </div>
                    <div className="error-page-buttons">
                        <SecondaryButton label="Go back" width="8.25rem" imageIcon="Back" onClickFunction={navigateBack} />
                        <PrimaryButton label="Country roads, take me home..." width="17.625rem" onClickFunction={navigateDashboard} />
                    </div>
                    <div className="error-page-resources">
                        {/* <ErrorPageRedirectButton label="Documentation" description="Dive in to learn all about our product." /> */}
                        {/* <ErrorPageRedirectButton label="Our blog" description="Read the latest posts on our blog." /> */}
                        <ErrorPageRedirectButton label="Contact support" description="Our friendly team is here to help." />
                    </div>
                </div>
                <div className="error-page-right-content">
                    <div className="error-page-image-container">
                        <img loading="lazy" src={populateErrorObject().peep} className="error-page-image" alt="peep" style={{ width: populateErrorObject().peepWidth, height: populateErrorObject().peepHeight }} />
                    </div>

                </div>
            </div>
        // </div>
    );
}
