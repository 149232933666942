import React, { useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { AuthContext } from '../../config/reducer/Auth';
import { APIRequestResetPassword } from '../../config/restAPI/PostAPI';
import ImgWithTitle from '../../component/workspaceContainer/ImgWithTitle';
import Text from '../../component/text/Text';
import { TextButton, BackButton, PrimaryButton } from '../../component/buttonComponent/ButtonComponent';
import SnackBar from '../../component/snackbar/Snackbar';
import './reset-password.css';

const CheckYourEmail = ({ history }) => {
    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    const token = Cookies.get('jwtToken');
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    // console.log(state);
    const [emailSent, setEmailSent] = useState(false);
    const [msgTitle, setMsgTitle] = useState('');
    const [msgSubt, setMsgSubt] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [input, setInput] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);

    const btnBack = (e) => {
        e.preventDefault();
        navigate('/login', { replace: true });
    };
    const btnInvalid = async (e) => {
        e.preventDefault();
        try {
            const result = await APIRequestResetPassword(state);
            if (result.data.status === 200) {
                setMsgTitle('Email sent');
                setMsgSubt('Please check your email app with instructions for resetting your password.');
                setEmailSent(true);
            }
        } catch (err) {
            console.log('Error from input');
        }
    };

    const openEmailApp = (e) => {
        e.preventDefault();
        window.open('mailto:email@example.com');
    };

    const btnSetNewPassword = (e) => {
        e.preventDefault();
        // navigate({ pathname: '/setNewPassword', search: '/U2FsdGVkX1/qJ6S+Y22z1rbv5Bp1/5J/LnIuzQDSbyMvr1PzFWpPQUWH1ynv3HZj' });
    };

    return (
    <>
    {isMobileOrTablet && (
        <section className="forgotPW-mainMobile">
            <div className="forgot-password-container-main-mobile">
                <div className="forgot-password-content-mobile">
                    <ImgWithTitle titleText="Check your email" subText="If the email you've entered exists in our system, we've sent instructions for resetting your password. Be sure to check your spam folder!" icon="Insights" style={{ width: '100%' }} />
                    <PrimaryButton label="Open email app" onClickFunction={openEmailApp} />
                    <div className="text-button-row" style={{ width: '100%' }}>
                        <Text className="login-row-text" color="#475467" size="0.875rem" weight="400" text="Didn't receive the email?" align="left" />
                        <TextButton label="Click to resend" onClickFunction={btnInvalid} fontWeight={600} color="#26404E" />
                    </div>
                    <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                </div>
            </div>
        </section>
    )}
    {isDefault && (
        <div className="forgot-password-container-main">
            <div className="forgot-password-container">
                <ImgWithTitle titleText="Check your email" subText="If the email you've entered exists in our system, we've sent instructions for resetting your password. Be sure to check your spam folder!" icon="Insights" />
                <div className="text-button-row">
                    <Text className="login-row-text" color="#475467" size="0.875rem" weight="400" text="Didn't receive the email?" align="left" />
                    <TextButton label="Click to resend" onClickFunction={btnInvalid} fontWeight={600} color="#26404E" />
                </div>
            <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
            </div>
        {emailSent && (
            <div className="notification">
                 <SnackBar state={emailSent} setState={setEmailSent} title={msgTitle} subtitile={msgSubt} module="Email" action="info" />
            </div>
        )}
        </div>
    )}
    </>
  );
};

export default CheckYourEmail;
