/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import './otpInput.css';

const RE_DIGIT = new RegExp(/^\d+$/);

export default function OtpInput({
 value, valueLength, setOtpValue, setKeyUp, keyUp,
}) {
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    const handleKeyUp = (e) => {
      if (e.key === 'Enter') {
          setKeyUp(!keyUp);
      }
  };

    for (let i = 0; i < valueLength; i += 1) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling || null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling || null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (e, index) => {
    const { target } = e;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue = value.substring(0, index) + targetValue + value.substring(index + 1);

      setOtpValue(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      setOtpValue(targetValue);

      target.blur();
    }
  };

  const inputOnKeyDown = (e) => {
    const { key, target } = e;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className="otp-group">
      {valueItems.map((digit, index) => (
        <>
          <input
            key={index}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={valueLength}
            className="otp-input"
            value={digit}
            onChange={(e) => inputOnChange(e, index)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
          />
          {index === 2 && '-'}
        </>
      ))}
    </div>
  );
}
