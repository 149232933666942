/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './panelNavigator.css';

const PanelNavigator = (props) => {
  const navigate = useNavigate();
  const {
    text, selected, widths, path, navigtionClick, setNavigtionClick, value,
  } = props;
  const stateChange = (e, nameValue) => {
    e.preventDefault();
    // console.log(nameValue);
    setNavigtionClick(nameValue);
    navigate(path, { replace: true });
  };

  return (
    <div
      style={navigtionClick === value ? {
        background: '#F9FAFB', width: widths, borderRadius: '0.5rem', cursor: 'pointer',
        } : {
        background: '#FFFFFF', width: widths, borderRadius: '0.5rem', cursor: 'pointer',
      }}
      onClick={(e) => stateChange(e, value)}
    >
        <div className="panelNavigator-container">{text}</div>
    </div>
  );
};

export default PanelNavigator;
