import React, { useState, useEffect } from 'react';
import './listNavigation.css';
import PanelNavigator from '../panelNavigator/PanelNavigator';

const ListNavigationTab = (props) => {
    const { list, navigtionClick, setNavigtionClick } = props;
    return (
        <div className="list-navigator-container">
            {
                list.map((x) => x.enabled === true && (
                    <PanelNavigator text={x.text} value={x.value} path={x.path} setNavigtionClick={setNavigtionClick} navigtionClick={navigtionClick} />
                ))
            }
        </div>
    );
};

export default ListNavigationTab;
