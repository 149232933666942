import React, { useCallback } from 'react';
import { OutlinedButton } from '../../../buttonComponent/ButtonComponent';
import './InventoryPagination.css';

function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
}

const InventoryPagination = (props) => {
    const {
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage,
        currentPage,
    } = props;

const handlePrevious = useCallback(() => onChangePage(currentPage - 1), [currentPage, onChangePage]);
const handleNext = useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage]);

const numPages = getNumberOfPages(rowCount, rowsPerPage);

const disabledPrevious = currentPage === 1;
const disabledNext = currentPage === numPages;

const paginationDetails = `Page ${currentPage} of ${numPages}`;

    return (
        <div className="pagination-body">
            <div className="pagination-details">
                {paginationDetails}
            </div>
            <div className="pagination-actions">
                <OutlinedButton label="Previous" onClickFunction={handlePrevious} width="87px" disable={disabledPrevious} />
                <OutlinedButton label="Next" onClickFunction={handleNext} width="60px" disable={disabledNext} />
            </div>
        </div>
    );
};

export default InventoryPagination;
