/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import './account.css';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import MenuPopup from '../menuPopup/MenuPopup';
import Menu from '../../image/hamburgerDotMenu.svg';
import Avatar from '../avatar/Avatar';

export default function Account(props) {
  const {
    name,
    agency,
    // menuButton,
    switchModalShow,
    setSwitchModalShow,
    history,
    logout,
    agencyList,
    cookiesUpdated,
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [profileImage, setProfileImage] = useState(false);

  const btnMenu = () => {
    setIsPopoverOpen(true);
  };

  const getLatestCookies = () => {
    setProfileImage(Cookies.get('profileImageUrl'));
  };

  useEffect(() => { getLatestCookies(); }, []);
  useEffect(() => {
 if (cookiesUpdated) {
    getLatestCookies();
  }
}, [cookiesUpdated]);

  // this to getInitials Name
  function getInitials(names) {
    let initialsArray = [];
    let initialsFinal;
      const arr = names.split(' ');
      if (arr.length > 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < arr.length; i++) {
          const initials = arr[i].charAt(0).toUpperCase();
          initialsArray = [...initialsArray, initials];
        }
        initialsFinal = initialsArray;
      } else if (arr.length === 1) {
        names.charAt(0).toUpperCase();
        initialsFinal = names.charAt(0).toUpperCase();
      }
      // this only display 2 initials slice
      const onlyTwoAlphabet = initialsFinal.length > 1 ? initialsFinal.slice(0, 2) : initialsFinal;
      return onlyTwoAlphabet;
}

  return (
    <>
      <div className="account-container">
        <div className="avatar-label-group">
          {
            profileImage !== 'undefined' ? (
              <Avatar name={name} size="2.5rem" fontSize="1rem" lineHeight="1.5rem" image={profileImage} />
            ) : (
              <Avatar name={name} size="2.5rem" fontSize="1rem" lineHeight="1.5rem" />
            )
          }

          <div className="text-container">
            <div className="text-account">{name}</div>
            <div className="supporting-text-account">{agency}</div>
          </div>
        </div>
        <Popover
          isOpen={isPopoverOpen}
          positions={['right']}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={(
            <div className="snackbar-shadow">
              <MenuPopup page="footerNavigation" history={history} logout={logout} setSwitchModalShow={setSwitchModalShow} switchModalShow={switchModalShow} agencyList={agencyList} />
            </div>
          )}
          containerStyle={{
            padding: '10px',
            zIndex: '3',
          }}
        >
          <div className="account-button" onClick={(e) => btnMenu(e)}>
            <div className="account-menu">
              <img src={Menu} loading="lazy" alt="menu_icon" />
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
}

Account.propTypes = {
  name: PropTypes.string,
  agency: PropTypes.string,
};
