/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './assetDetailsCard.css';
import AssetCardMore from './AssetCardMore';
import EmptyCardMore from './EmptyCardMore';

const AssetDetailCard = (props) => {
    const {
        closeButton,
        markerValue,
        timeStampDisplay,
        assetSelection,
        lastSeenTimestamp,
        emptyCardValue,
    } = props;

    // console.log('timestampDisplaaaayyyyy', timeStampDisplay);

    const getLatestDataButton = (e) => {
        e.preventDefault();
        // console.log('refreshButton');
    };

    return (
        <>
        {
            Object.keys(markerValue).length > 0 ? <AssetCardMore markerValue={markerValue} assetSelection={assetSelection} timeStampDisplay={timeStampDisplay} closeButton={closeButton} getLatestDataButton={getLatestDataButton} lastSeenTimestamp={lastSeenTimestamp} /> : <EmptyCardMore assetSelection={assetSelection} closeButton={closeButton} emptyCardValue={emptyCardValue} />
        }
        </>
  );
};

export default AssetDetailCard;
