import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { useOutletContext } from 'react-router-dom';
// import { inventoryNavigationList } from '../../../config/function/tabNavigationList';
import BreadCrumbs from '../../../component/breadcrumbs/BreadCrumbs';
import Title from '../../../component/title/Title';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import { useApiData } from '../../../config/reducer/Modules';
import TruckIcon from '../../../image/truckIcon.svg';
import './beacons.css';

export default function Beacons() {
    const [selectedOption, setSelectedOption] = useState(null);
    const { apiResponse } = useApiData();
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const inventoryNavigationList = [
        {
            text: 'Assets & Devices', value: 1, path: '/inventory/assetdevices', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.asset,
        },
        {
            text: 'Beacons', value: 2, path: '/inventory/beacons', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.beacon,
        },
        {
            text: 'Geofence', value: 3, path: '/inventory/geofence', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.fleetManagement?.geofence,
        },
        {
            text: 'Connectivity', value: 4, path: '/inventory/connectivity', enabled: true,
        },
    ];

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        setNavigtionClick(2);
        setNavigationState('inventory');
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return (
        <div className="beacon-container " style={{ width: windowSize.innerWidth - 280 }}>
            <ListNavigationTab list={inventoryNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="beacon-header">
                {/* <BreadCrumbs customSeperator={seperator} /> */}
                {/* <Title title="Geofence" subtitle="Manage policies that enable preemptive alerting or action triggers for devices/assets around geofence areas. " titleSize="1.875rem" /> */}
            </div>
            {/* <div className="geofence-body"> */}
                {/* <div className="telemetry-body-filter">
                    <div className="dropdown-button-container">
                    <Autocomplete list={listAsset} setSelectionList={setAssetSelectionList} selectionList={assetSelectionList} text="Select device / asset" isImage={false} isAgencySelector={false} stateInputValue={setInputSelectonText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                    </div>
                    <div className="telemetry-filter-button">
                        <SearchInput containerWidth="25rem" text="Search" containerHeight="22px" />
                        <ExportCSVButton label="Export View" buttonHeight="44px" />
                    </div>
                </div> */}
                {/* <div className="telemetry-body-empty">
                    <TelemetrySShotEmptyState />
                </div> */}
                {/* <div className="telemetry-body-text">
                    <Table
                    selectedRowCount={selectedRowCount}
                    setSelectedRowCount={setSelectedRowCount}
                    tableData={telemData}
                    checkbox={false}
                    pagination={false}
                    module="telemetry"
                    />
                </div> */}
                {/* {devicesState === true ? (
                    <div className="inventory-body-text">
                        <Table />
                    </div>
                ) : (
                    <div className="inventory-body-empty">
                        <img src={AssetsIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
                        <div className="inventory-body-text">
                            <Text text="No Assets & Devices" size="1.25rem" weight="600" align="center" height="auto" />
                            <Text text="Your search did not match any assets & devices. Newly added devices will appear momentarily" size="1rem" weight="400" align="center" color="#475467" height="auto" />
                        </div>
                    </div>
                )} */}
            {/* </div> */}
        </div>
    );
}
