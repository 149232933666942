import React, {
    createContext, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';
import { APIGetUsers } from '../restAPI/FetchAPI';

const ApiDataContext = createContext();

const useApiData = () => useContext(ApiDataContext);

const ApiDataProvider = ({ children }) => {
    const token = Cookies.get('jwtToken');
    const navigate = useNavigate();
    const agencyId = Cookies.get('agencyUUID');
    const [moduleLoading, setModuleLoading] = useState(true);
    const [apiResponse, setApiResponse] = useState(null);
    const [apiUser, setApiUser] = useState(null);

    const fetchData = async () => {
        const result = await APIGetUsers(setModuleLoading, token, agencyId);
        try {
            if (result.status === 200) {
                const userData = result.data;
                const data = result.data.privilege !== undefined ? result.data.privilege : null;
                setApiResponse(data);
                setModuleLoading(false);
                setApiUser(userData);
            }
        } catch (error) {
            setApiResponse({});
            setApiUser({});
            console.log('Error fetching user data');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const value = {
        moduleLoading,
        apiResponse,
        apiUser,
    };

    return <ApiDataContext.Provider value={value}>{children}</ApiDataContext.Provider>;
};

export { ApiDataContext, useApiData, ApiDataProvider };
