import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#101828',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#101828',
      padding: '8px 12px',
      boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      borderRadius: '8px',
      fontWeight: 600,
    },
  }));

const ToolTips = (props) => {
  const { text, disbledHover, children } = props;
  return (
    <BootstrapTooltip title={text} disableHoverListener={disbledHover} placement="top">
        {children}
    </BootstrapTooltip>
  );
};

export default ToolTips;
