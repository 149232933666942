export const liveNavigationList = [
    {
        text: 'Map Overview', value: 1, path: '/live/mapOverview',
    },
    {
        text: 'Telemetry Snapshot', value: 2, path: '/live/telemetrySnapshot',
    },
    {
        text: 'Asset Status', value: 3, path: '/live/assetStatus',
    },
];

export const inventoryNavigationList = [
    { text: 'Assets & Devices', value: 1, path: '/inventory/assetdevices' },
    { text: 'Beacons', value: 2, path: '/inventory/beacons' },
    { text: 'Geofence', value: 3, path: '/inventory/geofence' },
    { text: 'Connectivity', value: 4, path: '/inventory/connectivity' },
];

export const assetPerformanceNavigationList = [
    { text: 'Asset Performance', value: 1, path: '/insights/assetPerformance' },
    { text: 'IOrail', value: 2, path: '/insights/IOrail' },
    { text: 'IOroad', value: 3, path: '/insights/IOroad' },
    { text: 'Audits', value: 4, path: '/insights/audits' },
];

export const agenciesNavigationList = [
    {
        text: 'Agencies', value: 1, path: '/agencies', enabled: true,
    },
    {
        text: 'Reports', value: 2, path: '/agencies/Reports', enabled: false,
    },
    {
        text: 'Geofence', value: 3, path: '/agencies/Geofence', enabled: false,
    },
    {
        text: 'Connectivity', value: 4, path: '/agencies/Connectivity', enabled: false,
    },
];
