import React from 'react';
import Text from '../text/Text';
import { PrimaryButton } from '../buttonComponent/ButtonComponent';
import AgencyIcon from './img/agencyEmpty.svg';
import './tableemptystate.css';

const AgencyUsersEmptyState = (props) => {
    const { text } = props;
    return (
        <div style={{ paddingTop: '3rem' }}>
            <div className="empty-state-container">
                <img loading="lazy" src={AgencyIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
                <div className="empty-state-text">
                    <Text text="No Users" size="1.25rem" weight="600" align="center" height="auto" lineHeight="30px" />
                    <Text text="Begin adding users for them to access their agency on Horizon. The first user added to the agency will be designated Agency Owner." size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
                </div>
            </div>
        </div>
    );
};

export default AgencyUsersEmptyState;
