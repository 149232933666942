import React from 'react';
import ConnectivityEmptyIcon from './img/connectivityEmpty.svg';
import Text from '../text/Text';
import { CreateWithIcon } from '../buttonComponent/ButtonComponent';
import './tableemptystate.css';

const ConnectivityTableEmptyState = (props) => {
    const { detailsPage } = props;

  return (
    <div className="empty-state-container">
      <img loading="lazy" src={ConnectivityEmptyIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
      <div className="empty-state-text">
          <Text text="No VERGE IoT SIMs" size="1.25rem" weight="600" align="center" height="auto" lineHeight="30px" />
          <Text text="Your search did not match any assets & devices. Newly added SIMs will appear momentarily" size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
      </div>
    </div>
  );
};

export default ConnectivityTableEmptyState;
