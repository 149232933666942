import React, { useEffect, useState } from 'react';
import './assetStatus.css';
import ProgressBar from '../progressBar/ProgressBar';
import InMotionMarker from './img/inMotion-marker.svg';
import idleMarker from './img/idleMarker.svg';
import stopMarker from './img/stopMarker.svg';
import inTowMarker from './img/inTowMarker.svg';

const AssetStatus = (props) => {
    const {
        assetList,
    } = props;

    // console.log(assetList);
    const stop = assetList.filter((docs) => docs.motionInference === 100);
    const inMotion = assetList.filter((docs) => docs.motionInference === 200);
    const idle = assetList.filter((docs) => docs.motionInference === 400);
    const inTow = assetList.filter((docs) => docs.motionInference === 500);
    // console.log(idle);

    return (
        <div className="assetStatus-rows">
            <div className="assetStatus-count">
                {assetList.length}
            </div>
            <div className="assetStatus-inMotion">
                <img loading="lazy" src={InMotionMarker} alt="" />
                <div className="assetStatus-inMotion-progress">
                    <div className="assetStatus">
                        In Motion
                    </div>
                    <ProgressBar value={inMotion && inMotion.length > 0 ? inMotion.length : 0} max={assetList && assetList.length > 0 ? assetList.length : 0} />
                </div>
            </div>
            <div className="assetStatus-inMotion">
                <img loading="lazy" src={idleMarker} alt="" />
                <div className="assetStatus-inMotion-progress">
                    <div className="assetStatus">
                        Idle
                    </div>
                    <ProgressBar value={idle && idle.length > 0 ? idle.length : 0} max={assetList && assetList.length > 0 ? assetList.length : 0} />
                </div>
            </div>
            <div className="assetStatus-inMotion">
                <img loading="lazy" src={stopMarker} alt="" />
                <div className="assetStatus-inMotion-progress">
                    <div className="assetStatus">
                        Stop
                    </div>
                    <ProgressBar value={stop && stop.length > 0 ? stop.length : 0} max={assetList && assetList.length > 0 ? assetList.length : 0} />
                </div>
            </div>
            <div className="assetStatus-inMotion">
                <img loading="lazy" src={inTowMarker} alt="" />
                <div className="assetStatus-inMotion-progress">
                    <div className="assetStatus">
                        In Tow
                    </div>
                    <ProgressBar value={inTow && inTow.length > 0 ? inTow.length : 0} max={assetList && assetList.length > 0 ? assetList.length : 0} />
                </div>
            </div>
        </div>
    );
};

export default AssetStatus;
