import React from 'react';
import moment from 'moment';
import './loggedInSession.css';
import ColoredLine from '../coloredLine/ColoredLine';
import DesktopIcon from './img/desktop.svg';
import PhoneIcon from './img/phone.svg';

const switchIcon = (type) => {
    switch (type) {
        case 'desktop':
            return DesktopIcon;
        case 'phone':
            return PhoneIcon;
        default:
            return DesktopIcon;
    }
};

const LoggedInSession = (props) => {
    const { sessions } = props;
    const sortedSessions = sessions.sort((x, y) => (new Date(x.lastSeenTimestamp) < new Date(y.lastSeenTimestamp) ? 1 : -1)).sort((a, b) => b.isActive - a.isActive).sort((a, b) => b.currentSession - a.currentSession);

    return (
        <div className="loggedIn-session-container">
            {sortedSessions.slice(0, 7).map((x) => (
                <>
                    <ColoredLine />
                    <div className="locations-container">
                        <div className="device-type">
                            <img src={switchIcon(x.device.type)} alt="loggedin-device" loading="lazy" />
                        </div>
                        <div className="loggedIn-text-container">
                            <div className="loggedIn-text-badge">
                                <div className="loggedIn-text-model">
                                    {x.device.model}
                                </div>
                                {x.currentSession === true ? (
                                    <div className="loggedIn-current-badge">
                                        Current
                                    </div>
                                ) : (<></>)}
                                {x.isActive === true ? (
                                    <div className="loggedIn-active-session">
                                        <span className="loggedIn-dot" />
                                        Active now
                                    </div>
                                ) : (
                                    <>
                                    </>
                                )}
                            </div>
                            <div className="loggedIn-supporting-text">
                                {x.address}
                                {' '}
                                •
                                {' '}
                                {moment(x.lastSeenTimestamp).format('DD MMM')}
                                {' at '}
                                {moment(x.lastSeenTimestamp).format('h:mma')}
                            </div>
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
};

export default LoggedInSession;
