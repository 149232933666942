import React, { useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../pages/sidebar/Sidebar';
import { APIGetUsers } from '../../config/restAPI/FetchAPI';
import { useApiData } from '../../config/reducer/Modules';
import { WebsocketContext } from '../../config/reducer/Websocket';
import Topbar from '../../pages/topbar/Topbar';
import './dashboardlayout.css';

export default function DashboardLayout({ history, cookiesUpdated }) {
  const isDefault = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 1023px)',
  });
  const { moduleLoading, apiResponse } = useApiData();
  const [navigationClick, setNavigationClick] = useState(1);
  const [navigationState, setNavigationState] = useState();
  const [idleState, setIdleState] = useState(false);
  const payloadContext = useContext(WebsocketContext);
  const channel = Cookies.get('agency-id');
  const { setChannel } = payloadContext;

  useEffect(() => {
    if (channel) {
      setChannel(channel);
    }
  }, []);

  return (
    <>
    {isMobileOrTablet && (
        <section className="dashboard-layout-container-mobile" style={{ height: document.documentElement.clientHeight }}>
          <div className="topbar">
            {moduleLoading ? (
              <></>
            ) : (
              <Topbar history={history} navigationclick={navigationClick} setNavigationClick={setNavigationClick} navigationState={navigationState} setNavigationState={setNavigationState} cookiesUpdated={cookiesUpdated} />
            )}
          </div>
          <div className="main-content-mobile">
            <Outlet context={[navigationClick, setNavigationClick, navigationState, setNavigationState, idleState, setIdleState]} />
          </div>
        </section>
    )}
    {isDefault && (
      <div className="dashboard-layout-container">
        <div className="sidebar">
          {
            moduleLoading ? (
            <></>
            ) : (
            <Sidebar history={history} navigationclick={navigationClick} setNavigationClick={setNavigationClick} navigationState={navigationState} setNavigationState={setNavigationState} cookiesUpdated={cookiesUpdated} />
            )
          }
        </div>
        <div className="main-content">
          <Outlet context={[navigationClick, setNavigationClick, navigationState, setNavigationState, idleState, setIdleState]} />
        </div>
      </div>
    )}
    </>
  );
}
