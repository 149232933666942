import React from 'react';
import { Navigate, useNavigate } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthProvider } from './config/reducer/Auth';
import BrokenPage from './pages/errorPage/BrokenPage';
import Router from './config/routes/routes';
import './App.css';

function RuntimeErrorPage(props) {
  window.location.pathname = '/500';
}

function App() {
  const navigate = useNavigate();
  return (
    <div className="App">
      {/* <ErrorBoundary FallbackComponent={RuntimeErrorPage}> */}
      {/* <ErrorBoundary FallbackComponent={BrokenPage}> */}
        <AuthProvider>
          <Router />
        </AuthProvider>
      {/* </ErrorBoundary> */}
    </div>

  );
}

export default App;
