import React, { useState, useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { passwordStrength } from 'check-password-strength';
import { Popover } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import ProgressBar from '@ramonak/react-progress-bar';
import './security.css';
import ConfigTab from '../../../component/configurationTab/configurationTab';
import MenuPopup from '../../../component/menuPopup/MenuPopup';
import Title from '../../../component/title/Title';
import SearchInput from '../../../component/searchInput/SearchInput';
import TextInput from '../../../component/textInput/TextInput';
import { PrimaryButton, TextButton } from '../../../component/buttonComponent/ButtonComponent';
import Text from '../../../component/text/Text';
import ColoredLine from '../../../component/coloredLine/ColoredLine';
import { APIGetUsers } from '../../../config/restAPI/FetchAPI';
import { APIUpdateUsers } from '../../../config/restAPI/PatchAPI';
import { APILogoutSessions } from '../../../config/restAPI/DeleteAPI';
import { AuthContext } from '../../../config/reducer/Auth';
import { WebsocketContext } from '../../../config/reducer/Websocket';
import LoggedInSession from '../../../component/loggedInSession/LoggedInSession';
import VerticalDots from '../../../component/table/img/verticalDots.svg';
import Modal from '../../../component/modal/Modal';

const navigationList = [
    {
        text: 'Profile',
        value: 1,
        path: '/settings/profile',
        enabled: true,
    },
    {
        text: 'Security',
        value: 2,
        path: '/settings/security',
        enabled: true,
    },
    {
        text: 'Team',
        value: 3,
        path: '/settings/teams',
        enabled: true,
    },
    {
        text: 'Notifications',
        value: 4,
        path: '/settings/notifications',
        enabled: true,
    },
    // { text: 'Integrations', value: 5, path: '/settings/integrations' },
    // { text: 'API', value: 6, path: '/settings/API' },
    // { text: 'Billing', value: 7, path: '/settings/billing' },
];

const Security = (props) => {
    const { totalUsers } = props;
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const { logout } = context;
    const payloadContext = useContext(WebsocketContext);
    const { CloseConnection } = payloadContext;
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agencyUUID');
    const sessionID = Cookies.get('SessionID');
    const [loading, setLoading] = useState(false);
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [input, setInput] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [accountDetails, setAccountDetails] = useState({});
    const [sessions, setSessions] = useState([]);
    const [currentPwdError, setCurrentPwdError] = useState(false);
    const [currentPwdErrorMessage, setCurrentPwdErrorMessage] = useState('');
    const [newPwdError, setNewPwdError] = useState(false);
    const [newPwdErrorMessage, setNewPwdErrorMessage] = useState('');
    const [confirmPwdError, setConfirmPwdError] = useState(false);
    const [confirmPwdErrorMessage, setConfirmPwdErrorMessage] = useState('');
    const [keyUp, setKeyUp] = useState('', false);
    const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
    const [passwordStrengthString, setPasswordStrengthString] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [switchModalShow, setSwitchModalShow] = useState(false);
    const [updateSwitchModal, setUpdateSwitchModal] = useState(false);
    const btnDisabled = (!input.confirmPassword || !input.currentPassword || !input.newPassword || input.confirmPassword !== input.newPassword);

    const currentPasswordOnChange = (e) => {
        setInput({ ...input, currentPassword: e.target.value });
    };

    const newPasswordOnChange = (e) => {
        setInput({ ...input, newPassword: e.target.value });
    };

    const confirmPasswordOnChange = (e) => {
        setInput({ ...input, confirmPassword: e.target.value });
    };

    const fetchAccountDetails = async () => {
        try {
            const result = await APIGetUsers(setLoading, token, agencyId);
            if (result.data.status === 200) {
                const { data } = result.data;
                setAccountDetails(data);
                setSessions(data.sessions);
            }
        } catch (err) {
            // console.log(err);
            setLoading(false);
        }
    };

    const handleClick = (event) => {
        // console.log(event);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const APIUpdateAccount = async (params) => {
        const reducer = {
            password: {
                currentPassword: Base64.encode(input.currentPassword),
                newPassword: Base64.encode(input.newPassword),
            },
        };
        try {
            const result = await APIUpdateUsers(token, agencyId, reducer);
            if (result.data.status === 200) {
                const { data } = result.data;
                // console.log('Successfully updated');
                CloseConnection('logout');
                logout();
                navigate('/login', { replace: true });
                setLoading(false);
            } else {
                const { data } = result.data;
                setUpdateSwitchModal(false);
                setCurrentPwdError(true);
                setCurrentPwdErrorMessage('Your current password does not match.');
                console.log('Unsuccessfully updated');
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    // const btnUpdate = () => {
    //     if (input.currentPassword && input.newPassword && input.confirmPassword !== '') {
    //         try {
    //             const reducer = {
    //                 password: {
    //                     currentPassword: Base64.encode(input.currentPassword),
    //                     newPassword: Base64.encode(input.newPassword),
    //                 },
    //             };
    //             if (token) {
    //                 APIUpdateAccount(reducer);
    //             }
    //         } catch (err) {
    //             console.log('this is error from update submit catch', err);
    //             console.log(err);
    //         }
    //     }
    // };

    const handleSwitchModal = () => {
        setSwitchModalShow(!switchModalShow);
    };

    const handleUpdateSwitchModal = () => {
        setUpdateSwitchModal(!updateSwitchModal);
    };

    const logoutDevices = async () => {
        // console.log(sessions);
        const params = {
            sessionOids: sessions.length > 0 ? sessions.map((x) => x.oid) : [],
        };
        // console.log(params);
        try {
            const result = await APILogoutSessions(token, agencyId, params);
            if (result.data.status === 200) {
                const response = await axios.delete(`${process.env.REACT_APP_WEBSOCKET_LOGOUT}?session=${sessionID}`, null, { credentials: 'same-origin' });
                if (response.status === 200) {
                CloseConnection('logout');
                logout();
                navigate('/login', { replace: true });
                } else {
                    // console.log('this to add in snackbar ');
                }
            } else {
                const { data } = result.data;
                console.log('Unsuccessfully logout');
            }
        } catch (err) {
            // console.log(err);
        }
    };

    useEffect(() => {
        if (input.newPassword) {
            const passwordStr = passwordStrength(input.newPassword).value;

            switch (passwordStr) {
                case 'Too weak':
                    setNewPwdError(true);
                    setPasswordStrengthString(passwordStr);
                    setPasswordStrengthValue(15);
                    break;
                case 'Weak':
                    setNewPwdError(false);
                    setPasswordStrengthValue(35);
                    setPasswordStrengthString(passwordStr);
                    break;
                case 'Medium':
                    setNewPwdError(false);
                    setPasswordStrengthValue(60);
                    setPasswordStrengthString(passwordStr);
                    break;
                case 'Strong':
                    setNewPwdError(false);
                    setPasswordStrengthValue(100);
                    setPasswordStrengthString(passwordStr);
                    break;
                default:
                    setNewPwdError(false);
                    setPasswordStrengthValue(0);
                    setPasswordStrengthString('');
                    break;
            }
        }
    }, [input]);

    useEffect(() => {
        if (input.confirmPassword.length > 0) {
            if (input.confirmPassword !== input.newPassword) {
                setConfirmPwdError(true);
                setConfirmPwdErrorMessage('Passwords do not match!');
            } else {
                setNewPwdError(false);
                setConfirmPwdError(false);
            }
        }
        if (input.confirmPassword === '') {
            setConfirmPwdError(false);
        }
        if (input.newPassword === '') {
            setNewPwdError(false);
        }
    }, [input.confirmPassword, input.newPassword]);

    useEffect(() => {
        setCurrentPwdError(false);
    }, [input.currentPassword]);

    useEffect(() => {
        if (passwordStrengthString) {
            document.documentElement.style.setProperty('--strength-bar-width', `${passwordStrengthValue}%`);
            const color = getComputedStyle(document.documentElement).getPropertyValue('--strength-bar-width');
        }
    }, [passwordStrengthString]);

    useEffect(() => {
        fetchAccountDetails();
        setNavigationState('settings');
        setNavigtionClick(2);
    }, []);

    return (
        <div className="security-container">
            <div className="security-header-section">
                <div className="security-title">
                    <Title title="Settings" titleSize="1.875rem" lineHeight="2.375rem" />
                    {/* <SearchInput containerWidth="20rem" text="Search" /> */}
                </div>
                <div className="security-tabs">
                    <ConfigTab color="#FCFCFD" module="settings" types={navigationList} users={totalUsers} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="security-column">
                <div className="security-password-container">
                    <div className="security-password-form">
                        <div className="security-password-header">
                            <Title title="Change your password" titleSize="1.125rem" lineHeight="1.75rem" subtitle="Please enter your current password to change password." subtitleSize="0.875rem" />
                            <PrimaryButton label="Update" width="5.125rem" onClickFunction={handleUpdateSwitchModal} disable={btnDisabled} />
                        </div>
                        <div className="security-password-input">
                            <TextInput label="Current Password" setInput={setInput} input={input} name="password" type="password" error={currentPwdError} setError={setCurrentPwdError} onChangeFunction={currentPasswordOnChange} keyUp={keyUp} setKeyUp={setKeyUp} />
                            { currentPwdError && (
                                <div className="security-password-error">{currentPwdErrorMessage}</div>
                            )}
                        </div>
                        <div className="security-password-input">
                            <TextInput label="New Password" setInput={setInput} input={input} name="password" type="password" error={newPwdError} setError={setNewPwdError} onChangeFunction={newPasswordOnChange} keyUp={keyUp} setKeyUp={setKeyUp} />
                            { newPwdError && (
                                <div className="security-password-error">{newPwdErrorMessage}</div>
                            )}
                        </div>
                        { input.newPassword && (
                            <div className="security-password-strength-container">
                                <ProgressBar
                                  completed={passwordStrengthValue}
                                  className="securityWrapperBar"
                                  barContainerClassName="securityContainerBar"
                                  completedClassName="securityBarCompletedBar"
                                  isLabelVisible="false"
                                  customLabel=" "
                                  animateOnRender="true"
                                  transitionTimingFunction="ease-in-out"
                                  transitionDuration="1"
                                />
                                <Text size="0.875rem" color="#344054" weight="500" align="right" text={passwordStrengthString} />
                            </div>
                        )}
                        <div className="security-password-input">
                            <TextInput label="Confirm New Password" setInput={setInput} input={input} name="password" type="password" error={confirmPwdError} setError={setConfirmPwdError} onChangeFunction={confirmPasswordOnChange} keyUp={keyUp} setKeyUp={setKeyUp} />
                            { confirmPwdError && (
                                <div className="security-password-error">{confirmPwdErrorMessage}</div>
                            )}
                        </div>
                    </div>
                    <div className="security-authentication">
                        <ColoredLine />
                        <div className="two-factor-container">
                            <Title title="Two Factor Authentication (2FA)" titleSize="1.125rem" lineHeight="1.75rem" deleteSubtitle>
                                <div className="security-two-factor-container">
                                    Enable an extra layer of security towards your Horizon account by enabling 2FA.
                                </div>
                            </Title>
                            {/* <TextButton label="Manage" onClickFunction={() => {}} disable fontWeight={600} color="#26404E" /> */}
                            <div className="security-button-container">
                                <div className="security-coming-soon-badge ">
                                    Coming Soon
                                </div>
                            </div>
                        </div>
                        <ColoredLine />
                        <div className="single-sign-on-container">
                            <Title title="Single Sign-On (SSO)" titleSize="1.125rem" lineHeight="1.75rem" deleteSubtitle>
                                <div className="security-two-factor-container">
                                    Easily and securely authenticate to Horizon using supported SSOs such as Google, Apple and/or mobile phone.
                                </div>
                            </Title>
                            {/* <TextButton label="Manage" onClickFunction={() => {}} disable fontWeight={600} color="#26404E" /> */}
                            <div className="security-button-container">
                                <div className="security-coming-soon-badge ">
                                    Coming Soon
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="security-logged-in-container">
                    <div className="security-logged-in-header">
                        <Title title="Where you’re logged in" titleSize="1.125rem" lineHeight="1.75rem" deleteSubtitle subtitleSize="0.875rem" gap="0rem" width="27.9375rem">
                            We’ll alert you via
                            {' '}
                            <b>
                            {accountDetails.userEmail}
                            </b>
                            {' '}
                            if there is any unusual activity on your account.
                        </Title>
                        <div className="security-button">
                            <div className="security-menu" aria-hidden="true" onClick={handleClick}>
                                <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                            </div>
                        </div>
                        <Popover
                          sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                            <MenuPopup page="security" handleSwitchModal={handleSwitchModal} />
                        </Popover>
                    </div>
                    <LoggedInSession sessions={sessions} />
                    {
                        switchModalShow && (
                            <Modal switchModalShow={switchModalShow} setSwitchModalShow={setSwitchModalShow} openSwitchModal={handleSwitchModal} module="Security" subtitle="This action will log you out of Horizon from every device that you're currently logged in on. Proceed?" btnConfirm={() => logoutDevices()} />
                        )
                    }
{
                        updateSwitchModal && (
                            <Modal switchModalShow={updateSwitchModal} setSwitchModalShow={setUpdateSwitchModal} openSwitchModal={handleUpdateSwitchModal} module="Security" subtitle="Changing your password will require you to log in again. Proceed?" btnConfirm={() => APIUpdateAccount()} />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default Security;
