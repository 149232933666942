import React, { useState } from 'react';
import './inputtext.css';

export default function InputText(props) {
    const [keyUP, setKeyUP] = useState(false);
    const {
        placeholder, onChange, value, width,
    } = props;
    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            setKeyUP(!keyUP);
        }
    };

    return (
        <div className="text-input">
            <input style={{ width: width }} className="input-text-container" placeholder={placeholder} onChange={onChange} value={value} />
        </div>
    );
}
