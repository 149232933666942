import React from 'react';
import './coloredLine.css';

const ColoredLine = () => (
    <div className="container">
        <div className="border" />
        <div className="border" />
    </div>
);

export default ColoredLine;
