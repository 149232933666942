/* eslint-disable import/no-cycle */
import React from 'react';
import { Box, Modal } from '@mui/material';
import DeleteIcon from '../img/deleteIcon.svg';
import Title from '../../title/Title';
// import InviteUsers from '../../../image/usersPlus.svg';
import CloseButton from '../../../image/xButton.svg';
import { DeleteButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import SnackBar from '../../snackbar/Snackbar';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // height: 260,
    bgcolor: '#FFFFFF',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // p: 0,
    // boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    borderRadius: '12px',
};

const DeleteModal = (props) => {
    const {
        switchModalShow,
        openSwitchModal,
        setSwitchModalShow,
        btnConfirm,
        dataRow,
        source,
        errorState,
        triggerError,
        setTrigerError,
    } = props;

    const switchSource = (value) => {
        switch (value) {
            case 'Geofence':
                return (
                <Title title="Delete geofence policy" deleteSubtitle titleSize="1.125rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.75rem">
                Are you sure you want to delete
                {' '}
                <b>
                {dataRow.geofenceName}
                ?
                </b>
                {' '}
                This action cannot be undone.
                </Title>
            );
            case 'Teams':
                return (
                    <Title title="Remove user" deleteSubtitle titleSize="1.125rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.75rem">
                        Are you sure you want to remove
                        {' '}
                        <b>
                        {dataRow.member.firstName}
                        {' '}
                        {dataRow.member.lastName}
                        </b>
                        {' '}
                        from this agency? This action cannot be undone.
                    </Title>
                );
            case 'Agencies':
                return (
                    <Title title="Delete agency" deleteSubtitle titleSize="1.125rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.75rem">
                        This is a big deal! We’re double checking just in case.
                        <br />
                        <br />
                        Are you sure you want to delete
                        {' '}
                        <b>
                        {dataRow.agencyId}
                        </b>
                        {' '}
                        ? This process is irreversible.
                    </Title>
                );
            case 'AdminConsole':
                return (
                    <Title title="Delete device" deleteSubtitle titleSize="1.125rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.75rem">
                        Are you sure you want to remove this device?
                        {' '}
                        This action cannot be undone.
                    </Title>
                );
            default:
                return (<></>);
        }
    };

    return (
        <div>
            <Box sx={style}>
                <div>
                    <div className="modal-header-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <img loading="lazy" src={DeleteIcon} alt="invite-users-icon" />
                            <div type="button" role="button" className="x-button" onKeyUp={(e) => setSwitchModalShow(!switchModalShow)} tabIndex="0" onClick={(e) => setSwitchModalShow(!switchModalShow)}>
                                <img loading="lazy" style={{ position: 'relative', bottom: 15, left: 9 }} src={CloseButton} alt="close-modal" />
                            </div>
                        </div>
                        {switchSource(source)}
                    </div>
                    <div className="button-container-delete">
                        <SecondaryButton label="Cancel" width="170px" imageIcon={false} onClickFunction={(e) => setSwitchModalShow(!switchModalShow)} />
                        <DeleteButton label="Delete" buttonWidth="170px" disable={false} onClickFunction={() => btnConfirm()} />
                    </div>
                </div>
            </Box>
            {
                triggerError && (
                    <div style={{
                        position: 'absolute',
                        top: 24,
                        right: 24,
                    }}
                    >
                        <SnackBar state={triggerError} setState={setTrigerError} title="Error" subtitile={`${errorState.statusCode} : ${errorState.statusMessage}`} module="error" action="error" />
                    </div>
                )
            }

        </div>

    );
};

export default DeleteModal;
