/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import Title from '../../title/Title';
import Autocomplete from '../../autocomplete/Autocomplete';
import InviteUsers from '../../../image/usersPlus.svg';
import CloseButton from '../../../image/xButton.svg';
import LogoutIcon from '../img/logoutUpdateIcon.svg';
import { PrimaryButton, SecondaryButton } from '../../buttonComponent/ButtonComponent';
import './securityLogout.css';

const InviteUsersModal = (props) => {
    const {
        switchModalShow,
        openSwitchModal,
        setSwitchModalShow,
        emailList,
        setEmailList,
        btnConfirm,
        selectionList, setSelectionList,
        subtitle,
    } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        bgcolor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 0,
        boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        borderRadius: '12px',
    };

    const stateChange = () => {
        setSwitchModalShow(!switchModalShow);
    };

    return (
        <Modal
          open={switchModalShow}
          onClose={openSwitchModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backdropFilter: 'blur(8px)',
            backgroundColor: '#344054B2',
          }}
        >
            <Box sx={style}>
                <div className="modal-container-logout">
                    <div className="modal-header">
                        <div className="modal-header-content">
                            <img loading="lazy" src={LogoutIcon} alt="switch-agency-icon" />
                            <Title title="Logout Required" subtitle="" titleSize="1.125rem" subtitleSize="0rem" gap="0rem" lineHeight="1.75rem" />
                        </div>
                        <div className="button-container">
                            <div type="button" role="button" className="x-button" onKeyUp={(e) => stateChange(e)} tabIndex="0" onClick={(e) => stateChange(e)}>
                                <img loading="lazy" src={CloseButton} alt="close-modal" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-content">
                        <Title title="" subtitle={subtitle} titleSize="0rem" subtitleSize="0.875rem" gap="0rem" lineHeight="1.25rem" />
                    </div>
                    <div className="modal-actions-logout">
                        <div className="modal-actions-content">
                            <SecondaryButton label="Cancel" width="50%" onClickFunction={stateChange} />
                            <PrimaryButton label="Confirm" width="50%" onClickFunction={(e) => btnConfirm(e)} />
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default InviteUsersModal;
