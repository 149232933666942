import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Base64, decode } from 'js-base64';
import { passwordStrength } from 'check-password-strength';
import ProgressBar from '@ramonak/react-progress-bar';
import CryptoJS from 'crypto-js';
import { useMediaQuery } from 'react-responsive';
import { APIPatchUpdatePassword } from '../../config/restAPI/PatchAPI';
import { APICheckValidPassword } from '../../config/restAPI/PostAPI';
import ImgWithTitle from '../../component/workspaceContainer/ImgWithTitle';
import TextInput from '../../component/textInput/TextInput';
import Text from '../../component/text/Text';
import { BackButton, PrimaryButton } from '../../component/buttonComponent/ButtonComponent';
import './reset-password.css';

const SetNewPassword = ({ history }) => {
    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    const navigate = useNavigate();
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [passwordErrorVerify, setPasswordErrorVerify] = useState(false);
    const [passwordErrorMessageVerify, setPasswordErrorMessageVerify] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [keyUp, setKeyUp] = useState('', false);
    const [passwordStrengthValue, setPasswordStrengthValue] = useState('');
    const [passwordStrengthString, setPasswordStrengthString] = useState('');
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState({
        password: '',
        passwordVerify: '',
    });
    const [invalidLink, setInvalidLink] = useState(false);

    const btnBack = (e) => {
        e.preventDefault();
        navigate('/login', { replace: true });
    };

    const url = window.location.search;
    const params = url.replace('?/', '');

    const tempsecretkey = 'dev_asiamobiliti';
    const decodeParams = CryptoJS.AES.decrypt(params, tempsecretkey).toString(CryptoJS.enc.Utf8);

    const resetPassword = async (e) => {
        if (input.password) {
            const passwordStr = passwordStrength(input.password).value;
            if (passwordStr === 'Too weak') { // Password strength is too weak, will show error message, and the 'Reset password' button is disabled
                setPasswordError(true);
                setPasswordErrorMessage('Password is too weak');
                setPasswordStrengthValue(20);
                setPasswordStrengthString(passwordStr);
            } else { // Password strength is weak/medium/strong, error message is cleared, and the 'Reset password' button is enabled
                setPasswordError(false);
                setPasswordErrorMessage('');
                setPasswordStrengthValue(40);
                setPasswordStrengthString(passwordStr);
            }
        }
        if ((input.password === input.passwordVerify) && (passwordStrengthString !== 'Too weak')) {
            const base64Password = Base64.encode(input.password);
            const apiParams = {
                password: base64Password,
                passwordOid: decodeParams,
            };
            try {
                const result = await APIPatchUpdatePassword(apiParams, setLoading);
                if (result.data.status === 200) {
                    setPasswordStrengthValue(0);
                    setLoading(false);
                    navigate('/passwordReset', { replace: true });
                }
            } catch (err) {
                console.log('Error from input');
                setLoading(false);
            }
        }
    };

    const CheckValidPassword = async () => {
        const passwordParams = {
            passwordOid: decodeParams,
        };
        const result = await APICheckValidPassword(passwordParams);
        const { message } = result.data;
        if (result.data.status === 404) {
            if (message === 'User not found') {
                setInvalidLink(true);
            }
        }
    };

    function resetErrorStates() {
        setPasswordError(false);
        setPasswordErrorMessage('');
        setPasswordErrorVerify(false);
        setPasswordErrorMessageVerify('');
        setBtnDisabled(false);
    }

    useEffect(() => {
        if (input.password) {
            const passwordStr = passwordStrength(input.password).value;
            if (passwordStr === 'Too weak') { // Password strength is too weak, will show error message, and the 'Reset password' button is disabled
                setPasswordError(true);
                setPasswordErrorMessage('Password is too weak');
                setPasswordStrengthValue(15);
                setPasswordStrengthString(passwordStr);
                setBtnDisabled(true);
            } else { // Password strength is weak/medium/strong, error message is cleared, and the 'Reset password' button is enabled
                if (input.passwordVerify && (input.password === input.passwordVerify)) {
                    resetErrorStates();
                } else if (input.passwordVerify && (input.password !== input.passwordVerify)) {
                    setBtnDisabled(true);
                    setPasswordErrorVerify(true);
                    setPasswordErrorMessageVerify('Password does not match');
                }
                setPasswordError(false);
                setPasswordStrengthString(passwordStr);
                if (passwordStr === 'Weak') {
                    setPasswordStrengthValue(35);
                } else if (passwordStr === 'Medium') {
                    setPasswordStrengthValue(60);
                } else if (passwordStr === 'Strong') {
                    setPasswordStrengthValue(100);
                }
            }
        }
    }, [input]);

    useEffect(() => {
        if ((keyUp !== '') && (btnDisabled === false)) {
            resetPassword();
        }
    }, [keyUp]);

    // let strength;
    useEffect(() => {
        if (passwordStrengthString) {
            document.documentElement.style.setProperty('--strength-bar-width', `${passwordStrengthValue}%`);
            const color = getComputedStyle(document.documentElement).getPropertyValue('--strength-bar-width');
            // console.log('DYNAMIC CSS: ', color);
            // strength = passwordStrengthValue;
            // console.log('STRENGTH: ', passwordStrengthValue, passwordStrengthString);
        }
    }, [passwordStrengthString]);

    useEffect(() => {
        // to check if decode have value
        if (decodeParams) {
            // check the timestamp for objectId
            const timestamp = parseInt(decodeParams.slice(0, 8), 16) * 1000 + Math.floor(parseInt(decodeParams.slice(-6), 16) / 16777.217);

            const tenDigitTimestamp = timestamp / 1000; // change to 10digittimestamp

            // to check if valid 24 hour
            const remoteTimestamp = 60 * 60 * 24 * 1000; // this is temporary will change to remote config

            const day = new Date(tenDigitTimestamp * 1000);
            const curDay = new Date();

            if (curDay - day <= remoteTimestamp) {
                setInvalidLink(false);
            } else {
                setInvalidLink(true);
            }
        } else {
            setInvalidLink(true);
        }
        CheckValidPassword();
    }, []);

    const passwordOnChangeFunction = (e) => {
        setInput({ ...input, password: e.target.value });
    };

    const verifyPasswordOnChangeFunction = (e) => {
        setInput({ ...input, passwordVerify: e.target.value });
    };

    // console.log(input);

    return (
        <>
        {isMobileOrTablet && (
            <section className="forgotPW-mainMobile">
                <div className="forgot-password-container-main-mobile">
                    <div className="forgot-password-content-mobile">
                        {invalidLink ? (
                            <>
                                <ImgWithTitle titleText="Reset Password Link" subText="Your request to reset your password has expired, the link has already been used, or the link does not exist." icon="Invalid" style={{ width: '100%' }} />
                                <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                            </>
                        ) : (
                            <>
                                <ImgWithTitle titleText="Set new password" subText="" icon="Users" style={{ width: '100%' }} />
                                    <div className="reset-new-password-column">
                                        <div className="reset-password-input-container">
                                            <TextInput label="Password" placeholder="Enter your new password" setInput={setInput} input={input} name="password" type="password" error={passwordError} setError={setPasswordError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={passwordOnChangeFunction} />
                                            {passwordError && (
                                                <div className="email-password-error">{passwordErrorMessage}</div>
                                            )}
                                            {input.password && (
                                                <>
                                                    <ProgressBar
                                                      completed={passwordStrengthValue}
                                                      className="wrapperBar"
                                                      barContainerClassName="containerBar"
                                                      completedClassName="barCompletedBar"
                                                      isLabelVisible="false"
                                                      customLabel=" "
                                                      animateOnRender="true"
                                                      transitionTimingFunction="ease-in-out"
                                                      transitionDuration="1"
                                                    />
                                                    <Text size="0.875rem" color="#344054" weight="500" align="right" text={passwordStrengthString} />
                                                </>
                                            )}
                                        </div>
                                        <div className="reset-password-input-container">
                                            <TextInput label="Verify Password" placeholder="Verify your new password" setInput={setInput} input={input} name="passwordVerify" type="password" error={passwordErrorVerify} setError={setPasswordErrorVerify} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={verifyPasswordOnChangeFunction} />
                                            {passwordErrorVerify && (
                                                <div className="email-password-error" style={{ paddingTop: '0.5rem' }}>{passwordErrorMessageVerify}</div>
                                            )}
                                        </div>
                                    </div>
                                    <PrimaryButton label="Reset password" disable={btnDisabled} onClickFunction={resetPassword} loading={loading} />
                                    <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                            </>
                        )}
                    </div>
                </div>
            </section>
        )}
        {isDefault && (
            <div className="forgot-password-container-main">
                <div className="forgot-password-container">
                    {invalidLink ? (
                        <>
                            <ImgWithTitle titleText="Reset Password Link" subText="Your request to reset your password has expired, the link has already been used, or the link does not exist." icon="Invalid" />
                            <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                        </>
                    ) : (
                        <>
                            <ImgWithTitle titleText="Set new password" subText="" icon="Users" />
                            <div className="reset-new-password-column">
                                <div className="reset-password-input-container">
                                    <TextInput label="Password" placeholder="Enter your new password" setInput={setInput} input={input} name="password" type="password" error={passwordError} setError={setPasswordError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={passwordOnChangeFunction} />
                                    {passwordError && (
                                        <div className="email-password-error">{passwordErrorMessage}</div>
                                    )}
                                    {input.password && (
                                        <>
                                            <ProgressBar
                                              completed={passwordStrengthValue}
                                              className="wrapperBar"
                                              barContainerClassName="containerBar"
                                              completedClassName="barCompletedBar"
                                              isLabelVisible="false"
                                              customLabel=" "
                                              animateOnRender="true"
                                              transitionTimingFunction="ease-in-out"
                                              transitionDuration="1"
                                            />
                                            <Text size="0.875rem" color="#344054" weight="500" align="right" text={passwordStrengthString} />
                                        </>
                                    )}
                                </div>
                                <div className="reset-password-input-container">
                                    <TextInput label="Verify Password" placeholder="Verify your new password" setInput={setInput} input={input} name="passwordVerify" type="password" error={passwordErrorVerify} setError={setPasswordErrorVerify} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={verifyPasswordOnChangeFunction} />
                                    {passwordErrorVerify && (
                                        <div className="email-password-error" style={{ paddingTop: '0.5rem' }}>{passwordErrorMessageVerify}</div>
                                    )}
                                </div>
                            </div>
                            <PrimaryButton label="Reset password" disable={btnDisabled} onClickFunction={resetPassword} loading={loading} />
                            <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                        </>
                    )}
                </div>
            </div>
        )}
        </>
    );
};

export default SetNewPassword;
