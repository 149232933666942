import React, { useState } from 'react';
import DownArrowIcon from './img/downArrowIcon.svg';
import DotIcon from './img/dotIcon.svg';
import UpArrowIcon from './img/upArrowIcon.svg';

export default function SpeedIndicatorField(props) {
  const { kmhDifference } = props;

  const speedIndicator = (value) => {
    switch (value) {
        case 1:
          return <img loading="lazy" src={UpArrowIcon} alt="upArrowIcon" />;
        case 0:
          return <img loading="lazy" src={DotIcon} alt="dotIcon" />;
        case 2:
          return <img loading="lazy" src={DownArrowIcon} alt="downArrowIcon" />;
        default:
          return <img loading="lazy" src={DotIcon} alt="dotIcon" />;
      }
  };

  const colour = (value) => {
    switch (value) {
        case 1:
          return '#ECFDF3'; // up
        case 0:
          return '#F2F4F7'; // no change
        case 2:
          return '#FEF3F2';// down
        default:
          return '#F2F4F7'; // no change
      }
  };
  return (
    <div style={{
        padding: '2px 8px 2px 6px', display: 'flex', justifyContent: 'center', backgroundColor: colour(kmhDifference), borderRadius: '16px',
    }}
    >
        {speedIndicator(kmhDifference)}
    </div>
  );
}
