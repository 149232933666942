import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import GeofenceIcon from './img/geofenceAlert.svg';
import ColoredLine from '../coloredLine/ColoredLine';
import UnreadIcon from './img/unread.svg';
import NotificationIcon from './img/Notification.svg';
import { TextButton } from '../buttonComponent/ButtonComponent';
import { APIGetAlerts } from '../../config/restAPI/FetchAPI';
import { APIPatchAlerts } from '../../config/restAPI/PatchAPI';
import './NotificationFeed.css';

const NotificationFeed = (props) => {
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const role = Cookies.get('role');

    const { data, setData, fetchAlerts } = props;
    const [loading, setLoading] = useState(false);
    const [emptyState, setEmptyState] = useState(false);

    useEffect(() => {
        if (data) {
            if (data.filter((x) => x.read === false).length === 0) {
                setEmptyState(true);
            } else {
                setEmptyState(false);
            }
        } else {
            setEmptyState(true);
        }
    }, [data]);

    const markAlertAsRead = async (oids) => {
        const params = {
            input: {
                oids: [oids],
                read: true,
            },
        };
        try {
            const result = await APIPatchAlerts(token, setLoading, agencyID, params);
            if (result?.data?.status === 200) {
                setLoading(false);
                fetchAlerts();
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const checkAlertType = (type) => {
        switch (type) {
            case 101:
                return 'Geofence Alert';
            default:
                return null;
        }
    };

    return (
        <div className="notification-feed-container">
            <div className="notification-feed-header">
                Notifications
            </div>
            <div className="notification-feed-content">
                {emptyState ? (
                    <div className="notification-feed-empty-state">
                        <img src={NotificationIcon} alt="empty-notification-icon" loading="lazy" />
                        <div className="notification-feed-empty-body">
                            <div className="notification-feed-empty-header">
                                No Unread Notifications
                            </div>
                            <div className="notification-feed-empty-subtext">
                                You are all caught up
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {data && data.map((x, index) => x.read === false && (
                            <>
                                <div className="notification-feed-item">
                                    <img src={GeofenceIcon} loading="lazy" alt="geofence-alert-icon" />
                                    <div className="notification-feed-item-content">
                                        {x.read === false && (
                                            <img src={UnreadIcon} alt="unread-icon" loading="lazy" className="unread-icon-feed-item" />
                                        )}
                                        <div className="notification-feed-item-container">
                                            <div className="notification-feed-item-header">
                                                <div className="notification-feed-item-type">
                                                    {checkAlertType(x.type)}
                                                </div>
                                                <div className="notification-feed-item-timestamp">
                                                    {moment(x.timestamp).fromNow()}
                                                </div>
                                            </div>
                                            <div className="notification-feed-item-body">
                                                {x.message}
                                            </div>
                                        </div>
                                        <div>
                                            <TextButton
                                              label="Mark as read"
                                              onClickFunction={() => markAlertAsRead(x.oid)}
                                              fontWeight="600"
                                              color="#26404E"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ColoredLine />
                            </>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default NotificationFeed;
