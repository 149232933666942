import React from 'react';
import Text from '../text/Text';
import { PrimaryButton } from '../buttonComponent/ButtonComponent';
import AgencyIcon from './img/agencyEmpty.svg';
import './tableemptystate.css';

const AgencyUsersNotFoundState = (props) => {
    const { text } = props;
    return (
        <div style={{ paddingTop: '3rem' }}>
            <div className="empty-state-container">
                <img loading="lazy" src={AgencyIcon} alt="assetsIcon" style={{ margin: 'auto' }} />
                <div className="empty-state-text">
                    <Text text="No Agency Found" size="1.25rem" weight="600" align="center" height="auto" lineHeight="30px" />
                    <Text text="We could not locate the agency you entered. Please double-check the name and try again, or search for a different agency available on Horizon" size="1rem" weight="400" align="center" color="#475467" height="auto" lineHeight="24px" />
                </div>
            </div>
        </div>
    );
};

export default AgencyUsersNotFoundState;
