import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import getCountryISO2 from 'country-iso-3-to-2';
import {
    BackButton, CreateWithIcon, OutlinedDeleteButton, SecondaryButton,
} from '../../../../component/buttonComponent/ButtonComponent';
import Title from '../../../../component/title/Title';
import ColoredLine from '../../../../component/coloredLine/ColoredLine';
import SnackBar from '../../../../component/snackbar/Snackbar';
import { CountriesCallingCode } from '../../../../component/countriesDropdown/CountriesDropdown';
import VerticalLinearStepper from '../../../../component/stepComponent/stepComponent';
import { APIUpdateChannel, APITestMessage } from '../../../../config/restAPI/PostAPI';
import { APIGetUsers } from '../../../../config/restAPI/FetchAPI';
import './manageNotifications.css';

const ManageNotifications = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agencyUUID');
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    // Calling code dropdown
    const [listCallingCode, setListCallingCode] = useState([]);
    const [countryCallingCodeSelected, setCountryCallingCodeSelected] = useState({
      id: 'MY',
      name: 'Malaysia',
      value: '+60',
    });
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [message, setMessage] = useState('');
    const [userData, setUserData] = useState();
    const [telegramStatus, setTelegramStatus] = useState();
    const [snackBarState, setSnackBarState] = useState(false);
    const [title, setTitle] = useState('');
    const [subTitleMsg, setSubTitleMsg] = useState('');
    const [apiStatus, setApiStatus] = useState('');
    const disableButton = userData?.contactNumber === undefined;
    // console.log(userData);

    const handleBackButton = () => {
        navigate('/settings/notifications', { replace: true });
    };

    const fetchUserDetails = async () => {
        try {
            const result = await APIGetUsers(setLoading, token, agencyId);
            const { data } = result.data;
            // setUserData(data);
            setUserData({
                userFirstName: data.userFirstName,
                userLastName: data.userLastName,
                userEmail: data.userEmail,
                contactCode: data.contactCode,
                contactNumber: ((data.contactNumber !== 0) && (data.contactNumber !== null) && (data.contactNumber !== undefined)) ? (data.contactNumber) : '',
                countryCode: getCountryISO2(data.countryCode),
                timezone: data.timezone,
                profileImageUrl: data.profileImageUrl,
                verifiedEmail: data.verifiedEmail,
            });
            // console.log('data :', data);
            setTelegramStatus(data?.channels?.find((x) => x.type === 'Telegram'));
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleChannel = async () => {
        const params = {
            channel: {
                type: 'Telegram',
                isEnabled: true,
            },
        };
        try {
            const result = await APIUpdateChannel(setLoading, token, agencyId, params);
            fetchUserDetails();
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleDisableChannel = async () => {
        const params = {
            channel: {
                type: 'Telegram',
                isEnabled: false,
            },
        };
        try {
            const result = await APIUpdateChannel(setLoading, token, agencyId, params);
            fetchUserDetails();
            setLoading(false);
        } catch (error) {
            // console.log(error);
        }
    };

    const sendTestMessage = async () => {
        const params = {
            channelOid: telegramStatus?.oid,
        };
        const result = await APITestMessage(setLoading, token, agencyId, params);
        try {
            const { data } = result;
            if (data.status === 200) {
                setSnackBarState(true);
                setApiStatus('success');
                setTitle('Success');
                setSubTitleMsg(data.message);
            } else {
                setSnackBarState(true);
                setApiStatus('error');
                setTitle('Error');
                setSubTitleMsg(data.message);
            }
            setLoading(false);
        } catch (error) {
            setSnackBarState(true);
            setApiStatus('error');
            setTitle('Error');
            setSubTitleMsg(result.response.data.message);
            // console.log(error);
        }
    };

    const handleStep = (value) => {
        switch (value) {
            case 'Telegram':
                if (telegramStatus === undefined) {
                    setActiveStep(0);
                } else if (telegramStatus?.chatId) {
                    setActiveStep(1);
                    if (telegramStatus?.chatId && telegramStatus?.notificationEnabled === true) {
                        setActiveStep(2);
                    }
                    if (telegramStatus?.chatId && telegramStatus?.notificationEnabled === true && telegramStatus?.connectionStatus === 200) {
                        setActiveStep(3);
                    }
                }
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        handleStep(state?.module);
    }, [userData]);

    useEffect(() => {
        fetchUserDetails();
    }, []);

    return (
        <div className="manage-notification-main-content">
            <div className="manage-notification-back-button">
                <BackButton label="Back to notification channels" btnClickBack={() => handleBackButton()} color="#FCFCFD" />
            </div>
            <div className="manage-notification-form">
                <div className="manage-notification-header">
                    <div className="manage-notification-title">
                        <Title title="Manage notification channel" titleSize="1.125rem" lineHeight="1.75rem" gap="0.25rem" deleteSubtitle>
                            {state?.module}
                        </Title>
                        <div className="manage-notification-button-group">
                            {telegramStatus === undefined || (telegramStatus && telegramStatus.isEnabled === false) ? (
                                <CreateWithIcon
                                  buttonHeight="2.75rem"
                                  label="Enable Channel"
                                  module="assetPerformance"
                                  width="4.0625rem"
                                  disable={disableButton}
                                  onClickFunction={() => handleChannel()}
                                />
                            ) : (
                                <OutlinedDeleteButton
                                  label="Disable Channel"
                                  buttonHeight="2.75rem"
                                  onClickFunction={() => handleDisableChannel()}
                                />
                            )}
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="manage-notification-row">
                        <div className="manage-notification-row-name">
                            Phone Number
                        </div>
                        <div className="manage-notification-phoneNo-css">
                            <CountriesCallingCode
                              list={listCallingCode}
                              setSelectionList={setCountryCallingCodeSelected}
                              selectionList={countryCallingCodeSelected}
                              text="MY"
                              isImage
                              value={userData?.contactNumber ? userData.contactNumber : ''}
                              initialValue={userData?.contactNumber}
                              message={userData?.contactNumber || ''}
                              setMessage={setMessage}
                              disabled
                            />
                            {userData?.contactNumber ? (
                                <div className="manage-notification-input-supporting-text">
                                    To change your phone number, navigate to the
                                    {' '}
                                    <Link to="/settings/profile" style={{ textDecoration: 'none' }}>
                                        Profile Settings
                                    </Link>
                                    {' '}
                                    page.
                                </div>
                            ) : (
                                <div className="manage-notification-input-supporting-text">
                                    To enable this channel, navigate to the
                                    {' '}
                                    <Link to="/settings/profile" style={{ textDecoration: 'none' }}>
                                        Profile Settings
                                    </Link>
                                    {' '}
                                    page and provide your phone number.
                                </div>
                            )}
                        </div>
                    </div>
                    <ColoredLine />
                    <div className="manage-notification-row">
                        <div className="manage-notification-row-name">
                            Setup Instructions
                        </div>
                        {(telegramStatus?.isEnabled === false) || (telegramStatus === undefined) ? (
                            <div className="vertical-linear-stepper-disabled">
                                <VerticalLinearStepper module={state?.module} activeStep={activeStep} sendTestMessage={sendTestMessage} isEnabled />
                            </div>
                        ) : (
                            <div className="vertical-linear-stepper">
                                <VerticalLinearStepper module={state?.module} activeStep={activeStep} sendTestMessage={sendTestMessage} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {snackBarState && (
                <div className="notification">
                    <SnackBar state={snackBarState} setState={setSnackBarState} title={title} subtitile={subTitleMsg} module={apiStatus} action={apiStatus} />
                </div>
            )}
        </div>
    );
};

export default ManageNotifications;
