import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const urlV3 = process.env.REACT_APP_RESTAPI_V3;
const xApiKey = process.env.REACT_APP_XAPIKEY;
const wsLogin = process.env.REACT_APP_WEBSOCKET_LOGIN_V3;
const wsLogout = process.env.REACT_APP_WEBSOCKET_LOGOUT_V3;

export const APIWSDisconnect = async (token, agencyOid) => {
    const result = await axios.delete(wsLogout, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyOid,
        },
    }).catch((error) => error);
    return result;
};

export const APIDeleteTeamMember = async (token, agencyId, params) => {
    const result = await axios.delete(`${urlV3}/team`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        data: params,
    }).catch((error) => error);
    return result;
};

export const APILogoutSessions = async (token, agencyId, params) => {
    const result = await axios.delete(`${urlV3}/sessions`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        data: params,
    }).catch((error) => error);
    return result;
};

export const APIDeleteAgency = async (token, agencyId, params) => {
    const result = await axios.delete(`${urlV3}/agency`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        data: params,
    }).catch((error) => error);
    return result;
};

export const APIDeleteGeofence = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.delete(`${urlV3}/geofences`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        data: params,
    }).catch((error) => error);
    return result;
};

export const APIDeleteDevice = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.delete(`${urlV3}/devices`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        data: params,
    }).catch((error) => error);
    return result;
};

export const APIDeleteAlertPolicy = async (token, setLoading, agencyId, params) => {
    setLoading(true);
    const result = await axios.delete(`${urlV3}/alertPolicies`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        data: params,
    }).catch((error) => error);
    return result;
};
