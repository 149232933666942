import React, { useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { TextButton } from '../../../../../component/buttonComponent/ButtonComponent';
import ColoredLine from '../../../../../component/coloredLine/ColoredLine';
import BreadCrumbs from '../../../../../component/breadcrumbs/BreadCrumbs';
import Title from '../../../../../component/title/Title';
import ConfigTab from '../../../../../component/configurationTab/configurationTab';
import BuildingIcon from '../../../../../image/building.svg';
import DeviceIcon from './img/cpuChip.svg';
import BeaconsIcon from './img/bluetooth.svg';
import ConnectivityIcon from './img/simCard.svg';
import './agencyInventory.css';

const seperator = [
    <img loading="lazy" src={BuildingIcon} alt="live" style={{ display: 'flex', alignItem: 'center' }} />,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Agencies</div>,
    <div style={{ color: '#475467', fontSize: '14px', fontWeight: 500 }}>Configurations</div>,
];

const navigationList = [
    {
        text: 'General',
        value: 1,
        path: '/agencies/Configurations/General',
        enabled: true,
    },
    {
        text: 'Inventory',
        value: 2,
        path: '/agencies/Configurations/Inventory',
        enabled: true,
    },
    {
        text: 'Users',
        value: 3,
        path: '/agencies/Configurations/Users',
        enabled: true,
     },
    {
        text: 'Billing',
        value: 4,
        path: '/agencies/Configurations/Billing',
        enabled: true,
    },
];

const AgencyInventory = () => {
    const { state } = useLocation();
    const [agencyData, setAgencyData] = useState(state);
    const [navigtionClick, setNavigtionClick] = useOutletContext();

    return (
        <div className="agency-inventory-container">
            <div className="agency-inventory-header">
                <div className="agency-inventory-page-header">
                    <BreadCrumbs customSeperator={seperator} />
                    <Title title="Configurations" titleSize="1.875rem" lineHeight="2.375rem" deleteSubtitle>
                        {agencyData.agencyName}
                        {' '}
                        •
                        {' '}
                        {agencyData.agencyId}
                    </Title>
                </div>
                <div className="agency-inventory-tabs">
                    <ConfigTab color="#FFFFFF" types={navigationList} module="agencies" users={agencyData.totalUsers} agencyRow={agencyData} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="agency-inventory-body">
                <div className="agency-inventory-body-container">
                    <div className="agency-inventory-body-rows">
                        <div className="agency-inventory-content">
                            <div className="agency-inventory-text-container">
                                <div className="agency-inventory-main-text">
                                    <img src={DeviceIcon} loading="lazy" alt="devices-icon" />
                                    Devices
                                </div>
                                <div className="agency-inventory-supporting-text">
                                    IoT devices are the primary sources of telemetry data that enables a digital twin of the agency’s fleet of assets.
                                    <br />
                                    <br />
                                    Assign new IoT devices or manage existing IoT devices that have been assigned to the agency.
                                </div>
                            </div>
                            <div className="agency-inventory-button-container">
                                <TextButton label="Manage" fontWeight={600} color="#26404E" />
                                <div className="agency-inventory-badge">
                                    {agencyData.totalDevices || 0}
                                </div>
                            </div>
                        </div>
                        <ColoredLine />
                        <div className="agency-inventory-content">
                            <div className="agency-inventory-text-container">
                                <div className="agency-inventory-main-text">
                                    <img src={BeaconsIcon} loading="lazy" alt="devices-icon" />
                                    Beacons
                                </div>
                                <div className="agency-inventory-supporting-text">
                                    Beacons enable hyper-localised indoor tracking of devices.
                                    <br />
                                    <br />
                                    Assign new beacons or manage existing beacons that have been assigned to the agency.
                                </div>
                            </div>
                            <div className="agency-inventory-button-container">
                                <TextButton label="Manage" fontWeight={600} color="#26404E" />
                                <div className="agency-inventory-badge">
                                    {agencyData.totalBeacons || 0}
                                </div>
                            </div>
                        </div>
                        <ColoredLine />
                        <div className="agency-inventory-content">
                            <div className="agency-inventory-text-container">
                                <div className="agency-inventory-main-text">
                                    <img src={ConnectivityIcon} loading="lazy" alt="devices-icon" />
                                    Connectivity
                                </div>
                                <div className="agency-inventory-supporting-text">
                                    Allow agencies to manage their Verge IoT SIMs through a single pane of glass.
                                </div>
                            </div>
                            <div className="agency-inventory-button-container">
                                <div className="agency-inventory-coming-soon-badge">
                                    Coming Soon
                                </div>
                            </div>
                        </div>
                        <ColoredLine />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgencyInventory;
