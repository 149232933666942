import React from 'react';
import './title.css';
import PropTypes from 'prop-types';

export default function Title(props) {
    const {
        title, subtitle, titleSize, subtitleSize, gap, lineHeight, subtitleLineHeight, deleteSubtitle, children, width, margin, textAlign,
    } = props;

    return (
        <div className="title-container" style={{ gap: gap, width: width }}>
            <div className="text-title" style={{ fontSize: titleSize, lineHeight: lineHeight, margin: margin }}>{title}</div>
            { subtitle && (
                <div className="supporting-text-title" style={{ fontSize: subtitleSize, lineHeight: subtitleLineHeight, textAlign: textAlign }}>{subtitle}</div>
            )}
            {
                deleteSubtitle && (
                    <div className="supporting-text-title" style={{ fontSize: subtitleSize, lineHeight: subtitleLineHeight }}>
                        {children}
                    </div>
                )
            }
        </div>
    );
}

Title.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleSize: PropTypes.string,
    subtitleSize: PropTypes.string,
    gap: PropTypes.string,
    lineHeight: PropTypes.string,
};
