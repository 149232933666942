import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from '../../config/reducer/Auth';
import ImgWithTitle from '../../component/workspaceContainer/ImgWithTitle';
import Text from '../../component/text/Text';
import { TextButton, BackButton, PrimaryButton } from '../../component/buttonComponent/ButtonComponent';
import './reset-password.css';

const PasswordReset = ({ history }) => {
    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    const navigate = useNavigate();
    const btnBack = (e) => {
        e.preventDefault();
        navigate('/login', { replace: true });
    };

    return (
        <>
        {isMobileOrTablet && (
            <section className="forgotPW-mainMobile">
                <div className="forgot-password-container-main-mobile">
                    <div className="forgot-password-content-mobile">
                        <ImgWithTitle titleText="Password reset" subText="Voilà! Your password has been successfully reset. Now let's try logging in again." icon="Success" style={{ width: '100%' }} />
                        <PrimaryButton label="Back to log in" onClickFunction={btnBack} />
                    </div>
                </div>
            </section>
        )}
        {isDefault && (
            <div className="forgot-password-container-main">
                <div className="forgot-password-container">
                    <ImgWithTitle titleText="Password reset" subText="Voilà! Your password has been successfully reset. Now let's try logging in again." icon="Success" />
                    <PrimaryButton label="Back to log in" onClickFunction={btnBack} />
                </div>
            </div>
        )}
        </>
  );
};

export default PasswordReset;
