import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useMediaQuery } from 'react-responsive';
import { APIRequestResetPassword } from '../../config/restAPI/PostAPI';
import ImgWithTitle from '../../component/workspaceContainer/ImgWithTitle';
import TextInput from '../../component/textInput/TextInput';
import { BackButton, PrimaryButton } from '../../component/buttonComponent/ButtonComponent';
import './reset-password.css';

const ForgotPassword = ({ history }) => {
    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [input, setInput] = useState({
        email: '',
    });
    const [keyUp, setKeyUp] = useState('', false);

    const btnBack = (e) => {
        e.preventDefault();
        navigate('/login', { replace: true });
    };

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const resetPassword = async (e) => {
        // e.preventDefault();
        if (!input.email) {
            setEmailError(true);
            setEmailErrorMessage('Email address is required.');
        } else if (!isValidEmail(input.email)) {
            setEmailError(true);
            setEmailErrorMessage('Invalid email address.');
        } else {
            const base64Email = Base64.encode(input.email);
            const params = {
                email: base64Email,
            };
            try {
                const result = await APIRequestResetPassword(params, setLoading);
                    if (result.data.status === 200) {
                    setEmailError(false);
                    setLoading(false);
                    setEmailErrorMessage('');
                    navigate('/checkYourEmail', { replace: true, state: params });
                }
            } catch (err) {
                console.log('Error from input');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (keyUp !== '') {
            resetPassword();
        }
    }, [keyUp]);

    const emailOnChangeFunction = (e) => {
        setInput({ ...input, email: e.target.value });
    };

    return (
        <>
         {isMobileOrTablet && (
            <section className="forgotPW-mainMobile" style={{ height: document.documentElement.clientHeight }}>
                <div className="forgot-password-container-main-mobile">
                    <div className="forgot-password-content-mobile">
                        <ImgWithTitle titleText="Forgot password?" subText="No worries, it happens to the best of us. Enter your email below and we'll send you reset instructions." icon="Users" style={{ width: '100%' }} />
                        <div style={{ width: '100%' }}>
                            <TextInput label="Forgot" placeholder="Enter your email" setInput={setInput} input={input} name="email" type="text" error={emailError} setError={setEmailError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={(e) => emailOnChangeFunction(e)} />
                            { emailError && (
                                <div className="email-password-error" style={{ paddingTop: '0.5rem' }}>{emailErrorMessage}</div>
                            )}
                        </div>
                        <PrimaryButton label="Reset password" onClickFunction={resetPassword} loading={loading} disable={loading} />
                        <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                    </div>
                </div>
            </section>
         )}
         {isDefault && (
            <div className="forgot-password-container-main">
                <div className="forgot-password-container">
                    <ImgWithTitle titleText="Forgot password?" subText="No worries, it happens to the best of us. Enter your email below and we'll send you reset instructions." icon="Users" />
                    <div style={{ width: '100%' }}>
                        <TextInput label="Forgot" placeholder="Enter your email" setInput={setInput} input={input} name="email" type="text" error={emailError} setError={setEmailError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={(e) => emailOnChangeFunction(e)} />
                        { emailError && (
                            <div className="email-password-error" style={{ paddingTop: '0.5rem' }}>{emailErrorMessage}</div>
                        )}
                    </div>
                    <PrimaryButton label="Reset password" onClickFunction={resetPassword} loading={loading} disable={loading} />
                    <BackButton label="Back to log in" btnClickBack={btnBack} color="#FFFFFF" />
                </div>
            </div>
         )}
        </>
  );
};

export default ForgotPassword;
