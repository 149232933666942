import React, { useState, useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import { useApiData } from '../../../config/reducer/Modules';
// import { assetPerformanceNavigationList } from '../../../config/function/tabNavigationList';
import './audits.css';

export default function Audits() {
    const { apiResponse } = useApiData();
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const assetPerformanceNavigationList = [
        {
            text: 'Asset Performance', value: 1, path: '/insights/assetPerformance', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.assetPerformance,
        },
        {
            text: 'IOrail', value: 2, path: '/insights/IOrail', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.iorail,
        },
        {
            text: 'IOroad', value: 3, path: '/insights/IOroad', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.ioroad,
        },
        {
            text: 'Audits', value: 4, path: '/insights/audits', enabled: true,
        },
    ];
    useEffect(() => {
        setNavigtionClick(4);
        setNavigationState('insights');
    }, []);
    return (
        <div className="asset-performance-container">
            <ListNavigationTab list={assetPerformanceNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
        </div>
    );
}
