/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import moment from 'moment';
import ApexCharts from 'apexcharts';
import styled from 'styled-components';
import { BackButton, ExportCSVButton, IOSSwitch } from '../../../../component/buttonComponent/ButtonComponent';
import './TripReport.css';
import { downloadCSV } from '../../../../config/function/exportCSV';
import Title from '../../../../component/title/Title';
import TripReportList from '../../../../component/tripReportList/TripReportList';
import MapTripReport from '../../../../component/mapTripReport/mapTripReport';
import Drawer from '../../../../component/drawer/Drawer';
import SnackBar from '../../../../component/snackbar/Snackbar';
import { firstCoordinate } from '../../../../config/function/miscFunction';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 5,
  [`&.${linearProgressClasses.root}`]: {
    height: '8px',
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#EAECF0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#365A6D',
  },
}));

function parseReadableDuration(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  hours = (hours < 10) ? `${hours}` : hours;
  minutes = (minutes < 10) ? `${minutes}` : minutes;
  remainingSeconds = (remainingSeconds < 10) ? `${remainingSeconds}` : remainingSeconds;

  // return hours + ':' + minutes + ':' + remainingSeconds;
  return (
    `${hours > 0 ? `${hours > 1 ? `${Math.round(hours)} hours` : `${Math.round(hours)} hour`} ` : ''} ${minutes > 0 ? `${minutes > 1 ? `${Math.round(minutes)} minutes` : `${Math.round(minutes)} minute`} ` : ''} ${remainingSeconds > 0 ? `${remainingSeconds > 1 ? `${Math.round(remainingSeconds)} seconds` : `${Math.round(remainingSeconds)} second`} ` : ''}`
  );
  // return `${hours}:${minutes}:${remainingSeconds}`;
}

const TripReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    tripData,
    fromTime,
    toTime,
  } = location.state;

  const [oneTimeLoad, setOneTimeLoad] = useState(false);
  const [tripDetailsData, setTripDetailsData] = useState();
  const [firstCoordsOrigin, setFirstCoordsOrigin] = useState([0, 0]); // first coords
  const [tripsChart, setTripsChart] = useState(tripData.tripsPerHour.map((x) => [x.timestamp * 1000, x.totalTripPerHour])); // this state for chart
  const [page, setPage] = useState(0);

  const [hoverTripDetail, setHoverTripDetail] = useState();
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);
  const [progress, setProgress] = useState(0);

  const [checked, setChecked] = useState(false);

  const [hoverInfo, setHoverInfo] = useState(null);

  const reducer = (value) => {
    const featureCollection = {
      type: 'FeatureCollection',
      features: value.map((e, index) => {
        const container = {
          type: 'Feature',
          properties: {
            id: index + 1,
            content: {
              assetName: e.assetName,
              origin: e.origin,
              destination: e.destination,
              startTraceId: e.startTraceId,
              startTime: e.startTime,
              endTime: e.endTime,
              idlingRatio: e.idlingRatio,
              deviceSerial: e.deviceSerial,
              status: e.status,
              totalDistance: e.totalDistance,
              totalDuration: e.totalDuration,
              tripGeo: e.tripGeo,
            },
          },
          id: index,
          geometry: e.tripGeo,
        };
        return container;
      }),
    };
    return featureCollection;
  };

  const [tripsLineString, setTripsLineString] = useState(reducer(tripData.tripList));
  const [tripList, setTripList] = useState(tripData.tripList);
  const [filteredTrips, setFilteredTrips] = useState();

  const [isDrawerShowing, setDrawerShowing] = useState(true);
  const [resetButton, setResetButton] = useState(false);

  // console.log('tripData', tripData.tripList);

  const onClick = () => {
    setPage((page + 1) % 2);
    if (page === 1) {
      setTripDetailsData();
      setTripsChart(tripData.tripsPerHour.map((x) => [x.timestamp * 1000, x.totalTripPerHour]));
      setTripList(tripData.tripList);
      setTripsLineString(reducer(tripData.tripList));
      setDrawerShowing(true);
    }
  };

  const [dateRange, setDateRange] = useState();

  const [resetState, setResetState] = useState(false);

  const handleToggleDrawer = () => {
    setDrawerShowing(!isDrawerShowing);
  };

  const backToSearch = () => {
    navigate('/insights/odAnalytics', { replace: true });
  };

  const setVisible = () => {
    setIncorrectCredentials(true);
    setProgress(20);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const resetChart = () => {
    if (!isDrawerShowing) {
      const chart = ApexCharts.getChartByID('trips-bar-Chart');
      chart.resetSeries();
    }
    setTripList(tripData.tripList);
    // this to reset chart state
    setTripsChart(tripData.tripsPerHour.map((x) => [x.timestamp * 1000, x.totalTripPerHour]));
    // this for reset marker indicate icons
    const mappedValue = tripsLineString.features.map((x) => x.geometry);
    const takeFirstValue = firstCoordinate(mappedValue);
    setFirstCoordsOrigin(takeFirstValue);
    setDateRange();
    setResetState(!resetState);
  };

  useEffect(() => {
    if (incorrectCredentials === true) {
      if (progress < 100) {
        const timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress + 20));
        }, 500);
        return () => {
          if (progress === 80) {
            const fromTimeFormatted = moment(fromTime).format('YYYY-MM-DD');
            const toTimeFormatted = moment(toTime).format('YYYY-MM-DD');
            try {
              const data = tripData.tripList.map((x) => ({
                assetName: x.assetName,
                deviceSerial: x.deviceSerial,
                origin: x.origin,
                destination: x.destination,
                startTime: x.startTime,
                endTime: x.endTime,
                totalDistance: `${x.totalDistance / 1000} km`,
                totalDuration: parseReadableDuration(x.totalDuration),
              }));
              downloadCSV(data, `Trip_Report_${fromTimeFormatted}_${toTimeFormatted}.csv`);
            } catch (err) {
              console.log('Failed to export csv');
            }
          }
          clearInterval(timer);
        };
      }
    }
  }, [progress]);

  // this logic is for reset button disabled. if its filtered it will enable the reset button.
  useEffect(() => {
    if (tripData.tripList.length === tripList.length) {
      setResetButton(true);
    } else {
      setResetButton(false);
    }
  }, [tripList, tripData]);

  // this to reload the chart every time the drawer open. this logic to fix the reset button clicked when graph not render yet.
  useEffect(() => {
    if (!isDrawerShowing) {
      const chart = ApexCharts.getChartByID('trips-bar-Chart');
      chart.resetSeries();
    }
  }, [isDrawerShowing]);

  return (
    <div className="trip-report-container">
      <div className="trip-report-header">
        <div className="trip-report-button-container" style={{ display: 'flex' }}>
          <BackButton label="Back to search" btnClickBack={backToSearch} color="#FFFFFF" />
        </div>
        <div className="trip-report-header-title-container">
          <Title title="Trips" titleSize="30px" lineHeight="38px" deleteSubtitle />
          <ExportCSVButton label="Export CSV" buttonHeight="40px" onClickFunction={(e) => setVisible(e)} />
        </div>
      </div>
      <div className="trip-report-body">
        <div className="trip-report-map-container">
          {page === 0 && (
            <div className="trip-marker-button-container">
              <div className="trip-marker-button-text">
                Show trip no.
              </div>
              <IOSSwitch checked={checked} onChange={handleChange} />
            </div>
          )}
          <MapTripReport
            oneTimeLoad={oneTimeLoad}
            setOneTimeLoad={setOneTimeLoad}
            tripsLineString={tripsLineString}
            tripDetailsData={tripDetailsData}
            page={page}
            hoverTripDetail={hoverTripDetail}
            dateRange={dateRange}
            setDateRange={setDateRange}
            tripList={tripList}
            resetChart={resetChart}
            setFirstCoordsOrigin={setFirstCoordsOrigin}
            firstCoordsOrigin={firstCoordsOrigin}
            hoverInfo={hoverInfo}
            setHoverInfo={setHoverInfo}
            resetState={resetState}
            setTripDetailsData={setTripDetailsData}
            setPage={setPage}
            checked={checked}
            setChecked={setChecked}
          />
          <TripReportList
            tripData={tripData}
            tripList={tripList}
            setTripList={setTripList}
            setTripDetailsData={setTripDetailsData}
            tripDetailsData={tripDetailsData}
            page={page}
            setPage={setPage}
            onClick={onClick}
            hoverTripDetail={hoverTripDetail}
            setHoverTripDetail={setHoverTripDetail}
            dateRange={dateRange}
            setDateRange={setDateRange}
            filteredTrips={filteredTrips}
            setFilteredTrips={setFilteredTrips}
            hoverInfo={hoverInfo}
            setHoverInfo={setHoverInfo}
          />
          {page === 0 && (
            <Drawer show={isDrawerShowing} resetButtonState={resetButton} handleToggleDrawer={handleToggleDrawer} tripData={tripData} tripChart={tripsChart} setTripChart={setTripsChart} setDateRange={setDateRange} setTripList={setTripList} resetChart={resetChart} />
          )}
        </div>
      </div>
      {incorrectCredentials && (
        <div className="notification">
          <SnackBar state={incorrectCredentials} setState={setIncorrectCredentials} title="Exporting Data" deleteSubtitle module="TelemetryLog" action="info">
            <div className="trips-notification-body">
              Your download will begin shortly
              <div className="trips-progress-bar">
                <BorderLinearProgress variant="determinate" value={progress} style={{ width: '280px' }} />
                <div className="trips-progress-bar-text">
                  {progress}
                  % processed...
                </div>
              </div>
            </div>
          </SnackBar>
        </div>
      )}
    </div>
  );
};

export default TripReport;
