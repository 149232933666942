/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React, { createContext, useReducer } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { APIWSConnect } from '../restAPI/PostAPI';

let socket;

const initialState = {
 channel: null, data: null, setChannel: (channel) => {}, CloseConnection: (value) => {}, payload: null, newPayloadId: null, selectedAssetInfo: null, viewAssetCard: false, selectedAsset: { _id: '', getAssetInfo: false }, switchAgency: false,
};

const wsConnect = process.env.REACT_APP_WEBSOCKET;

const WebsocketContext = createContext({
    payload: null,
    newPayloadId: null,
    viewAssetCard: false,
    selectedAsset: { _id: '', getAssetInfo: false },
    selectedAssetInfo: null,
    switchAgency: false,
    selectAsset: (variables, asset) => {},
    closeAssetCard: () => {},
});

const convertTimestamp = (timstamp) => {
    const convert = moment(timstamp).format('X');
    return convert;
};

const WebsocketReducer = (state, action) => {
    // console.log({ state, action });
    // console.log(action);
    switch (action.type) {
        case 'DATA': {
            return {
                ...state,
                channel: action.channel,
                data: action.data,
                newPayloadId: action.data.data && action.data.data.timestamp && `${convertTimestamp(action.data.data.timestamp)}-${action.data.data.deviceOid}`,
            };
        }
        case 'ASSET': {
            // an asset selected either from map or card
            return {
                ...state,
                viewAssetCard: true,
                selectedAsset: action.selectedAsset,
                selectedAssetInfo: action.assetInfo,
            };
        }
        case 'CLOSE_ASSET_CARD': {
            // to close the asset card
            return {
                ...state,
                viewAssetCard: false,
                selectedAssetInfo: null,
                selectedAsset: null,
            };
        }
        default:
        return state;
    }
};

const WebsocketProvider = (props) => {
    const [state, dispatch] = useReducer(WebsocketReducer, initialState);

    // console.log('stateWORRRKKK', state);
    const CloseConnection = (value) => {
        if (socket) {
            if (value === 'disconnect') {
                socket.readyState === WebSocket.OPEN && socket.close(1000); // this to reconnect
            }
            if (value === 'logout') {
                socket.readyState === WebSocket.OPEN && socket.close(3000); // this to logout
            }
        }
    };
    const setChannel = (channel) => {
        socket = new WebSocket(`${wsConnect}?token=${Cookies.get('jwtToken')}`, 'string');
        // console.log('socket', socket);
        // Handle websocket open event
        // Sends the agency id as the channel name to "subscribe" to the "topic"
        socket.addEventListener('open', async (evt) => {
            // console.log('open', evt);
            if (socket.readyState === 1) {
                console.log(`Websocket Connection Established: ${channel} @ ${new Date().toISOString()}`);
                // console.log('websocket connection established');
                socket.send(channel);
            }
        });

        // Handles the websocket close event
        // Attempts to refresh the Websocket SessionId if and only if jwtToken exists
        // This does NOT handle expired tokens, which should rightfully be handled by on Error
        socket.addEventListener('close', async (evt) => {
            // console.log('event', evt);
            if (evt.code === 1006) {
                console.log(`Server unexpectedly terminated Websocket connection: ${new Date().toISOString()}`);
            } else {
                console.log(`Websocket Connection Closed: ${new Date().toISOString()}`);
            }
            // console.log('go here close', evt);

            // this to check if there is a switch agency, if there is switch agency or logout it will not go to this statement
            if (evt.code !== 1000 && evt.code !== 3000 && evt.code !== 1005) {
                // console.log('this for switch agency');
                if (typeof Cookies.get('jwtToken') !== 'undefined') {
                    console.log(`Attempting to reconnect to Websocket: ${new Date().toISOString()}`);

                    await APIWSConnect(Cookies.get('jwtToken'), Cookies.get('agencyUUID')).then((res) => {
                        setChannel(Cookies.get('agency-id'));
                    }).catch((error) => {
                        console.log(`Something went wrong: ${error}`);
                        window.location.reload();
                    });
                }
            }
        });

        // Handles the websocket error event
        // Removes existing cookies (assumption that the tokens have expired) and reloads the page
        // User should expect to be redirected to the '/' login page.
        socket.addEventListener('error', (evt) => {
            // if the token are already return error but the token has removed will not go to this if statement. this scenario if graphql sending in error 498 invalid token
            // console.log('this in the addEventListener error');
            if (Cookies.get('jwtToken')) {
                console.log('Websocket Connection Error. Reauthentication required.');
                Cookies.remove('jwtToken');
                Cookies.remove('agency-id');
                Cookies.remove('agencyUUID');
                Cookies.remove('role');
                Cookies.remove('SessionID');
                Cookies.remove('agency-name');
                Cookies.remove('name');
                Cookies.remove('_id');
                Cookies.remove('privileges');
                Cookies.remove('firstName');
                Cookies.remove('listAgency');
                Cookies.remove('profileImageUrl');
                window.location.reload();
            }
        });

        socket.addEventListener('message', (evt) => {
            let payload = {};
            try {
                // const websocketData = JSON.parse(evt.data);
                // // console.log('payload', payload);
                // if (websocketData.type === 'telemetry') {
                //     payload = websocketData.data;
                // }
                payload = JSON.parse(evt.data);
            } catch (error) {
                console.log('Parse Websocket Data error', error);
            }
            dispatch({ type: 'DATA', data: payload, channel });
        });
    };

    const selectAsset = (variables, info) => {
        dispatch({ type: 'ASSET', selectedAsset: variables, assetInfo: info });
    };
    const closeAssetCard = () => {
        dispatch({ type: 'CLOSE_ASSET_CARD' });
    };

    return (
        <WebsocketContext.Provider
          value={{
            channel: state.channel, data: state.data, setChannel, CloseConnection, payload: state.payload, newPayloadId: state.newPayloadId, viewAssetCard: state.viewAssetCard, selectedAsset: state.selectedAsset, selectedAssetInfo: state.selectedAssetInfo, selectAsset, closeAssetCard, switchAgency: state.switchAgency,
           }}
          {...props}
        />
    );
};

export { WebsocketContext, WebsocketProvider };
