import React, {
    useEffect, useState, useContext, useCallback, useRef, useLayoutEffect, Components,
   } from 'react';
   import moment from 'moment';
   import 'moment-timezone';
   import Select, { components } from 'react-select';
   import Cookies from 'js-cookie';
   import { useNavigate, useOutletContext } from 'react-router';
   import Dropzone from 'react-dropzone';
   import countryCodes from 'country-codes-list';
   import { CircleFlag } from 'react-circle-flags';
   import timezones from 'timezones-list';
   import TimezoneSelect, { allTimezones } from 'react-timezone-select';
   import getCountryISO2 from 'country-iso-3-to-2';
   import getCountryISO3 from 'country-iso-2-to-3';
   import axios from 'axios';
   import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
  } from 'react-image-crop';
  import 'react-image-crop/dist/ReactCrop.css';
   import {
    getTimeZones, rawTimeZones, timeZonesNames, abbreviations,
   } from '@vvo/tzdb';
   import { WebsocketContext } from '../../../config/reducer/Websocket';
   import { AuthContext } from '../../../config/reducer/Auth';
   import Modal from '../../../component/modal/Modal';
  import { APIGetUsers, APIGetCountries, APIGetV3TeamMembers } from '../../../config/restAPI/FetchAPI';
  import { APIUpdateUsers } from '../../../config/restAPI/PatchAPI';
   import Title from '../../../component/title/Title';
   import Avatar from '../../../component/avatar/Avatar';
   import './Profile.css';
   import SearchInput from '../../../component/searchInput/SearchInput';
   import ConfigTab from '../../../component/configurationTab/configurationTab';
   import TextInput from '../../../component/textInput/TextInput';
   import UploadFile from '../../../component/uploadFile/UploadFile';
   import { CountriesTimezone, CountriesWithFlags, CountriesCallingCode } from '../../../component/countriesDropdown/CountriesDropdown';
   import {
 SecondaryButton, BackButton, PrimaryButton, TextButton,
} from '../../../component/buttonComponent/ButtonComponent';
   import TimezoneIcon from './img/timezone.svg';
   import VerifiedIcon from './img/verified.svg';
   import SnackBar from '../../../component/snackbar/Snackbar';

   const { Option, SingleValue } = components;
   const IconOption = (props) => (
       <Option {...props}>
           <CircleFlag countryCode="my" style={{ width: '1.25rem' }} />
           {props}
       </Option>
   );
   const ValueOption = (props) => (
       <SingleValue {...props}>
           {/* <img className="maybeUseClassName" src={props.data.label} style={{ width: 30 }} alt="" /> */}
           <CircleFlag countryCode="my" style={{ width: '1.25rem' }} />
       </SingleValue>
   );

   const Profile = (props, { history }) => {
    const { setCookiesUpdated, cookiesUpdated, totalUsers } = props;
       const navigate = useNavigate();

       // Logout
       const payloadContext = useContext(WebsocketContext);
       const context = useContext(AuthContext);
       const { logout } = context;
       const { CloseConnection } = payloadContext;

       function getWindowSize() {
           const { innerWidth, innerHeight } = window;
           return { innerWidth, innerHeight };
       }
       const labelRef = useRef(null);
       const inputRef = useRef(null);
       const callingCodeRef = useRef(null);
       const countriesSelectorRef = useRef(null);
       const countriesTimezoneRef = useRef(null);

       const name = Cookies.get('name');
       const token = Cookies.get('jwtToken');
       const agencyID = Cookies.get('agencyUUID');
       const sessionID = Cookies.get('SessionID');

       // Update Cookies state
    //    const [cookiesUpdated, setCookiesUpdated] = useState(false);

       const [windowSize, setWindowSize] = useState(getWindowSize());

       const [input, setInput] = useState('');
       const [emailError, setEmailError] = useState(false);
       const [loading, setLoading] = useState(false);
       const [timezoneList, setTimezoneList] = useState([]);
       const [selectedTimezone, setSelectedTimezone] = useState({
           value: 'MY',
           label: 'Asia/Kuala Lumpur (MYT) UTC+8:00',
       });
       // const [selectedTimezone, setSelectedTimezone] = useState({});
       const [selectedCountry, setSelectedCountry] = useState({
           value: 'MY',
           label: 'Malaysia',
           icons: 'https://hatscripts.github.io/circle-flags/flags/my.svg',
       });
       const [countryCode, setCountryCode] = useState([]);
       const [inputHP, setInputHP] = useState('');
       const [selectedCountryCode, setSelectedCountryCode] = useState({
           value: '+60',
           label: 'MY',
         });
       const [countries, setCountries] = useState([]);

       const [isLoaded, setIsLoaded] = useState(false);
       const [isErrored, setIsErrored] = useState(false);
       const [svg, setSvg] = useState(null);
       const [timezoneCountryCode, setTimezoneCountryCode] = useState('');

       const [svgurl, setSvgurl] = useState(null);

       const [saveButtonState, setSaveButtonstate] = useState(false);
       const [displaySnackbar, setDisplaySnackbar] = useState(false);
       const [displayResendSnackbar, setDisplayResendSnackbar] = useState(false);

     // Timezone dropdown
     const [listTimezone, setListTimezone] = useState([]);
     const [timezoneSelectionList, setTimezoneSelectionList] = useState({});
     const [inputSelectonText, setInputSelectonText] = useState('');
     const [loadAsset, setLoadAsset] = useState(false);

     // Country dropdown
     const [listCountry, setListCountry] = useState([]); // this is for list search country dropdown
     const [countrySelectionList, setCountrySelectionList] = useState({}); // this for country selection state
     const [inputSelectonTextCountry, setInputSelectonTextCountry] = useState('');
     const [selectedFlag, setSelectedFlag] = useState('');
     const [countryUpdated, setCountryUpdated] = useState(0);

     // Calling code dropdown
     const [listCallingCode, setListCallingCode] = useState([]);
     const [countryCallingCodeSelected, setCountryCallingCodeSelected] = useState({
        id: 'MY',
        name: 'Malaysia',
        value: '+60',
     });
     const [countryCallingCodeUpdate, setCountryCallingCodeUpdate] = useState(false);
     const [message, setMessage] = useState('');

     // Profile photo
     const [profileImage, setProfileImage] = useState('');
     const [output, setOutput] = useState(null);

     // Low positioned components
     const [mousePos, setMousePos] = useState({});
     const [lowPositionCallingCode, setLowPositionCallingCode] = useState(false);
     const [lowPositionCountry, setLowPositionCountry] = useState(false);
     const [lowPositionTimezone, setLowPositionTimezone] = useState(false);

     // Email comment states
     const [emailState, setEmailState] = useState('sent');

     // Fetch account details
     const [getAccount, setGetAccount] = useState({
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        contactCode: '',
        contactNumber: '',
        countryCode: '',
        timezone: '',
        profileImage: '',
     });

     // Update account details
     const [updateAccount, setUpdateAccount] = useState({});
     const [fieldType, setFieldType] = useState({
        type: '',
        value: '',
     });

     const [firstNameValue, setFirstNameValue] = useState('');
     const [firstNameInput, setFirstNameInput] = useState(false);

     const [lastNameValue, setLastNameValue] = useState('');
     const [lastNameInput, setLastNameInput] = useState(false);

     const [emailValue, setEmailValue] = useState('');
     const [emailInput, setEmailInput] = useState(false);
     const [emailErrorMessage, setEmailErrorMessage] = useState('');

     const [phoneNumberValue, setPhoneNumberValue] = useState('');
     const [phoneNumberInput, setPhoneNumberInput] = useState(false);

     const [profileImageValue, setProfileImageValue] = useState('');
     const [profileImageInput, setProfileImageInput] = useState(false);

     const [countriesValue, setCountriesValue] = useState('');
     const [countriesInput, setCountriesInput] = useState(false);

     const [timezoneValue, setTimezoneValue] = useState(false);
     const [timezoneInput, setTimezoneInput] = useState(false);

     const [updateEmailLogout, setUpdateEmailLogout] = useState(false);
     const [btnSavePressed, setBtnSavePressed] = useState(false);
     const [btnResendPressed, setBtnResendPressed] = useState(false);
     const [logoutWarn, setLogoutWarn] = useState(false);
     const [updateAccountFieldState, setUpdateAccountFieldState] = useState(false);
     const [updateAccountFieldStateCallingCode, setUpdateAccountFieldStateCallingCode] = useState(false);
     const [updateAccountFieldStateCountries, setUpdateAccountFieldStateCountries] = useState(false);
     const [updateAccountFieldStateTimezone, setUpdateAccountFieldStateTimezone] = useState(false);
     const [cancelButtonState, setCancelButtonState] = useState(false);
     const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);

     const [phoneNumberFix, setPhoneNumberFix] = useState(false);

    // const [totalUsers, setTotalUsers] = useState(0);
    // const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        if (selectedCountry.value) {
            setTimezoneCountryCode(selectedCountry.value);
        }
    }, [selectedCountry]);

    const CircleFlags = (country) => <CircleFlag countryCode="my" style={{ width: '1.25rem' }} />;

    const getCountries = async () => {
        try {
            const countriesAPI = await APIGetCountries(setLoading);
            if (countriesAPI.status === 200) {
                const { data } = countriesAPI;
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
                setLoading(false);
            } else {
                // console.log('error');
            }
        } catch (e) {
            // console.log(e);
        }
    };

    const resetPage = () => {
        setSaveButtonstate(false);
        setUpdateAccount({});
        setCancelButtonState(true);
        setPhoneNumberUpdated(false);

        // Reset phone number
        setMessage(getAccount.contactNumber);

        // Reset timezone
        setSelectedTimezone(getAccount.timezone);

        setCountryUpdated(0);

        setLogoutWarn(false);
        setBtnSavePressed(false);

        setOutput(null);

        // Reset input fields
        setFirstNameInput(false);
        setLastNameInput(false);
        setEmailInput(false);
        setPhoneNumberInput(false);
        setProfileImageInput(false);
        setCountriesInput(false);
        setTimezoneInput(false);
        setTimezoneValue(false);

        setEmailErrorMessage('');

        setPhoneNumberFix(false);
        setCountryCallingCodeUpdate(false);

        // Reset country selected
        setSelectedFlag(getAccount.countryCode);
    };
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    // to call the Fetch User API
    const APICalledUsers = async (params) => {
        try {
            const result = await APIGetUsers(setLoading, token, agencyID);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (params) {
                    setCookiesUpdated(cookiesUpdated + 1);
                }
                Cookies.set('profileImageUrl', data.profileImageUrl);

                setGetAccount({
                    userFirstName: data.userFirstName,
                    userLastName: data.userLastName,
                    userEmail: data.userEmail,
                    contactCode: data.contactCode,
                    contactNumber: ((data.contactNumber !== 0) && (data.contactNumber !== null) && (data.contactNumber !== undefined)) ? (data.contactNumber) : '',
                    countryCode: getCountryISO2(data.countryCode),
                    timezone: data.timezone,
                    profileImageUrl: data.profileImageUrl,
                    verifiedEmail: data.verifiedEmail,
                });
                if (data.verifiedEmail) {
                    setEmailState('verified');
                }
                setLoading(false);
                resetPage();
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const confirmLogout = async (e) => {
            const response = await axios.delete(`${process.env.REACT_APP_WEBSOCKET_LOGOUT}?session=${sessionID}`, null, { credentials: 'same-origin' });
            if (response.status === 200) {
                CloseConnection('logout');
                logout();
                navigate('/login', { replace: true });
            } else {
                // console.log('this to add in snackbar ', response);
            }
    };

    // to call the Update User API
    const APIUpdateAccount = async (params) => {
        try {
            // console.log('PARAMS: ', params);
            const result = await APIUpdateUsers(token, agencyID, params);
            // console.log('result: ', result);
            if (result.data.status === 200) {
                if (params.userFirstName && params.userLastName) {
                    Cookies.set('name', `${params.userFirstName} ${params.userLastName}`);
                    setCookiesUpdated(cookiesUpdated + 1);
                } else if (params.userFirstName && !params.userLastName) {
                    Cookies.set('name', `${params.userFirstName} ${getAccount.userLastName}`);
                    setCookiesUpdated(cookiesUpdated + 1);
                } else if (!params.userFirstName && params.userLastName) {
                    Cookies.set('name', `${getAccount.userFirstName} ${params.userLastName}`);
                    setCookiesUpdated(cookiesUpdated + 1);
                }

                const { data } = result.data;
                setLoading(false);
                setSaveButtonstate(false);
                APICalledUsers(params.profileImage);
                const option = { expires: 10 };
                if (updateEmailLogout) {
                    await confirmLogout();
                    resetPage();
                }
            } else {
                const { data } = result.data;
                console.log('Unsuccessfully updated');
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    let timeout;

    // Resend OTP email
    const APIResendOTP = async (params) => {
        try {
            const result = await APIUpdateUsers(token, agencyID, params);
            if (result.data.status === 200) {
                setDisplayResendSnackbar(true);
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    setDisplayResendSnackbar(false);
                }, 4000);
            } else {
                const { data } = result.data;
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

       // Get width of input fields and retrieve all countries
       useEffect(() => {
            document.documentElement.style.setProperty('--label-width', `${labelRef.current.clientWidth}px`);
            const labelWidth = getComputedStyle(document.documentElement).getPropertyValue('--label-width');
            document.documentElement.style.setProperty('--input-width', `${inputRef.current.clientWidth}px`);
            const inputWidth = getComputedStyle(document.documentElement).getPropertyValue('--input-width');

            const myCountryCodesObject = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');
            const countryCodeKeys = Object.keys(myCountryCodesObject);
            const countryCodeValues = Object.values(myCountryCodesObject);
            if (token) {
                APICalledUsers('');
                // fetchTeamMembers('');
            }
       }, []);

    const heightPercentage = (ref) => {
        let heightState;
        if (ref.current !== null) {
            const percentage = (ref.current.offsetTop / windowSize.innerHeight) * 100;
            if (percentage > 60) {
                heightState = true;
            } else {
                heightState = false;
            }
        }
        return heightState;
    };

    if (name) {
        const firstName = () => {
            const nameArray = name.split(' ');
            return nameArray[0];
        };
    }

       const navigationList = [
        {
            text: 'Profile',
            value: 1,
            path: '/settings/profile',
            enabled: true,
        },
        {
            text: 'Security',
            value: 2,
            path: '/settings/security',
            enabled: true,
        },
        {
            text: 'Team',
            value: 3,
            path: '/settings/teams',
            enabled: true,
        },
        {
            text: 'Notifications',
            value: 4,
            path: '/settings/notifications',
            enabled: true,
        },
        //    { text: 'Integrations', value: 5, path: '/settings/integrations' },
        //    { text: 'API', value: 6, path: '/settings/API' },
        //    { text: 'Billing', value: 7, path: '/settings/billing' },
       ];

    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();

    const btnCancel = (e) => {
        e.preventDefault();
        resetPage();
    };

    const btnResendVerification = (e) => {
        e.preventDefault();
        const params = { userEmail: updateAccount.userEmail ? updateAccount.userEmail : getAccount.userEmail };
            if (token) {
                APIResendOTP(params);
            }
    };

    const btnSave = (e) => {
        e.preventDefault();
        setBtnSavePressed(true);
        setPhoneNumberFix(true);
        if (logoutWarn === false) {
            if (token) {
                APIUpdateAccount(updateAccount);
                setDisplaySnackbar(true);
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    setDisplaySnackbar(false);
                }, 4000);
            }
        }
    };

    const btnLogout = async () => {
        const response = await axios.delete(`${process.env.REACT_APP_WEBSOCKET_LOGOUT}?session=${sessionID}`, null, { credentials: 'same-origin' });
        if (response.status === 200) {
            CloseConnection('logout');
            logout();
            navigate('/login', { replace: true });
        } else {
            // console.log('this to add in snackbar ');
        }
    };

    const btnSaveAndLogout = (e) => {
        e.preventDefault();
            if (token) {
                APIUpdateAccount(updateAccount);
            }
    };

    /// FIRST NAME ONCHANGE ///
    const firstNameOnChange = (value) => {
        setCancelButtonState(false);
        setFirstNameValue(value);
        if (value) {
            if (value === getAccount.userFirstName) {
                setFirstNameInput(false);
                delete updateAccount.userFirstName;
            } else if (value !== getAccount.userFirstName) {
                setUpdateAccount({ ...updateAccount, userFirstName: value });
                setFirstNameInput(true);
            }
        } else if (getAccount.userFirstName === '') {
            delete updateAccount.userFirstName;
            setFirstNameInput(false);
        } else {
            setUpdateAccount({ ...updateAccount, userFirstName: '' });
            setFirstNameInput(true);
        }
    };

    /// LAST NAME ONCHANGE ///
    const lastNameOnChange = (value) => {
        setCancelButtonState(false);
        setLastNameValue(value);
        if (value) {
            if (value === getAccount.userLastName) {
                setLastNameInput(false);
                delete updateAccount.userLastName;
            } else if (value !== getAccount.userLastName) {
                setUpdateAccount({ ...updateAccount, userLastName: value });
                setLastNameInput(true);
            }
        } else if (getAccount.userLastName === '') {
            delete updateAccount.userLastName;
            setLastNameInput(false);
        } else {
            setUpdateAccount({ ...updateAccount, userLastName: '' });
            setLastNameInput(true);
        }
    };

    /// EMAIL ONCHANGE ///
    const emailOnChange = (value) => {
        setCancelButtonState(false);
        setEmailValue(value);
        if (value) {
            if (value === getAccount.userEmail) {
                setEmailInput(false);
                setLogoutWarn(false);
                delete updateAccount.userEmail;
                setEmailErrorMessage('');
            } else if (value !== getAccount.userEmail) {
                if (isValidEmail(value)) {
                    setUpdateAccount({ ...updateAccount, userEmail: value });
                    setEmailInput(true);
                    setLogoutWarn(true);
                    setEmailErrorMessage('');
                } else {
                    setUpdateAccount({ ...updateAccount, userEmail: value });
                    setEmailInput(true);
                    setEmailErrorMessage('Email is invalid');
                }
            }
        } else if (getAccount.userEmail === '') {
            delete updateAccount.userEmail;
            setEmailInput(false);
            setLogoutWarn(false);
            setEmailErrorMessage('Email is required');
        } else {
            setUpdateAccount({ ...updateAccount, userEmail: '' });
            setEmailInput(true);
            setEmailErrorMessage('Email is required');
        }
    };

    /// PHONE NUMBER ONCHANGE ///
    useEffect(() => {
        setCancelButtonState(false);
        setPhoneNumberUpdated(true);
        if (phoneNumberValue) {
            if (phoneNumberValue === getAccount.contactNumber) {
                setPhoneNumberInput(false);
                delete updateAccount.contactNumber;
            } else if (phoneNumberValue !== getAccount.contactNumber) {
                setUpdateAccount({ ...updateAccount, contactNumber: phoneNumberValue });
                setPhoneNumberInput(true);
            }
        } else if (getAccount.contactNumber === '') {
            delete updateAccount.contactNumber;
            setPhoneNumberInput(false);
        } else {
            setUpdateAccount({ ...updateAccount, contactNumber: '', contactCode: '' });
            setPhoneNumberInput(true);
        }
    }, [phoneNumberValue]);

    useEffect(() => {
        if (countryCallingCodeSelected) {
            setCancelButtonState(false);
            setCountryCallingCodeUpdate(true);
            const callingCode = countryCallingCodeSelected.value;
            const matches = callingCode.match(/(\d+)/);
            if (+matches[0] === getAccount.contactCode) {
                delete updateAccount.contactCode;
            } else if (+matches[0] !== getAccount.contactCode) {
                setUpdateAccount({ ...updateAccount, contactCode: +matches[0] });
            }
        }
    }, [countryCallingCodeSelected]);

    /// PROFILE IMAGE ONCHANGE ///
    useEffect(() => {
        setCancelButtonState(false);
        if (output) {
            if (output === getAccount.profileImage) {
                setProfileImageInput(false);
                delete updateAccount.profileImage;
            } else if (profileImageValue !== getAccount.profileImage) {
                setUpdateAccount({ ...updateAccount, profileImage: output });
                setProfileImageInput(true);
            }
        } else if (getAccount.profileImage === '') {
            delete updateAccount.profileImage;
            setProfileImageInput(false);
        }
    }, [output]);

    /// COUNTRY ONCHANGE ///
    useEffect(() => {
        if ((countryUpdated > 0) && (timezoneSelectionList.name)) {
            setCancelButtonState(false);
            if (countrySelectionList.id.length !== undefined) {
                if (countrySelectionList.id === getAccount.countryCode) {
                    if ((timezoneSelectionList.name).includes(getAccount.timezone)) {
                        delete updateAccount.countryCode;
                        setCountriesInput(false);
                    } else {
                        const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                        setUpdateAccount({ ...updateAccount, countryCode: getCountryISO3(countrySelectionList.id), timezone: filteredTimezone });
                        setCountriesInput(true);
                    }
                } else if (countrySelectionList.id !== getAccount.countryCode) {
                    const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                    setUpdateAccount({ ...updateAccount, countryCode: getCountryISO3(countrySelectionList.id), timezone: filteredTimezone });
                    setCountriesInput(true);
                }
            }
        }
    }, [countryUpdated, timezoneSelectionList.name]);

    /// TIMEZONE ONCHANGE ///
    useEffect(() => {
        if (timezoneValue) {
            setCancelButtonState(false);
            if (getAccount.timezone) {
                if ((timezoneSelectionList.name).includes(getAccount.timezone)) {
                    delete updateAccount.timezone;
                    setTimezoneInput(false);
                } else if (!(timezoneSelectionList.name).includes(getAccount.timezone)) {
                    const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                    setUpdateAccount({ ...updateAccount, timezone: filteredTimezone });
                    setTimezoneInput(true);
                }
            } else {
                const filteredTimezone = (timezoneSelectionList.name).substring(0, (timezoneSelectionList.name).indexOf(' ('));
                setUpdateAccount({ ...updateAccount, timezone: filteredTimezone });
                setTimezoneInput(true);
            }
        }
    }, [timezoneValue, timezoneSelectionList.name]);

    /// Enable save button ///
    useEffect(() => {
        if ((firstNameInput === true)
        || (lastNameInput === true)
        || (emailInput === true)
        || (phoneNumberInput === true)
        || updateAccount.contactCode
        || (profileImageInput === true)
        || (countriesInput === true)
        || (timezoneInput === true)) {
            if (phoneNumberInput && !getAccount.contactCode) {
                if (!countryCallingCodeUpdate) {
                    const firstCallingCode = 60;
                    setUpdateAccount({ ...updateAccount, contactCode: firstCallingCode });
                }
            }

            // Only enabling the Save button if email field is not empty
            if (!emailErrorMessage) {
                setSaveButtonstate(true);
            } else {
                setSaveButtonstate(false);
            }
        } else {
            setSaveButtonstate(false);
        }
    }, [firstNameInput, lastNameInput, emailInput, phoneNumberInput, updateAccount.contactCode, profileImageInput, countriesInput, timezoneInput, emailErrorMessage]);

    useEffect(() => {
        if (updateEmailLogout) {
            APIUpdateAccount(updateAccount);
        }
    }, [updateEmailLogout]);

    useEffect(() => {
        setNavigtionClick(1);
        setNavigationState('settings');
    }, []);

    if (name) {
        const arr = name.split(' ');
    }

    const APICalledSearchAssetNGroup = async () => {
    try {
        const timezone = timezones.length > 0 ? timezones.map((x) => ({
            name: x.tzCode,
            code: x.devices.length > 0 ? x.devices[0].sn : '',
        })) : [];
        setTimezoneList(timezone);
    } catch (err) {
        console.log('Timezone retrieval error');
    }
    };

    const getDropdownPosition = (componentHeight) => {
    const fullHeight = windowSize.innerHeight;
    const componentPosition = fullHeight - componentHeight;
    // console.log('componentPosition: ', componentPosition);
    };

    function BoldText({ children, bold }) {
        return (
            <span style={{ fontWeight: bold ? 'bold' : 'normal' }}>
            {children}
            </span>
        );
    }
       return (
           <div className="settings-container">
               <div className="settings-header">
                   <div className="settings-title">
                       <Title title="Settings" subtitle="" titleSize="1.875rem" />
                       {/* <SearchInput containerWidth="20rem" text="Search" /> */}
                   </div>
                   <div className="settings-config-tabs">
                    <ConfigTab color="#FCFCFD" module="settings" types={navigationList} users={totalUsers} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                   </div>
               </div>
               <div className="settings-form-container">
                       <div className="settings-section-header">
                               <Title title="Personal info" subtitle="Update your personal details here." titleSize="1.125rem" subtitleSize="0.875rem" gap="0.25rem" lineHeight="1.75rem" subtitleLineHeight="1.25rem" />
                           <div className="settings-actions">
                               <SecondaryButton
                                 disable={!saveButtonState}
                                 label="Reset"
                                 width="5rem"
                                 height="2.5rem"
                                 fontSize="0.875rem"
                                 onClickFunction={btnCancel}
                               />
                                <PrimaryButton
                                  disable={!saveButtonState}
                                  label="Save"
                                  onClickFunction={btnSave}
                                  height="2.5rem"
                                  fontSize="0.875rem"
                                />
                           </div>
                       </div>
                       <div className="settings-divider" />
                       <div className="settings-content">
                           <div className="settings-content-form">
                               <div className="settings-input-label">
                                   Name
                               </div>
                               <div className="settings-name-container">
                                   <div className="settings-name-field">
                                       <div className="settings-name-label">
                                           First name
                                       </div>
                                       <input
                                         name="firstName"
                                         className="settings-input-box"
                                         placeholder="First name"
                                         value={firstNameInput ? firstNameValue : getAccount.userFirstName}
                                         onChange={(e) => firstNameOnChange(e.target.value)}
                                       />
                                   </div>
                                   <div className="settings-name-field">
                                       <div className="settings-name-label">
                                           Last name
                                       </div>
                                       <input
                                         name="lastName"
                                         className="settings-input-box"
                                         placeholder="Last name"
                                         value={lastNameInput ? lastNameValue : getAccount.userLastName}
                                         onChange={(e) => lastNameOnChange(e.target.value)}
                                       />
                                   </div>
                               </div>
                           </div>
                           <div className="settings-content-form">
                               <div className="settings-input-label">
                                   Email address
                               </div>
                               <div className="settings-email-container">
                                   <TextInput
                                     label="Settings"
                                     placeholder="Enter your email"
                                     setInput={setInput}
                                     input={input}
                                     name="email"
                                     type="text"
                                     error={emailError}
                                     setError={setEmailError}
                                     width="32rem"
                                     emailState={emailState}
                                     onChangeFunction={(e) => emailOnChange(e.target.value)}
                                     value={emailInput ? emailValue : getAccount.userEmail}
                                   />
                                    {emailState === 'sent' ? (
                                        <div className="email-comments-container">
                                            <div className="settings-comments">
                                                We&apos;ve sent an email verification to
                                                {' '}
                                                <BoldText bold>{getAccount.userEmail}</BoldText>
                                                . Please check your inbox and follow the instructions.
                                            </div>
                                            <div className="settings-comments">
                                                Didn&apos;t receive the email?
                                                {' '}
                                            <TextButton label="Resend it now" onClickFunction={btnResendVerification} fontWeight={0} color="#1570EF" backgroundColor="#F9FAFB" />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                        {emailState === 'google' ? (
                                            <div className="email-comments-container">
                                                <div className="settings-comments">
                                                    Your Horizon account is now associated with a
                                                    {' '}
                                                    <BoldText bold>Google account</BoldText>
                                                    . Changing the email address here will disconnect your Google account and disable single sign-on capabilities.
                                                </div>
                                                <div className="settings-comments">
                                                    Manage
                                                    {' '}
                                                    <a style={{ textDecoration: 'none' }} href="google.com">Single Sign-On (SSO).</a>
                                                </div>
                                            </div>
) : (
                                                <div />
                                            )}
                                        </>
)}
                               </div>
                               {emailErrorMessage && (
                                    <div className="invalid-otp-container">
                                        <div className="invalid-otp">
                                            {emailErrorMessage}
                                        </div>
                                    </div>
                                )}
                           </div>
                           <div className="settings-content-form">
                               <div className="settings-input-label" ref={callingCodeRef}>
                                   Phone number
                               </div>
                                   <div className="country-css">
                                        <CountriesCallingCode
                                          list={listCallingCode}
                                          setSelectionList={setCountryCallingCodeSelected}
                                          selectionList={countryCallingCodeSelected}
                                          text="MY"
                                          isImage
                                          stateInputValue={setInputSelectonText}
                                          width="4.6rem"
                                          lowPosition={heightPercentage(callingCodeRef)}
                                          message={message}
                                          setMessage={setMessage}
                                          disabled={false}
                                          value={phoneNumberInput ? phoneNumberValue : getAccount.contactNumber}
                                          initialValue={getAccount.contactCode}
                                          cancelState={cancelButtonState}
                                          phoneNumberUpdated={phoneNumberUpdated}
                                          setPhoneNumberValue={setPhoneNumberValue}
                                          setPhoneNumberInput={setPhoneNumberInput}
                                        />
                                   </div>
                           </div>
                           <div className="settings-content-form">
                               <div className="settings-input-subtitle">
                                   <div className="settings-input-label">
                                       Your photo
                                   </div>
                                   <div className="settings-label-subtitle" ref={labelRef}>
                                       This will be displayed on your profile.
                                   </div>
                               </div>
                               <div className="settings-upload-container" ref={inputRef}>
                                   <Avatar name={name} size="4rem" fontSize="1.5rem" lineHeight="2rem" image={output || getAccount.profileImageUrl} />
                                   <UploadFile
                                     profileImage={profileImage}
                                     setProfileImage={setProfileImage}
                                     output={output}
                                     setOutput={setOutput}
                                   />
                               </div>
                           </div>
                           <div className="settings-content-form">
                               <div className="settings-input-label-countries" ref={countriesSelectorRef}>
                                   Country
                               </div>
                           <div className="inner">
                                    <CountriesWithFlags
                                      list={listCountry}
                                      setSelectionList={setCountrySelectionList}
                                      selectionList={countrySelectionList}
                                      text="Select country"
                                      isImage
                                      stateInputValue={setInputSelectonText}
                                      width="30.5rem"
                                      setSelectedFlag={setSelectedFlag}
                                      lowPosition={heightPercentage(countriesSelectorRef)}
                                      initialValue={getAccount.countryCode}
                                      cancelState={cancelButtonState}
                                      timezoneCheck={getAccount.timezone}
                                      setCountryUpdated={setCountryUpdated}
                                      countryUpdated={countryUpdated}
                                      saveButtonState={saveButtonState}
                                    />
                           </div>
                           </div>
                           <div className="settings-content-form">
                               <div className="settings-input-label" ref={countriesTimezoneRef}>
                                   Timezone
                               </div>
                           <div className="inner">
                                   <CountriesTimezone
                                     list={listTimezone}
                                     setTimezoneSelectionList={setTimezoneSelectionList}
                                     timezoneSelectionList={timezoneSelectionList}
                                     text="Select timezone"
                                     isImage
                                     timezone
                                     stateInputValue={setInputSelectonTextCountry}
                                     width="30.5rem"
                                     selectedFlag={selectedFlag}
                                     lowPosition={heightPercentage(countriesTimezoneRef)}
                                     initialValue={getAccount.timezone}
                                     cancelState={cancelButtonState}
                                     timezoneCheck={getAccount.timezone}
                                     countryUpdated={countryUpdated}
                                     setTimezoneValue={setTimezoneValue}
                                     timezoneValue={timezoneValue}
                                   />
                           </div>
                           </div>
                           <div className="settings-footer">
                               <div className="settings-actions" style={{ justifyItems: 'flex-end' }}>
                                   <SecondaryButton
                                     disable={!saveButtonState}
                                     label="Reset"
                                     width="5rem"
                                     height="2.5rem"
                                     fontSize="0.875rem"
                                     onClickFunction={btnCancel}
                                   />
                                   <PrimaryButton
                                     disable={!saveButtonState}
                                     label="Save"
                                     onClickFunction={btnSave}
                                     height="2.5rem"
                                     fontSize="0.875rem"
                                   />
                               </div>
                           </div>
                       </div>
               </div>
        { displaySnackbar && (
        <div className="notification">
            <SnackBar state={displaySnackbar} setState={setDisplaySnackbar} title="Updated Successfully" subtitile="Your updated details have been successfully saved." module="Update" action="success" />
        </div>
        )}
        { displayResendSnackbar && (
        <div className="notification">
            <SnackBar state={displayResendSnackbar} setState={setDisplayResendSnackbar} title="Verification code sent" subtitile="Check your inbox for the verification code." module="Update" action="success" />
        </div>
        )}
        { (btnSavePressed && logoutWarn) && (
            <div className="modal-container-bg-topleft">
                <Modal switchModalShow={btnSavePressed} setSwitchModalShow={setBtnSavePressed} module="LogoutWarn" setUpdateEmailLogout={setUpdateEmailLogout} />
            </div>
        ) }
           </div>
       );
   };

   export default Profile;
